import TagManager from "react-gtm-module";
import { TAG_MANAGER } from "../config";

function accentsTidy(s) {
  let r = s.toLowerCase();
  const non_asciis = {
    a: "[àáâãäå]",
    ae: "æ",
    c: "ç",
    e: "[èéêë]",
    i: "[ìíîï]",
    n: "ñ",
    o: "[òóôõö]",
    oe: "œ",
    u: "[ùúûűü]",
    y: "[ýÿ]",
  };
  for (let i in non_asciis) {
    r = r.replace(new RegExp(non_asciis[i], "g"), i);
  }
  return r;
}

export function sanitizeString(str) {
  return accentsTidy(
    str
      .toLowerCase()
      .replace(/[^0-9a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024F\s]+/, "")
      .replace(/\s+/g, " ")
      .trim()
      .replace(/ /g, "-")
  );
}

export function getEventLabelPlusMinus(env, type) {
  const title = accentsTidy(
    env.title
      .toLowerCase()
      .replace(/[^0-9a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024F\s]+/, "")
      .replace(/\s+/g, " ")
      .trim()
      .replace(/ /g, "-")
  );
  const qtd =
    type === "mais" ? env.quantity + 1 : Math.max(0, env.quantity) - 1;
  return `${title}:${type}:${qtd}`;
}

export function getEventLabelBuy(envs) {
  const env = envs.find((e) => e.quantity > 0);
  if (!env) return "";
  const title = accentsTidy(
    env.title
      .toLowerCase()
      .replace(/[^0-9a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024F\s]+/, "")
      .replace(/\s+/g, " ")
      .trim()
      .replace(/ /g, "-")
  );
  return `comprar:${title}`;
}

export function getEventLabelFurnitureName(name) {
  const title = accentsTidy(
    name
      .toLowerCase()
      .replace(/[^0-9a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024F\s]+/, "")
      .replace(/\s+/g, " ")
      .trim()
      .replace(/ /g, "-")
  );
  return `comprar:${title}`;
}

export function fireEvent(name, category, action, label) {
  let event = {
    gtmId: TAG_MANAGER,
    dataLayer: {
      event: name,
      eventCategory: category,
      eventAction: action,
    },
  };

  if (typeof label === "string") {
    event.dataLayer.eventLabel = label;
  } else {
    event.dataLayer = { ...event.dataLayer, ...label };
  }
  TagManager.initialize(event);
}
