import React, { useState, useEffect } from "react";
import { Row, Card, CardHeader, CardBody, Col, Button } from "shards-react";
import PageTitle from "../../common/PageTitle";
import FurnitureMeasureForm from "../sub-component/FurnitureMeasureForm";
import { toast } from "react-toastify";
import { copyObject } from "../../../utils/util";
import StepsForm from "./StepsForm";

const FurnitureMeasures = (props) => {
  const STEP = 5;
  const [measuresFiles, setMeasuresFiles] = useState([]);
  const [measuresDetails, setMeasuresDetails] = useState("");
  const [validForm, setValidForm] = useState(true);
  const [hasFuriture, setHasFuriture] = useState(false);

  useEffect(() => {
    if (props.project.furnitureMeasures?.files?.length > 0) {
      setMeasuresFiles(props.project.furnitureMeasures.files);
      setHasFuriture(true);
    }

    if (props.project.furnitureMeasures?.details) {
      setMeasuresDetails(props.project.furnitureMeasures.details);
    }
  }, [props.project]);

  const nextStep = () => {
    if (validForm) {
      const rollback = {
        furnitureMeasures: copyObject(props.project.furnitureMeasures.files),
      };

      props
        .updateStepCompleted({
          furnitureMeasures: {
            ...props.project.furnitureMeasures,
            files: measuresFiles,
            details: measuresDetails,
          },
          completedSteps: props.project.completedSteps,
          _id: props.project._id,
        })
        .then(() => props.callbackReturnView())
        .catch(() => {
          props.project.furnitureMeasures.files = rollback.furnitureMeasures;
          props.project.completedSteps.pop();
        });
    } else {
      toast.error("Todos os campos são obrigatórios para enviar um móvel!", {
        autoClose: 5000,
      });
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col>
          {!hasFuriture && (
            <>
              <h4 style={{ textAlign: "center" }}>
                Você possui algum móvel (Móveis soltos e em marcenaria) para ser
                aproveitado em seu ambiente?
              </h4>
              <h6 style={{ textAlign: "center" }}>
                * Somente utilizaremos os móveis ou objetos enviados por este
                passo, portanto verifique se foi realizado o envio por outro
                caminho e por gentileza envie novamente por aqui para
                validarmos!
              </h6>
              <div style={{ textAlign: "center" }}>
                <Button
                  size="lg"
                  theme="accent"
                  style={{ marginRight: "12px" }}
                  onClick={() => {
                    setMeasuresFiles([
                      {
                        nameMeasure: "",
                        height: "",
                        width: "",
                        length: "",
                        quantity: "",
                        reform: "yes",
                        keep: "yes",
                        files: [],
                      },
                    ]);
                    setValidForm(false);
                    setHasFuriture(!hasFuriture);
                  }}
                >
                  Sim
                </Button>
                <Button
                  size="lg"
                  theme="accent"
                  className="btn-outline-accent"
                  onClick={nextStep}
                >
                  Não
                </Button>
              </div>
            </>
          )}
          {hasFuriture && (
            <>
              <span>
                Neste momento você ira nos informar as medidas e proporções do
                seus móveis. Caso não tenha móveis que deseja manter, você
                poderá pular esta etapa.
              </span>
              <FurnitureMeasureForm
                measures={measuresFiles}
                setMeasures={setMeasuresFiles}
                project={props.project}
                setValidForm={setValidForm}
                validForm={validForm}
              />
              <br />
              <span>
                Deseja adicionar mais alguma observação sobre seus móveis?
              </span>
              <textarea
                rows="4"
                className="form-control"
                placeholder="Observações gerais"
                value={measuresDetails}
                onChange={(e) => {
                  setMeasuresDetails(e.target.value);
                }}
              />
              <br />
            </>
          )}
        </Col>
      </Row>
      <div className="d-flex flex-column  fixed-right-botton wizard-footer">
        <div className="d-flex flex-row align-items-center">
          <button
            type="button"
            className=" ml-4 btn btn-accent"
            onClick={() => props.goBack(3)}
            disabled={props.project.forcedStep?.length}
          >
            Voltar etapa
          </button>
          <button
            type="button"
            className="ml-auto mr-4 btn btn-accent"
            onClick={nextStep}
          >
            Próxima etapa
          </button>
        </div>
        {props.showSummary ? (
          <div className="d-flex align-items-center justify-content-center">
            <Button
              theme="success"
              type="button"
              size="lg"
              className="mt-3"
              onClick={props.onClose}
            >
              Revisar informações
            </Button>
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );
};
export default FurnitureMeasures;
