import React, { useState, useEffect } from "react";
import { Row, Col, Card, FormInput, Button, FormSelect } from "shards-react";
import DropzoneArea from "../../file-manager-cards/DropzoneArea";

import { updateProject } from "../../../actions/project";
import { download } from "../../../utils/util";
import AspectRatioPicture from "../../common/AspectRatioPicture";
import { getImgFullLink } from "../steps/helper";

const FurnitureMeasureForm = (props) => {
  const [filesUploaded, setFilesUploaded] = useState([]);
  const [requiredField, setRequiredField] = useState(false);

  useEffect(() => {
    setFilesUploaded(props.project.furnitureMeasures.files);
  }, []);

  const removeImg = (idx, nImg) => {
    const list = [...props.measures];
    list[idx]["files"].splice(nImg, 1);
    props.project.furnitureMeasures.files = list;
    setFilesUploaded(props.project.furnitureMeasures.files);
    updateProject(props.project._id, props.project);
  };

  const onFileUpload = (fileLinks, index) => {
    setRequiredField(true);
    fileLinks = fileLinks.map((fileLink) => {
      return {
        ...fileLink,
        source: fileLink.source.match(/([^/]*)$/)[0],
        thumbnail: fileLink.thumbnail
          ? fileLink.thumbnail.match(/([^/]*)$/)[0]
          : null,
      };
    });

    const list = [...props.measures];

    if (filesUploaded[index] === undefined) {
      list[index]["files"] = [...fileLinks];
    } else {
      list[index]["files"] = [...filesUploaded[index]["files"], ...fileLinks];
    }

    props.project.furnitureMeasures.files = list;

    setFilesUploaded(props.project.furnitureMeasures.files);

    validateFields();
  };

  const handleInputChange = (e, index) => {
    setRequiredField(true);
    if (e.target.value === "") {
      props.setValidForm(false);
    }
    const { name, value } = e.target;

    const list = [...props.measures];
    list[index][name] = value;

    setFilesUploaded(list);
    props.setMeasures(list);

    setFilesUploaded(props.project.furnitureMeasures.files);
    validateFields();
  };

  const handleAddClick = () => {
    props.setMeasures([
      ...props.measures,
      {
        nameMeasure: "",
        height: "",
        width: "",
        length: "",
        quantity: "",
        reform: "yes",
        keep: "yes",
        files: [],
      },
    ]);
    setFilesUploaded([
      ...props.project.furnitureMeasures.files,
      {
        nameMeasure: "",
        height: "",
        width: "",
        length: "",
        quantity: "",
        reform: "yes",
        keep: "yes",
        files: [],
      },
    ]);
  };

  const handleRemoveClick = (index) => {
    const list = [...props.measures];
    list.splice(index, 1);
    props.setMeasures(list);

    setFilesUploaded(props.project.furnitureMeasures.files);
  };

  const validateFields = () => {
    props.measures.forEach((measure) => {
      if (
        measure.nameMeasure !== "" &&
        measure.height !== "" &&
        measure.width !== "" &&
        measure.length !== "" &&
        measure.quantity !== "" &&
        measure.reform !== "" &&
        measure.keep !== "" &&
        measure.files.length > 0
      ) {
        props.setValidForm(true);
      } else {
        props.setValidForm(false);
      }
    });
  };

  return props.measures.map((measure, idx) => {
    return (
      <div className="mt-3" key={idx}>
        <Row form>
          <Col md="4">
            <label htmlFor="furniture-measure-form-name-input">
              Nome do objeto
            </label>
            <FormInput
              id="furniture-measure-form-name-input"
              key={idx}
              type="text"
              className={"requiredIf"}
              required={requiredField}
              name="nameMeasure"
              value={measure.nameMeasure}
              onChange={(e) => handleInputChange(e, idx)}
            />
          </Col>
          <Col md="2">
            <label htmlFor="furniture-measure-form-height-input">
              Altura (cm)
            </label>
            <FormInput
              id="furniture-measure-form-height-input"
              type="text"
              className={"requiredIf"}
              required={requiredField}
              placeholder="Altura (cm)"
              key={idx}
              name="height"
              value={measure.height}
              onChange={(e) => handleInputChange(e, idx)}
            />
          </Col>
          <Col md="2">
            <label htmlFor="furniture-measure-form-width-input">
              Largura (cm)
            </label>
            <FormInput
              id="furniture-measure-form-name-input"
              type="text"
              className={"requiredIf"}
              required={requiredField}
              placeholder="Largura (cm)"
              key={idx}
              name="width"
              value={measure.width}
              onChange={(e) => handleInputChange(e, idx)}
            />
          </Col>
          <Col md="2">
            <label htmlFor="furniture-measure-form-name-input">
              Comprimento (cm)
            </label>
            <FormInput
              id="furniture-measure-form-length-input"
              type="text"
              className={"requiredIf"}
              required={requiredField}
              placeholder="Comprimento (cm)"
              key={idx}
              name="length"
              value={measure.length}
              onChange={(e) => handleInputChange(e, idx)}
            />
          </Col>
        </Row>
        <Row form className="mt-1">
          <Col md="2">
            <label htmlFor="furniture-measure-form-count-input">
              Quantidade
            </label>
            <FormInput
              id="furniture-measure-form-count-input"
              type="text"
              className={"requiredIf"}
              required={requiredField}
              placeholder="Quantidade"
              key={idx}
              name="quantity"
              value={measure.quantity}
              onChange={(e) => handleInputChange(e, idx)}
            />
          </Col>
          <Col md="2">
            <label htmlFor="furniture-measure-form-can-rebuild-input">
              Podemos reformar?
            </label>
            <FormSelect
              id="furniture-measure-form-can-rebuild-input"
              className={"requiredIf"}
              required={requiredField}
              key={idx}
              name="reform"
              value={measure.reform}
              onChange={(e) => handleInputChange(e, idx)}
            >
              <option value="yes">Sim</option>
              <option value="no">Não</option>
            </FormSelect>
          </Col>
          <Col md="3">
            <label htmlFor="furniture-measure-form-should-keep-input">
              É obrigatório manter este móvel?
            </label>
            <FormSelect
              id="furniture-measure-form-should-keep-input"
              className={"requiredIf"}
              required={requiredField}
              key={idx}
              name="keep"
              value={measure.keep}
              onChange={(e) => handleInputChange(e, idx)}
            >
              <option value="yes">Sim</option>
              <option value="no">Não</option>
            </FormSelect>
          </Col>
        </Row>
        <Row form className="mt-1">
          <Col md="8">
            <DropzoneArea
              idx={idx}
              step="furnitureMeasures"
              projectId={props.project._id}
              callbackHandleDrop={onFileUpload}
            />
          </Col>
          <Col md="4" style={{ display: "flex" }}>
            {props.measures.length !== 1 && (
              <Button
                className="mr-2"
                theme="accent"
                size="sm"
                onClick={() => handleRemoveClick(idx)}
              >
                - Remover móvel
              </Button>
            )}
            {props.measures.length - 1 === idx && (
              <Button theme="accent" size="sm" onClick={handleAddClick}>
                + Adicionar novo móvel
              </Button>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <br />
            {filesUploaded[idx] !== undefined &&
              filesUploaded[idx]["files"].length > 0 && (
                <div className="user-details__user-data border-top border-bottom p-4">
                  <Row>
                    <Col>
                      <h6 className="text-center m-0 mt-2">Arquivos</h6>
                    </Col>
                  </Row>
                  <Row>
                    {filesUploaded[idx]["files"].map((img, nImg) => {
                      return (
                        <Col lg="3" sm="6" key={nImg}>
                          <Card className="card-file">
                            <AspectRatioPicture
                              height="200px"
                              width="200px"
                              isDeletable={true}
                              src={getImgFullLink(
                                img.source,
                                props.project._id
                              )}
                              thumbnail={
                                img.thumbnail
                                  ? getImgFullLink(
                                      img.thumbnail,
                                      props.project._id
                                    )
                                  : null
                              }
                              name={img.name}
                              title={img.name}
                              dowloadPdf={() => {
                                download(
                                  getImgFullLink(img.source, props.project._id)
                                );
                              }}
                              onDeleteClick={() => {
                                removeImg(idx, nImg);
                              }}
                            />
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              )}
          </Col>
        </Row>
        <hr />
      </div>
    );
  });
};
export default FurnitureMeasureForm;
