import React, { useEffect } from "react";

const Merlin = ({ idMerlin }) => {
  useEffect(() => {
    if (idMerlin) {
      // setTimeout(function () {
      //   const checkMerlin = document.querySelectorAll(".merlin-button");
      //   if (checkMerlin) {
      //     document.querySelector(".merlin-button").click();
      //   }
      // }, 25000);
      const merlinScript = document.createElement("script");
      merlinScript.src = "https://scripts.gomerlin.com.br/merlin.js";
      merlinScript.async = true;
      const popupScript = document.createElement("script");

      merlinScript.onload = () => {
        popupScript.textContent = `
        Merlin.Popup.initFromSource("https://app.gomerlin.com.br/popup/${idMerlin}");
      `;
        document.body.appendChild(popupScript);
      };

      document.body.appendChild(merlinScript);

      return () => {
        // Clean up the script when the component unmounts
        document.body.removeChild(merlinScript);
        document.body.removeChild(popupScript);

        const popup = document.querySelectorAll(
          "#merlin-popup-container, .merlin-popup-container"
        );

        if (popup) {
          popup.forEach((e) => {
            e.remove();
          });
        }
      };
    }
  }, []);
  return <></>;
};

export default Merlin;
