import React, { useContext } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Container, Navbar, NavbarBrand } from "shards-react";

import NavbarNav from "./NavbarNav/NavbarNav";
import NavbarToggle from "./NavbarToggle";

import { LAYOUT_TYPES } from "../../../utils/constants";
import { SystemContext } from "../../SystemConfig/SystemContext";

const MainNavbar = ({ layout, stickyTop }) => {
  const { isDefault, company } = useContext(SystemContext);
  const LOGO_LINK = !isDefault
    ? company.logo
    : require("../../../images/La_Decora_principal.jpeg");
  const isHeaderNav = layout === LAYOUT_TYPES.HEADER_NAVIGATION;
  const classes = classNames(
    "main-navbar",
    "bg-white",
    stickyTop && "sticky-top"
  );

  return (
    <div className={classes}>
      <Container fluid={!isHeaderNav || null} className="p-0">
        <Navbar type="light" className="flex-row p-0">
          {isHeaderNav && (
            <NavbarBrand href="#" style={{ lineHeight: "20px" }}>
              <div className="d-table m-auto">
                <img
                  id="main-logo"
                  className="d-inline-block align-top mr-1 ml-3"
                  style={{ maxWidth: "20px" }}
                  src={LOGO_LINK}
                  alt="Ladecora"
                />
                <span className="d-none d-md-inline ml-1">Ladecora</span>
              </div>
            </NavbarBrand>
          )}
          <NavbarNav />
          <NavbarBrand href="#" className="mobile-brand">
            <div className="d-table m-auto">
              <img
                id="main-logo"
                className="d-inline-block align-top mr-1 ml-3"
                style={{ maxHeight: "60px", maxWidth: "170px" }}
                src={LOGO_LINK}
                alt="Ladecora"
              />
            </div>
          </NavbarBrand>
          <NavbarToggle />
        </Navbar>
      </Container>
    </div>
  );
};

MainNavbar.propTypes = {
  /**
   * The layout type where the MainNavbar is used.
   */
  layout: PropTypes.string,
  /**
   * Whether the main navbar is sticky to the top, or not.
   */
  stickyTop: PropTypes.bool,
};

MainNavbar.defaultProps = {
  stickyTop: true,
};

export default MainNavbar;
