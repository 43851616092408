import React from "react";
import PropTypes from "prop-types";
import PictureItem from "./PictureItem";

function Blueprints({ enterprise, onBlueprintClick, selectedItem, isLoading }) {
  function onClick(idx) {
    onBlueprintClick(enterprise.rooms[idx]);
  }

  return (
    <div className="small-container">
      <div className="question-title mb-4 mt-4">
        Seja bem vindo, escolha a planta do imóvel
      </div>

      <div
        className="d-flex align-items-center justify-content-center flex-row"
        style={{ flexWrap: "wrap", flex: 1 }}
      >
        {enterprise.rooms.map((room, idx) => (
          <div
            key={idx}
            className={`ml-3 mr-3 mb-3 ${room.mirrored && "flip-image"}`}
          >
            <PictureItem
              isSelected={selectedItem && room.name === selectedItem.name}
              src={room.img}
              thumbnail={room.img}
              idx={idx}
              onClick={onClick}
              width="300px"
              height="300px"
              text={room.name}
              isLoading={isLoading}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

Blueprints.propTypes = {
  enterprise: PropTypes.object.isRequired,
  selectedItem: PropTypes.object,
  isLoading: PropTypes.bool,
  onBlueprintClick: PropTypes.func,
};

Blueprints.defaultProps = {
  isLoading: false,
};

export default Blueprints;
