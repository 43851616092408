import React, { useState, useEffect } from "react";

import InputMask from "react-input-mask";

import { PhoneNumberUtil } from "google-libphonenumber";

import { getUser } from "../../actions/user";

import { sendEmailContact } from "../../actions/payment";

import { toast } from "react-toastify";

const HistoryPaymentContact = (props) => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  const [user, setUser] = useState({});
  const [rawUser, setRawUser] = useState(null);
  const formValidations = {
    message: { validators: [required], isValid: true },
  };
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getUser()
      .then((res) => {
        setRawUser(res);
        setUser({
          email: res.email,
          name: `${res.profile.firstName} ${res.profile.lastName}`,
          document: res.profile.document,
          phone: formatPhone(res.profile.phone),
          zipcode: res.address.postalCode,
          street: res.address.street,
          number: res.address.number,
          documentType: res.profile.documentType,
          message: "",
        });
      })

      .catch((err) => {
        console.log(err);
      });
    validate();
  }, []);

  function formatPhone(value) {
    try {
      value = phoneUtil.parse(value, "BR");
      if (!phoneUtil.isValidNumberForRegion(value, "BR")) return null;
      return phoneUtil.formatInOriginalFormat(value, "BR");
    } catch (err) {
      return null;
    }
  }

  function required(value) {
    return !!value;
  }

  function validate() {
    let fieldNames = Object.keys(formValidations);
    let validations = fieldNames.reduce((acc, current) => {
      acc[current] = isFieldValid(current);
      return acc;
    }, {});
    applyValidationsToForm(validations);
  }

  function applyValidationsToForm(validations) {
    let keys = Object.keys(formValidations);
    keys.forEach((key) => {
      formValidations[key].isValid = validations[key];
      if (!validations[key]) {
        user[key] = "";
      }
    });
  }

  function isFieldValid(field) {
    let value = user[field];
    let fieldValidations = formValidations[field].validators;
    let result = fieldValidations.reduce((isValid, nextValidation) => {
      return isValid && nextValidation(value);
    }, true);
    return result;
  }

  const notifySucess = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifyError = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  function sendHistoryPaymentContact(e) {
    e.preventDefault();
    validate();
    rawUser.email = user.email;
    rawUser.profile.document = user.document.replace(/[^0-9]/g, "");
    rawUser.profile.phone = `+55${user.phone.replace(/\D/g, "")}`;
    rawUser.address.postalCode = user.zipcode.replace(/[^0-9]/g, "");
    rawUser.address.street = user.street;
    rawUser.address.number = user.number;

    if (
      `${rawUser.profile.firstName} ${rawUser.profile.lastName}` !== user.name
    ) {
      rawUser.profile.firstName = user.name;
      rawUser.profile.lastName = "";
    }
    setIsLoading(true);
    let emailSend = user.email;
    let nameSend = user.name;
    let messageSend = user.message;
    let phoneSend = `+55${user.phone.replace(/\D/g, "")}`;
    sendEmailContact(messageSend, emailSend, phoneSend, nameSend)
      .then((response) => {
        notifySucess(response.msg);
        setIsLoading(false);
      })
      .catch(() => {
        notifyError(
          "Falha ao enviar email, por favor verefique os dados digitados ou tente mais tarde."
        );
        setIsLoading(false);
      });
    props.toggleSendContactModal();
  }

  function handleFormChange(key, value) {
    setUser({
      ...user,
      [key]: value,
    });
  }

  function handleMessageChange(ev) {
    handleFormChange("message", ev.target.value);
  }

  return (
    <form onSubmit={sendHistoryPaymentContact}>
      <div className="form-group">
        <label htmlFor="exampleInputEmail1">Email</label>
        <input
          type="text"
          className="form-control"
          disabled
          name="email"
          id="emailSend"
          value={user.email}
          onChange={handleMessageChange}
          aria-describedby="emailHelp"
          placeholder="Digite seu email"
        />
      </div>
      <div className="form-group">
        <label htmlFor="phoneSend">Telefone</label>
        <InputMask
          mask="(99) 99999-9999"
          class="form-control"
          maskChar={null}
          disabled
          name="phone"
          id="phoneSend"
          onChange={handleMessageChange}
          value={user.phone}
        />
      </div>
      <div className="form-group">
        <label htmlFor="message">Mensagem</label>
        <textarea
          type="text"
          className="form-control"
          required
          name="message"
          id="messageSend"
          onChange={handleMessageChange}
          aria-describedby="emailHelp"
          placeholder="Digite sua mensagem"
        />
      </div>
      <div style={{ display: "flex" }}>
        <button
          type="submit"
          onSubmit={handleMessageChange}
          className="btn btn-custom-primary"
          disabled={isLoading}
          style={{
            backgroundColor: "rgb(10, 119, 90)",
            borderColor: "rgb(10, 119, 90)",
            color: "white",
            marginLeft: "auto",
          }}
        >
          {isLoading ? "Enviado" : "Enviar"}
        </button>
      </div>
    </form>
  );
};

HistoryPaymentContact.propTypes = {};

export default HistoryPaymentContact;
