import React, { useState, useEffect } from "react";
import {
  Row,
  Card,
  CardHeader,
  CardBody,
  Col,
  Button,
  Fade,
} from "shards-react";
import PageTitle from "../../common/PageTitle";
import DropzoneArea from "../../file-manager-cards/DropzoneArea";

import "@ladecora/react-image-lightbox/style.css";
import { updateProject } from "../../../actions/project";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { download, copyObject } from "../../../utils/util";
import AspectRatioPicture from "../../common/AspectRatioPicture";
import StepsForm from "./StepsForm";
import { getImgFullLink } from "./helper";

const Inspiration = (props) => {
  const STEP = 6;
  const [countFiles, setCountFiles] = useState();
  const [files, setFiles] = useState([]);
  const [text, setText] = useState("");
  const [filesUploaded, setFilesUploaded] = useState([]);
  const toggle = () => {
    const rollback = {
      inspirations: copyObject(props.project.inspirations),
    };
    props.project.completedSteps.push(STEP);
    props.project.inspirations.details = text;
    props
      .updateStepCompleted({
        // completedSteps: props.project.completedSteps,
        inspirations: {
          ...props.project.inspirations,
          details: text,
        },
        _id: props.project._id,
      })
      .then(() => {
        props.callbackReturnView();
      })
      .catch(() => {
        props.project.inspirations = rollback.inspirations;
        props.project.completedSteps.pop();
      });
  };

  useEffect(() => {
    if (props.project.inspirations.files.length > 0) {
      setCountFiles(props.project.inspirations.files.length);
      setFiles(props.project.inspirations.files);
      setFilesUploaded(props.project.inspirations.files);
    }
    if (props.project.inspirations.details) {
      let details = props.project.inspirations.details;
      setText(details);
    }
  }, [props.project]);

  const onFileUpload = (fileLinks) => {
    fileLinks = fileLinks.map((fileLink) => {
      return {
        ...fileLink,
        source: fileLink.source.match(/([^/]*)$/)[0],
        thumbnail: fileLink.thumbnail
          ? fileLink.thumbnail.match(/([^/]*)$/)[0]
          : null,
      };
    });
    setCountFiles([...files, ...fileLinks].length);

    props.project.inspirations.files = [...files, ...fileLinks];
    setFiles([...files, ...fileLinks]);

    setFilesUploaded(props.project.inspirations.files);
  };

  const removeImg = (idx) => {
    const data = props.project.inspirations.files;
    data.splice(idx, 1);
    setCountFiles(data.length);
    props.project.inspirations.files = data;
    setFiles(data);
    setFilesUploaded(props.project.inspirations.files);
    updateProject(props.project._id, props.project);
  };

  const nextStep = () => {
    if (text.length > 0) {
      toggle();
    } else {
      toast.error(
        "Para que a gente entenda bem as suas expectativas, esse passo é muito importante. Pedimos que descreva para a gente um pouco mais no campo de texto sobre o que você espera desse espaço, assim como seus desejos e como será o uso.",
        {
          position: "top-right",
          autoClose: 20000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col>
          <h5>
            Gostaria de nos informar algo a mais sobre suas inspirações ou
            detalhes?
          </h5>
          <h6>
            Para criarmos um ambiente personalizado precisamos entender bem as
            suas expectativas e necessidades, portanto esse passo é muito
            importante. Pedimos que descreva para a gente um pouco sobre o que
            você espera desse espaço, assim como seus desejos e como será a
            utilização do ambiente.
            <br />
            Envie para nós algumas fotos sobre o que mais te agrada e inspira no
            dia a dia!
          </h6>
          <span />
          <DropzoneArea
            step="inspirations"
            projectId={props.project._id}
            callbackHandleDrop={onFileUpload}
          />
          {countFiles && (
            <Fade in={!!countFiles} theme="accent">
              Arquivos enviados: <b>{countFiles}</b>
            </Fade>
          )}
          <br />
          {filesUploaded && filesUploaded.length > 0 && (
            <div className="user-details__user-data border-top border-bottom p-4">
              <Row>
                <Col>
                  <h6 className="text-center m-0 mt-2">Arquivos</h6>
                </Col>
              </Row>
              <Row>
                {filesUploaded.map((img, idx) => {
                  return (
                    <Col lg="3" sm="6" key={idx}>
                      <Card className="card-file">
                        <AspectRatioPicture
                          height="200px"
                          width="200px"
                          isDeletable={true}
                          src={getImgFullLink(img.source, props.project._id)}
                          thumbnail={
                            img.thumbnail
                              ? getImgFullLink(img.thumbnail, props.project._id)
                              : null
                          }
                          name={img.name}
                          title={img.name}
                          dowloadPdf={() => {
                            download(
                              getImgFullLink(img.source, props.project._id)
                            );
                          }}
                          onDeleteClick={() => {
                            removeImg(idx);
                          }}
                        />
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </div>
          )}
          <textarea
            value={text}
            onChange={(e) => {
              setText(e.target.value);
            }}
            rows="4"
            className="form-control mt-2"
            placeholder="Conte todos os detalhes de observação ou que gostaria que soubéssemos sobre as suas inspirações"
          />
          <br />
        </Col>
      </Row>
      <br />

      <br />
      <div className="d-flex flex-column  fixed-right-botton wizard-footer">
        <div className="d-flex flex-row align-items-center">
          <button
            type="button"
            className=" ml-4 btn btn-accent"
            onClick={() => props.goBack(4)}
            disabled={props.project.forcedStep?.length}
          >
            Voltar etapa
          </button>
          <button
            type="button"
            className="ml-auto mr-4 btn btn-accent"
            onClick={nextStep}
          >
            Próxima etapa
          </button>
        </div>
        {props.showSummary ? (
          <div className="d-flex align-items-center justify-content-center">
            <Button
              theme="success"
              type="button"
              size="lg"
              className="mt-3"
              onClick={props.onClose}
            >
              Revisar informações
            </Button>
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );
};
export default Inspiration;
