import React, { useEffect } from "react";
import { authWithToken, authenticate } from "../actions/auth";
import { Row, Col } from "shards-react";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import { getAllUrlParams } from "../utils/util";

function AuthThenRedirect() {
  const params = getAllUrlParams();
  useEffect(() => {
    authWithToken(params.token)
      .then((res) => {
        authenticate(res, () => {
          const queryParams = new URLSearchParams(window.location.search);

          if (queryParams.has("token")) {
            queryParams.delete("token");
            queryParams.delete("page");
            if (!params.page) {
              window.open("environments", "_self");
            } else {
              const page = atob(params.page);
              const url = new URL(`${window.origin}/${page}`);
              window.open(`${url.pathname}${url.search}`, "_self");
            }
          }
        });
      })
      .catch(() => {
        window.open("/login", "_self");
      });
  }, []);

  return (
    <div style={{ marginTop: "2%" }}>
      <Row className="mt-5 mb-5">
        <Col
          sm={{ size: 6, order: 2, offset: 5 }}
          xs={{ size: 4, order: 4, offset: 2 }}
          className="mt-5"
        />
      </Row>
      <Row className="mt-5">
        <Col xs="12">
          <LoadingOverlay active={true} spinner={<BounceLoader />} />
        </Col>
      </Row>
      <Row className="mt-5">
        <Col xs="12" className="text-align-center mt-5">
          <p>Olá!</p>
          <p>
            Aguarde um instante e iremos redirecionar você para dar continuidade
            ao processo.
          </p>
          <p>Obrigado!</p>
        </Col>
      </Row>
    </div>
  );
}

AuthThenRedirect.propTypes = {};

export default AuthThenRedirect;
