import React, { useContext } from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import Faq from "react-faq-component";
import { SystemContext } from "../components/SystemConfig/SystemContext";

const EnterprisesFaq = () => {
  const { company } = useContext(SystemContext);

  const data = {
    rows: [
      {
        title: "Como funciona o banco de projetos?",
        content: `Ao comprar o seu imóvel você ganha acesso à plataforma ‘’Banco de Projetos’’. <br /> <br />
        Em poucas etapas você terá acesso ao projeto ideal para o seu ambiente:<br />
        <ul>
          <li>1 - Responda um questionário interativo e prático para descobrirmos qual o tipo do seu apartamento e qual seu estilo de decoração</li>
          <li>2 - Nosso algoritmo vai entender seu estilo e recomendar o projeto ideal para o seu perfil </li>
          <li>3 - Você poderá verificar o projeto do seu ambiente com imagens em 3D e baixar o caderno técnico de execução da decoração, com links para compra de produtos, orientações para a execução, detalhamentos de marcenaria e estimativa total de valores da decoração de cada ambiente </li>
        </ul>
        `,
      },
      {
        title: "E se eu não gostar do projeto apresentado?",
        content:
          "O sistema do ‘’Banco de projetos’’ seleciona o projeto ideal de acordo com suas respostas, porém você terá a liberdade de verificar as outras opções de projeto e selecionar a que mais te agrada.",
      },
      {
        title: "Os valores são atualizados?",
        content:
          "Não, os valores são estimados na época do desenvolvimento do projeto, porém podem sofrer variação de acordo com o período de compra e com a região. Os valores de marcenaria são estimados através de um cálculo médio de material e mão-de-obra.",
      },
      {
        title: "Eu posso utilizar os projetos para a execução da marcenaria?",
        content:
          "Você pode utilizar os projetos como base para a orçamentação da marcenaria com o seu marceneiro de confiança. Porém para a execução, deverão ser feitos os projetos específicos pela empresa responsável.",
      },
      {
        title: "Eu sou obrigada a executar o projeto completo?",
        content:
          "Não, você não é obrigado. Os projetos são executados de acordo com as tendências e estilos propostos, porém, cada cliente fica livre para dar seu toque especial.",
      },
      {
        title:
          "Posso misturar os estilos? (será que não vão pensar que existe a possibilidade de mesclar itens na própria plataforma)",
        content:
          "Pode, porém com cautela, pois os projetos são feitos com todo o cuidado para refletirem o estilo de decoração proposto.",
      },
      {
        title: "Posso responder novamente o questionário?",
        content:
          "Pode, você poderá responder o questionário quantas vezes quiser. O algoritmo do nosso sistema vai calcular suas novas respostas e poderá apresentar projetos diferentes.",
      },
      {
        title: "Quem fez o meu projeto?",
        content:
          "Seu projeto foi desenvolvido por um time de arquitetos e decoradores especializados em decoração de interiores. Foram levados em consideração os estilos propostos e os padrões de acabamentos do empreendimento.",
      },
      {
        title:
          "Pode ter variação de cores e acabamentos do projeto para os produtos reais?",
        content:
          "Sim. Pode ter variação de tonalidades do que foi apresentado em projeto para os produtos reais. Tanto pela tela do equipamento em que foi visualizado o projeto, quanto para os lotes de materiais. Recomendamos que faça uma verificação e aprovação das cores e acabamentos propostos no momento da compra.",
      },
      {
        title:
          "Posso me basear nos quantitativos de projetos para a compra dos produtos e materiais?",
        content:
          "Não. Os quantitativos são estimados com base somente no projeto e não no ambiente real. Portanto recomendamos que o fornecedor faça a medição dos ambientes e o cálculo de cada produto e seus complementos para a execução, já que sempre pode haver diferenças entre a planta versus o executado em obra. *(Pensar em como podemos explicar que sempre é preciso a medição in loco, para não pensarem que o projeto não é nada assertivo)",
      },
      {
        title: "O valor de mão-de-obra está calculado na estimativa de custos?",
        content:
          "Não. Devido à variação de custo de mão-de-obra, não utilizamos este parâmetro em nossa calculadora automatizada.",
      },
      {
        title: "Quanto vou pagar por esse projeto?",
        content: `
      Nada. este projeto é um presente da ${company.name} para você. Ficamos felizes em te ajudar com a decoração do seu lar 
        `,
      },
      {
        title: "Vocês indicam ou fazem a execução da obra?",
        content:
          "Não. O produto que entregamos é o projeto de decoração. Não indicamos os fornecedores para a execução e não executamos as modificações de decoração.",
      },
      {
        title:
          "Posso utilizar as medidas indicadas para a compra de materiais ou contratação de serviços?",
        content:
          "Não. Por se tratar de um projeto feito com a base de arquitetura, as medidas não poderão ser utilizadas para a compra de materiais ou contratação de serviços. Recomendamos que seja feita a medição no local por cada fornecedor contratado.sempre pode haver diferenças entre a planta versus o executado em obra. *(Pensar em como podemos explicar que sempre é preciso a medição in loco, para não pensarem que o projeto não é nada assertivo)",
      },
      {
        title: "O que está incluso no projeto final?",
        content: `
        O material técnico, que contém: <br />
        
        - Posicionamento dos itens de decoração; <br />
        - Posicionamento  de luminárias; <br />
        - Sugestão de  acabamentos de paredes; <br />
        - Projeto de decoração com imagens em  3D; <br />
        - Tabelas técnicas de móveis, decoração, luminárias, papel de parede, cortinas, persianas, tapetes e toda a decoração; <br />
        - Considerações para executar a decoração; <br />
        - Dicas úteis e personalizadas de decoração; <br />
        `,
      },
      {
        title: "É possível solicitar uma revisão do projeto?",
        content:
          "Sim, você pode solicitar até 2 (duas) revisões do projeto em até 20 (vinte) dias corridos após envio da primeira versão de projeto. Caso o cliente não solicite nenhuma revisão dentro do prazo estipulado, o projeto será considerado aprovado pelo sistema e o material técnico será enviado em 3 (três) dias úteis.",
      },
      {
        title:
          "Qual é o formato dos arquivos que fazem parte do material técnico e das imagens em 3D? É necessário ter algum programa especial para abri-los?",
        content:
          "Não é necessário ter programa especial para abrir, pois as imagens serão enviadas em formato JPG e o material técnico em formato PDF.",
      },
      {
        title:
          "É possível abrir as imagens 3D e o material técnico no meu celular?",
        content: "Sim, nosso sistema é responsivo para celular.",
      },
      {
        title: "É possível solicitar uma revisão do projeto?",
        content: "Não",
      },
      {
        title: "Ainda não encontrou o que você estava buscando?",
        content: `Para outras dúvidas relacionadas ao serviço ${company.name} basta entrar em contato pelo e-mail ${company.email.logOrigin}.`,
      },
      {
        title: "É de graça?",
        content: "Sim!",
      },
    ],
  };

  const styles = {
    rowContentColor: "#686868",
    rowTitleColor: "#3C3C3E",
    rowContentPaddingLeft: "10px",
    rowContentPaddingRight: "10px",
    rowContentPaddingTop: "10px",
    rowTitleTextSize: "16px",
    rowContentTextSize: "15px",
  };

  return (
    <Container fluid className="main-content-container px-4">
      <Row className="page-header py-4">
        <Col>
          <PageTitle
            title="FAQ - Banco de Projetos"
            className="text-sm-left mb-3"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card small className="mb-4">
            <CardHeader className="border-bottom" />
            <CardBody className="p-0 pb-3">
              <Col />
              <Col>
                <Faq data={data} styles={styles} />
              </Col>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default EnterprisesFaq;
