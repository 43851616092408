import { API } from "../config";
import { getCookie } from "./auth";

import { requestWrapper } from "../utils/api";

export const validateGift = (company, label, projectTemplateHash) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/gift/${company}/${label}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify({
        projectTemplateHash,
      }),
    }),
    []
  );
};
