import React, { useContext, useState, useRef, useEffect } from "react";

import { SystemContext } from "../components/SystemConfig/SystemContext";
import HowItWorksLp from "../components/landing-page/HowItWorksLp";
import NavbarLp from "../components/landing-page/NavbarLp";
import WhatYouGet from "../components/landing-page/WhatYouGet";
import FaqLp from "../components/landing-page/Faq";
import ProjectsDone from "../components/landing-page/ProjectsDone";
import Price from "../components/landing-page/Price";
import HelpToBuy from "../components/landing-page/HelpToBuy";
import Testimony from "../components/landing-page/Testimony";
import ReactPixel from "react-facebook-pixel";
import Merlin from "../components/landing-page/Merlin";
import VivadecoraInspirations from "../components/landing-page/VivadecoraInspirations";

const LandingPage = () => {
  const { company } = useContext(SystemContext);
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  const mainRef = useRef(null);
  const projectsDoneRef = useRef(null);
  const priceRef = useRef(null);
  const howItWorksRef = useRef(null);

  const scrollToTarget = (ref) => {
    setIsNavExpanded(false);
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  if (company.tracking && company.tracking.facebook) {
    const options = {
      autoConfig: true,
      debug: false,
    };
    ReactPixel.init(`${company.tracking.facebook}`, {}, options);
    ReactPixel.pageView();
  }

  return (
    <div id="mainRef" className={`${company?.label}-class`} ref={mainRef}>
      <div id="info-top">
        <p>Projeto de decoração online</p>
        <hr />
      </div>
      <NavbarLp
        logo={company.logo}
        name={company.name}
        label={company.label}
        mainRef={mainRef}
        priceRef={priceRef}
        projectsDoneRef={projectsDoneRef}
        howItWorksRef={howItWorksRef}
        isNavExpanded={isNavExpanded}
        setIsNavExpanded={setIsNavExpanded}
        scrollToTarget={scrollToTarget}
      />
      {company.idMerlin ? <Merlin idMerlin={company.idMerlin} /> : null}
      <div id="howItWorksRef" ref={howItWorksRef}>
        <HowItWorksLp company={company} />
      </div>
      <div id="WhatYouGet">
        <WhatYouGet />
      </div>
      <div id="projects-done" ref={projectsDoneRef}>
        <ProjectsDone />
      </div>
      <div id="price" ref={priceRef}>
        <Price company={company} priceIncrease={company.priceIncrease} />
      </div>
      <div id="help-to-buy">
        <HelpToBuy company={company} />
      </div>
      {company.label === "vivadecora" ? (
        <div id="vivadecora-inspirations">
          <VivadecoraInspirations />
        </div>
      ) : null}

      <div id="testimony">
        <Testimony />
      </div>
      <div id="faqLp">
        <FaqLp company={company} />
      </div>
    </div>
  );
};

export default LandingPage;
