import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  Row,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from "shards-react";
import classNames from "classnames";
import PageTitle from "../../common/PageTitle";
import Slider from "react-slick";
import { BUCKET_URL } from "../../../config";
import {
  updateProject,
  updateLogDates,
  approveReview,
} from "../../../actions/project";
import { getProjectShareId } from "../../../actions/projectV2";
import { toast, ToastContainer } from "react-toastify";
import PropTypes from "prop-types";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import "@ladecora/react-image-lightbox/style.css";

import { updateCommentInProjectReview } from "../../../actions/project";

import ReviewImage from "./ReviewImage";
import { copyObject } from "../../../utils/util";
import { PROJECT_PROGRESS_STATUS } from "../../../utils/constants";
import { debounce } from "../../../utils/throttle";
import { showReviewProjects } from "../../../utils/util";
import Chat from "../../Chat/Chat";
import "./review.scss";
import { ShowLightbox } from "../../common/ShowLightbox";
import Moment from "react-moment";
import { limitDateFunction } from "../../../services/project";
import ProductList from "../sub-component/ProductList";
import ShareModal from "./ShareModal";
import { SystemContext } from "../../SystemConfig/SystemContext";

const Review = (props) => {
  const { LOCALES } = useContext(SystemContext);
  const tabs = { 0: "preview", 1: "firstReview", 2: "secondReview" };
  const updateCommentsInReview = useCallback(
    debounce((data) => {
      const { projectId, review, fileId, comment } = data;
      updateCommentInProjectReview(projectId, review, fileId, comment).then(
        () => {}
      );
    }, 800),
    []
  );
  const [files, setFiles] = useState([]);
  const [filesFirstReview, setFilesFirstReview] = useState([]);
  const [filesSecondReview, setFilesSecondReview] = useState([]);
  const [projectId, setProjectId] = useState("");
  const [project, setProject] = useState();
  const [lightbox, setLightbox] = useState({
    open: false,
    index: 0,
    images: [],
  });
  const [valueTab, setValueTab] = useState(0);
  const [readOnly, setReadOnly] = useState(false);
  const [modalChooseProject, setModalChooseProject] = useState(false);
  const [openModalRequestRevision, setOpenModalRequestRevision] =
    useState(false);
  const [chatOpened, setChatOpened] = useState(false);
  const [suggestionsEnabled, setSuggestionsEnabled] = useState(false);

  const [tableData, setTableData] = useState({
    table: {
      pageSizeOptions: [5, 10, 15, 20, 25, 30],
      pageSize: 5,
      data: [],
    },
  });
  const [customImg, setCustomImg] = useState(true);

  const [modalComment, setModalComment] = useState({
    show: false,
    likeComments: "",
    dislikeComments: "",
  });
  const [showShareModal, setShowShareModal] = useState(false);

  const [furnitures, setFurnitures] = useState([]);

  useEffect(() => {
    let furnitures = [];
    if (props.project && props.project.finalProject) {
      if (
        valueTab === 0 &&
        props.project.finalProject.preview &&
        props.project.finalProject.preview.furnitures
      ) {
        furnitures = props.project.finalProject.preview.furnitures;
      } else if (
        valueTab === 1 &&
        props.project.finalProject.firstReview &&
        props.project.finalProject.firstReview.furnitures
      ) {
        furnitures = props.project.finalProject.firstReview.furnitures;
      } else if (
        valueTab === 2 &&
        props.project.finalProject.secondReview &&
        props.project.finalProject.secondReview.furnitures
      ) {
        furnitures = props.project.finalProject.secondReview.furnitures;
      }
    }
    setFurnitures(furnitures);
  }, [valueTab]);

  useEffect(() => {
    if (
      props.project.status === "waiting_quality" ||
      props.project.status === "reviewing_quality" ||
      props.project.status === "customer-reproved" ||
      props.project.status === "waiting_render_approved" ||
      props.project.status === "waiting_render_reproved"
    ) {
      setReadOnly(true);
    }
    let stepsProjectToShow = showReviewProjects(props.project);

    setFiles(props.project.finalProject.preview.files);
    /*  eslint-disable */
    if (
      props.project.finalProject.firstReview.files != "" &&
      stepsProjectToShow.firstReview
    ) {
      setFilesFirstReview(props.project.finalProject.firstReview.files);
    }
    if (
      props.project.finalProject.secondReview.files != "" &&
      stepsProjectToShow.secondReview
    ) {
      setFilesSecondReview(props.project.finalProject.secondReview.files);
    }

    if (
      isReviewApproved("2º Revisão") ||
      isReviewApproved("secondReview") ||
      (!isReadOnly() && props.project.finalProject.secondReview.lastUpdate)
    ) {
      setValueTab(2);
      setTableData({
        table: {
          ...tableData.table,
          data: props.project.finalProject.secondReview.furnitures,
        },
      });
      props.project.finalProject.secondReview.furnitures;
    } else if (
      isReviewApproved("1º Revisão") ||
      isReviewApproved("firstReview") ||
      (!isReadOnly() && props.project.finalProject.firstReview.lastUpdate)
    ) {
      setValueTab(1);
      setTableData({
        table: {
          ...tableData.table,
          data: props.project.finalProject.firstReview.furnitures,
        },
      });
    } else if (
      isReviewApproved("Proposta Inicial") ||
      isReviewApproved("preview") ||
      (!isReadOnly() && props.project.finalProject.preview.lastUpdate)
    ) {
      setValueTab(0);
      setTableData({
        table: {
          ...tableData.table,
          data: props.project.finalProject.preview.furnitures,
        },
      });
    } else {
      setTableData({
        table: {
          ...tableData.table,
          data: props.project.finalProject.preview.furnitures,
        },
      });
    }

    /*  eslint-disable */
    setProjectId(props.project._id);
    setProject(props.project);
  }, [props.project]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const customSettings = {
    className: "center slider variable-width",
    centerMode: true,
    infinite: true,
    centerPadding: "8px",
    slidesToShow: 1,
    speed: 500,
    dots: true,
    variableWidth: true,
  };

  const requestRevision = (ev) => {
    if (suggestionsEnabled) {
      let el = document
        .querySelector(".MuiTab-textColorPrimary.Mui-selected")
        .getAttribute("data-tab-detail");
      if (el !== undefined) {
        const rollback = {
          finalProject: JSON.parse(JSON.stringify(props.project.finalProject)),
          status: props.project.status,
          reprovedMsg: props.project.reprovedMsg,
        };

        props.project.finalProject.secondReview.details = "";

        if (props.project.status === "second_review") {
          props.project.status = "waiting_render_reproved";
        } else {
          props.project.status = "waiting_confirm_review";
        }

        props.project.reprovedMsg =
          props.project.finalProject.secondReview.details;

        props.project.finalProject.preview.files = files;
        props.project.finalProject.firstReview.files = filesFirstReview;
        props.project.finalProject.secondReview.files = filesSecondReview;

        setProject({ ...props.project });
        // setSuggestionsEnabled(false);
        Promise.all([
          updateLogDates(props.project._id, props.project.status),
          updateProject(projectId, {
            finalProject: { ...props.project.finalProject },
            reprovedMsg: props.project.reprovedMsg,
            status: props.project.status,
          }),
        ])
          .then(() => {
            setSuggestionsEnabled(false);
            toast.success("Solicitação enviada com sucesso!", {
              autoClose: 5000,
            });
          })
          .catch((err) => {
            props.project.finalProject = rollback.finalProject;
            props.project.reprovedMsg = rollback.reprovedMsg;
            props.project.status = rollback.status;
            setProject({ ...props.project });
          });
      }
      setCustomImg(true);
    } else {
      toggleRequestRevisionModal();
      setCustomImg(false);
    }
  };

  const approveProject = () => {
    toggleModalChooseProject();
  };

  const nextStep = (projectSelected = "Preview") => {
    const rollback = {
      status: props.project.status,
      finalProject: copyObject(props.project.finalProject),
    };

    Promise.all([
      updateLogDates(props.project._id, "waiting_render_approved"),
      approveReview(
        props.project._id,
        PROJECT_PROGRESS_STATUS.asTitle()[projectSelected]
      ),
    ])
      .then((res) => {
        props.callbackReturnView(res[1]);
        toggleModalChooseProject();
        toast.success(
          `${PROJECT_PROGRESS_STATUS.asTitle()[projectSelected]} aprovada`,
          { autoClose: 5000 }
        );
      })
      .catch((err) => {
        props.project.finalProject = rollback.finalProject;
        props.project.status = rollback.status;
      });
  };

  const selectApprovedProject = (projectSelected) => {
    nextStep(projectSelected);
  };

  const handleChange = (img, comment) => {
    const review = tabs[valueTab];
    setFiles(
      files.map((file) => {
        return file._id === img._id
          ? {
              ...file,
              likeComments: comment.likeComments,
              dislikeComments: comment.dislikeComments,
            }
          : file;
      })
    );
    updateCommentsInReview({
      projectId: props.project._id,
      review,
      fileId: img._id,
      comment,
    });
  };

  const handleChangeFirstR = (img, comment) => {
    const review = tabs[valueTab];
    setFilesFirstReview(
      filesFirstReview.map((file) => {
        return file._id === img._id
          ? {
              ...file,
              likeComments: comment.likeComments,
              dislikeComments: comment.dislikeComments,
            }
          : file;
      })
    );
    updateCommentsInReview({
      projectId: props.project._id,
      review,
      fileId: img._id,
      comment,
    });
  };

  const handleChangeSecondR = (img, comment) => {
    const review = tabs[valueTab];
    setFilesSecondReview(
      filesSecondReview.map((file) => {
        return file._id === img._id
          ? {
              ...file,
              likeComments: comment.likeComments,
              dislikeComments: comment.dislikeComments,
            }
          : file;
      })
    );
    updateCommentsInReview({
      projectId: props.project._id,
      review,
      fileId: img._id,
      comment,
    });
  };

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
    setSuggestionsEnabled(false);
    setTableData({
      table: {
        ...tableData.table,
        data: props.project.finalProject[tabs[newValue]].furnitures,
      },
    });
  };

  const sendLightbox = (data, idx) => {
    if (Array.isArray(data)) {
      setLightbox({
        ...lightbox,
        images: data,
        open: true,
        index: idx,
      });
    } else {
      setLightbox({
        ...lightbox,
        images: [data],
        open: true,
        index: 0,
      });
    }
  };

  const toggleRequestRevisionModal = () => {
    setOpenModalRequestRevision(!openModalRequestRevision);
    setSuggestionsEnabled(true);
  };

  const toggleModalChooseProject = () => {
    setModalChooseProject(!modalChooseProject);
  };

  const onNewMessages = () => {};

  const haveQuestion = () => {
    setSuggestionsEnabled(false);
    setOpenModalRequestRevision(false);
    setChatOpened(true);
    setCustomImg(true);
  };

  const disabledButton = () => {
    return (
      project &&
      (project.status === "waiting_render_approved" ||
        project.status === "waiting_render_reproved" ||
        project.status === "waiting_confirm_review" ||
        project.status === "customer-reproved" ||
        project.status === "waiting_quality" ||
        project.status === "reviewing_quality")
    );
  };

  const displayReviewMsg = () => {
    return (
      project &&
      (project.status === "customer-reproved" ||
        project.status === "waiting_confirm_review" ||
        project.status === "waiting_quality" ||
        project.status === "reviewing_quality")
    );
  };

  function onChatToggle(value) {
    if (value !== chatOpened) {
      setChatOpened(value);
    }
  }

  function isReviewApproved(name) {
    return (
      props.project &&
      props.project.finalProject.approvedProject === name &&
      ![
        "preview",
        "first_review",
        "second_review",
        "waiting_confirm_review",
        "waiting_user_feedback",
        "customer-reproved",
      ].includes(props.project.status)
    );
  }

  function isReadOnly() {
    return (
      props.project &&
      (props.project.status === "waiting_render_approved" ||
        props.project.status === "waiting_render_reproved" ||
        props.project.status === "waiting_confirm_review" ||
        props.project.status === "customer-reproved" ||
        props.project.status === "waiting_quality" ||
        props.project.status === "reviewing_quality")
    );
  }

  function onShareClick() {
    setShowShareModal(true);
    if (!props.project.shareId)
      getProjectShareId(projectId).then((res) =>
        setShowShareModal(res.shareId)
      );
    else setShowShareModal(props.project.shareId);
  }

  return (
    <React.Fragment>
      <ToastContainer autoClose={false} />
      {lightbox.open && (
        <ShowLightbox lightbox={lightbox} setLightbox={setLightbox} />
      )}

      <Row className="mobile-review">
        <Col>
          {displayReviewMsg() && (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <div
                alt=""
                className="img-review"
                style={{
                  backgroundImage: `url("${require("../../../images/review.PNG")}`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                }}
              />

              <div className="d-flex flex-column justify-content-center align-items-center text-center mt-3">
                <h3>
                  {project?.status === "waiting_confirm_review"
                    ? "Informações da revisão em análise"
                    : "Seu ambiente está em revisão"}
                </h3>
                {limitDateFunction(
                  props.project.logDates,
                  props.project.status,
                  props.project.forcedStep
                ) !== "" && (
                  <>
                    <h5>Data de entrega:</h5>
                    <Moment format="DD/MM/YYYY">
                      {limitDateFunction(
                        props.project.logDates,
                        props.project.status,
                        props.project.forcedStep
                      ).toString()}
                    </Moment>
                  </>
                )}
              </div>

              <div className="mt-5 pb-2 text-center">
                <h4 style={{ color: "var(--newbackground-color)" }}>
                  Versão anterior
                </h4>
              </div>
            </div>
          )}

          <div className="mt-0">
            <div md="12" style={{ textAlign: "center" }}>
              <div>
                <ThemeProvider theme={theme}>
                  <AppBar position="static" color="default">
                    <Tabs
                      value={valueTab}
                      onChange={handleChangeTab}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="fullWidth"
                      aria-label="full width tabs example"
                      centered
                    >
                      <Tab
                        label="Proposta Inicial"
                        data-tab-detail="preview"
                        {...a11yProps(0)}
                      />
                      {/*  eslint-disable */}
                      {filesFirstReview != "" ? (
                        <Tab
                          label="1º Revisão"
                          data-tab-detail="first"
                          {...a11yProps(1)}
                        />
                      ) : (
                        ""
                      )}
                      {filesSecondReview != "" ? (
                        <Tab
                          label="2º Revisão"
                          data-tab-detail="second"
                          {...a11yProps(2)}
                        />
                      ) : (
                        ""
                      )}
                      {/* eslint-disable */}
                    </Tabs>
                  </AppBar>
                  <TabPanel value={valueTab} index={0} dir={theme.direction}>
                    {isReviewApproved("Proposta Inicial") ||
                    isReviewApproved("preview") ? (
                      <div>
                        <h3 style={{ color: "#00c853" }}>Aprovado</h3>
                      </div>
                    ) : null}
                    <Slider
                      {...(customImg && files.length > 1
                        ? customSettings
                        : settings)}
                    >
                      {files.map((file, idx) => {
                        const imgLink =
                          BUCKET_URL +
                          "/projects/" +
                          projectId +
                          "/preview/" +
                          file.file;
                        return (
                          <ReviewImage
                            key={idx}
                            file={file}
                            disabled={!suggestionsEnabled}
                            projectId={projectId}
                            onChange={handleChange}
                            link={imgLink}
                            onImageClick={() =>
                              sendLightbox(
                                files.map(
                                  (elem) =>
                                    BUCKET_URL +
                                    "/projects/" +
                                    projectId +
                                    "/preview/" +
                                    elem.file
                                ),
                                idx
                              )
                            }
                            customImg={customImg && files.length > 1}
                            setModalComment={setModalComment}
                          />
                        );
                      })}
                    </Slider>
                  </TabPanel>
                  <TabPanel value={valueTab} index={1} dir={theme.direction}>
                    {isReviewApproved("1º Revisão") ||
                    isReviewApproved("firstReview") ? (
                      <div>
                        <h3 style={{ color: "#00c853" }}>Aprovado</h3>
                      </div>
                    ) : null}

                    <Slider
                      {...(customImg && filesFirstReview.length > 1
                        ? customSettings
                        : settings)}
                    >
                      {filesFirstReview.map((file, idx) => {
                        const imgLink =
                          BUCKET_URL +
                          "/projects/" +
                          projectId +
                          "/firstReview/" +
                          file.file;
                        return (
                          <ReviewImage
                            key={idx}
                            file={file}
                            disabled={!suggestionsEnabled}
                            projectId={projectId}
                            onChange={handleChangeFirstR}
                            link={imgLink}
                            onImageClick={() =>
                              sendLightbox(
                                filesFirstReview.map(
                                  (elem) =>
                                    BUCKET_URL +
                                    "/projects/" +
                                    projectId +
                                    "/firstReview/" +
                                    elem.file
                                ),
                                idx
                              )
                            }
                            customImg={customImg && filesFirstReview.length > 1}
                            setModalComment={setModalComment}
                          />
                        );
                      })}
                    </Slider>
                  </TabPanel>
                  <TabPanel value={valueTab} index={2} dir={theme.direction}>
                    {isReviewApproved("2º Revisão") ||
                    isReviewApproved("secondReview") ? (
                      <div>
                        <h3 style={{ color: "#00c853" }}>Aprovado</h3>
                      </div>
                    ) : null}
                    <Slider
                      {...(customImg && filesSecondReview.length > 1
                        ? customSettings
                        : settings)}
                    >
                      {filesSecondReview.map((file, idx) => {
                        const imgLink =
                          BUCKET_URL +
                          "/projects/" +
                          projectId +
                          "/secondReview/" +
                          file.file;
                        return (
                          <ReviewImage
                            key={idx}
                            file={file}
                            disabled={!suggestionsEnabled}
                            projectId={projectId}
                            onChange={handleChangeSecondR}
                            link={imgLink}
                            onImageClick={() =>
                              sendLightbox(
                                filesSecondReview.map(
                                  (elem) =>
                                    BUCKET_URL +
                                    "/projects/" +
                                    projectId +
                                    "/secondReview/" +
                                    elem.file
                                ),
                                idx
                              )
                            }
                            customImg={
                              customImg && filesSecondReview.length > 1
                            }
                            setModalComment={setModalComment}
                          />
                        );
                      })}
                    </Slider>
                  </TabPanel>
                </ThemeProvider>
              </div>
              <br />
              <br />
              {!disabledButton() && (
                <ul className="text-left">
                  <li>
                    Se o ambiente estiver de acordo, clique em "Aprovar
                    ambiente" para que possamos produzir o seu caderno técnico
                    com os detalhes finais. Caso haja mais de uma revisão,
                    selecione a revisão escolhida antes de aprovar.
                  </li>
                  <li>
                    Caso você queira alguma modificação no ambiente, clique em
                    "Iniciar revisão" e comente as fotos do seu ambiente, após
                    comentar clique em confirmar revisão.
                  </li>
                  <li>
                    Se precisar tirar alguma dúvida sobre a assessoria, clique
                    no botão do chat que em até 1 dia útil iremos responder.
                  </li>
                </ul>
              )}
              <div
                style={{ float: "center" }}
                className="mt-2 mobile-bottom-buttons"
              >
                {!suggestionsEnabled && !disabledButton() && (
                  <Button size="lg" theme="accent" onClick={approveProject}>
                    Aprovar ambiente
                  </Button>
                )}
                {suggestionsEnabled ? (
                  <Button
                    size="lg"
                    theme="accent"
                    className="btn-outline-accent ml-2"
                    onClick={() => {
                      setSuggestionsEnabled(false);
                      setCustomImg(true);
                    }}
                  >
                    Voltar
                  </Button>
                ) : null}
                {((props.project.status === "preview" && valueTab === 0) ||
                  (props.project.status === "first_review" &&
                    valueTab === 1)) &&
                  !disabledButton() && (
                    <Button
                      theme="accent"
                      size="lg"
                      className={`${
                        !suggestionsEnabled ? "btn-outline-accent" : ""
                      } ml-2`}
                      onClick={requestRevision}
                    >
                      {suggestionsEnabled
                        ? "Confirmar Revisão"
                        : "Iniciar revisão"}
                    </Button>
                  )}
              </div>
            </div>
            <div className="mt-5">
              <Button
                size="lg"
                theme="accent"
                disabled={showShareModal === true}
                className="ml-auto mr-auto d-flex align-items-center justify-content-center"
                onClick={onShareClick}
              >
                <i style={{ fontSize: "25px" }} className="material-icons mr-3">
                  share
                </i>
                Compartilhar
                {showShareModal === true ? "..." : null}
              </Button>
            </div>
            <div className="productlist-mobile">
              {furnitures?.length > 0 && (
                <ProductList
                  products={furnitures}
                  projectId={props.project._id}
                />
              )}
            </div>
          </div>
        </Col>
      </Row>

      <Modal
        size="lg"
        animation={true}
        open={openModalRequestRevision}
        toggle={toggleRequestRevisionModal}
      >
        <ModalHeader toggle={toggleRequestRevisionModal}>
          Por favor, forneça mais detalhes para a revisão.
        </ModalHeader>
        <ModalBody
          className="modalBodyRevisaò"
          style={{ maxHeight: "80vh", overflow: "auto" }}
        >
          <p style={{ textAlign: "center" }}>
            Caso deseje conversar com um especialista ou ainda tenha alguma
            dúvida, clique no botão "Tirar Dúvidas".
            <br />
            Caso não tenha nenhuma dúvida, você pode enviar suas observações
            sobre as imagens nos campos abaixo das mesmas.
            <br />
          </p>
          <div className="center text-center mt-2 mobile-bottom-buttons">
            <Button
              size="lg"
              theme="accent"
              className="btn-outline-accent ml-2"
              onClick={() => haveQuestion()}
            >
              Tirar Dúvidas
            </Button>
            <Button
              theme="accent"
              size="lg"
              className="btn-accent ml-2"
              onClick={toggleRequestRevisionModal}
            >
              Não Tenho Dúvidas
            </Button>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        size="lg"
        animation={true}
        open={modalChooseProject}
        toggle={toggleModalChooseProject}
      >
        <ModalHeader toggle={toggleModalChooseProject}>
          Aprovar ambiente.
        </ModalHeader>
        <ModalBody
          className="modalBodyRevisaò"
          style={{ maxHeight: "80vh", overflow: "auto" }}
        >
          <Row>
            <p>
              Atenção! Ao realizar a aprovação, iniciaremos a montagem do
              caderno técnico e você não poderá solicitar mais nenhuma revisão.
              Você tem certeza de sua aprovação?
            </p>
          </Row>
          <Row>
            {files.map((file, idx) => {
              const classes = classNames(
                "mb-3",
                "file-manager__item",
                "file-manager__item--selected",
                "approve-project"
              );
              if (idx === 0) {
                return (
                  <Col key={idx}>
                    <Card small className={classes}>
                      <CardBody className="text-center file-manager__item-preview px-0 pb-0 pt-4">
                        <span className="file-manager__item-size ml-auto">
                          Proposta Inicial
                        </span>
                        <br />
                        <img
                          src={
                            BUCKET_URL +
                            "/projects/" +
                            projectId +
                            "/preview/" +
                            file.file
                          }
                          alt={file.comments}
                          height="150"
                          width="150"
                          className={"mb-2"}
                          onClick={() =>
                            sendLightbox(
                              BUCKET_URL +
                                "/projects/" +
                                projectId +
                                "/preview/" +
                                file.file
                            )
                          }
                        />
                      </CardBody>
                      <CardFooter>
                        <div className="center text-center">
                          <Button
                            theme="accent"
                            size="sm"
                            onClick={() => {
                              selectApprovedProject("preview");
                            }}
                          >
                            Escolher Proposta Inicial
                          </Button>
                        </div>
                      </CardFooter>
                    </Card>
                  </Col>
                );
              }
            })}
            {filesFirstReview.map((file, idx) => {
              const classes = classNames(
                "mb-3",
                "file-manager__item",
                "file-manager__item--selected",
                "approve-project"
              );
              if (idx === 0) {
                return (
                  <Col key={idx}>
                    <Card small className={classes}>
                      <CardBody className="text-center file-manager__item-preview px-0 pb-0 pt-4">
                        <span className="file-manager__item-size ml-auto">
                          1º Revisão
                        </span>
                        <br />
                        <img
                          src={
                            BUCKET_URL +
                            "/projects/" +
                            projectId +
                            "/firstReview/" +
                            file.file
                          }
                          alt={file.comments}
                          height="150"
                          width="150"
                          className={"mb-2"}
                          onClick={() =>
                            sendLightbox(
                              BUCKET_URL +
                                "/projects/" +
                                projectId +
                                "/firstReview/" +
                                file.file
                            )
                          }
                        />
                      </CardBody>
                      <CardFooter>
                        <div className="center text-center">
                          <Button
                            theme="accent"
                            size="sm"
                            onClick={() => {
                              selectApprovedProject("first_review");
                            }}
                          >
                            Escolher 1º Revisão
                          </Button>
                        </div>
                      </CardFooter>
                    </Card>
                  </Col>
                );
              }
            })}
            {filesSecondReview.map((file, idx) => {
              const classes = classNames(
                "mb-3",
                "file-manager__item",
                "file-manager__item--selected",
                "approve-project"
              );
              if (idx === 0) {
                return (
                  <Col key={idx}>
                    <Card small className={classes}>
                      <CardBody className="text-center file-manager__item-preview px-0 pb-0 pt-4">
                        <span className="file-manager__item-size ml-auto">
                          2º Revisão
                        </span>
                        <br />
                        <img
                          src={
                            BUCKET_URL +
                            "/projects/" +
                            projectId +
                            "/secondReview/" +
                            file.file
                          }
                          alt={file.comments}
                          height="150"
                          width="150"
                          className={"mb-2"}
                          onClick={() =>
                            sendLightbox(
                              BUCKET_URL +
                                "/projects/" +
                                projectId +
                                "/secondReview/" +
                                file.file
                            )
                          }
                        />
                      </CardBody>
                      <CardFooter>
                        <div className="center text-center">
                          <Button
                            theme="accent"
                            size="sm"
                            onClick={() => {
                              selectApprovedProject("second_review");
                            }}
                          >
                            Escolher 2º Revisão
                          </Button>
                        </div>
                      </CardFooter>
                    </Card>
                  </Col>
                );
              }
            })}
          </Row>
        </ModalBody>
      </Modal>

      <Modal
        size="lg"
        open={modalComment.show}
        toggle={() => setModalComment({ ...modalComment, show: false })}
      >
        <ModalHeader
          className="text-right"
          toggle={() => setModalComment({ ...modalComment, show: false })}
        />
        <ModalBody>
          <Row className="review-image-comments">
            <Col md="6" xs="12" className="like-comment">
              {modalComment.likeComments ? (
                <>
                  <h6>O que gostou.</h6>
                  {modalComment.likeComments}
                </>
              ) : (
                ""
              )}
            </Col>
            <Col md="6" xs="12" className="dislike-comment">
              {modalComment.dislikeComments ? (
                <>
                  <h6>O que não gostou.</h6>
                  {modalComment.dislikeComments}
                </>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      {showShareModal && showShareModal !== true ? (
        <ShareModal
          shareId={showShareModal}
          onClose={() => setShowShareModal()}
        />
      ) : null}

      <Chat
        onNewMessages={onNewMessages}
        senderId={props.project._id}
        show={true}
        chatOpened={chatOpened}
        onChatToggle={onChatToggle}
        readOnly={readOnly}
      />
    </React.Fragment>
  );
};
export default Review;

//TODO Create component out of this
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const theme = createTheme({
  overrides: {
    // Style sheet name
    MuiTab: {
      root: {
        "&$selected": {
          textColorPrimary: {
            color: `${getComputedStyle(
              document.documentElement
            ).getPropertyValue("--newbackground-color")} !important`,
          },
        },
      },
      wrapper: {
        selected: {
          color: getComputedStyle(document.documentElement).getPropertyValue(
            "--newbackground-color"
          ),
        },
        color: getComputedStyle(document.documentElement).getPropertyValue(
          "--newbackground-color"
        ),
      },
      textColorPrimary: {
        selected: {
          color: getComputedStyle(document.documentElement).getPropertyValue(
            "--newbackground-color"
          ),
        },
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: getComputedStyle(
          document.documentElement
        ).getPropertyValue("--newbackground-color"),
      },
    },
  },
});
