import React from "react";
import { Button, Row, Col } from "shards-react";
import { useHistory } from "react-router-dom";
import Faq from "react-faq-component";

const FaqLp = ({ company }) => {
  const history = useHistory();
  const data = {
    rows: [
      {
        title: `O tipo e o tamanho do ambiente influenciam no preço do serviço?`,
        content: `Não, o valor de R$ ${
          299 + company.priceIncrease
        } é válido para qualquer cômodo, independentemente do tamanho.
        `,
      },
      {
        title: `Como faço para comprar o projeto?`,
        content: `Você pode selecionar o ambiente que deseja decorar e seguir para o carrinho de compras, onde será necessário concluir seu cadastro na plataforma, assim será direcionado para a compra via cartão de crédito ou PIX. Após a confirmação de compra, o ambiente selecionado será liberado na sua plataforma para início da jornada de decoração.`,
      },
      {
        title:
          "Como é calculado o custo da assessoria para ambientes integrados?",
        content: `
        No caso de ambientes integrados, os valores serão cobrados separadamente de acordo com o ambiente e a função do mesmo. Por exemplo:
        <ul>
        <li>Cozinha com sala de jantar e sala de estar = 3 ambientes;</li>
        <li>Sala de jantar com sala de estar = 2 ambientes;</li>
        <li>Quarto com closet = 2 ambientes;</li>
        <li>Cozinha com área de serviço = 2 ambientes.</li>
        </ul>
        `,
      },
      {
        title: "Quais os tipos de ambientes que o serviço abrange?",
        content: `
        O ${company.name} pode ser utilizado em qualquer canto da sua casa que você deseje decorar! Nossos decoradores estão à disposição para entregar assessorias de salas de estar, salas de jantar, quartos, cozinhas, banheiros e qualquer outro cômodo que você tenha em mente. Você tem a oportunidade de explicar tudo o que precisa no início da assessoria para que nós possamos entregar algo do jeito que você deseja.
        `,
      },
      {
        title: "O que está incluso no projeto final?",
        content: `
        Após a conclusão da etapa de envio de informações, nossos decoradores criam o seu ambiente personalizado e, assim que ele estiver pronto, o sistema disponibiliza as imagens 3D para a sua aprovação. <br />
        Com a aprovação do 3D, nossa equipe monta todo o material técnico, que contém: <br />
        - Posicionamento  da decoração; <br />
        - Posicionamento  de luminárias; <br />
        - Sugestão de  acabamentos de paredes; <br />
        - Ambiente decorado com imagens em  3D; <br />
        - Tabelas técnicas de móveis, decoração, luminárias, papel de parede, cortinas, persianas, tapetes e toda a decoração; <br />
        - Considerações para executar a decoração; <br />
        - Dicas úteis e personalizadas de decoração; <br />
        `,
      },
      {
        title:
          "Eu gostaria de aproveitar alguns móveis que já tenho. Como posso incluí-los na assessoria?",
        content: `
        Para aproveitar seus móveis atuais, você só precisa nos enviar as fotos e medidas de todos os objetos que deseja manter e vamos considerá-los na elaboração da assessoria.
        `,
      },
      {
        title: "Gostaria de cancelar a compra do meu projeto, é possível?",
        content: `
        Se o desejo de cancelamento ocorrer em até 7 dias da compra e o projeto não tiver sido iniciado, fazemos o estorno da compra de forma a garantir o direito legal de arrependimento. Caso o projeto tenha sido iniciado e não fique satisfeito com o projeto enviado, temos as 2 revisões para o alinhamento das expectativas para assim garantir a sua satisfação com relação ao projeto recebido. Se mesmo após a utilização das 2 revisões ainda estiver insatisfeito com o projeto, formalizaremos o cancelamento da compra e a devolução do investimento.
        `,
      },
    ],
  };

  const styles = {
    rowContentColor: "#686868",
    rowTitleColor: "#3C3C3E",
    rowContentPaddingLeft: "15px",
    rowContentPaddingRight: "10px",
    rowContentPaddingTop: "10px",
    rowTitleTextSize: "16px",
    rowContentTextSize: "15px",
  };

  return (
    <>
      <h4>Perguntas Frequentes</h4>
      <Row className="col-md-6">
        <Col>
          <Faq data={data} styles={styles} />
        </Col>
      </Row>
      <Button
        theme="accent"
        onClick={() => {
          history.push(`/faq${history.location.search}`);
        }}
      >
        Ver Mais
      </Button>
    </>
  );
};

export default FaqLp;
