function getFormattedNumber(number) {
  return number < 10 ? `0${number}` : number;
}

function getFormattedDate(stringDate) {
  const date = new Date(stringDate);
  const formattedCreatedAt = `${getFormattedNumber(
    date.getDate()
  )}/${getFormattedNumber(
    date.getMonth() + 1
  )}/${date.getFullYear()} ${getFormattedNumber(
    date.getHours()
  )}:${getFormattedNumber(date.getMinutes())}`;
  return formattedCreatedAt;
}

export { getFormattedDate };
