import React, { useState, useEffect, useContext, useMemo } from "react";
import { Row, Card, CardBody, Col, CardHeader, Button } from "shards-react";
import PageTitle from "../../common/PageTitle";
import Slider from "react-slick";
import { BUCKET_URL } from "../../../config";
import { toast, ToastContainer } from "react-toastify";
import ImagesDownloadProject from "../sub-component/ImagesDownloadProject";
import Rating from "../Rating";
import { AuthContext } from "../../common/Authorization/AuthContext";
import DecorationGuide from "../../common/DecorationGuide";
import { useHistory } from "react-router-dom";

import { ShowLightbox } from "../../common/ShowLightbox";
import ProductList from "../sub-component/ProductList";
import { ContractorSettings, WoodWorker } from "../../common/considerations";
import { getPdfDefaultItems, getTextItems } from "../../../actions/pdfTextItem";
import { CustomImageToSlide } from "../../common/CustomImageToSlide";
import { getProjectShareId } from "../../../actions/projectV2";
import ShareModal from "./ShareModal";
import { SystemContext } from "../../SystemConfig/SystemContext";
import { sendMessageSuport } from "../../../actions/support";

const DownloadProject = (props) => {
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const { company } = useContext(SystemContext);
  const { updateStepCompleted } = props;
  const settings = {
    className: "center slider variable-width",
    centerMode: true,
    infinite: true,
    centerPadding: "8px",
    slidesToShow: 1,
    speed: 500,
    dots: true,
    variableWidth: true,
  };

  const settingsForOne = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [lightbox, setLightbox] = useState({
    open: false,
    index: 0,
    images: [],
    caption: [],
  });
  const [showShareModal, setShowShareModal] = useState(false);
  const [clickOnExtraReview, setClickOnExtraReview] = useState(false);
  const furnitures = useMemo(() => {
    const approvedReview = props.project.finalProject.approvedProject;
    return props.project.furnitures.length
      ? props.project.furnitures
      : approvedReview &&
        props.project.finalProject[approvedReview] &&
        props.project.finalProject[approvedReview].furnitures &&
        props.project.finalProject[approvedReview].furnitures.length
      ? props.project.finalProject[approvedReview].furnitures
      : [];
  }, []);

  function download(fileURL, fileName) {
    // for non-IE
    if (!window.ActiveXObject) {
      var save = document.createElement("a");
      save.href = fileURL;
      save.target = "_self";
      var filename = fileURL.substring(fileURL.lastIndexOf("/") + 1);
      save.download = fileName || filename;
      if (
        navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
        navigator.userAgent.search("Chrome") < 0
      ) {
        document.location = save.href;
        // window event not working here
      } else {
        var evt = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: false,
        });
        save.dispatchEvent(evt);
        (window.URL || window.webkitURL).revokeObjectURL(save.href);
      }
    }

    // for IE < 11
    else if (!!window.ActiveXObject && document.execCommand) {
      var _window = window.open(fileURL, "_blank");
      _window.document.close();
      _window.document.execCommand("SaveAs", true, fileName || fileURL);
      _window.close();
    }
  }

  const sendLightbox = (data, idx, flipImage = false, caption = []) => {
    console.log(flipImage);
    if (Array.isArray(data)) {
      setLightbox({
        ...lightbox,
        images: data,
        open: true,
        index: idx,
        caption,
      });
    } else {
      setLightbox({
        ...lightbox,
        images: [data],
        open: true,
        index: 0,
        caption,
      });
    }
  };

  const [project, setProject] = useState();
  const [projectId, setProjectId] = useState("");

  const [files, setFiles] = useState([]);
  const [workPlanLayout, setWorkPlanLayout] = useState([]);
  const [workPlanLabels, setWorkPlanLabels] = useState([]);
  const [finishingLayout, setFinishingLayout] = useState([]);
  const [finishingLabels, setFinishingLabels] = useState([]);
  const [lights, setLights] = useState([]);
  const [lightsLabels, setLightsLabels] = useState([]);
  const [details, setDetails] = useState([]);
  const [detailsLabels, setDetailsLabels] = useState([]);
  const [allPdfDefaultItems, setAllPdfDefaultItems] = useState([]);
  const [allTextItems, setAllTextItems] = useState([]);

  useEffect(() => {
    let initialImages = [];
    if (typeof props.project.render.decorationPanel !== "undefined") {
      props.project.render.decorationPanel.map((elem) => {
        initialImages.push(elem.source);
      });
    }
    if (typeof props.project.render.workPlanLayout !== "undefined") {
      props.project.render.workPlanLayout.map((elem) => {
        initialImages.push(elem.source);
      });
    }
    setFiles(initialImages);
    setProjectId(props.project._id);
    setProject(props.project);
    if (typeof props.project.render.workPlanLayout !== "undefined") {
      let tempWorPlanLayout = [];
      let tempWorkPlanLabels = [];
      props.project.render.workPlanLayout.map((elem) => {
        tempWorPlanLayout.push(elem.source);
        tempWorkPlanLabels.push(
          typeof elem.labels === "undefined" ? [] : elem.labels
        );
      });

      setWorkPlanLayout(tempWorPlanLayout);
      setWorkPlanLabels(tempWorkPlanLabels);
    }
    if (typeof props.project.render.finishingLayout !== "undefined") {
      let tempFinishingLayout = [];
      let tempFinishingLabels = [];
      props.project.render.finishingLayout.map((elem) => {
        tempFinishingLayout.push(elem.source);
        tempFinishingLabels.push(
          typeof elem.labels === "undefined" ? [] : elem.labels
        );
      });
      setFinishingLayout(tempFinishingLayout);
      setFinishingLabels(tempFinishingLabels);
    }
    if (typeof props.project.render.lights !== "undefined") {
      let tempLights = [];
      let tempLightsLabels = [];
      props.project.render.lights.map((elem) => {
        tempLights.push(elem.source);
        tempLightsLabels.push(
          typeof elem.labels === "undefined" ? [] : elem.labels
        );
      });
      setLights(tempLights);
      setLightsLabels(tempLightsLabels);
    }
    if (typeof props.project.render.details !== "undefined") {
      let tempDetails = [];
      let tempDetailsLabels = [];
      props.project.render.details.map((elem) => {
        tempDetails.push(elem.source);
        tempDetailsLabels.push(
          typeof elem.labels === "undefined" ? [] : elem.labels
        );
      });
      setDetails(tempDetails);
      setDetailsLabels(tempDetailsLabels);
    }

    getTextItems().then((res) => {
      setAllTextItems(res);
    });

    getPdfDefaultItems().then((res) => {
      setAllPdfDefaultItems(res);
    });
  }, [props.project]);

  function goToSupport() {
    history.push(`/support?project=${projectId}`);
  }

  function onShareClick() {
    setShowShareModal(true);
    if (!props.project.shareId)
      getProjectShareId(projectId).then((res) =>
        setShowShareModal(res.shareId)
      );
    else setShowShareModal(props.project.shareId);
  }

  return (
    <React.Fragment>
      <ToastContainer />
      {lightbox.open && (
        <ShowLightbox lightbox={lightbox} setLightbox={setLightbox} />
      )}

      <Row className="mobile-review">
        <Col>
          <Card>
            <CardHeader>
              <i
                className="fa fa-times"
                onClick={() => {
                  props.goBack();
                }}
                style={{ fontSize: "22px", cursor: "pointer", float: "right" }}
                aria-hidden="true"
              />
            </CardHeader>
            <CardBody>
              {/* eslint-disable-next-line */}
              <div className=" p-2">
                <h5 className="text-center">Seu ambiente está pronto!</h5>
                <span className="text-justify">
                  Aqui está o seu ambiente com sua nova decoração, desenvolvido
                  a partir das suas escolhas e com muito carinho pela equipe{" "}
                  {company.name}. <br />
                  {company.label === "tok_stok" && (
                    <>
                      Pensamos uma solução que utilize apenas produtos em linha.
                      Entretanto, trabalhamos com estoque flutuante e, no
                      momento da compra, alguns dos itens sugeridos podem estar
                      indisponíveis. Neste caso, entre em contato com um de
                      nossos vendedores especializados! Eles poderão informar a
                      previsão para disponibilidade em estoque ou ajudá-lo na
                      escolha de produtos semelhantes. Agradecemos a confiança e
                      desejamos bom proveito de seu novo ambiente decorado!
                    </>
                  )}
                </span>
              </div>

              <div className="mt-3 Final-project-card-mobile">
                <Col md="12" className="mb-2" style={{ textAlign: "center" }}>
                  <div style={{ marginTop: "50px" }}>
                    <Slider {...(files.length > 1 ? settings : settingsForOne)}>
                      {files.map((file, idx) => {
                        return (
                          <div
                            key={idx}
                            className="d-flex justify-content-center align-items-center"
                          >
                            {files.length > 1 ? (
                              <CustomImageToSlide
                                file={file}
                                onclick={() => sendLightbox(files, idx)}
                              />
                            ) : (
                              <div className="d-flex justify-content-center align-items-center carousel-container">
                                {/* eslint-disable-next-line*/}
                                <img
                                  alt=""
                                  style={{ cursor: "pointer" }}
                                  className="rounded img-fluid carousel-image"
                                  src={file}
                                  onClick={() => sendLightbox(files, idx)}
                                />
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </Slider>
                  </div>
                  {workPlanLayout.length > 0 && (
                    <ImagesDownloadProject
                      title="Planta de layout"
                      data={workPlanLayout}
                      labels={workPlanLabels}
                      settings={settings}
                      settingsForOne={settingsForOne}
                      sendLightbox={sendLightbox}
                    />
                  )}
                  {finishingLayout.length > 0 && (
                    <ImagesDownloadProject
                      title="Planta de acabamentos"
                      data={finishingLayout}
                      labels={finishingLabels}
                      settings={settings}
                      settingsForOne={settingsForOne}
                      sendLightbox={sendLightbox}
                    />
                  )}
                  {lights.length > 0 && (
                    <ImagesDownloadProject
                      title="Distribuição de luminárias"
                      data={lights}
                      labels={lightsLabels}
                      settings={settings}
                      settingsForOne={settingsForOne}
                      sendLightbox={sendLightbox}
                    />
                  )}
                  {details.length > 0 && (
                    <ImagesDownloadProject
                      title="Detalhamentos"
                      data={details}
                      labels={detailsLabels}
                      settings={settings}
                      settingsForOne={settingsForOne}
                      isDetailing={true}
                      sendLightbox={sendLightbox}
                    />
                  )}
                  {allPdfDefaultItems.length > 0 && (
                    <WoodWorker
                      project={props.project}
                      allPdfDefaultItems={allPdfDefaultItems}
                    />
                  )}
                  {allTextItems.length > 0 && (
                    <ContractorSettings
                      project={props.project}
                      allTextItems={allTextItems}
                    />
                  )}
                </Col>
                <br />
                <ProductList
                  products={furnitures}
                  projectId={props.project._id}
                />
                <br />
                <DecorationGuide
                  action={() => {
                    download(
                      BUCKET_URL +
                        "/projects/" +
                        projectId +
                        "/lastVersion/" +
                        project.finalProject.lastVersion.pdf,
                      project.finalProject.lastVersion.pdf
                    );
                  }}
                />
                <div className="mt-5">
                  <Button
                    size="lg"
                    theme="accent"
                    className="ml-auto mr-auto d-flex align-items-center justify-content-center"
                    disabled={showShareModal === true}
                    onClick={onShareClick}
                  >
                    <i
                      style={{ fontSize: "25px" }}
                      className="material-icons mr-3"
                    >
                      share
                    </i>
                    Compartilhar
                    {showShareModal === true ? "..." : null}
                  </Button>
                </div>
                <div className="d-flex justify-content-center align-items-center flex-column mt-5">
                  <Button onClick={goToSupport} theme="accent" size="lg">
                    Alguma dúvida sobre o ambiente?
                  </Button>
                </div>
                <div className="d-flex justify-content-center align-items-center flex-column mt-5">
                  <Rating
                    stars={props.project.rating.value}
                    text={props.project.rating.text}
                    size={30}
                    onFinish={(review) => {
                      props.project.rating = {
                        value: review.stars,
                        text: review.text,
                      };
                      updateStepCompleted({
                        rating: {
                          value: review.stars,
                          text: review.text,
                        },
                        _id: props.project._id,
                      }).then(() => {
                        history.replace(
                          `/environments-list?show=${props.project._id}`
                        );
                      });
                    }}
                    title={`Olá ${user.profile.firstName}, em uma escala de satisfação,
                      como você avalia nosso atendimento automatizado e a
                      assessoria recebida?`}
                  />
                  <br />
                </div>
                <div className="mt-5 mb-5">
                  <Card style={{ backgroundColor: "#f7f7f7" }}>
                    <CardBody>
                      <div className="banner-guide">
                        <div style={{ maxWidth: "350px" }}>
                          <h3>Revisão extra</h3>
                          <p>
                            Ainda não ficou feliz com o resultado do seu
                            projeto? Agora você pode solicitar uma revisão extra
                            por R$ 99,00
                          </p>
                        </div>
                        <div className="d-flex flex-column align-items-center">
                          <div
                            style={{
                              top: 0,
                              backgroundImage: `url("${require("../../../images/extra-reviw.png")}")`,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                              backgroundPosition: "center center",
                              height: "84px",
                              width: "84px",
                            }}
                          />
                          <div>
                            <Button
                              title={"Comprar revisão extra"}
                              theme="accent"
                              size="md"
                              disabled={clickOnExtraReview}
                              className="mt-3"
                              onClick={() => {
                                setClickOnExtraReview(true);
                                sendMessageSuport({
                                  detail: `O cliente fez uma solicitação de revisão extra do Ambiente ${project?.name}`,
                                  project: projectId,
                                  subject: `Revisão extra do Ambiente - ${project?.name}`,
                                }).then(() => {
                                  toast.success(
                                    "Solicitação realizada com sucesso!",
                                    {
                                      position: "top-right",
                                      autoClose: 5000,
                                      hideProgressBar: false,
                                      closeOnClick: true,
                                      pauseOnHover: true,
                                      draggable: true,
                                      progress: undefined,
                                    }
                                  );
                                });
                              }}
                              style={{ width: "150px" }}
                            >
                              Comprar revisão extra
                            </Button>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {showShareModal && showShareModal !== true ? (
        <ShareModal
          shareId={showShareModal}
          onClose={() => setShowShareModal()}
        />
      ) : null}
    </React.Fragment>
  );
};
export default DownloadProject;
