import React, { useContext, useEffect } from "react";
import { getQueryStringValue, authenticate, isAuth } from "../../actions/auth";
import { authByForeignToken } from "../../actions/authV2";
import { brokenLink } from "../../actions/logs";
import { Row, Col } from "shards-react";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";

import { SystemContext } from "../SystemConfig/SystemContext";

function Validator() {
  const { company } = useContext(SystemContext) as any;

  const LINK = company.externalLoginUrl || "/login";
  useEffect(() => {
    const token = getQueryStringValue("u") as string;
    const page = getQueryStringValue("page");
    const show = getQueryStringValue("show");
    authByForeignToken(company.label, token).then((validate) => {
      if (!validate) {
        brokenLink(window.location.href).then(() => {
          window.open(`${LINK}`, "_self");
        });
      } else if (validate.error) {
        brokenLink(window.location.href).then(() => {
          window.open(`${LINK}`, "_self");
        });
      } else {
        authenticate(validate, () => {
          if (isAuth()) {
            if (page === "checkout") {
              let urlOpen = `/checkout`;
              if (window.location.search.length > 0) {
                urlOpen = `/checkout${window.location.search}`;
              }
              window.open(urlOpen, "_self");
            } else if (page === "myorders") {
              window.open(`/environments-list`, "_self");
            } else if (!page) {
              brokenLink(window.location.href).then(() => {
                const urlOpen = `/environments`;
                window.open(urlOpen, "_self");
              });
            } else {
              brokenLink(window.location.href).then(() => {
                const urlOpen = `/environments`;
                window.open(urlOpen, "_self");
              });
            }
          }
        });
      }
    });
  }, []);
  return (
    <div style={{ marginTop: "2%" }}>
      <Row className="mt-5 mb-5">
        <Col
          sm={{ size: 6, order: 2, offset: 5 }}
          xs={{ size: 4, order: 4, offset: 2 }}
          className="mt-5"
        >
          <img
            id="mmain-logo"
            className="align-center"
            src={company.logo}
            alt="Tok&Stok"
            style={{ maxHeight: "5vh" }}
          />
        </Col>
      </Row>
      <Row className="mt-5">
        <Col xs="12">
          <LoadingOverlay active={true} spinner={<BounceLoader />} />
        </Col>
      </Row>
      <Row className="mt-5">
        <Col xs="12" className="text-align-center mt-5">
          <p>Olá!</p>
          <p>
            Aguarde um instante e iremos redirecionar você para dar continuidade
            ao processo.
          </p>
          <p>Obrigado!</p>
        </Col>
      </Row>
    </div>
  );
}

export default Validator;
