import React from "react";
import { Card } from "shards-react";

type Props = {
  children?: React.ReactNode;
  title: string;
  className: string;
};

const Panel: React.FC<Props> = ({ children, title, className }) => {
  return (
    <Card className={className}>
      <div
        className="text-center mb-3"
        style={{
          fontSize: "24px",
          color: "var(--newbackground-color)",
        }}
      >
        {title}
      </div>
      <div>{children}</div>
    </Card>
  );
};

export default Panel;
