import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { Button } from "shards-react";
import Slider from "react-slick";
import AspectRatioPicture from "../components/common/AspectRatioPicture";
import PageTitle from "../components/common/PageTitle";
import { BUCKET_URL } from "../config";
import styles from "./Projects.module.scss";
import CollapseButton from "../components/common/CollapseButton";
import { getAllProjectsV2 } from "../actions/project";
import { useHistory } from "react-router-dom";
import Moment from "react-moment";
import { getQueryStringValue } from "../actions/auth";
import Loader from "react-spinners/BounceLoader";
import { showReviewProjects } from "../utils/util";
import { SocketIoContext } from "../components/shared/SocketIoContext";
import { AuthContext } from "../components/common/Authorization/AuthContext";
import { getStatusInfo } from "@ladecora/ladecora-utils";
import DefaultQuestions from "../components/environments/steps/DefaultQuestions";
import { updateUser } from "../actions/user";
const Projects = () => {
  const history = useHistory();
  const [projects, setProjects] = useState();
  const [groupSize, setGroupSize] = useState();
  const { user } = useContext(AuthContext);
  const { registerEventHandler, removeEventHandler } =
    useContext(SocketIoContext);
  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 250,
    slidesToScroll: 1,
    slidesToShow: 1,
    draggable: false,
    responsive: [
      {
        breakpoint: 1024,
        draggable: true,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [unfinishedGroupedProjects, finishedGroupedProjects] = useMemo(() => {
    if (groupSize && projects) {
      let [unfinished, finished] = projects.reduce(
        (acc, cur) => {
          const [unfinished, finished] = acc;
          if (cur._viewInfo.priority === 4) {
            finished.push(cur);
          } else {
            unfinished.push(cur);
          }
          return acc;
        },
        [[], []]
      );
      unfinished = unfinished.concat([{}]);

      return [
        unfinished.reduce(
          (a, c, i) => {
            const item = a[a.length - 1];

            if (item.length === groupSize) {
              const group = a[a.length - 1];
              a.push([group[groupSize - 1], c]);
              if (i === unfinished.length - 1 && groupSize === 2) {
                a.push([c]);
              }
            } else {
              item.push(c);
              if (i === unfinished.length - 1) {
                const group = a[a.length - 1];
                if (group.length === groupSize) {
                  a.push([group[group.length - 1]]);
                }
              }
            }
            return a;
          },
          [[]]
        ),
        finished.reduce(
          (a, c, i) => {
            const item = a[a.length - 1];

            if (item.length === groupSize) {
              const group = a[a.length - 1];
              a.push([group[groupSize - 1], c]);
              if (i === unfinished.length - 1 && groupSize === 2) {
                a.push([c]);
              }
            } else {
              item.push(c);
              if (i === unfinished.length - 1) {
                const group = a[a.length - 1];
                if (group.length === groupSize) {
                  a.push([group[group.length - 1]]);
                }
              }
            }
            return a;
          },
          [[]]
        ),
      ];
    }
    return [[], []];
  }, [groupSize, projects]);
  const selectedProjectId = getQueryStringValue("show");

  const handleNotification = useCallback((res) => {
    if (res.room === user._id) {
      if (res.project) {
        refreshProject(getStatusInfo(res.project, [], []));
      }
      if (res.notification?.data?.asaasPaymentId) {
        window.location.reload(false);
      }
    }
  }, []);

  useEffect(() => {
    registerEventHandler("project", handleNotification);
    return () => {
      removeEventHandler("project", handleNotification);
    };
  }, []);

  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth > 1236) {
        setGroupSize(Math.floor(window.innerWidth / 403) + 1);
      } else if (window.innerWidth > 768 && window.innerWidth < 945) {
        setGroupSize(2);
      } else if (window.innerWidth >= 709) {
        setGroupSize(3);
      } else {
        setGroupSize(2);
      }
    }
    if (projects) {
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }
  }, [projects]);

  useEffect(() => {
    getAllProjectsV2().then((data) => {
      setProjects(data);
    });
  }, []);

  useEffect(() => {
    if (selectedProjectId && user.answerDefaultQuestion.length) {
      history.push(`/my_project/${selectedProjectId}`);
    }
  }, [selectedProjectId]);

  const PRIORITY = {
    HIGH: 1,
    LOW: 2,
    NONE: 3,
  };

  const refreshProject = (project) => {
    setProjects((previewProjects) =>
      previewProjects.map((previewProject) => {
        if (previewProject._id === project._id) {
          previewProject = project;
        }
        return previewProject;
      })
    );
  };

  const onNewProject = () => {
    history.push("/environments");
  };

  const getProjectPicture = (project) => {
    const stepsProjectToShow = showReviewProjects(project);

    let review = "";
    if (
      project.finalProject &&
      project.status !== "reviewing_quality" &&
      project.status !== "waiting_quality"
    ) {
      if (project.finalProject.approvedProject) {
        if (
          project.finalProject.approvedProject === "Preview" ||
          project.finalProject.approvedProject === "preview"
        ) {
          review = "preview";
        } else if (
          project.finalProject.approvedProject === "firstReview" ||
          project.finalProject.approvedProject === "first_review" ||
          project.finalProject.approvedProject === "1º Review"
        ) {
          review = "firstReview";
        } else if (
          project.finalProject.approvedProject === "secondReview" ||
          project.finalProject.approvedProject === "second_review" ||
          project.finalProject.approvedProject === "2º Review"
        ) {
          review = "secondReview";
        }
      } else if (
        project.finalProject.secondReview &&
        project.finalProject.secondReview.files.length
      ) {
        review = "secondReview";
      } else if (
        project.finalProject.firstReview &&
        project.finalProject.firstReview.files.length
      ) {
        review = "firstReview";
      } else if (
        project.finalProject.preview &&
        project.finalProject.preview.files.length
      ) {
        review = "preview";
      }
      if (review) {
        const files = project.finalProject[review].files.filter(
          (f) => f.tag === "decorationPanel"
        );
        if (files.length) {
          return `${BUCKET_URL}/projects/${project._id}/${review}/${files[0].file}`;
        }
      }
    }

    if (stepsProjectToShow.preview) {
      review = "preview";
    }
    if (stepsProjectToShow.firstReview) {
      review = "firstReview";
    }
    if (stepsProjectToShow.secondReview) {
      review = "secondReview";
    }

    if (review) {
      const files = project.finalProject[review].files.filter(
        (f) => f.tag === "decorationPanel"
      );
      if (files.length) {
        return `${BUCKET_URL}/projects/${project._id}/${review}/${files[0].file}`;
      }
    }

    return project.questionTemplate.thumbnail
      ? BUCKET_URL + "/" + project.questionTemplate.thumbnail
      : "https://i.pinimg.com/originals/a3/a2/53/a3a253b2f7828ffb273f4b1fde9ddd2f.jpg";
  };

  const updateDefaultQuestionsCompleted = (answers) => {
    updateUser(user._id, { answerDefaultQuestion: answers }).then(() => {
      history.replace("/environments-list");
      window.location.reload(false);
    });
  };

  return (
    <div className={`${styles.page}  mb-5`}>
      {/* <ToastContainer /> */}
      {!user.answerDefaultQuestion.length ? (
        <div className="px-4">
          <DefaultQuestions
            callbackReturnView={() => {}}
            updateDefaultQuestionsCompleted={updateDefaultQuestionsCompleted}
          />
        </div>
      ) : projects && projects.length === 0 ? (
        <div className="error">
          <div className="error__content">
            <h2>&#8251;</h2>
            <h3>Você não possui nenhuma assessoria</h3>
            <p>Clique no botão abaixo para comprar um nova assessoria.</p>
            <Button
              pill
              theme="accent"
              onClick={() => history.push("/environments")}
            >
              &#43; Novo ambiente
            </Button>
          </div>
        </div>
      ) : (
        <div className="page-header py-4">
          <PageTitle
            title="Projetos"
            className="text-sm-left mb-3 col-sm-8 ml-md-4"
          />
          <div className={styles.container}>
            {!projects ? (
              <div className="d-flex align-items-center justify-content-center">
                <Loader />
              </div>
            ) : null}
            <Slider {...sliderSettings}>
              {unfinishedGroupedProjects.map((group, i) => {
                const emptyDivs = [];
                for (let k = 0; k < 4 - group.length; k++) {
                  emptyDivs.push(
                    <div className={styles.slide} key={k}>
                      <div className="w-100" />
                    </div>
                  );
                }
                return (
                  <div className={styles.slideGroup} key={i}>
                    {group.map((p) => {
                      if (!p._id) {
                        return (
                          <div
                            className={styles.slide}
                            key={new Date().getTime()}
                          >
                            <button
                              className={`${styles.newProject} ${styles.card} clickable`}
                              onClick={onNewProject}
                            >
                              <i className="material-icons">add_circle</i>
                              <div>Novo projeto</div>
                            </button>
                          </div>
                        );
                      }
                      const goToProject = function () {
                        history.push(`/my_project/${p._id}`, {
                          project: p,
                        });
                      };
                      const thumbnail = getProjectPicture(p);
                      console.log(p);

                      return (
                        <div className={styles.slide} key={p._id}>
                          <div
                            className={`${styles.card} ${
                              p._viewInfo.priority === PRIORITY.NONE
                                ? ""
                                : p._viewInfo.priority === PRIORITY.HIGH
                                ? styles.missingInfo
                                : styles.missingApproval
                            }`}
                          >
                            <div className={styles.warnSign}>
                              <i className="material-icons">priority_high</i>
                            </div>
                            <div className={styles.cardHeader}>
                              <div
                                className={`${styles.title} ${
                                  p.name.length > 28 ? "align-items-start" : ""
                                }`}
                              >
                                {p.name}
                              </div>
                              <hr className={styles.divider} />
                            </div>
                            <div className={styles.cardBody}>
                              <div className={styles.image}>
                                <AspectRatioPicture
                                  isDeletable={false}
                                  height="220px"
                                  width="220px"
                                  src={thumbnail}
                                  name={thumbnail}
                                  title={""}
                                  onDeleteClick={() => {}}
                                />
                              </div>
                              <div className={styles.statusHeader}>
                                {p._viewInfo.header}
                              </div>
                              <div className={styles.statusText}>
                                {p._viewInfo.text}
                              </div>
                              {p?.status !== "waiting_quality" && (
                                <div className={styles.dueDateText}>
                                  Prazo:{" "}
                                  {p._viewInfo.dueDate ? (
                                    <Moment format="DD/MM/YYYY">
                                      {p._viewInfo.dueDate}
                                    </Moment>
                                  ) : (
                                    "Sem data definida"
                                  )}
                                </div>
                              )}
                            </div>
                            <Button
                              size="lg"
                              theme="accent"
                              onClick={goToProject}
                              className={styles.actionButton}
                            >
                              {["preview", "firstReview", "secondReview"].find(
                                (x) => p.status === x
                              )
                                ? "Ver Projeto"
                                : "Entrar"}
                            </Button>
                          </div>
                        </div>
                      );
                    })}
                    {emptyDivs}
                  </div>
                );
              })}
            </Slider>
          </div>
          <div className="mt-5">
            <CollapseButton collapsed={true} />
            <hr />
          </div>
          <div className={styles.container}>
            <Slider {...sliderSettings}>
              {finishedGroupedProjects.map((group, i) => {
                const emptyDivs = [];
                for (let k = 0; k < 4 - group.length; k++) {
                  emptyDivs.push(
                    <div className={styles.slide} key={k}>
                      <div className="w-100" />
                    </div>
                  );
                }
                return (
                  <div className={styles.slideGroup} key={i}>
                    {group.map((p) => {
                      if (!p._id) {
                        return (
                          <div
                            className={styles.slide}
                            key={new Date().getTime()}
                          >
                            <div
                              className={`${styles.newProject} ${styles.card}`}
                            >
                              <i className="material-icons">add_circle</i>
                              <div>Novo projeto</div>
                            </div>
                          </div>
                        );
                      }
                      const goToProject = function () {
                        history.push(`/my_project/${p._id}`, {
                          project: p,
                        });
                      };
                      const thumbnail = getProjectPicture(p);

                      return (
                        <div className={styles.slide} key={p._id}>
                          <div
                            // key={p[0]._id}
                            className={`${styles.card} `}
                          >
                            <div className={styles.cardHeader}>
                              <div className={styles.title}>{p.name}</div>
                              <hr className={styles.divider} />
                            </div>
                            <div className={styles.cardBody}>
                              <div className={styles.image}>
                                <AspectRatioPicture
                                  isDeletable={false}
                                  height="220px"
                                  width="220px"
                                  src={thumbnail}
                                  name={thumbnail}
                                  title={""}
                                  onDeleteClick={() => {}}
                                />
                              </div>
                              <div className={styles.statusHeader}>
                                {p._viewInfo.header}
                              </div>
                              <div className={styles.statusText}>
                                {p._viewInfo.text}
                              </div>
                            </div>
                            <Button
                              size="lg"
                              theme="accent"
                              onClick={goToProject}
                              className={styles.actionButton}
                            >
                              {["preview", "firstReview", "secondReview"].find(
                                (x) => p.status === x
                              )
                                ? "Ver Projeto"
                                : "Entrar"}
                            </Button>
                          </div>
                        </div>
                      );
                    })}
                    {emptyDivs}
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      )}
    </div>
  );
};
export default Projects;
