import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  FormInput,
  Button,
} from "shards-react";
import Loader from "react-loader-spinner";
import { resetPassword, getQueryStringValue } from "../actions/auth";
import Logo from "../components/common/Logo";

function ChangePassword() {
  const [values, setValues] = useState({
    token: "",
    password: "",
    confirmPassword: "",
    loading: false,
    error: false,
    message: "",
  });
  const { token, password, confirmPassword, loading, error, message } = values;

  useEffect(() => {
    setValues({ ...values, token: getQueryStringValue("token") });
  }, []);

  const handleChange = (name) => (e) => {
    setValues({ ...values, error: false, [name]: e.target.value });
  };

  const showError = () =>
    error ? <div className="alert alert-danger">{error}</div> : "";
  const showMessage = () =>
    message ? <div className="alert alert-info">{message}</div> : "";

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setValues({ ...values, loading: true });
    resetPassword({ token, password, confirmPassword }).then((data) => {
      if (data.error) {
        setValues({ ...values, error: data.error, loading: false });
      } else {
        setValues({
          ...values,
          message: `${data.msg} Estamos redirecionando você para a página de login em 5 segundos`,
          loading: true,
        });
        setTimeout(() => {
          window.open(`/login`, "_self");
          setValues({
            ...values,
            token: "",
            password: "",
            confirmPassword: "",
            loading: false,
            error: false,
            message: "",
          });
        }, 5000);
      }
    });
  };

  return (
    <Container fluid className="main-content-container h-100 px-4">
      <Row noGutters className="h-100">
        <Col lg="3" md="5" className="auth-form mx-auto my-auto">
          <Card>
            <CardBody>
              {/* Logo */}
              <Logo
                props={{
                  style: {
                    padding: "30px",
                    paddingBottom: "20px",
                    maxWidth: "215px",
                  },
                  className: "auth-form__logo d-table mx-auto mb-3",
                  alt: "La Decora",
                }}
                defaultProps={{
                  style: {
                    // backgroundColor: company.color,
                    padding: "30px",
                    paddingBottom: "20px",
                    maxWidth: "215px",
                  },
                  className: "auth-form__logo d-table mx-auto mb-3",
                  alt: "La Decora - Register Template",
                }}
              />
              {/* Title */}
              <h5 className="auth-form__title text-center mb-4">
                Alterar senha
              </h5>

              {/* Form Fields */}
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <label htmlFor="exampleInputPassword1">Senha</label>
                  <FormInput
                    type="password"
                    placeholder="Informe sua senha"
                    autoComplete="new-password"
                    value={password}
                    onChange={handleChange("password")}
                  />
                </FormGroup>
                <FormGroup>
                  <label htmlFor="exampleInputPassword2">Confirmar senha</label>
                  <FormInput
                    type="password"
                    placeholder="Confirmar senha"
                    autoComplete="new-password"
                    value={confirmPassword}
                    onChange={handleChange("confirmPassword")}
                  />
                </FormGroup>
                {showError()}
                {showMessage()}
                <Button
                  pill
                  theme="accent"
                  className="d-table mx-auto"
                  type="submit"
                >
                  {loading && (
                    <Loader
                      width="30px"
                      height="15px"
                      type="Rings"
                      color="#ffffff"
                    />
                  )}
                  {!loading && "Alterar senha"}
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default ChangePassword;
