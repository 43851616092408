import React, { useEffect } from "react";

import { GOOGLE_APP_ID } from "../config";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { default as ER } from "../components/ExternalLogin";

const ExternalLogin = () => {
  return (
    <GoogleOAuthProvider clientId={GOOGLE_APP_ID}>
      <ER />
    </GoogleOAuthProvider>
  );
};

export default ExternalLogin;
