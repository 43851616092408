import React from "react";
import PictureItem from "./PictureItem";
function UserTypes({ onUserTypeClick, userType, enterprise }) {
  return (
    <div className="small-container">
      {" "}
      <div>
        <center>
          <div className="question-title ml-4 mb-4 mt-4">
            Agora vamos começar a analisar seu perfil, conte para a gente, qual
            será o uso deste imóvel?
          </div>
        </center>
      </div>
      <div
        className="d-flex align-items-center justify-content-center flex-row"
        style={{ flexWrap: "wrap" }}
      >
        {enterprise?.profiles?.map((elem, idx) => (
          <div key={idx}>
            <PictureItem
              src={elem?.img}
              thumbnail={elem?.img}
              width="250px"
              height="250px"
              idx={parseInt(idx)}
              isSelected={userType === elem?.kinda}
              onClick={() => onUserTypeClick(elem?.kinda)}
              text={elem?.name}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

UserTypes.propTypes = {};

export default UserTypes;
