export const getDefaultValues = (label) => {
  const furnitures =
    label === "remaxfranquia"
      ? [
          {
            name: "TIMEGLASS ADORNO AMPULHETA 19 CM",
            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5f412378e127a2689dab0fb7/main_picture.jpg?v=1633032938126",
            supplier: "Tok&Stok",
            count: 3,
          },
          {
            name: "SEATTLE CADEIRA HOME OFFICE CROMADO/PRETO",

            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5f412378e127a2689dab1b99/main_picture.jpg?v=1633032873078",
            supplier: "Tok&Stok",

            count: 1,
          },
          {
            name: "FESTA LUMINÁRIA MESA - PRETO",

            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5f412378e127a2689dab19bf/main_picture.jpg?v=1633033072612",
            supplier: "Tok&Stok",

            count: 1,
          },
          {
            name: "MDF Branco Diamante",

            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5faac4aa7a61160f8a940ee9/main_picture.PNG?v=1624552358337",
            supplier: "Duratex",

            count: "CONFORME METRAGEM DO AMBIENTE",
          },
          {
            name: "Tinta efeito cimento queimado prata",

            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/60a3a8066ece1b0fe84a083d/main_picture.png?v=1621338119141",
            supplier: "Suvinil",

            count: "CONFORME METRAGEM DO AMBIENTE",
          },
          {
            name: "Tijolinho ABBEY ROAD",

            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/613bb1bc2c62523133c2ae0c/main_picture.PNG?v=1631302100745",
            supplier: "Portobello Shop",

            count: "CONFORME METRAGEM DO AMBIENTE",
          },
          {
            name: "Trilho Eletrificado de Sobrepor 1M",

            refimage:
              "https://app.wikeep.com.br/especificacoes/160478795265548_740011119503419.jpg",
            supplier: "Stella",

            count: 1,
          },
          {
            name: "TRILHO ELETRIFICADO DE SOBREPOR 1,5 METROS",

            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/60d38f237aa0e156b26acb6a/main_picture.PNG?v=1624477580723",
            supplier: "Stella",

            count: 1,
          },
          {
            name: "SPOT PARA TRILHO FLOW PAR20",

            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/605a1474043b210fe7fd920a/main_picture.PNG?v=1616516213631",
            supplier: "Stella",

            count: 6,
          },
          {
            name: "VASO PILÃO M - AUTO BRILHO",

            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/625ec3ed8eb56d6a8a5eaf6d/main_picture.JPG?v=1650377738171",
            supplier: "Fornecedor Local",

            count: 1,
          },
          {
            name: "PRIOR DECO PORTA-JOIAS 15 CM - PRETO/OURO",

            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5f412378e127a2689dab1206/main_picture.jpg?v=1633032939567",
            supplier: "Tok&Stok",

            count: 1,
          },
          {
            name: "HANG IT AZ APARADOR DE LIVROS C/2 - PRETO/PRETO",

            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5f412378e127a2689dab13d4/main_picture.jpg?v=1633032810298",
            supplier: "Tok&Stok",

            count: 2,
          },
          {
            name: "BAIKAL VASO/GARRAFA DECORATIVA 15 CM - AZUL",

            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5f412378e127a2689dab0f12/main_picture.jpg?v=1633032814031",
            supplier: "Tok&Stok",

            count: 1,
          },
          {
            name: "BAIKAL VASO/GARRAFA DECORATIVA 24 CM - AZUL",

            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5f412378e127a2689dab0f11/main_picture.jpg?v=1633032814142",
            supplier: "Tok&Stok",

            count: 1,
          },
          {
            name: "MONTREAL CAIXA 23 CM X 14 CM X 8 CM",

            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5f412378e127a2689dab052a/main_picture.jpg?v=1633032875797",
            supplier: "Tok&Stok",

            count: 2,
          },
          {
            name: "COUPER VASO 15 CM",

            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/6183c8e10f80d738ccfbed31/main_picture.JPG?v=1636026669570",
            supplier: "Tok&Stok",

            count: 1,
          },
          {
            name: "COUPER VASO 25 CM",

            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/6183c98c0f80d738ccfbed3c/main_picture.JPG?v=1636026813718",
            supplier: "Tok&Stok",

            count: 1,
          },
          {
            name: "SOLO GLOBO ADORNO 25 CM",

            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5f412378e127a2689dab0a38/main_picture.jpg?v=1633032812607",
            supplier: "Tok&Stok",

            count: 1,
          },
          {
            name: "WALD CAIXA 22 CM X 13 CM X 8 CM",

            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5f412378e127a2689dab04ef/main_picture.jpg?v=1633032820009",
            supplier: "Tok&Stok",

            count: 2,
          },
          {
            name: "BETON CEREUS ADORNO 13 CM",

            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5f412378e127a2689dab04c4/main_picture.jpg?v=1633032964413",
            supplier: "Tok&Stok",

            count: 1,
          },
          {
            name: "BETON TUNICATA ADORNO 11 CM",

            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5f412378e127a2689dab04c1/main_picture.jpg?v=1633032913211",
            supplier: "Tok&Stok",

            count: 1,
          },
          {
            name: "BOTTLE VASO 38 CM",

            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5f412378e127a2689dab041b/main_picture.jpg?v=1633032828350",
            supplier: "Tok&Stok",

            count: 2,
          },
          {
            name: "CATALYZED VASO/GARRAFA DECORATIVA 36 CM - ULTRAMARINE PROFUNDO REATIVO",

            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5f412378e127a2689dab11e8/main_picture.jpg?v=1633033048626",
            supplier: "Tok&Stok",

            count: 1,
          },
          {
            name: "CATALYZED VASO DECORATIVO 18 CM-ULTRAMARINE PROFUNDO REATIVO",

            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5f412378e127a2689dab11ec/main_picture.jpg?v=1633033048755",
            supplier: "Tok&Stok",

            count: 1,
          },
          {
            name: "CEIBO POLTRONA",

            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/60115cd218cbd00fe3766935/main_picture.JPG?v=1611751758308",
            supplier: "Tok&Stok",

            count: 2,
          },
          {
            name: "PIRIZ MESA LATERAL REDONDA 50 CM",

            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/619b97920faa4f0f5b567185/main_picture.png?v=1637586844634",
            supplier: "Tok&Stok",

            count: 1,
          },
          {
            name: "Tinta - Elefante",

            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5f6e26da1b074f5ed15db617/main_picture.PNG?v=1637351187806",
            supplier: "Suvinil",

            count: "CONFORME METRAGEM DO AMBIENTE",
          },
          {
            name: "Tinta Mar Noturno",

            refimage:
              "https://app.wikeep.com.br/especificacoes/5lkx6eoz919m7ed_ti8x27myxqhyp2m.jpg",
            supplier: "Suvinil",

            count: "CONFORME METRAGEM DO AMBIENTE",
          },
          {
            name: "EAMES WOOD III CADEIRA",

            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5f412378e127a2689dab1781/main_picture.jpg?v=1633032853513",
            supplier: "Tok&Stok",

            count: 2,
          },
          {
            name: "Tinta Branco Neve - Forro/teto",

            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5ff4ad0633f01e0fe66ac8dd/main_picture.png?v=1609870599051",
            supplier: "Suvinil",

            count: "CONFORME METRAGEM DO AMBIENTE",
          },
          {
            name: "INDEX ARMÁRIO BAIXO 2P",

            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5f412378e127a2689dab1084/main_picture.jpg?v=1633032900825",
            supplier: "Tok&Stok",

            count: 2,
          },
          {
            name: "STRAITY ESTANTE 60X196 BRANCO",

            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5f412378e127a2689dab1e47/main_picture.jpg?v=1633032846745",
            supplier: "Tok&Stok",

            count: 2,
          },
          {
            name: "TE QUIERO CONE VASO 24 CM",

            refimage:
              "https://tokstok.vteximg.com.br/arquivos/ids/1812513-1000-1000/Cone-Vaso-24-Cm-Incolor-Te-Quiero.jpg?v=637012288160830000",
            supplier: "Tok&Stok",

            count: 1,
          },
          {
            name: "ALLEZ LÀ VASO PAREDE 15 CM - AZUL CLARO/TERRACOTA",

            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5f412378e127a2689dab168b/main_picture.jpg?v=1633032920778",
            supplier: "Tok&Stok",

            count: 1,
          },
        ]
      : [
          {
            typology: {
              _id: "62f68f4d7671370f6f1fe852",
              name: "Móveis soltos",
              category: "typology",
            },
            projectTemplateNames: ["Sala de estar", "Sala de estar e jantar"],
            _id: "5f412378e127a2689daafc21",
            sku: "957011793",
            name: "PELICANO MESA CENTRO RED. 65",
            refx: "65",
            refy: "65",
            refz: "35,7",
            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5f412378e127a2689daafc21/main_picture.jpg?v=1633032893053",
            status: true,
            updatedAt: "2022-08-22T17:23:15.843Z",
            supplier: "Tok&Stok",
            available: false,
            latestUnits: true,
            frequency_used: 6,
            tags: [
              {
                _id: "60ad913c1beb6dd98f4daf21",
                name: "Mesas",
              },
              {
                _id: "60ad913e1beb6dd98f4daf22",
                name: "Tampos",
              },
              {
                _id: "60ad8df3a76cf1b577071bc2",
                name: "Móveis",
              },
            ],
            colors: [],
            file: "957011793_V2.fbx",
            notUse: false,
            styles: [],
            typologies: [],
            value: 1089.98,
            inventory: 0,
            __v: 1,
            otherFiles: [],
            count: 1,
          },
          {
            typology: {
              _id: "62f69b347671370f6f1ff8a7",
              name: "Decoração",
              category: "typology",
            },
            projectTemplateNames: [
              "Quarto casal",
              "Sala de estar",
              "Sala de estar, jantar e cozinha",
              "Sala de Jantar",
              "Sala de estar e jantar",
              "Escritório ",
              "Home office",
              "Studio até 40m²",
              "Sala de reuniões",
              "Quarto jovem",
              "Sacada | Churrasqueira ",
              "Cozinha",
              "Recepção",
              "Closet",
              "Quarto infantil",
              "Quarto de bebê",
              "Quarto de hóspedes",
              "Banheiro",
            ],
            _id: "5f412378e127a2689dab0168",
            sku: "392396",
            name: "SPEND TIME AMPULHETA 15 CM",
            refx: "4.8",
            refy: "4.8",
            refz: "15",
            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5f412378e127a2689dab0168/main_picture.jpg?v=1633032810730",
            status: true,
            updatedAt: "2022-08-29T17:40:17.314Z",
            supplier: "Tok&Stok",
            available: false,
            latestUnits: true,
            frequency_used: 218,
            tags: [
              {
                _id: "60ad91f71beb6dd98f4daf5d",
                name: "Decoração",
              },
              {
                _id: "60ad8df4a76cf1b577071bc6",
                name: "Acessórios",
              },
            ],
            colors: [
              {
                _id: "604b58d9ebab150fdefee362",
                name: "Cinza",
                createdAt: "2021-03-12T12:04:41.856Z",
                updatedAt: "2021-03-12T12:04:41.856Z",
                __v: 0,
              },
            ],
            file: "392396_V2.FBX",
            value: 32.9,
            inventory: 0,
            notUse: true,
            __v: 1,
            otherFiles: [],
            styles: [],
            typologies: [],
            count: 2,
          },
          {
            typology: {
              _id: "62f69b347671370f6f1ff8a7",
              name: "Decoração",
              category: "typology",
            },
            projectTemplateNames: [
              "Quarto casal",
              "Sala de estar e jantar",
              "Home office",
              "Varanda | Churrasqueira ",
              "Sala de estar",
              "Cozinha",
              "Sacada | Churrasqueira ",
              "Escritório ",
              "Sala de estar, jantar e cozinha",
              "Quarto jovem",
              "Sala de reuniões",
              "Quarto de hóspedes",
              "Sala de Jantar",
              "Studio até 40m²",
              "Recepção",
              "Quarto de casal com Closet",
              "Sala de jogos",
              "Quarto de bebê",
            ],
            _id: "5f412378e127a2689dab02ad",
            sku: "390639",
            name: "DEGRADIM VASO 16 CM",
            refx: "11",
            refy: "11",
            refz: "16",
            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5f412378e127a2689dab02ad/main_picture.jpg?v=1633032812263",
            status: true,
            updatedAt: "2022-08-29T17:40:09.964Z",
            supplier: "Tok&Stok",
            available: false,
            latestUnits: true,
            frequency_used: 246,
            tags: [
              {
                _id: "60ad91f71beb6dd98f4daf5d",
                name: "Decoração",
              },
              {
                _id: "60ad8df4a76cf1b577071bc6",
                name: "Acessórios",
              },
              {
                _id: "60ad93b21beb6dd98f4dafa9",
                name: "Objetos Decorativos",
              },
              {
                _id: "60ad91411beb6dd98f4daf26",
                name: "Ambientes",
              },
              {
                _id: "60ad91f71beb6dd98f4daf5e",
                name: "Vasos",
              },
            ],
            colors: [
              {
                _id: "60ad91441beb6dd98f4daf28",
                name: "Branco",
              },
            ],
            file: "390639_v2.FBX",
            value: 17.9,
            inventory: -2,
            notUse: false,
            __v: 1,
            otherFiles: [],
            styles: [],
            typologies: [],
            count: 1,
          },
          {
            typology: {
              _id: "62f69b347671370f6f1ff8a7",
              name: "Decoração",
              category: "typology",
            },
            projectTemplateNames: [
              "Quarto casal",
              "Sala de estar",
              "Quarto de hóspedes",
              "Sala de estar e jantar",
              "Home office",
              "Escritório ",
              "Recepção",
              "Quarto jovem",
              "Sala de estar, jantar e cozinha",
              "Sala de Jantar",
              "Cozinha",
              "Sacada | Churrasqueira ",
              "Studio até 40m²",
            ],
            _id: "5f412378e127a2689dab07c5",
            sku: "367627",
            name: "KARIBU VASO 13 CM",
            refx: "12",
            refy: "12",
            refz: "13",
            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5f412378e127a2689dab07c5/main_picture.jpg?v=1633032811119",
            status: true,
            updatedAt: "2022-08-29T18:26:19.354Z",
            supplier: "Tok&Stok",
            available: false,
            latestUnits: true,
            frequency_used: 165,
            tags: [
              {
                _id: "60ad91f71beb6dd98f4daf5d",
                name: "Decoração",
              },
              {
                _id: "60ad8df4a76cf1b577071bc6",
                name: "Acessórios",
              },
              {
                _id: "60ad93b21beb6dd98f4dafa9",
                name: "Objetos Decorativos",
              },
              {
                _id: "60ad91411beb6dd98f4daf26",
                name: "Ambientes",
              },
              {
                _id: "60ad91f71beb6dd98f4daf5e",
                name: "Vasos",
              },
            ],
            colors: [
              {
                _id: "604b58bbebab150fdefee361",
                name: "Preto",
                createdAt: "2021-03-12T12:04:11.194Z",
                updatedAt: "2021-03-12T12:04:11.194Z",
                __v: 0,
              },
            ],
            file: "367627_v2.FBX",
            notUse: true,
            value: 79.9,
            inventory: 0,
            __v: 1,
            otherFiles: [],
            styles: [],
            typologies: [],
            count: 1,
          },
          {
            typology: {
              _id: "62f69b347671370f6f1ff8a7",
              name: "Decoração",
              category: "typology",
            },
            projectTemplateNames: [
              "Sala de estar",
              "Home office",
              "Sala de estar e jantar",
              "Escritório ",
              "Sala de Jantar",
              "Quarto casal",
              "Quarto de hóspedes",
              "Banheiro",
              "Sacada | Churrasqueira ",
              "Sala de estar, jantar e cozinha",
            ],
            _id: "5f412378e127a2689dab08e5",
            sku: "365239",
            name: "CENTAURI  VASO 37 CM",
            refx: "11",
            refy: "11",
            refz: "37",
            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5f412378e127a2689dab08e5/main_picture.jpg?v=1633032820315",
            status: true,
            updatedAt: "2022-08-29T17:42:15.095Z",
            supplier: "Tok&Stok",
            available: false,
            latestUnits: true,
            frequency_used: 48,
            tags: [
              {
                _id: "60ad93b21beb6dd98f4dafa9",
                name: "Objetos Decorativos",
              },
              {
                _id: "60ad91411beb6dd98f4daf26",
                name: "Ambientes",
              },
            ],
            colors: [
              {
                _id: "60ad943d1beb6dd98f4dafc5",
                name: "Dourado",
              },
            ],
            file: "365239_v2.FBX",
            notUse: true,
            value: 149.9,
            inventory: 0,
            __v: 1,
            otherFiles: [],
            styles: [],
            typologies: [],
            count: 1,
          },
          {
            typology: {
              _id: "62f69b347671370f6f1ff8a7",
              name: "Decoração",
              category: "typology",
            },
            projectTemplateNames: [
              "Sala de estar e jantar",
              "Home office",
              "Sala de estar",
              "Quarto casal",
              "Sala de Jantar",
              "Escritório ",
              "Quarto de casal com Closet",
              "Quarto de hóspedes",
              "Sala de reuniões",
              "Banheiro",
              "Sacada | Churrasqueira ",
              "Sala de estar, jantar e cozinha",
            ],
            _id: "5f412378e127a2689dab08e8",
            sku: "365235",
            name: "CENTAURI  VASO 24 CM",
            refx: "18",
            refy: "18",
            refz: "24",
            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5f412378e127a2689dab08e8/main_picture.jpg?v=1633032816389",
            status: true,
            updatedAt: "2022-08-29T18:26:52.678Z",
            supplier: "Tok&Stok",
            available: false,
            latestUnits: true,
            frequency_used: 82,
            tags: [
              {
                _id: "60ad93b21beb6dd98f4dafa9",
                name: "Objetos Decorativos",
              },
              {
                _id: "60ad91411beb6dd98f4daf26",
                name: "Ambientes",
              },
            ],
            colors: [
              {
                _id: "60ad943d1beb6dd98f4dafc5",
                name: "Dourado",
              },
            ],
            file: "365235_v2.FBX",
            notUse: true,
            value: 299.9,
            inventory: 0,
            __v: 1,
            otherFiles: [],
            styles: [],
            typologies: [],
            count: 1,
          },
          {
            typology: {
              _id: "62f69b347671370f6f1ff8a7",
              name: "Decoração",
              category: "typology",
            },
            projectTemplateNames: [
              "Home office",
              "Cozinha",
              "Sala de estar",
              "Quarto jovem",
              "Sala de estar e jantar",
              "Banheiro",
              "Sala de estar, jantar e cozinha",
              "Studio até 40m²",
              "Quarto casal",
              "Sala de Jantar",
              "Quarto de hóspedes",
              "Escritório ",
              "Recepção",
              "Sacada | Churrasqueira ",
            ],
            _id: "5f412378e127a2689dab0959",
            sku: "364793",
            name: "CASPIO VASO 10 CM",
            refx: "8",
            refy: "8",
            refz: "10",
            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5f412378e127a2689dab0959/main_picture.jpg?v=1633032813890",
            status: true,
            updatedAt: "2022-08-29T17:41:03.798Z",
            supplier: "Tok&Stok",
            available: false,
            latestUnits: true,
            frequency_used: 112,
            tags: [
              {
                _id: "60ad91f71beb6dd98f4daf5d",
                name: "Decoração",
              },
              {
                _id: "60ad8df4a76cf1b577071bc6",
                name: "Acessórios",
              },
              {
                _id: "60ad93b21beb6dd98f4dafa9",
                name: "Objetos Decorativos",
              },
              {
                _id: "60ad91411beb6dd98f4daf26",
                name: "Ambientes",
              },
              {
                _id: "60ad91f71beb6dd98f4daf5e",
                name: "Vasos",
              },
            ],
            colors: [
              {
                _id: "604b58bbebab150fdefee361",
                name: "Preto",
                createdAt: "2021-03-12T12:04:11.194Z",
                updatedAt: "2021-03-12T12:04:11.194Z",
                __v: 0,
              },
            ],
            file: "364793_V2.FBX",
            notUse: true,
            value: 49.9,
            inventory: 0,
            __v: 1,
            otherFiles: [],
            styles: [],
            typologies: [],
            count: 1,
          },
          {
            typology: {
              _id: "62f69b347671370f6f1ff8a7",
              name: "Decoração",
              category: "typology",
            },
            projectTemplateNames: [
              "Home office",
              "Quarto jovem",
              "Quarto de hóspedes",
              "Quarto casal",
              "Sala de estar e jantar",
              "Sala de estar",
              "Escritório ",
              "Quarto de casal com Closet",
              "Sala de reuniões",
              "Recepção",
              "Sala de Jantar",
              "Quarto infantil",
              "Studio até 40m²",
              "Cozinha",
              "Escritório",
              "Coworking",
              "Franquia Re/Max",
              "Sacada | Churrasqueira ",
            ],
            _id: "5f412378e127a2689dab0a38",
            sku: "364007",
            name: "SOLO GLOBO ADORNO 25 CM",
            refx: "14",
            refy: "13",
            refz: "25",
            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5f412378e127a2689dab0a38/main_picture.jpg?v=1633032812607",
            status: true,
            updatedAt: "2022-08-29T17:40:14.756Z",
            supplier: "Tok&Stok",
            available: false,
            latestUnits: true,
            frequency_used: 222,
            tags: [
              {
                _id: "60ad91f71beb6dd98f4daf5d",
                name: "Decoração",
              },
              {
                _id: "60ad8df4a76cf1b577071bc6",
                name: "Acessórios",
              },
              {
                _id: "60ad94c31beb6dd98f4dafce",
                name: "Adornos e Objetos Decorativos",
              },
              {
                _id: "60ad93b21beb6dd98f4dafa9",
                name: "Objetos Decorativos",
              },
              {
                _id: "60ad91411beb6dd98f4daf26",
                name: "Ambientes",
              },
            ],
            colors: [
              {
                _id: "604b58bbebab150fdefee361",
                name: "Preto",
                createdAt: "2021-03-12T12:04:11.194Z",
                updatedAt: "2021-03-12T12:04:11.194Z",
                __v: 0,
              },
            ],
            file: "364007_v2.FBX",
            value: 239.9,
            inventory: 0,
            notUse: false,
            __v: 1,
            otherFiles: [],
            styles: [],
            typologies: [],
            count: 1,
          },
          {
            typology: {
              _id: "62f69b347671370f6f1ff8a7",
              name: "Decoração",
              category: "typology",
            },
            projectTemplateNames: [
              "Quarto jovem",
              "Sacada | Churrasqueira ",
              "Quarto casal",
              "Sala de estar e jantar",
              "Home office",
              "Sala de estar",
              "Sala de estar, jantar e cozinha",
              "Sala de Jantar",
              "Escritório ",
              "Cozinha",
              "Banheiro",
              "Quarto de hóspedes",
              "Quarto de casal com Closet",
              "Studio até 40m²",
              "Coworking",
              "Salão gourmet",
            ],
            _id: "5f412378e127a2689dab0b8d",
            sku: "363169",
            name: "GRES VASO 20 CM",
            refx: "10",
            refy: "10",
            refz: "20.3",
            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5f412378e127a2689dab0b8d/main_picture.jpg?v=1633032814603",
            status: true,
            updatedAt: "2022-08-29T17:40:41.295Z",
            supplier: "Tok&Stok",
            available: false,
            latestUnits: true,
            frequency_used: 143,
            tags: [
              {
                _id: "60ad91f71beb6dd98f4daf5d",
                name: "Decoração",
              },
              {
                _id: "60ad8df4a76cf1b577071bc6",
                name: "Acessórios",
              },
              {
                _id: "60ad93b21beb6dd98f4dafa9",
                name: "Objetos Decorativos",
              },
              {
                _id: "60ad91411beb6dd98f4daf26",
                name: "Ambientes",
              },
              {
                _id: "60ad91f71beb6dd98f4daf5e",
                name: "Vasos",
              },
            ],
            colors: [
              {
                _id: "604b58d9ebab150fdefee362",
                name: "Cinza",
                createdAt: "2021-03-12T12:04:41.856Z",
                updatedAt: "2021-03-12T12:04:41.856Z",
                __v: 0,
              },
            ],
            file: "363169_v2.FBX",
            value: 39.9,
            inventory: 0,
            notUse: true,
            __v: 1,
            otherFiles: [],
            styles: [],
            typologies: [],
            count: 1,
          },
          {
            typology: {
              _id: "62f69b347671370f6f1ff8a7",
              name: "Decoração",
              category: "typology",
            },
            projectTemplateNames: [
              "Sala de estar e jantar",
              "Sala de estar",
              "Varanda | Churrasqueira ",
              "Cozinha",
              "Sala de Jantar",
              "Sacada | Churrasqueira ",
              "Sala de estar, jantar e cozinha",
              "Área de serviço",
              "Quarto de hóspedes",
              "Escritório ",
            ],
            _id: "5f412378e127a2689dab0e65",
            sku: "358555",
            name: "STRUCTURE HEX PORTA-GARRAFAS P/6",
            refx: "30",
            refy: "18",
            refz: "28",
            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5f412378e127a2689dab0e65/main_picture.jpg?v=1633032813552",
            status: true,
            updatedAt: "2022-08-29T17:40:40.576Z",
            supplier: "Tok&Stok",
            available: true,
            latestUnits: false,
            frequency_used: 146,
            tags: [
              {
                _id: "60adb2b21beb6dd98f4db020",
                name: "Acessórios para Bar",
              },
              {
                _id: "60ad8df4a76cf1b577071bc6",
                name: "Acessórios",
              },
              {
                _id: "60adb2b21beb6dd98f4db021",
                name: "Acessórios para bar",
              },
              {
                _id: "60ad93a81beb6dd98f4dafa3",
                name: "Baldes para Gelo e Garrafa",
              },
              {
                _id: "60ad91411beb6dd98f4daf26",
                name: "Ambientes",
              },
              {
                _id: "60ad93881beb6dd98f4daf93",
                name: "Mesa",
              },
            ],
            colors: [
              {
                _id: "60ad919f1beb6dd98f4daf3f",
                name: "Cobre",
              },
            ],
            file: "358555_v2.FBX",
            value: 239.9,
            inventory: 40,
            notUse: false,
            __v: 1,
            otherFiles: [],
            styles: [],
            typologies: [],
            count: 1,
          },
          {
            typology: {
              _id: "62f69b347671370f6f1ff8a7",
              name: "Decoração",
              category: "typology",
            },
            projectTemplateNames: [
              "Sala de estar e jantar",
              "Home office",
              "Sala de estar",
              "Quarto casal",
              "Sala de Jantar",
              "Recepção",
            ],
            _id: "5f412378e127a2689dab0eca",
            sku: "358017",
            name: "HYGGE MANTA 1,20 M X 1,70 M",
            refx: "120",
            refy: "170",
            refz: "3",
            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5f412378e127a2689dab0eca/main_picture.PNG?v=1617018519542",
            status: false,
            updatedAt: "2022-08-29T18:29:03.425Z",
            supplier: "Tok&Stok",
            available: false,
            latestUnits: true,
            frequency_used: 19,
            colors: [
              {
                _id: "60ad8df4a76cf1b577071bc8",
                name: "Bege",
              },
            ],
            styles: [],
            tags: [
              {
                _id: "60ad91411beb6dd98f4daf26",
                name: "Ambientes",
              },
              {
                _id: "60adb9731beb6dd98f4db02d",
                name: "Mantas e Cobertores",
              },
              {
                _id: "60ad8df4a76cf1b577071bc6",
                name: "Acessórios",
              },
              {
                _id: "60ad947f1beb6dd98f4dafc8",
                name: "Cama",
              },
              {
                _id: "60ad947f1beb6dd98f4dafc9",
                name: "Edredons, Colchas e Mantas",
              },
            ],
            typologies: [],
            notUse: true,
            value: 129.9,
            inventory: 0,
            __v: 1,
            otherFiles: [],
            count: 1,
          },
          {
            typology: {
              _id: "62f64a127671370f6f1fbd7c",
              name: "Iluminação",
              category: "typology",
            },
            category: {
              _id: "62f64a367671370f6f1fbd9c",
              name: "Iluminação decorativa",
              category: "typology",
            },
            subCategory: null,
            projectTemplateNames: [
              "Sala de estar e jantar",
              "Sala de estar",
              "Quarto casal",
              "Studio até 40m²",
              "Sala de estar, jantar e cozinha",
              "Home office",
              "Sala de Jantar",
              "Escritório ",
              "Coworking",
              "Recepção",
              "Franquia Re/Max",
            ],
            _id: "5f412378e127a2689dab105e",
            sku: "356245",
            name: "PELICANO LUMINÁRIA PISO - PRETO/WHISKY | 1xBULBO",
            refx: "23",
            refy: "33",
            refz: "154",
            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5f412378e127a2689dab105e/main_picture.jpg?v=1633032821090",
            status: true,
            updatedAt: "2022-09-05T17:11:29.640Z",
            supplier: "Tok&Stok",
            available: true,
            latestUnits: false,
            frequency_used: 88,
            tags: [
              {
                _id: "60ad8df4a76cf1b577071bc5",
                name: "Iluminação",
              },
              {
                _id: "60ad8df4a76cf1b577071bc6",
                name: "Acessórios",
              },
              {
                _id: "60ad91fa1beb6dd98f4daf63",
                name: "Luminárias de Chão",
              },
              {
                _id: "60ad91411beb6dd98f4daf26",
                name: "Ambientes",
              },
              {
                _id: "60ad91b91beb6dd98f4daf4b",
                name: "Luminárias e Cúpulas",
              },
            ],
            colors: [
              {
                _id: "604b58bbebab150fdefee361",
                name: "Preto",
              },
              {
                _id: "60ad913f1beb6dd98f4daf25",
                name: "Madeira",
              },
              {
                _id: "6109a9f70c04101a12481ae0",
                name: "couro",
              },
            ],
            file: "356245_v2.fbx",
            value: 419.9,
            notUse: false,
            styles: [
              {
                _id: "6082d43ac8e1fd02c5f408a1",
                name: "Contemporâneo",
              },
              {
                _id: "6136678d6b1a4b0f58cfde84",
                name: "Urbano Contemporâneo",
              },
              {
                _id: "614e0340ab8f3c0f56055fe3",
                name: "Urbano Dark Decor",
              },
              {
                _id: "6093db388940ec0fbf72a8d9",
                name: "industrial",
              },
              {
                _id: "60894e815069cc0fba41d5e2",
                name: "moderno",
              },
              {
                _id: "60899aba75206a0fcab218fc",
                name: "rústico",
              },
            ],
            typologies: [
              {
                _id: "6089494e5069cc0fba41d532",
                name: "iluminação decorativa",
              },
            ],
            inventory: 380,
            __v: 1,
            otherFiles: [],
            count: 1,
          },
          {
            typology: {
              _id: "62f69b347671370f6f1ff8a7",
              name: "Decoração",
              category: "typology",
            },
            projectTemplateNames: [
              "Sala de estar",
              "Home office",
              "Sala de estar e jantar",
              "Quarto jovem",
              "Quarto casal",
              "Quarto de hóspedes",
              "Sala de estar, jantar e cozinha",
              "Sala de Jantar",
              "Escritório ",
              "Studio até 40m²",
              "Sala de reuniões",
              "Quarto infantil",
              "Cozinha",
              "Sacada | Churrasqueira ",
              "Escritório",
              "Quarto de casal com Closet",
              "Coworking",
              "Escritório individual ",
              "Franquia Re/Max",
            ],
            _id: "5f412378e127a2689dab13d4",
            sku: "350097",
            name: "HANG IT AZ APARADOR DE LIVROS C/2 - PRETO/PRETO",
            refx: "15",
            refy: "12",
            refz: "19",
            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5f412378e127a2689dab13d4/main_picture.jpg?v=1633032810298",
            status: true,
            updatedAt: "2022-08-29T17:39:50.228Z",
            supplier: "Tok&Stok",
            available: true,
            latestUnits: false,
            frequency_used: 651,
            tags: [
              {
                _id: "60ad93851beb6dd98f4daf8a",
                name: "Aparador de Livros",
              },
              {
                _id: "60ad91b51beb6dd98f4daf47",
                name: "Escritório",
              },
              {
                _id: "60ad8df4a76cf1b577071bc6",
                name: "Acessórios",
              },
              {
                _id: "60ad93851beb6dd98f4daf8b",
                name: "Escritório e Papelaria",
              },
              {
                _id: "60ad93851beb6dd98f4daf8c",
                name: "Organizadores e Porta Objetos",
              },
              {
                _id: "60ad91411beb6dd98f4daf26",
                name: "Ambientes",
              },
              {
                _id: "60ad93851beb6dd98f4daf8d",
                name: "Acessórios de Mesa",
              },
            ],
            colors: [
              {
                _id: "604b58bbebab150fdefee361",
                name: "Preto",
              },
            ],
            file: "350097.fbx",
            value: 139.9,
            notUse: false,
            styles: [],
            typologies: [
              {
                _id: "6082d3e9c8e1fd02c5f408a0",
                name: "objeto decorativo",
              },
            ],
            inventory: 19,
            __v: 1,
            otherFiles: [],
            count: 1,
          },
          {
            typology: {
              _id: "62f68f4d7671370f6f1fe852",
              name: "Móveis soltos",
              category: "typology",
            },
            projectTemplateNames: [
              "Sala de estar e jantar",
              "Sala de estar",
              "Quarto de hóspedes",
              "Quarto casal",
              "Sala de estar, jantar e cozinha",
              "Sacada | Churrasqueira ",
              "Sala de Jantar",
              "Escritório ",
              "Home office",
              "Quarto jovem",
              "Sala de reuniões",
            ],
            _id: "5f412378e127a2689dab175c",
            sku: "399517",
            name: "COMFY PUFE",
            refx: "40",
            refy: "40",
            refz: "42",
            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5f412378e127a2689dab175c/main_picture.JPG?v=1628859838769",
            status: true,
            updatedAt: "2022-08-22T17:05:43.046Z",
            supplier: "Tok&Stok",
            available: false,
            latestUnits: true,
            frequency_used: 143,
            tags: [
              {
                _id: "60ad924d1beb6dd98f4daf71",
                name: "Pufes",
              },
              {
                _id: "60ad91911beb6dd98f4daf3b",
                name: "Puffs",
              },
            ],
            colors: [
              {
                _id: "604b58bbebab150fdefee361",
                name: "Preto",
              },
            ],
            file: "399517 .fbx",
            value: 599.99,
            notUse: false,
            styles: [
              {
                _id: "6082d43ac8e1fd02c5f408a1",
                name: "Contemporâneo",
              },
              {
                _id: "60d5c1a87aa0e156b26ae791",
                name: "Escandinavo",
              },
              {
                _id: "60d5c1ae7aa0e156b26ae792",
                name: "Minimalista",
              },
              {
                _id: "6093db388940ec0fbf72a8d9",
                name: "industrial",
              },
              {
                _id: "60894e815069cc0fba41d5e2",
                name: "moderno",
              },
            ],
            typologies: [
              {
                _id: "608310b8803e5c0fd365b8ba",
                name: "mobiliário solto",
              },
            ],
            inventory: 7,
            __v: 1,
            otherFiles: [],
            count: 2,
          },
          {
            typology: {
              _id: "62f69b347671370f6f1ff8a7",
              name: "Decoração",
              category: "typology",
            },
            projectTemplateNames: [
              "Quarto casal",
              "Sala de estar e jantar",
              "Sala de estar",
              "Quarto jovem",
              "Sala de Jantar",
              "Quarto de bebê",
              "Quarto infantil",
              "Sala de estar, jantar e cozinha",
              "Studio até 40m²",
              "Sacada | Churrasqueira ",
              "Quarto de casal com Closet",
              "Home office",
              "Escritório ",
              "Cozinha",
              "Quarto de hóspedes",
            ],
            _id: "5f412378e127a2689dab191a",
            sku: "337175",
            name: "DIA ENCOSTADO CAPA DE ALMOFADA 45CM C/2 - BRANCO/PRETO",
            refx: "45",
            refy: "0",
            refz: "45",
            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5f412378e127a2689dab191a/main_picture.jpg?v=1633032813789",
            status: true,
            updatedAt: "2022-08-29T17:40:36.306Z",
            supplier: "Tok&Stok",
            available: false,
            latestUnits: true,
            frequency_used: 152,
            tags: [
              {
                _id: "60ad91f71beb6dd98f4daf5d",
                name: "Decoração",
              },
              {
                _id: "60ad8df4a76cf1b577071bc6",
                name: "Acessórios",
              },
              {
                _id: "60ad94d41beb6dd98f4dafd4",
                name: "Almofadas e Rolos",
              },
              {
                _id: "60ad94d41beb6dd98f4dafd5",
                name: "Almofadas e Enchimentos",
              },
              {
                _id: "60ad91411beb6dd98f4daf26",
                name: "Ambientes",
              },
            ],
            colors: [
              {
                _id: "604b58bbebab150fdefee361",
                name: "Preto",
                createdAt: "2021-03-12T12:04:11.194Z",
                updatedAt: "2021-03-12T12:04:11.194Z",
                __v: 0,
              },
            ],
            file: "337175_v2.FBX",
            value: 35.9,
            inventory: -2,
            notUse: false,
            __v: 1,
            otherFiles: [],
            styles: [],
            typologies: [],
            count: 1,
          },
          {
            typology: {
              _id: "62f69b347671370f6f1ff8a7",
              name: "Decoração",
              category: "typology",
            },
            projectTemplateNames: [
              "Varanda | Churrasqueira ",
              "Quarto jovem",
              "Quarto casal",
              "Sala de estar",
              "Sala de estar e jantar",
              "Quarto infantil",
              "Quarto de casal com Closet",
              "Home office",
              "Sacada | Churrasqueira ",
              "Studio até 40m²",
              "Sala de estar, jantar e cozinha",
              "Cozinha e Jantar",
              "Escritório ",
              "Quarto de hóspedes",
              "Sala de Jantar",
              "Recepção",
              "Closet",
              "Sala de jogos",
              "Salão de festas",
              "Loja ",
            ],
            _id: "5f412378e127a2689dab1962",
            sku: "335184",
            name: "LORD CAPA ALMOFADA 45CM - COBRE",
            refx: "45",
            refy: "0",
            refz: "45",
            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5f412378e127a2689dab1962/main_picture.jpg?v=1633032810851",
            status: true,
            updatedAt: "2022-08-29T17:39:57.000Z",
            supplier: "Tok&Stok",
            available: true,
            latestUnits: false,
            frequency_used: 319,
            tags: [
              {
                _id: "60ad91f71beb6dd98f4daf5d",
                name: "Decoração",
              },
              {
                _id: "60ad8df4a76cf1b577071bc6",
                name: "Acessórios",
              },
              {
                _id: "60ad94d41beb6dd98f4dafd4",
                name: "Almofadas e Rolos",
              },
              {
                _id: "60ad94d41beb6dd98f4dafd5",
                name: "Almofadas e Enchimentos",
              },
              {
                _id: "60ad91411beb6dd98f4daf26",
                name: "Ambientes",
              },
            ],
            colors: [
              {
                _id: "60ad919f1beb6dd98f4daf3f",
                name: "Cobre",
              },
            ],
            file: "335184_v2.fbx",
            value: 69.9,
            notUse: false,
            styles: [],
            typologies: [
              {
                _id: "6082d3e9c8e1fd02c5f408a0",
                name: "objeto decorativo",
              },
            ],
            inventory: 101,
            __v: 1,
            otherFiles: [],
            count: 1,
          },
          {
            typology: {
              _id: "62f68f4d7671370f6f1fe852",
              name: "Móveis soltos",
              category: "typology",
            },
            projectTemplateNames: [
              "Sala de estar, jantar e cozinha",
              "Sacada | Churrasqueira ",
              "Sala de estar",
              "Cozinha",
              "Quarto casal",
              "Studio até 40m²",
            ],
            _id: "5f412378e127a2689dab1a3b",
            sku: "331700",
            name: "PRADO CADEIRA",
            refx: "50",
            refy: "55",
            refz: "77.5",
            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5f412378e127a2689dab1a3b/main_picture.jpg?v=1633032922684",
            status: true,
            updatedAt: "2022-08-22T21:23:34.075Z",
            supplier: "Tok&Stok",
            available: true,
            latestUnits: false,
            frequency_used: 11,
            tags: [
              {
                _id: "60ad913c1beb6dd98f4daf20",
                name: "Móveis Office",
              },
              {
                _id: "60ad91c21beb6dd98f4daf50",
                name: "Bancos e Cadeiras",
              },
              {
                _id: "60ad916a1beb6dd98f4daf37",
                name: "Móveis para Varanda e Jardim",
              },
              {
                _id: "60ad8df3a76cf1b577071bc2",
                name: "Móveis",
              },
              {
                _id: "60ad91411beb6dd98f4daf26",
                name: "Ambientes",
              },
              {
                _id: "60ad91c21beb6dd98f4daf51",
                name: "Cadeiras",
              },
            ],
            colors: [
              {
                _id: "604b58bbebab150fdefee361",
                name: "Preto",
                createdAt: "2021-03-12T12:04:11.194Z",
                updatedAt: "2021-03-12T12:04:11.194Z",
                __v: 0,
              },
            ],
            file: "331700_V2.FBX",
            value: 599.99,
            inventory: 380,
            notUse: false,
            __v: 1,
            otherFiles: [],
            styles: [],
            typologies: [],
            count: 2,
          },
          {
            typology: {
              _id: "62f69b347671370f6f1ff8a7",
              name: "Decoração",
              category: "typology",
            },
            projectTemplateNames: [
              "Quarto casal",
              "Quarto de hóspedes",
              "Quarto jovem",
              "Sala de estar",
              "Quarto de bebê",
              "Sala de estar e jantar",
              "Home office",
              "Quarto infantil",
              "Sacada | Churrasqueira ",
              "Sala de estar, jantar e cozinha",
              "Studio até 40m²",
              "Sala de Jantar",
              "Recepção",
              "Cozinha",
              "Escritório ",
              "Sala de reuniões",
            ],
            _id: "5f412378e127a2689dab1c96",
            sku: "313781",
            name: "GRAFIC ALMOFADA 45 - PRETO/NATURAL",
            refx: "45",
            refy: "0",
            refz: "45",
            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5f412378e127a2689dab1c96/main_picture.jpg?v=1633032814311",
            status: true,
            updatedAt: "2022-08-29T17:40:58.801Z",
            supplier: "Tok&Stok",
            available: false,
            latestUnits: true,
            frequency_used: 118,
            tags: [
              {
                _id: "60ad91f71beb6dd98f4daf5d",
                name: "Decoração",
              },
              {
                _id: "60ad8df4a76cf1b577071bc6",
                name: "Acessórios",
              },
              {
                _id: "60ad94d41beb6dd98f4dafd4",
                name: "Almofadas e Rolos",
              },
              {
                _id: "60ad94d41beb6dd98f4dafd5",
                name: "Almofadas e Enchimentos",
              },
              {
                _id: "60ad91411beb6dd98f4daf26",
                name: "Ambientes",
              },
            ],
            colors: [
              {
                _id: "604b58bbebab150fdefee361",
                name: "Preto",
                createdAt: "2021-03-12T12:04:11.194Z",
                updatedAt: "2021-03-12T12:04:11.194Z",
                __v: 0,
              },
            ],
            file: "313781_v2.FBX",
            value: 139.9,
            inventory: 0,
            notUse: true,
            __v: 1,
            otherFiles: [],
            styles: [],
            typologies: [],
            count: 1,
          },
          {
            typology: {
              _id: "62f69b347671370f6f1ff8a7",
              name: "Decoração",
              category: "typology",
            },
            projectTemplateNames: [
              "Varanda | Churrasqueira ",
              "Sala de estar e jantar",
              "Sala de estar",
              "Cozinha",
              "Sacada | Churrasqueira ",
              "Sala de Jantar",
              "Sala de estar, jantar e cozinha",
              "Escritório ",
              "Recepção",
              "Sala de reuniões",
              "Franquia Re/Max",
              "Salão gourmet",
              "Studio até 40m²",
            ],
            _id: "5f412378e127a2689dab1e0a",
            sku: "285274",
            name: "BIZOTS BANDEJA 45 CM X 30 CM - PRATA",
            refx: "45",
            refy: "30",
            refz: "5",
            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5f412378e127a2689dab1e0a/main_picture.jpg?v=1633032812704",
            status: true,
            updatedAt: "2022-08-29T17:40:23.708Z",
            supplier: "Tok&Stok",
            available: true,
            latestUnits: false,
            frequency_used: 195,
            tags: [
              {
                _id: "60ad8df4a76cf1b577071bc6",
                name: "Acessórios",
              },
              {
                _id: "60ad93b41beb6dd98f4dafab",
                name: "Louças e Utensílios para Servir",
              },
              {
                _id: "60ad91411beb6dd98f4daf26",
                name: "Ambientes",
              },
              {
                _id: "60ad93881beb6dd98f4daf93",
                name: "Mesa",
              },
              {
                _id: "60adaf1a1beb6dd98f4db016",
                name: "Bandejas",
              },
            ],
            colors: [
              {
                _id: "60ad919d1beb6dd98f4daf3e",
                name: "Metal",
              },
            ],
            file: "285274_v2.FBX",
            value: 352.9,
            inventory: 564,
            notUse: false,
            __v: 1,
            otherFiles: [],
            styles: [],
            typologies: [],
            count: 1,
          },
          {
            typology: {
              _id: "62f69b347671370f6f1ff8a7",
              name: "Decoração",
              category: "typology",
            },
            projectTemplateNames: [
              "Sala de estar e jantar",
              "Quarto casal",
              "Sala de estar",
              "Sala de Jantar",
              "Home office",
              "Cozinha",
              "Quarto de hóspedes",
              "Banheiro",
              "Sacada | Churrasqueira ",
              "Escritório ",
              "Quarto infantil",
              "Sala de estar, jantar e cozinha",
              "Loja de acessorios",
              "Escritório",
              "Hall de entrada | Portaria",
              "Escritório individual ",
              "Studio até 40m²",
            ],
            _id: "5f69f97900348e0faa8fd5cd",
            name: "TE QUIERO CONE VASO 24 CM",
            sku: "144465",
            refx: "8,5",
            refy: "8,5",
            refz: "24",
            refimage:
              "https://tokstok.vteximg.com.br/arquivos/ids/1812513-1000-1000/Cone-Vaso-24-Cm-Incolor-Te-Quiero.jpg?v=637012288160830000",
            supplier: "Tok&Stok",
            createdAt: "2020-09-22T13:17:45.939Z",
            updatedAt: "2022-08-29T18:26:28.522Z",
            __v: 0,
            available: false,
            latestUnits: true,
            frequency_used: 100,
            tags: [
              {
                _id: "60ad8df4a76cf1b577071bc6",
                name: "Acessórios",
              },
              {
                _id: "60ad91f71beb6dd98f4daf5d",
                name: "Decoração",
              },
              {
                _id: "60ad93b21beb6dd98f4dafa9",
                name: "Objetos Decorativos",
              },
              {
                _id: "60ad91411beb6dd98f4daf26",
                name: "Ambientes",
              },
              {
                _id: "60ad91f71beb6dd98f4daf5e",
                name: "Vasos",
              },
            ],
            colors: [
              {
                _id: "60ad92021beb6dd98f4daf65",
                name: "Incolor",
              },
            ],
            value: 55.9,
            inventory: 0,
            notUse: false,
            file: "144465.fbx",
            otherFiles: [],
            styles: [],
            typologies: [],
            count: 1,
          },
          {
            typology: {
              _id: "62f69b347671370f6f1ff8a7",
              name: "Decoração",
              category: "typology",
            },
            projectTemplateNames: [
              "Sala de estar e jantar",
              "Sala de estar",
              "Home office",
              "Quarto casal",
              "Quarto de casal com Closet",
              "Quarto jovem",
              "Varanda | Churrasqueira ",
              "Sala de estar, jantar e cozinha",
              "Sacada | Churrasqueira ",
              "Escritório ",
              "Quarto de hóspedes",
              "Sala de Jantar",
              "Banheiro",
              "Quarto infantil",
              "Salão gourmet",
              "Cozinha",
              "Recepção",
              "Loja de acessorios",
              "Studio até 40m²",
              "Academia",
              "Quarto de bebê",
            ],
            _id: "5f6a3e3c253d07338aa9640b",
            name: "TABOA POTIM CESTO 27 CM X 27 CM X16 CM",
            sku: "333087",
            refx: "27",
            refy: "27",
            refz: "16",
            refimage:
              "https://tokstok.vteximg.com.br/arquivos/ids/2259065-1000-1000/potim-cesto-27-cm-x-27-cm-x16-cm-natural-taboa_st0.jpg?v=637291249414930000",
            supplier: "Tok&Stok",
            createdAt: "2020-09-22T18:11:08.190Z",
            updatedAt: "2022-08-29T17:40:07.836Z",
            __v: 0,
            available: true,
            latestUnits: false,
            frequency_used: 276,
            tags: [
              {
                _id: "60ad91f71beb6dd98f4daf5d",
                name: "Decoração",
              },
              {
                _id: "60ad8df4a76cf1b577071bc6",
                name: "Acessórios",
              },
              {
                _id: "60ad93c51beb6dd98f4dafb9",
                name: "Cestos, Caixas e Baús Artesanais",
              },
              {
                _id: "60ad93c61beb6dd98f4dafba",
                name: "Artesanato",
              },
              {
                _id: "60ad93c61beb6dd98f4dafbb",
                name: "Cestos e Caixas",
              },
            ],
            colors: [
              {
                _id: "60f1745548f42b6a2421cee6",
                name: "Palha",
              },
            ],
            value: 119.9,
            file: "333087.fbx",
            notUse: false,
            styles: [],
            typologies: [],
            inventory: 487,
            otherFiles: [],
            count: 1,
          },
          {
            typology: {
              _id: "6303b88b88e535103b196c85",
              name: "Materiais especificados",
              category: "typology",
            },
            projectTemplateNames: [
              "Quarto casal",
              "Cozinha",
              "Sala de estar e jantar",
              "Quarto jovem",
              "Sala de estar",
              "Sacada | Churrasqueira ",
              "Sala de estar, jantar e cozinha",
              "Studio até 40m²",
              "Quarto de casal com Closet",
              "Home office",
              "Quarto infantil",
              "Banheiro",
              "Quarto de hóspedes",
              "Coworking",
              "Salão gourmet",
              "Franquia Re/Max",
              "Recepção",
              "Hall de entrada | Portaria",
            ],
            _id: "5fa17487e996cd0ff0dc27ed",
            name: "MDF Cinza Sagrado",
            sku: "",
            refx: "",
            refy: "",
            refz: "",
            refimage:
              "https://app.wikeep.com.br/especificacoes/863066223174365_314542530355479.jpg",
            supplier: "Duratex",
            createdAt: "2020-11-03T15:17:27.591Z",
            updatedAt: "2022-08-31T12:51:19.939Z",
            __v: 1,
            frequency_used: 130,
            colors: [],
            otherFiles: [],
            styles: [],
            tags: [],
            typologies: [],
            count: 1,
          },
          {
            typology: {
              _id: "6303b88b88e535103b196c85",
              name: "Materiais especificados",
              category: "typology",
            },
            projectTemplateNames: [
              "Quarto de bebê",
              "Sacada | Churrasqueira ",
              "Quarto infantil",
              "Quarto casal",
              "Sala de estar e jantar",
              "Sala de estar",
              "Escritório",
              "Studio até 40m²",
              "Sala de estar, jantar e cozinha",
              "Banheiro",
              "Sala de Jantar",
              "Quarto de casal com Closet",
              "Cozinha",
              "Quarto jovem",
              "Loja de acessorios",
              "Home office",
              "Quarto de hóspedes",
              "Sala de jogos",
              "Hall de entrada | Portaria",
              "Sala de treinamento",
              "Área de serviço",
              "Recepção",
              "Coworking",
              "Salão gourmet",
              "Escritório ",
              "Sala de reuniões",
            ],
            _id: "5faec39d4e30140fd567c171",
            name: "MDF Carvalho Hanover",
            sku: "",
            refx: "",
            refy: "",
            refz: "",
            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5faec39d4e30140fd567c171/main_picture.PNG?v=1631709090601",
            supplier: "Duratex",
            createdAt: "2020-11-13T17:34:21.690Z",
            updatedAt: "2022-08-31T12:51:12.137Z",
            __v: 1,
            frequency_used: 378,
            colors: [],
            file: "",
            link: "",
            notUse: false,
            styles: [],
            tags: [],
            typologies: [],
            value: null,
            otherFiles: [],
            count: 1,
          },
          {
            typology: {
              _id: "62f3ff3c7f733808b86d5c75",
              name: "Estrutura",
              category: "typology",
            },
            projectTemplateNames: [
              "Home office",
              "Quarto casal",
              "Escritório ",
              "Sala de reuniões",
              "Sacada | Churrasqueira ",
              "Recepção",
              "Sala de estar",
              "Cozinha",
              "Sala de Jantar",
              "Banheiro",
              "Quarto jovem",
              "Studio até 40m²",
              "Quarto de hóspedes",
              "Sala de estar e jantar",
              "Quarto de casal com Closet",
              "Loja de acessorios",
              "Academia",
            ],
            _id: "5fc79e9e9caa030f8b21b170",
            name: "Tinta Tunel de Concreto",
            sku: "",
            refx: "",
            refy: "",
            refz: "",
            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5fc79e9e9caa030f8b21b170/main_picture.JPG?v=1618853324709",
            supplier: "Suvinil",
            createdAt: "2020-12-02T14:03:10.267Z",
            updatedAt: "2022-09-02T13:52:26.431Z",
            __v: 1,
            tags: [],
            frequency_used: 55,
            colors: [],
            notUse: false,
            styles: [],
            typologies: [],
            otherFiles: [],
            count: 1,
          },
          {
            typology: {
              _id: "62f3ff3c7f733808b86d5c75",
              name: "Estrutura",
              category: "typology",
            },
            subCategory: null,
            projectTemplateNames: [
              "Sala de estar e jantar",
              "Quarto jovem",
              "Home office",
              "Sala de estar",
              "Varanda | Churrasqueira ",
              "Quarto casal",
              "Quarto de bebê",
              "Sala de estar, jantar e cozinha",
              "Closet",
              "Banheiro",
              "Cozinha",
              "Sacada | Churrasqueira ",
              "Quarto infantil",
              "Studio até 40m²",
              "Sala de Jantar",
              "Escritório ",
              "Área de serviço",
              "Quarto de hóspedes",
              "Quarto de casal com Closet",
              "Recepção",
              "Sala de reuniões",
              "Escritório",
              "Coworking",
              "Salão gourmet",
              "Hall de entrada | Portaria",
              "Loja de acessorios",
              "Sala de jogos",
              "Brinquedoteca",
              "Academia",
              "Franquia Re/Max",
              "Salão de festas",
              "Copa ",
              "Escritório individual ",
              "Sala de descompressão ",
              "Escritório corretores ",
            ],
            _id: "5ff4ad0633f01e0fe66ac8dd",
            tags: [
              {
                _id: "60ad92b21beb6dd98f4daf79",
                name: "Acessórios Parede e Porta",
              },
            ],
            name: "Tinta Branco Neve ",
            sku: "",
            refx: "",
            refy: "",
            refz: "",
            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/5ff4ad0633f01e0fe66ac8dd/main_picture.png?v=1660059356661",
            supplier: "Suvinil",
            createdAt: "2021-01-05T18:16:38.233Z",
            updatedAt: "2022-09-02T13:52:29.031Z",
            __v: 0,
            frequency_used: 1482,
            colors: [
              {
                _id: "60ad91441beb6dd98f4daf28",
                name: "Branco",
              },
            ],
            file: "",
            notUse: false,
            styles: [
              {
                _id: "6082d43ac8e1fd02c5f408a1",
                name: "Contemporâneo",
              },
              {
                _id: "60d487707aa0e156b26ad6b0",
                name: "Retrô",
              },
              {
                _id: "60882441f60fdc0fd77aec40",
                name: "boho",
              },
              {
                _id: "60ae85a209bc490259ba4ad1",
                name: "caleidocolor",
              },
              {
                _id: "60aba41b78bd530fe01da5bf",
                name: "clássico",
              },
            ],
            typologies: [
              {
                _id: "6088241df60fdc0fd77aec39",
                name: "acabamento de parede",
              },
              {
                _id: "60d4c9007aa0e156b26adf15",
                name: "acabamento de forro",
              },
            ],
            link: "",
            value: 159.9,
            inventory: null,
            otherFiles: [],
            count: 1,
          },
          {
            typology: {
              _id: "62f69b347671370f6f1ff8a7",
              name: "Decoração",
              category: "typology",
            },
            projectTemplateNames: [
              "Sala de estar e jantar",
              "Quarto de hóspedes",
              "Home office",
              "Sala de estar, jantar e cozinha",
              "Quarto casal",
              "Sala de estar",
              "Sacada | Churrasqueira ",
              "Sala de Jantar",
              "Quarto infantil",
              "Cozinha",
              "Quarto jovem",
              "Escritório ",
              "Sala de reuniões",
              "Quarto de bebê",
              "Recepção",
              "Sala de jogos",
              "Studio até 40m²",
            ],
            _id: "60339eb0ab67ba0fc79a19df",
            tags: [
              {
                _id: "60adc7901beb6dd98f4db045",
                name: "Travesseiros e Enchimentos",
              },
              {
                _id: "60ad91f71beb6dd98f4daf5d",
                name: "Decoração",
              },
              {
                _id: "60ad8df4a76cf1b577071bc6",
                name: "Acessórios",
              },
              {
                _id: "60ad94d41beb6dd98f4dafd5",
                name: "Almofadas e Enchimentos",
              },
              {
                _id: "60ad947f1beb6dd98f4dafc8",
                name: "Cama",
              },
            ],
            name: "FEITIO ENCHIMENTO PENA ALMOFADA 45",
            sku: "316823",
            refx: "45",
            refy: "10",
            refz: "45",
            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/60339eb0ab67ba0fc79a19df/main_picture.PNG?v=1613995698067",
            supplier: "Tok&Stok",
            createdAt: "2021-02-22T12:08:16.462Z",
            updatedAt: "2022-08-29T17:40:12.123Z",
            __v: 1,
            available: false,
            latestUnits: true,
            frequency_used: 237,
            colors: [
              {
                _id: "60ad91441beb6dd98f4daf28",
                name: "Branco",
              },
            ],
            value: 45.9,
            inventory: 0,
            notUse: false,
            otherFiles: [],
            styles: [],
            typologies: [],
            count: 3,
          },
          {
            typology: {
              _id: "62f69b347671370f6f1ff8a7",
              name: "Decoração",
              category: "typology",
            },
            projectTemplateNames: [
              "Quarto casal",
              "Quarto de hóspedes",
              "Quarto jovem",
              "Sala de estar",
              "Studio até 40m²",
              "Home office",
              "Sala de Jantar",
              "Sacada | Churrasqueira ",
              "Sala de estar e jantar",
              "Cozinha",
              "Escritório ",
              "Closet",
              "Sala de estar, jantar e cozinha",
              "Quarto infantil",
            ],
            _id: "6081875c375ee50fe5c8fb41",
            tags: [
              {
                _id: "60adb9731beb6dd98f4db02d",
                name: "Mantas e Cobertores",
              },
              {
                _id: "60ad91f71beb6dd98f4daf5d",
                name: "Decoração",
              },
              {
                _id: "60ad8df4a76cf1b577071bc6",
                name: "Acessórios",
              },
              {
                _id: "60adaf0d1beb6dd98f4db012",
                name: "Capas e Xales para Sofá",
              },
              {
                _id: "60ad91411beb6dd98f4daf26",
                name: "Ambientes",
              },
              {
                _id: "60adaf701beb6dd98f4db017",
                name: "Xales e Capas para Sofás",
              },
            ],
            styles: [
              {
                _id: "6082d43ac8e1fd02c5f408a1",
                name: "Contemporâneo",
              },
            ],
            colors: [
              {
                _id: "60ad919f1beb6dd98f4daf3f",
                name: "Cobre",
              },
            ],
            typologies: [
              {
                _id: "6082d3e9c8e1fd02c5f408a0",
                name: "objeto decorativo",
              },
            ],
            name: "DOM XALE P/ SOFÁ 1,20 M X 1,60 M",
            sku: "325565",
            refx: "120",
            refy: "160",
            refz: "",
            notUse: true,
            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/6081875c375ee50fe5c8fb41/main_picture.JPG?v=1619101533404",
            supplier: "Tok&Stok",
            createdAt: "2021-04-22T14:25:32.326Z",
            updatedAt: "2022-08-29T17:41:34.135Z",
            __v: 1,
            available: false,
            latestUnits: true,
            frequency_used: 70,
            value: 99.9,
            file: "325565.fbx",
            inventory: 0,
            otherFiles: [],
            count: 1,
          },
          {
            typology: {
              _id: "62f64a127671370f6f1fbd7c",
              name: "Iluminação",
              category: "typology",
            },
            category: {
              _id: "62f64a367671370f6f1fbd9c",
              name: "Iluminação decorativa",
              category: "typology",
            },
            subCategory: null,
            projectTemplateNames: [
              "Closet",
              "Cozinha e Jantar",
              "Home office",
              "Quarto casal",
              "Quarto jovem",
              "Recepção",
              "Sala de estar e jantar",
              "Sala de reuniões",
              "Studio até 40m²",
              "Sala de estar",
              "Quarto de hóspedes",
              "Escritório ",
              "Sacada | Churrasqueira ",
              "Quarto infantil",
              "Cozinha",
              "Coworking",
              "Sala de estar, jantar e cozinha",
              "Academia",
              "Franquia Re/Max",
              "Copa ",
            ],
            _id: "609c32418eb4850fc6161e36",
            tags: [
              {
                _id: "60ad8df4a76cf1b577071bc6",
                name: "Acessórios",
              },
              {
                _id: "60ad8df4a76cf1b577071bc5",
                name: "Iluminação",
              },
              {
                _id: "60ad947e1beb6dd98f4dafc7",
                name: "Lustres e Pendentes",
              },
            ],
            styles: [
              {
                _bsontype: "ObjectID",
                id: {
                  type: "Buffer",
                  data: [96, 130, 212, 58, 200, 225, 253, 2, 197, 244, 8, 161],
                },
              },
              {
                _bsontype: "ObjectID",
                id: {
                  type: "Buffer",
                  data: [96, 137, 78, 129, 80, 105, 204, 15, 186, 65, 213, 226],
                },
              },
            ],
            colors: [
              {
                _id: "604b58bbebab150fdefee361",
                name: "Preto",
              },
            ],
            typologies: [
              {
                _id: "6089494e5069cc0fba41d532",
                name: "iluminação decorativa",
              },
            ],
            name: "WIRE CAGE LUMINÁRIA TETO | 1xBULBO",
            sku: "400118",
            refx: "14",
            refy: "14",
            refz: "17,2",
            notUse: false,
            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/609c32418eb4850fc6161e36/main_picture.PNG?v=1620849218518",
            supplier: "Tok&Stok",
            createdAt: "2021-05-12T19:53:37.569Z",
            updatedAt: "2022-09-05T18:41:56.975Z",
            __v: 1,
            available: true,
            latestUnits: false,
            frequency_used: 32,
            file: "400118_v2.fbx",
            value: 159.9,
            inventory: 43,
            otherFiles: [],
            count: 3,
          },
          {
            typology: {
              _id: "62f69b347671370f6f1ff8a7",
              name: "Decoração",
              category: "typology",
            },
            projectTemplateNames: [
              "Closet",
              "Escritório ",
              "Home office",
              "Quarto casal",
              "Quarto de hóspedes",
              "Recepção",
              "Sala de estar e jantar",
              "Sala de reuniões",
              "Studio até 40m²",
              "Sala de estar",
              "Quarto jovem",
            ],
            _id: "609ea75f35c3890fd3887a56",
            tags: [
              {
                _id: "60ad96091beb6dd98f4dafe8",
                name: "Quadros e Pôsteres",
              },
              {
                _id: "60ad8df4a76cf1b577071bc6",
                name: "Acessórios",
              },
              {
                _id: "60ad91f71beb6dd98f4daf5d",
                name: "Decoração",
              },
              {
                _id: "60ad95ff1beb6dd98f4dafe6",
                name: "Quadros e Telas",
              },
              {
                _id: "60ad93b21beb6dd98f4dafa9",
                name: "Objetos Decorativos",
              },
              {
                _id: "60ad91411beb6dd98f4daf26",
                name: "Ambientes",
              },
              {
                _id: "60ad94c51beb6dd98f4dafcf",
                name: "Quadros e Pôsters",
              },
            ],
            styles: [
              {
                _id: "608310a9803e5c0fd365b8b9",
                name: "vintage",
              },
              {
                _id: "6082d43ac8e1fd02c5f408a1",
                name: "Contemporâneo",
              },
              {
                _id: "60d5c1ae7aa0e156b26ae792",
                name: "Minimalista",
              },
              {
                _id: "60d487707aa0e156b26ad6b0",
                name: "Retrô",
              },
            ],
            colors: [
              {
                _id: "604b58bbebab150fdefee361",
                name: "Preto",
              },
            ],
            typologies: [
              {
                _id: "6082d3e9c8e1fd02c5f408a0",
                name: "objeto decorativo",
              },
            ],
            name: "BEHIND TYPEWRITER QUADRO 30 CM X 42 CM",
            sku: "347211",
            refx: "30",
            refy: "",
            refz: "42",
            notUse: false,
            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/609ea75f35c3890fd3887a56/main_picture.PNG?v=1621010272926",
            supplier: "Tok&Stok",
            createdAt: "2021-05-14T16:37:51.707Z",
            updatedAt: "2022-08-29T22:09:24.564Z",
            __v: 1,
            available: false,
            latestUnits: true,
            frequency_used: 9,
            file: "347211.fbx",
            value: 57.9,
            inventory: 0,
            otherFiles: [],
            count: 1,
          },
          {
            typology: {
              _id: "62f69b347671370f6f1ff8a7",
              name: "Decoração",
              category: "typology",
            },
            projectTemplateNames: [
              "Closet",
              "Cozinha e Jantar",
              "Escritório ",
              "Home office",
              "Quarto casal",
              "Quarto de hóspedes",
              "Quarto jovem",
              "Recepção",
              "Sala de estar e jantar",
              "Sala de reuniões",
              "Studio até 40m²",
              "Sala de estar",
              "Sala de Jantar",
            ],
            _id: "609ea7b335c3890fd3887aae",
            tags: [
              {
                _id: "60ad96091beb6dd98f4dafe8",
                name: "Quadros e Pôsteres",
              },
              {
                _id: "60ad8df4a76cf1b577071bc6",
                name: "Acessórios",
              },
              {
                _id: "60ad91f71beb6dd98f4daf5d",
                name: "Decoração",
              },
              {
                _id: "60ad95ff1beb6dd98f4dafe6",
                name: "Quadros e Telas",
              },
              {
                _id: "60ad93b21beb6dd98f4dafa9",
                name: "Objetos Decorativos",
              },
              {
                _id: "60ad91411beb6dd98f4daf26",
                name: "Ambientes",
              },
              {
                _id: "60ad94c51beb6dd98f4dafcf",
                name: "Quadros e Pôsters",
              },
            ],
            styles: [
              {
                _id: "608310a9803e5c0fd365b8b9",
                name: "vintage",
              },
            ],
            colors: [
              {
                _id: "604b58bbebab150fdefee361",
                name: "Preto",
              },
            ],
            typologies: [
              {
                _id: "6082d3e9c8e1fd02c5f408a0",
                name: "objeto decorativo",
              },
            ],
            name: "BEHIND CAMERA QUADRO 30 CM X 42 CM",
            sku: "347210",
            refx: "30",
            refy: "",
            refz: "42",
            notUse: false,
            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/609ea7b335c3890fd3887aae/main_picture.PNG?v=1621010456234",
            supplier: "Tok&Stok",
            createdAt: "2021-05-14T16:39:15.136Z",
            updatedAt: "2022-08-29T17:51:46.799Z",
            __v: 1,
            available: false,
            latestUnits: true,
            frequency_used: 5,
            file: "347210.fbx",
            value: 49.9,
            inventory: 0,
            otherFiles: [],
            count: 1,
          },
          {
            typology: {
              _id: "62f68f4d7671370f6f1fe852",
              name: "Móveis soltos",
              category: "typology",
            },
            projectTemplateNames: [
              "Sala de estar",
              "Sala de estar e jantar",
              "Sala de estar, jantar e cozinha",
              "Studio até 40m²",
            ],
            _id: "60b0fa3c93e86d0ffbaaf5ec",
            name: "Sofá Carbon cinza",
            sku: "",
            refx: "",
            refy: "",
            refz: "",
            notUse: false,
            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/60b0fa3c93e86d0ffbaaf5ec/main_picture.PNG?v=1622211155430",
            supplier: "Seats Home",
            tags: [
              {
                _id: "60ad914c1beb6dd98f4daf2a",
                name: "Sofás",
              },
            ],
            styles: [
              {
                _id: "6082d43ac8e1fd02c5f408a1",
                name: "Contemporâneo",
              },
            ],
            colors: [
              {
                _id: "604b58d9ebab150fdefee362",
                name: "Cinza",
              },
            ],
            typologies: [
              {
                _id: "6082d326c8e1fd02c5f40888",
                name: "mobiliario sob medida",
              },
            ],
            createdAt: "2021-05-28T14:12:12.465Z",
            updatedAt: "2022-08-22T17:39:20.896Z",
            __v: 1,
            file: "",
            frequency_used: 2,
            link: "",
            value: null,
            otherFiles: [],
            count: 1,
          },
          {
            typology: {
              _id: "62f69b347671370f6f1ff8a7",
              name: "Decoração",
              category: "typology",
            },
            projectTemplateNames: [
              "Closet",
              "Home office",
              "Quarto casal",
              "Escritório ",
              "Quarto de hóspedes",
              "Quarto jovem",
              "Recepção",
              "Sala de estar e jantar",
              "Studio até 40m²",
              "Sala de estar",
              "Sala de jogos",
              "Sala de estar, jantar e cozinha",
            ],
            _id: "60b0fabfcd91130fef0b2ed2",
            name: "Tapete Kilim Stripes B&W - Medidas: 2,00x3,00",
            sku: "",
            refx: "200",
            refy: "300",
            refz: "",
            notUse: false,
            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/60b0fabfcd91130fef0b2ed2/main_picture.PNG?v=1622211282872",
            supplier: "Tenda dos Tapetes",
            tags: [
              {
                _id: "60ad92591beb6dd98f4daf73",
                name: "Tapetes",
              },
            ],
            styles: [
              {
                _id: "6082d43ac8e1fd02c5f408a1",
                name: "Contemporâneo",
              },
            ],
            colors: [
              {
                _id: "604b58bbebab150fdefee361",
                name: "Preto",
              },
              {
                _id: "60ad91441beb6dd98f4daf28",
                name: "Branco",
              },
            ],
            typologies: [
              {
                _id: "60886354f60fdc0fd77b003d",
                name: "ambientação",
              },
            ],
            createdAt: "2021-05-28T14:14:23.485Z",
            updatedAt: "2022-08-29T17:57:33.520Z",
            __v: 1,
            file: "Tapete Kilim Stripes B&W - Medidas_ 2,00x3,00.fbx",
            frequency_used: 4,
            value: 3600,
            otherFiles: [],
            count: 1,
          },
          {
            typology: {
              _id: "62f69b347671370f6f1ff8a7",
              name: "Decoração",
              category: "typology",
            },
            projectTemplateNames: [
              "Closet",
              "Escritório ",
              "Home office",
              "Quarto casal",
              "Quarto de bebê",
              "Quarto de hóspedes",
              "Quarto infantil",
              "Quarto jovem",
              "Recepção",
              "Sala de estar e jantar",
              "Studio até 40m²",
              "Sala de estar",
              "Sacada | Churrasqueira ",
              "Sala de estar, jantar e cozinha",
              "Sala de jogos",
            ],
            _id: "60b0fddacd91130fef0b2f01",
            name: "Cortina em trilho cinza ",
            sku: "",
            refx: "",
            refy: "",
            refz: "",
            notUse: false,
            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/60b0fddacd91130fef0b2f01/main_picture.PNG?v=1622212062576",
            supplier: "Fornecedor local",
            tags: [
              {
                _id: "60adb2ea1beb6dd98f4db024",
                name: "Cortinas e Suportes",
              },
            ],
            styles: [],
            colors: [
              {
                _id: "604b58d9ebab150fdefee362",
                name: "Cinza",
              },
            ],
            typologies: [
              {
                _id: "60886354f60fdc0fd77b003d",
                name: "ambientação",
              },
            ],
            createdAt: "2021-05-28T14:27:38.876Z",
            updatedAt: "2022-08-29T18:30:16.598Z",
            __v: 1,
            file: "",
            frequency_used: 11,
            otherFiles: [],
            count: 1,
          },
          {
            typology: {
              _id: "62f69b347671370f6f1ff8a7",
              name: "Decoração",
              category: "typology",
            },
            projectTemplateNames: [
              "Closet",
              "Escritório ",
              "Home office",
              "Quarto de hóspedes",
              "Quarto jovem",
              "Sala de estar e jantar",
              "Studio até 40m²",
              "Sala de estar",
            ],
            _id: "60b0fe7bcd91130fef0b2f14",
            name: "Quadro URBANISMO ARTE",
            sku: "",
            refx: "47,5",
            refy: "",
            refz: "62,5",
            notUse: false,
            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/60b0fe7bcd91130fef0b2f14/main_picture.PNG?v=1622212237507",
            supplier: "Urban Arts",
            tags: [
              {
                _id: "60ad95ff1beb6dd98f4dafe6",
                name: "Quadros e Telas",
              },
            ],
            styles: [],
            colors: [
              {
                _id: "60ad92401beb6dd98f4daf6f",
                name: "Multicor",
              },
            ],
            typologies: [
              {
                _id: "6082d3e9c8e1fd02c5f408a0",
                name: "objeto decorativo",
              },
            ],
            createdAt: "2021-05-28T14:30:19.536Z",
            updatedAt: "2022-08-29T22:17:11.371Z",
            __v: 1,
            file: "",
            frequency_used: 1,
            otherFiles: [],
            count: 1,
          },
          {
            typology: {
              _id: "62f69b347671370f6f1ff8a7",
              name: "Decoração",
              category: "typology",
            },
            projectTemplateNames: [
              "Escritório ",
              "Home office",
              "Quarto casal",
              "Quarto de hóspedes",
              "Quarto jovem",
              "Recepção",
              "Sala de estar e jantar",
              "Studio até 40m²",
              "Sala de estar",
            ],
            _id: "60b0ff53cd91130fef0b2fa0",
            name: "Quadro STREET BASQUIAT",
            sku: "",
            refx: "67,5",
            refy: "",
            refz: "90",
            notUse: false,
            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/60b0ff53cd91130fef0b2fa0/main_picture.PNG?v=1622212459200",
            supplier: "Urban Arts",
            tags: [
              {
                _id: "60ad95ff1beb6dd98f4dafe6",
                name: "Quadros e Telas",
              },
            ],
            styles: [
              {
                _id: "6082d43ac8e1fd02c5f408a1",
                name: "Contemporâneo",
              },
            ],
            colors: [
              {
                _id: "60ad92401beb6dd98f4daf6f",
                name: "Multicor",
              },
            ],
            typologies: [
              {
                _id: "6082d3e9c8e1fd02c5f408a0",
                name: "objeto decorativo",
              },
            ],
            createdAt: "2021-05-28T14:33:55.848Z",
            updatedAt: "2022-08-29T22:17:12.379Z",
            __v: 1,
            file: "",
            frequency_used: 1,
            otherFiles: [],
            count: 1,
          },
          {
            typology: {
              _id: "62f69b347671370f6f1ff8a7",
              name: "Decoração",
              category: "typology",
            },
            projectTemplateNames: [
              "Escritório ",
              "Home office",
              "Quarto jovem",
              "Recepção",
              "Sala de estar e jantar",
              "Studio até 40m²",
              "Sala de estar",
            ],
            _id: "60b0ffbccd91130fef0b2fa4",
            name: "Quadro HISTORY OF ROCK (BLACK BOARD) - SCHOOL OF ROCK",
            sku: "",
            refx: "120",
            refy: "",
            refz: "90",
            notUse: false,
            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/60b0ffbccd91130fef0b2fa4/main_picture.PNG?v=1622212559706",
            supplier: "Urban Arts",
            tags: [
              {
                _id: "60ad95ff1beb6dd98f4dafe6",
                name: "Quadros e Telas",
              },
            ],
            styles: [
              {
                _id: "6082d43ac8e1fd02c5f408a1",
                name: "Contemporâneo",
              },
            ],
            colors: [
              {
                _id: "604b58bbebab150fdefee361",
                name: "Preto",
              },
              {
                _id: "60ad91441beb6dd98f4daf28",
                name: "Branco",
              },
            ],
            typologies: [
              {
                _id: "6082d3e9c8e1fd02c5f408a0",
                name: "objeto decorativo",
              },
            ],
            createdAt: "2021-05-28T14:35:40.256Z",
            updatedAt: "2022-08-29T22:17:13.160Z",
            __v: 1,
            file: "",
            frequency_used: 1,
            otherFiles: [],
            count: 1,
          },
          {
            typology: {
              _id: "6303b88b88e535103b196c85",
              name: "Materiais especificados",
              category: "typology",
            },
            projectTemplateNames: [
              "Banheiro",
              "Cozinha",
              "Cozinha e Jantar",
              "Sala de estar",
              "Sala de estar e jantar",
              "Sala de estar, jantar e cozinha",
              "Studio até 40m²",
              "Sacada | Churrasqueira ",
            ],
            _id: "60b129814560710ff1b54c8d",
            name: "Silestone Seaport",
            sku: "",
            refx: "",
            refy: "",
            refz: "",
            notUse: false,
            refimage:
              "https://s3.amazonaws.com/ladecora.platform/mobi/60b129814560710ff1b54c8d/main_picture.PNG?v=1622223242043",
            supplier: "Dekton",
            tags: [],
            styles: [
              {
                _id: "6093db388940ec0fbf72a8d9",
                name: "industrial",
              },
              {
                _id: "60894e815069cc0fba41d5e2",
                name: "moderno",
              },
            ],
            colors: [
              {
                _id: "604b58d9ebab150fdefee362",
                name: "Cinza",
              },
            ],
            typologies: [
              {
                _id: "6082d326c8e1fd02c5f40888",
                name: "mobiliario sob medida",
              },
            ],
            createdAt: "2021-05-28T17:33:53.864Z",
            updatedAt: "2022-08-31T13:03:58.085Z",
            __v: 1,
            file: "",
            frequency_used: 3,
            otherFiles: [],
            count: 1,
          },
        ];

  const files =
    label === "remaxfranquia"
      ? [
          "https://s3.amazonaws.com/ladecora.platform/projects/62570ba2fcdafe0880f6c839/firstReview/V2-04.png",
          "https://s3.amazonaws.com/ladecora.platform/projects/62570ba2fcdafe0880f6c839/firstReview/V2-03.png",
          "https://s3.amazonaws.com/ladecora.platform/projects/62570ba2fcdafe0880f6c839/firstReview/V2-01.png",
          "https://s3.amazonaws.com/ladecora.platform/projects/62570ba2fcdafe0880f6c839/firstReview/V2-02.png",
          "https://s3.amazonaws.com/ladecora.platform/projects/62570ba2fcdafe0880f6c839/render/d4db9b1a16ade5c32704d686a96116ec.jpeg",
          "https://s3.amazonaws.com/ladecora.platform/projects/62570ba2fcdafe0880f6c839/render/e2b1cb7e445be608e6478623940a1626.jpeg",
          "https://s3.amazonaws.com/ladecora.platform/projects/62570ba2fcdafe0880f6c839/firstReview/V2-PB1.png",
        ]
      : [
          "https://s3.amazonaws.com/ladecora.platform/exemple/general/1.png",
          "https://s3.amazonaws.com/ladecora.platform/exemple/general/2.png",
          "https://s3.amazonaws.com/ladecora.platform/exemple/general/3.png",
          "https://s3.amazonaws.com/ladecora.platform/exemple/general/4.png",
          "https://s3.amazonaws.com/ladecora.platform/exemple/general/5.png",
        ];

  const details =
    label === "remaxfranquia"
      ? [
          "https://s3.amazonaws.com/ladecora.platform/projects/62570ba2fcdafe0880f6c839/render/fecbca5a3ba92e4be5bb027ed5c343e2.jpeg",

          "https://s3.amazonaws.com/ladecora.platform/projects/62570ba2fcdafe0880f6c839/render/ceb44de9f556bd1e905b1c033f167c06.jpeg",
        ]
      : [
          "https://s3.amazonaws.com/ladecora.platform/projects/60a27e376380860fdd0633af/render/85ef8e248cdb60387a20268feeb43a98.png",

          "https://s3.amazonaws.com/ladecora.platform/projects/60a27e376380860fdd0633af/render/18e3347be18386e5c5105da691d27078.png",

          "https://s3.amazonaws.com/ladecora.platform/projects/60a27e376380860fdd0633af/render/b43108f51b9ab51a2c99aa30a53f8cca.png",

          "https://s3.amazonaws.com/ladecora.platform/projects/60a27e376380860fdd0633af/render/2cddcc0f09c9afa430526a33e90a0245.png",

          "https://s3.amazonaws.com/ladecora.platform/projects/60a27e376380860fdd0633af/render/b60dcee135203c698e1a457580add826.png",

          "https://s3.amazonaws.com/ladecora.platform/projects/60a27e376380860fdd0633af/render/f481c8094e1add5d3b3eb49ec623f708.png",
        ];

  const workPlanLayout =
    label === "remaxfranquia"
      ? [
          "https://s3.amazonaws.com/ladecora.platform/projects/62570ba2fcdafe0880f6c839/firstReview/V2-PB1.png",
        ]
      : [
          "https://s3.amazonaws.com/ladecora.platform/projects/60a27e376380860fdd0633af/render/de9261cbfbc63ae4e63979047b85fa2b.png",
        ];

  const finishingLayout =
    label === "remaxfranquia"
      ? [
          "https://s3.amazonaws.com/ladecora.platform/projects/62570ba2fcdafe0880f6c839/render/44b49440fe5dddf53131c1bef386fda2.jpeg",
        ]
      : [
          "https://s3.amazonaws.com/ladecora.platform/projects/60a27e376380860fdd0633af/render/c19f80d4477d10ee0412d6cae60422bb.png",
        ];

  const lights =
    label === "remaxfranquia"
      ? [
          "https://s3.amazonaws.com/ladecora.platform/projects/62570ba2fcdafe0880f6c839/render/be7107af2e10ff515631de0e4d65c041.jpeg",
        ]
      : [
          "https://s3.amazonaws.com/ladecora.platform/projects/60a27e376380860fdd0633af/render/e5f10361a4d6c61246622fe26d20907e.png",
        ];

  const downloadLink =
    label === "remaxfranquia"
      ? "https://s3.amazonaws.com/ladecora.platform/exemple/remax.pdf"
      : "https://s3.amazonaws.com/ladecora.platform/exemple/book.pdf";
  return {
    lights: { links: lights, labels: [] },
    finishingLayout: { links: finishingLayout, labels: [] },
    workPlanLayout: { links: workPlanLayout, labels: [] },
    details: { links: details, labels: [] },
    files,
    furnitures,
    downloadLink,
  };
};
