import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Lightbox from "@ladecora/react-image-lightbox";
import { useParams, useHistory } from "react-router-dom";
import { getProject } from "../actions/project";
import AspectRatioPicture from "../components/common/AspectRatioPicture";
import { BUCKET_URL } from "../config";
import { download } from "../utils/util";
import { getImgFullLink } from "../components/environments/steps/helper";

const Summary = () => {
  const [projectName, setProjectName] = useState("name");
  const [answers, setAnswers] = useState();
  const [learningEnvironment, setLearningEnvironment] = useState({});
  const [inspirations, setInspirations] = useState({});
  const [furnitureMeasures, setFurnitureMeasures] = useState({});
  const [project, setProject] = useState();
  const [lightbox, setLightbox] = useState({
    open: false,
    img: "",
    index: 0,
    images: [],
  });
  const [winWidth, setWinWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", detectSize);

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [winWidth]);

  const detectSize = () => {
    setWinWidth(window.innerWidth);
  };

  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    getProject(id)
      .then((res) => {
        const project = res[0];
        setProject(project);
        setAnswers(project.answers);
        setLearningEnvironment(project.learningEnvironment);
        setInspirations(project.inspirations);
        setProjectName(project.name);
        setFurnitureMeasures(project.furnitureMeasures);
      })
      .catch(() => {
        history.push("/environments-list");
      });
  }, []);

  function sendLightbox(data) {
    setLightbox({ ...lightbox, index: 0, images: [data], open: true });
  }

  return (
    <Container
      fluid
      className="main-content-container px-4"
      style={{ resize: "both" }}
    >
      {lightbox.open && (
        <Lightbox
          mainSrc={lightbox.images[lightbox.index]}
          nextSrc={
            lightbox.images[(lightbox.index + 1) % lightbox.images.length]
          }
          reactModalStyle={{ overlay: { zIndex: 10000 } }}
          onCloseRequest={() => setLightbox({ ...lightbox, open: false })}
        />
      )}

      <Row noGutters className="page-header py-4">
        <PageTitle
          title={`Ambiente: ${projectName}`}
          subtitle="Informações enviadas"
          md="12"
          className="ml-sm-auto mr-sm-auto"
        />
      </Row>
      <Row>
        <Col>
          <Card small className="mb-4">
            <CardBody>
              {/* eslint-disable-next-line */}
              <label
                className="mb-1"
                style={{ border: "none", cursor: "pointer" }}
                onClick={() => {
                  history.push("/environments-list");
                }}
              >
                &#8592; VOLTAR
              </label>
              <Tabs>
                <TabList>
                  <Tab>Questionário do Ambiente</Tab>
                  <Tab>Conhecendo seu espaço</Tab>
                  <Tab>Inspiração</Tab>
                  <Tab>Móveis e medidas</Tab>
                </TabList>

                <TabPanel>
                  {/* Questionario do ambiente */}
                  {answers &&
                    answers.map((question, idx) => {
                      return (
                        <div
                          className="user-activity__item pr-3 py-3"
                          key={idx}
                        >
                          <div className="user-activity__item__icon">
                            <i className="material-icons">&#xE7FE;</i>
                          </div>
                          <div className="user-activity__item__content">
                            <span className="text-light">
                              {question.question}
                            </span>

                            <p>
                              {Array.isArray(question.answer) ? (
                                question.answer.map((answer, i) => {
                                  const isUpload = !!answer.source;

                                  const value = isUpload
                                    ? answer.thumbnail || answer.source
                                    : answer;
                                  return isUpload ? (
                                    <AspectRatioPicture
                                      key={i}
                                      height="200px"
                                      width="200px"
                                      isDeletable={false}
                                      src={`${BUCKET_URL}/${answer.source}`}
                                      thumbnail={
                                        answer.thumbnail
                                          ? `${BUCKET_URL}/${answer.thumbnail}`
                                          : null
                                      }
                                      name={answer.name}
                                      title={answer.name}
                                      dowloadPdf={() => {
                                        download(
                                          `${BUCKET_URL}/${answer.source}`
                                        );
                                      }}
                                    />
                                  ) : value
                                      .toLowerCase()
                                      .match("(.jpeg|.png|.jpg|.jfif)") ? (
                                    /* eslint-disable-next-line */
                                    <img
                                      key={i}
                                      alt={value}
                                      className="border rounded"
                                      style={{
                                        width: "12.5rem",
                                        cursor: "pointer",
                                      }}
                                      src={BUCKET_URL + "/" + value}
                                      onClick={() => {
                                        sendLightbox(
                                          isUpload
                                            ? `${BUCKET_URL}/${answer.source}`
                                            : `${BUCKET_URL}/${value}`
                                        );
                                      }}
                                    />
                                  ) : (
                                    <>
                                      {value} <br />
                                    </>
                                  );
                                })
                              ) : question.answer
                                  .toLowerCase()
                                  .match("(.jpeg|.png|.jpg|.jfif)") ? (
                                /* eslint-disable-next-line */
                                <img
                                  alt={question.answer}
                                  className="border rounded"
                                  style={{
                                    width: "12.5rem",
                                    cursor: "pointer",
                                  }}
                                  src={BUCKET_URL + "/" + question.answer}
                                  onClick={() => {
                                    sendLightbox(
                                      `${BUCKET_URL}/${question.answer}`
                                    );
                                  }}
                                />
                              ) : (
                                question.answer
                              )}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                </TabPanel>
                <TabPanel>
                  {/* Comentarios */}
                  {learningEnvironment.details && (
                    <div className="user-activity__item pr-3 py-3">
                      <div className="user-activity__item__icon">
                        <i className="material-icons">&#xE7FE;</i>
                      </div>
                      <div className="user-activity__item__content">
                        <span className="text-light">Comentarios</span>
                        <p>{learningEnvironment.details}</p>
                      </div>
                    </div>
                  )}
                  {/* Arquivos */}
                  {learningEnvironment.files &&
                    learningEnvironment.files.length > 0 && (
                      <div className="user-activity__item pr-3 py-3">
                        <div className="user-activity__item__icon">
                          <i className="material-icons">&#xE7FE;</i>
                        </div>
                        <div className="user-activity__item__content w-100">
                          <span className="text-light">Arquivos</span>
                          <Row>
                            {learningEnvironment.files.map((img, i) => {
                              return (
                                <Col
                                  lg={winWidth > 1286 ? "3" : "4"}
                                  md="6"
                                  sm="6"
                                  key={i}
                                >
                                  <Card className="card-file">
                                    <AspectRatioPicture
                                      height="200px"
                                      width="200px"
                                      isDeletable={false}
                                      src={getImgFullLink(
                                        img.source,
                                        project._id
                                      )}
                                      thumbnail={
                                        img.thumbnail
                                          ? getImgFullLink(
                                              img.thumbnail,
                                              project._id
                                            )
                                          : null
                                      }
                                      name={img.name}
                                      title={img.name}
                                      dowloadPdf={() => {
                                        download(
                                          getImgFullLink(
                                            img.source,
                                            project._id
                                          )
                                        );
                                      }}
                                    />
                                  </Card>
                                </Col>
                              );
                            })}
                          </Row>
                        </div>
                      </div>
                    )}
                </TabPanel>
                <TabPanel>
                  {/* Comentarios */}
                  {inspirations.details && (
                    <div className="user-activity__item pr-3 py-3">
                      <div className="user-activity__item__icon">
                        <i className="material-icons">&#xE7FE;</i>
                      </div>
                      <div className="user-activity__item__content">
                        <span className="text-light">Comentarios</span>
                        <p>{inspirations.details}</p>
                      </div>
                    </div>
                  )}
                  {/* Arquivos */}
                  {inspirations.files && inspirations.files.length > 0 && (
                    <div className="user-activity__item pr-3 py-3">
                      <div className="user-activity__item__icon">
                        <i className="material-icons">&#xE7FE;</i>
                      </div>
                      <div className="user-activity__item__content w-100">
                        <span className="text-light">Arquivos</span>
                        <Row>
                          {inspirations.files.map((img, i) => {
                            return (
                              <Col
                                lg={winWidth > 1286 ? "3" : "4"}
                                md="6"
                                sm="6"
                                key={i}
                              >
                                <Card className="card-file">
                                  <AspectRatioPicture
                                    height="200px"
                                    width="200px"
                                    isDeletable={false}
                                    src={getImgFullLink(
                                      img.source,
                                      project._id
                                    )}
                                    thumbnail={
                                      img.thumbnail
                                        ? getImgFullLink(
                                            img.thumbnail,
                                            project._id
                                          )
                                        : null
                                    }
                                    name={img.name}
                                    title={img.name}
                                    dowloadPdf={() => {
                                      download(
                                        getImgFullLink(img.source, project._id)
                                      );
                                    }}
                                  />
                                </Card>
                              </Col>
                            );
                          })}
                        </Row>
                      </div>
                    </div>
                  )}
                </TabPanel>
                <TabPanel>
                  {furnitureMeasures.details && (
                    <div className="user-activity__item pr-3 py-3">
                      <div className="user-activity__item__icon">
                        <i className="material-icons">&#xE7FE;</i>
                      </div>
                      <div className="user-activity__item__content">
                        <span className="text-light">Comentarios</span>
                        <p>{furnitureMeasures.details}</p>
                      </div>
                    </div>
                  )}
                  {furnitureMeasures.files && (
                    <div className="user-activity__item pr-3 py-3">
                      <div className="user-activity__item__icon">
                        <i className="material-icons">&#xE7FE;</i>
                      </div>
                      <div className="user-activity__item__content w-100">
                        <span className="text-light">Arquivos</span>

                        {furnitureMeasures.files.map((file, i) => {
                          return (
                            <div
                              key={i}
                              style={{
                                borderBottom: "2px solid #E9ECEF",
                                marginTop: "20px",
                              }}
                            >
                              <span
                                className="text-light"
                                style={{ fontSize: "14px" }}
                              >
                                Nome:{file.nameMeasure}
                              </span>
                              <p>
                                Altura: {file.height} cm; Largura: {file.width}{" "}
                                cm; Comprimento: {file.length} cm; Quantidade:{" "}
                                {file.quantity}; Podemos reformar?{" "}
                                {file.reform
                                  ? file.reform === "yes"
                                    ? "Sim"
                                    : "Não"
                                  : "Não Informado"}
                                ; É obrigatório manter este móvel? {""}
                                {file.keep
                                  ? file.keep === "yes"
                                    ? "Sim"
                                    : "Não"
                                  : "Não Informado"}
                              </p>
                              {file.files && (
                                <Row>
                                  {file.files.map((img, i) => {
                                    return (
                                      <Col
                                        lg={winWidth > 1286 ? "3" : "4"}
                                        md="6"
                                        sm="6"
                                        key={i}
                                      >
                                        <Card className="card-file">
                                          <AspectRatioPicture
                                            height="200px"
                                            width="200px"
                                            isDeletable={false}
                                            src={getImgFullLink(
                                              img.source,
                                              project._id
                                            )}
                                            thumbnail={
                                              img.thumbnail
                                                ? getImgFullLink(
                                                    img.thumbnail,
                                                    project._id
                                                  )
                                                : null
                                            }
                                            name={img.name}
                                            title={img.name}
                                            dowloadPdf={() => {
                                              download(
                                                getImgFullLink(
                                                  img.source,
                                                  project._id
                                                )
                                              );
                                            }}
                                          />
                                        </Card>
                                      </Col>
                                    );
                                  })}
                                </Row>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </TabPanel>
              </Tabs>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Summary;
