import React, { useEffect, useState } from "react";

import "@ladecora/react-image-lightbox/style.css";
import Slider from "react-slick";
import { Button } from "shards-react";
import FlexSlider from "../common/FlexSlider";
import { ShowLightbox } from "../common/ShowLightbox";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
function ProjectGroup({
  room,
  projects,
  onProjectAcceptClick,
  acceptedRooms,
  isLoading,
  scrollToTop,
  bluePrint,
  enterprise,
}) {
  const [suggestions, setSuggestions] = useState([]);
  const [project, setProject] = useState(null);
  const [lightbox, setLightbox] = useState({
    open: false,
    index: 0,
    images: [],
  });
  const settings = {
    dots: true,
    infinite: true,
    lazyLoad: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const roomNames = Object.keys(acceptedRooms).filter(
    (rn) => rn !== room.name && !!acceptedRooms[rn]
  );

  const sendLightbox = (data, idx) => {
    if (Array.isArray(data)) {
      setLightbox({ ...lightbox, images: data, open: true, index: idx });
    } else {
      setLightbox({ ...lightbox, images: [data], open: true, index: 0 });
    }
  };
  useEffect(() => {
    if (projects) {
      const selectedProject = acceptedRooms[room.name];
      if (selectedProject) {
        const list = projects.filter((p) => {
          return p.name !== selectedProject.name;
        });
        setProject(selectedProject);
        setSuggestions(list);
      } else {
        const list = projects.filter((p, i) => {
          return i !== 0;
        });
        setSuggestions(list);
        setProject(projects[0]);
      }
    }
  }, [projects, room]);

  function onSuggestionClick(project) {
    scrollToTop();
    window.scrollTo(0, 0);
    onProjectAcceptClick(null, room.name);
    const list = [];
    projects.forEach((p) => {
      if (p._id !== project._id) {
        list.push(p);
      } else {
        setProject(p);
      }
    });
    setSuggestions(list);
  }
  const { showPrise } = enterprise;
  return (
    <div className={`${bluePrint.mirrored && "flipImage"}`}>
      {lightbox.open && (
        <ShowLightbox
          lightbox={lightbox}
          setLightbox={setLightbox}
          flipImage={bluePrint.mirrored ? true : false}
        />
      )}
      <div className="question-title mb-4 mt-4">
        Selecione os ambientes e estilos mais indicados para você (Veja mais
        opções no final da página):
      </div>
      <div
        className="text-center question-title"
        style={{ fontSize: "1.3rem" }}
      >
        {project
          ? `${project.enterprise.environment} - ${project.enterprise.style}`
          : ""}
      </div>

      {room && (
        <div className="project-group">
          {isLoading || !project ? (
            <div className="mt-4 mb-4 d-flex justify-content-center">
              <div className="project-group-images">
                <div className="carousel-container">
                  <Skeleton height={370} />
                </div>
              </div>
              {showPrise && (
                <div className="d-flex flex-column project-group-prices">
                  <div>
                    <div
                      className="mt-3"
                      style={{
                        marginLeft: "1rem",
                        marginRight: "5rem",
                        fontSize: "1.2rem",
                        fontFamily: "montserrat",
                        fontWeight: 600,
                        color: "black",
                      }}
                    >
                      Estimativa de custos <br />
                      <span
                        style={{
                          fontSize: "0.8rem",
                        }}
                      >
                        Data de cotação - Maio/2022
                      </span>
                    </div>
                    <hr />
                  </div>
                  <div
                    className="pt-3 h-100 d-flex flex-column"
                    style={{ marginLeft: "1rem", marginRight: "1rem" }}
                  >
                    <div className="d-flex flex-column" style={{ flex: 1 }}>
                      <div className="d-flex">
                        <span
                          className="pr-2"
                          style={{
                            width: "80px",
                          }}
                        >
                          <Skeleton count={1} />
                        </span>
                        <hr
                          style={{
                            width: "100%",
                            borderTop: "2px dashed",
                            marginTop: "0.86rem",
                          }}
                        />
                        <span
                          className="pl-2"
                          style={{
                            width: "80px",
                          }}
                        >
                          <Skeleton count={1} />
                        </span>
                      </div>
                      <div className="d-flex">
                        <span
                          className="pr-2"
                          style={{
                            width: "80px",
                          }}
                        >
                          <Skeleton count={1} />
                        </span>
                        <hr
                          style={{
                            width: "100%",
                            borderTop: "2px dashed",
                            marginTop: "0.86rem",
                          }}
                        />
                        <span
                          className="pl-2"
                          style={{
                            width: "80px",
                          }}
                        >
                          <Skeleton count={1} />
                        </span>
                      </div>
                      <div className="d-flex">
                        <span
                          className="pr-2"
                          style={{
                            width: "80px",
                          }}
                        >
                          <Skeleton count={1} />
                        </span>
                        <hr
                          style={{
                            width: "100%",
                            borderTop: "2px dashed",
                            marginTop: "0.86rem",
                          }}
                        />
                        <span
                          className="pl-2"
                          style={{
                            width: "80px",
                          }}
                        >
                          <Skeleton count={1} />
                        </span>
                      </div>
                    </div>

                    <div>
                      <div className="d-flex">
                        <span
                          style={{
                            fontSize: "1rem",
                            fontFamily: "montserrat",
                            whiteSpace: "nowrap",
                            marginRight: ".8rem",
                          }}
                        >
                          Total
                        </span>
                        <hr
                          style={{
                            width: "100%",
                            borderTop: "1px dashed",
                            marginTop: "1rem",
                          }}
                        />
                        <span
                          className="pl-2"
                          style={{
                            width: "80px",
                          }}
                        >
                          <Skeleton count={1} />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="mt-4 mb-4 d-flex justify-content-center">
              <div className="project-group-images">
                <Slider {...settings}>
                  {project.render.decorationPanel.map((file, idx) => {
                    return (
                      <div
                        key={idx}
                        className="d-flex justify-content-center align-items-center carousel-container clickable"
                      >
                        {/* eslint-disable-next-line */}
                        <img
                          alt=""
                          className="rounded img-fluid carousel-image"
                          src={`${file.thumbnail || file.source}`}
                          onClick={() =>
                            sendLightbox(
                              project.render.decorationPanel.map(
                                (elem) => elem.source
                              ),
                              idx
                            )
                          }
                        />
                      </div>
                    );
                  })}
                </Slider>
              </div>
              {showPrise && (
                <div className="d-flex flex-column project-group-prices">
                  <div>
                    <div
                      className="mt-3"
                      style={{
                        marginLeft: "1rem",
                        marginRight: "5rem",
                        fontSize: "1.2rem",
                        fontFamily: "montserrat",
                        fontWeight: 600,
                        color: "black",
                      }}
                    >
                      Estimativa de custos <br />
                      <span
                        style={{
                          fontSize: "0.8rem",
                        }}
                      >
                        Data de cotação - Maio/2022
                      </span>
                    </div>
                    <hr />
                  </div>
                  <div
                    className="pt-3 h-100 d-flex flex-column"
                    style={{ marginLeft: "1rem", marginRight: "1rem" }}
                  >
                    <div className="d-flex flex-column" style={{ flex: 1 }}>
                      {acceptedRooms[room.name] ? (
                        <div className="d-flex">
                          <span
                            style={{
                              fontSize: "1rem",
                              fontFamily: "montserrat",
                              whiteSpace: "nowrap",
                              marginRight: ".8rem",
                              fontWeight: 600,
                            }}
                          >
                            {room.name}
                          </span>
                          <hr
                            style={{
                              width: "100%",
                              borderTop: "2px dashed",
                              marginTop: "0.86rem",
                            }}
                          />
                          <span
                            style={{
                              whiteSpace: "nowrap",
                              fontSize: "1rem",
                              fontFamily: "montserrat",
                              marginLeft: ".8rem",
                              fontWeight: 600,
                            }}
                          >
                            {acceptedRooms[
                              room.name
                            ].furnituresPrice.toLocaleString("pt-br", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </span>
                        </div>
                      ) : (
                        <div className="d-flex">
                          <span
                            style={{
                              fontSize: "1rem",
                              fontFamily: "montserrat",
                              whiteSpace: "nowrap",
                              marginRight: ".8rem",
                              fontWeight: 600,
                            }}
                          >
                            {room.name}
                          </span>
                          <hr
                            style={{
                              width: "100%",
                              borderTop: "2px dashed",
                              marginTop: "0.86rem",
                            }}
                          />
                          <span
                            style={{
                              whiteSpace: "nowrap",
                              fontSize: "1rem",
                              fontFamily: "montserrat",
                              marginLeft: ".8rem",
                              fontWeight: 600,
                            }}
                          >
                            {project.furnituresPrice.toLocaleString("pt-br", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </span>
                        </div>
                      )}
                      {roomNames.map((rn, i) => {
                        return (
                          <div className="d-flex" key={`${rn}-${i}`}>
                            <span
                              style={{
                                fontSize: "1rem",
                                fontFamily: "montserrat",
                                whiteSpace: "nowrap",
                                marginRight: ".8rem",
                              }}
                            >
                              {rn}
                            </span>
                            <hr
                              style={{
                                width: "100%",
                                borderTop: "1px dashed",
                                marginTop: "1rem",
                              }}
                            />
                            <span
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "1rem",
                                fontFamily: "montserrat",
                                marginLeft: ".8rem",
                              }}
                            >
                              {acceptedRooms[rn].furnituresPrice.toLocaleString(
                                "pt-br",
                                {
                                  style: "currency",
                                  currency: "BRL",
                                }
                              )}
                            </span>
                          </div>
                        );
                      })}
                    </div>

                    <div>
                      <div className="d-flex">
                        <span
                          style={{
                            fontSize: "1rem",
                            fontFamily: "montserrat",
                            whiteSpace: "nowrap",
                            marginRight: ".8rem",
                          }}
                        >
                          Total
                        </span>
                        <hr
                          style={{
                            width: "100%",
                            borderTop: "1px dashed",
                            marginTop: "1rem",
                          }}
                        />
                        <span
                          style={{
                            whiteSpace: "nowrap",
                            fontSize: "1rem",
                            fontFamily: "montserrat",
                            marginLeft: ".8rem",
                          }}
                        >
                          {(
                            project.furnituresPrice +
                            roomNames.reduce((acc, cur) => {
                              return acc + acceptedRooms[cur].furnituresPrice;
                            }, 0)
                          ).toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          <div className="d-flex align-items-center justify-content-center mt-5 mb-5">
            {!!acceptedRooms[room.name] || isLoading ? (
              <div className="question-title mb-4 mt-4">
                <h5>
                  Você selecionou todos os ambientes, clique em Finalizar para
                  ver mais detalhes de todos os seus ambientes.
                </h5>
              </div>
            ) : (
              <Button
                size="lg"
                theme="success"
                disabled={!!acceptedRooms[room.name] || isLoading}
                onClick={() => onProjectAcceptClick(project, room.name)}
              >
                Confirmar ambiente
              </Button>
            )}
          </div>
          {suggestions.length ? (
            <h5 className="text-center">
              Ver outras opções de projeto para o seu ambiente
            </h5>
          ) : null}
          <div className="d-flex align-items-center justify-content-center overflow-x-hidden">
            {!isLoading && (
              <FlexSlider
                items={suggestions}
                opt={{ minWidth: 300 }}
                onClick={onSuggestionClick}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

ProjectGroup.propTypes = {};

export default ProjectGroup;
