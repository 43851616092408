import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "shards-react";

const StepChanger = ({
  goToStep,
  totalSteps,
  step,
  node,
  flatData,
  answers,
  onSave,
}) => {
  const history = useHistory();
  const nextQuestion = () => {
    window.scrollTo(0, 0);
    if (node.node.input) {
      if (node.node.input.action) {
        goToStep(parseInt(node.node.input.action) + 1);
      } else {
        let parentNode = flatData.find(
          (element) => element.treeIndex === node.path[0]
        );
        let nextStep = !parentNode.childCount
          ? node.path[0] + 2
          : parentNode.childCount + node.path[0] + 2;
        goToStep(nextStep);
      }
    } else {
      let parentNode = flatData.find(
        (element) => element.treeIndex === node.path[0]
      );
      //MUDADO APENAS O 2 para 1
      // let nextStep = !parentNode.childCount
      //   ? node.path[0] + 2
      //   : parentNode.childCount + node.path[0] + 2;
      let nextStep = !parentNode.childCount
        ? node.path[0] + 2
        : parentNode.childCount + node.path[0] + 2;
      goToStep(nextStep);
    }
  };

  const previousStepHistory = () => {
    window.scrollTo(0, 0);
    history.goBack();
  };

  const saveAnswers = () => {
    window.scrollTo(0, 0);
    onSave(answers);
  };

  return (
    <div style={{ cssFloat: "right" }} className="mt-2">
      {step > 1 && (
        <Button
          outline
          theme="accent"
          className="btn mr-1"
          onClick={previousStepHistory}
        >
          Voltar
        </Button>
      )}
      {step < totalSteps ? (
        <Button theme="accent" className="btn" onClick={nextQuestion}>
          Próximo
        </Button>
      ) : (
        <Button theme="accent" className="btn" onClick={saveAnswers}>
          Finalizar
        </Button>
      )}
    </div>
  );
};

export default StepChanger;
