import React, { useState } from "react";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import { Row } from "shards-react";

import Loader from "react-loader-spinner";
import { uploadFile } from "../../services/file";

const DropzoneArea = (props) => {
  const [values, setValues] = useState({
    error: false,
    message: "",
    loading: false,
  });

  const handleDrop = (files) => {
    setValues({ ...values, loading: true });

    Promise.all(
      files.map((file) => {
        return uploadFile({
          path: `projects/${props.projectId}/customer`,
          file,
        }).then((res) => {
          if (res.file) {
            return {
              name: file.name,
              source: res.file,
              size: file.size,
            };
          } else {
            return {
              name: file.name,
              source: res.fullSize,
              thumbnail: res.thumbnail,
              size: file.size,
            };
          }
        });
      })
    )
      .then((data) => {
        if (data.error) {
          setValues({ ...values, error: data.error, loading: false });
        } else {
          if (props["idx"] === undefined) {
            props.callbackHandleDrop(data);
          } else {
            props.callbackHandleDrop(data, props.idx);
          }
          setValues({
            ...values,
            message: "Upload realizado com sucesso",
            loading: true,
          });
          setTimeout(() => {
            setValues({
              ...values,
              loading: false,
              error: false,
              message: "",
            });
          }, 4000);
        }
      })
      .catch(() => {
        setValues({ ...values, loading: false });
      });
  };
  const showError = () =>
    error ? <div className="alert alert-danger">{error}</div> : "";
  const showMessage = () =>
    message ? <div className="alert alert-info">{message}</div> : "";
  const { error, message, loading } = values;

  return (
    <Row noGutters>
      <div className="file-manager-cards__dropzone w-100 p-2">
        {showError()}
        {showMessage()}
        <Dropzone
          onDrop={handleDrop}
          accept="image/png, image/jpg, image/jpeg, .pdf, .dwg, .skp, .heic"
        >
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div
                {...getRootProps()}
                className={classNames("dropzone", {
                  "dropzone--isActive": isDragActive,
                })}
              >
                <input {...getInputProps()} />
                <div className="m-0">
                  {!loading &&
                    "Tente soltar alguns arquivos aqui ou clique para selecionar os arquivos a serem enviados."}
                  {loading && <Loader type="Rings" color="#33666d" />}
                </div>
              </div>
            );
          }}
        </Dropzone>
      </div>
    </Row>
  );
};

export default DropzoneArea;
