import React from "react";
import { Controller } from "react-hook-form";
import { valida_cpf_cnpj } from "../../utils/form_validation";
import CpfCnpj from "../common/CpfCnpjV2";

import FieldError from "../common/FieldError";

function FormCpfCnpj({ control }) {
  return (
    <Controller
      control={control}
      name="document"
      defaultValue=""
      rules={{
        required: "Digite seu CPF ou CNPJ",
        validate: {
          isValid: (value: string) => {
            return valida_cpf_cnpj(value) ? true : "Documento inválido";
          },
        },
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <>
            <CpfCnpj
              id="register-cpf-cnpj-input"
              value={value}
              onChange={(document) => {
                onChange(document.value);
              }}
              placeholder="CPF/CNPJ"
              autoComplete="document"
              className="form-control"
            />
            <FieldError isValid={!!error} message={error?.message} />
          </>
        );
      }}
    />
  );
}

export default FormCpfCnpj;
