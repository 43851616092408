import { API } from "../config";
import { getCookie } from "../actions/auth";
import { requestWrapper } from "../utils/api";

export const confirmTrasaction = (transactionToken, amount, projects) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/transaction/capture`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify({
        transactionToken: transactionToken,
        amount: amount,
        projects: projects,
      }),
    })
  );
};

export const listPayments = () => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/transaction/list`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const charge = (value, creditCard, projectTemplates, voucher, gift) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/transaction/charge`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify({
        voucher: voucher,
        value: value,
        creditCard: creditCard,
        projectTemplates: projectTemplates,
        gift,
      }),
    })
  );
};

export const sendEmailContact = (message, emailSend, phoneSend, nameSend) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/transaction/contact`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify({
        email: emailSend,
        phone: phoneSend,
        name: nameSend,
        message: message,
      }),
    })
  );
};

export const pix = (projectTemplates, voucher) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/transaction/pix`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify({
        projectTemplates,
        voucher,
      }),
    })
  );
};
