import React from "react";

import { useParams } from "react-router-dom";

import Enterprise from "../components/Enterprise";

const EnterpriseView = () => {
  const { id } = useParams();

  return <Enterprise id={id} />;
};

export default EnterpriseView;
