import React, { useEffect } from "react";
import {
  validateAdminAccessToken,
  getQueryStringValue,
  authenticate,
  isAuth,
} from "../actions/auth";
import { Row, Col } from "shards-react";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import { LANDING_PAGE_LADECORA } from "../config";

function Validator() {
  useEffect(() => {
    let token = getQueryStringValue("token");
    let page = getQueryStringValue("page");
    validateAdminAccessToken(token, true).then((validate) => {
      if (!validate.user) {
        window.open(`${LANDING_PAGE_LADECORA}`, "_self");
      } else {
        authenticate(
          validate,
          () => {
            if (page === "show-p") {
              window.open(
                `/environments-list${window.location.search}`,
                "_self"
              );
            } else {
              if (isAuth()) {
                window.open(`/environments`, "_self");
              }
            }
          },
          true
        );
      }
    });
  }, []);

  return (
    <div style={{ marginTop: "2%" }}>
      <Row className="mt-5 mb-5 bg-gray">
        <Col
          sm={{ size: 3, order: 1, offset: 5 }}
          xs={{ size: 4, order: 4, offset: 2 }}
          className="pl-5 pt-5 pb-5"
        >
          <img
            id="mmain-logo"
            className="align-center"
            src={require("../images/La_Decora_principal.jpeg")}
            alt="La Decora"
            style={{ maxHeight: "5vh" }}
          />
        </Col>
      </Row>
      <Row className="mt-5">
        <Col xs="12">
          <LoadingOverlay
            className="bg-gray"
            active={true}
            spinner={<BounceLoader />}
          />
        </Col>
      </Row>
      <Row className="mt-5">
        <Col xs="12" className="text-align-center mt-5">
          <p>Olá!</p>
          <p>
            Aguarde um instante e iremos redirecionar você para dar continuidade
            ao processo.
          </p>
          <p>Obrigado!</p>
        </Col>
      </Row>
    </div>
  );
}

export default Validator;
