import React, { useRef } from "react";
import PropTypes from "prop-types";

function Container({ children, scroll }) {
  const ref = useRef(null);

  function scrollToTop() {
    ref.current.scrollTo(0, 0);
  }
  return (
    <div className="enterprise-container" ref={ref}>
      <div>
        {React.cloneElement(
          children,
          scroll ? { scrollToTop: scrollToTop } : {}
        )}
      </div>
    </div>
  );
}

Container.propTypes = {
  scroll: PropTypes.bool,
};

export default Container;
