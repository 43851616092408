import React from "react";
import { toast } from "react-toastify";
import { Button, Modal, ModalBody, ModalHeader } from "shards-react";
import { Clipboard } from "../../../services/clipboard";

const ShareModal = ({ shareId, onClose }) => {
  const link = `${window.location.origin}/project?shareId=${shareId}`;
  function onCopyToClipboardClick() {
    Clipboard.copy(link);
    toast.success("Link copiado!", {
      position: "top-center",
      autoClose: 1200,
    });
  }

  function onWhatsappClick() {
    window.open(`https://api.whatsapp.com/send?text=${link}`, "_blank");
  }

  return (
    <Modal size="lg" open={true} toggle={onClose}>
      <ModalHeader toggle={onClose}>Link de compartilhamento</ModalHeader>
      <ModalBody>
        <div>
          <div>
            <div
              style={{
                fontSize: "1.1rem",
                border: "1px solid lightgrey",
                padding: "1rem",
                borderRadius: "0.3rem",
              }}
            >
              <i
                style={{
                  wordBreak: "break-all",
                  overflowWrap: "break-word",
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                }}
              >
                {link}
              </i>
            </div>

            <Button
              theme="accent"
              className="w-100 mt-3"
              onClick={onCopyToClipboardClick}
              type="button"
            >
              <i style={{ fontSize: "1.2rem" }} className="material-icons mr-2">
                content_copy
              </i>
              <div>Copiar Link</div>
            </Button>
            <div className="d-flex align-items-center justify-content-center mt-3">
              <button className="transparent" onClick={onWhatsappClick}>
                <img
                  src="https://s3.amazonaws.com/ladecora.platform/images/whatsapp.png"
                  alt=""
                />
              </button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ShareModal;
