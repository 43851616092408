import React from "react";

import { ModalBody, ModalHeader, Modal } from "shards-react";
import { grey } from "@material-ui/core/colors";

function NotificationBanner({ notification, onBannerClose }) {
  return (
    <Modal open={true} size="md" toggle={onBannerClose}>
      <ModalHeader toggle={onBannerClose} closeAriaLabel>
        {notification.title}
      </ModalHeader>
      <ModalBody>
        <div>
          <div
            style={{ fontSize: "16px" }}
            dangerouslySetInnerHTML={{ __html: notification.description }}
          />
          <p
            style={{ backgroundColor: grey, padding: "15px", fontSize: "13" }}
            dangerouslySetInnerHTML={{ __html: notification.bannerContent }}
          />
        </div>
      </ModalBody>
    </Modal>
  );
}

NotificationBanner.propTypes = {};

export default NotificationBanner;
