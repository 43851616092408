import React, { useCallback, useContext, useEffect, useState } from "react";
import { SocketIoContext } from "../shared/SocketIoContext";
import {
  Button,
  Col,
  Form,
  InputGroup,
  InputGroupAddon,
  Row,
  FormRadio,
} from "shards-react";

import { useForm } from "react-hook-form";
import { charge } from "../../actions/payment";
import {
  CompanyI,
  EnvironmentI,
  getTotal,
  VoucherI,
} from "@ladecora/ladecora-utils";
import { toast } from "react-toastify";
import { getVoucherByCode } from "../../actions/voucher";
import { useHistory } from "react-router-dom";
import AnimatedCreditCard from "./AnimatedCreditCard";
import { fireEvent } from "../../utils/tag-manager";
import {
  CreditCardI,
  QrCodeI,
  QrCodeStatusEnum,
  SocketNotificationI,
} from "./types";
import { pix } from "../../actions/paymentV2";
import { sanitizeString } from "../../utils/sanitize";
import { FormCardNumber } from "./FormCardNumber";
import { FormCardCVC } from "./FormCardCVC";
import CreditCardExpiry from "../common/CreditCardExpiryV2";
import QrCodeComp from "./QrCodeComp";
import styles from "./PaymentInfo.module.scss";
import { LadecoraDBI, WaitPaymentI } from "../../services/db";
import { SystemContext } from "../SystemConfig/SystemContext";
import { getQueryStringValue } from "../../actions/auth";
import { AuthContext } from "../common/Authorization/AuthContext";
import { validateGift } from "../../actions/gift";
import { checkCPFDECORA20 } from "../../utils/checkCpf";
import { createProjectByHashAsGift } from "../../actions/project";
import { getAllUrlParams } from "../../utils/util";

const PIX_IMAGE = "https://s3.amazonaws.com/ladecora.platform/images/pix.png";

interface PaymentInfoI {
  total: number;
  company: CompanyI;
  installmentsPrices: number[];
  projectTemplatesToBuy: EnvironmentI[];
  onVoucherChange(voucher: VoucherI): void;
  voucher: VoucherI | undefined;
  calculateVoucherNoComission(): number;
}

function PaymentInfo({
  total,
  company,
  installmentsPrices,
  projectTemplatesToBuy,
  onVoucherChange,
  voucher,
  calculateVoucherNoComission,
}: PaymentInfoI) {
  const { registerEventHandler, removeEventHandler } = useContext(
    SocketIoContext
  ) as any;
  const { user } = useContext(AuthContext) as any;
  const { LadecoraDB } = useContext(SystemContext) as any;
  const [isLoading, setIsLoading] = useState<"gift" | "payment" | undefined>();
  const [paymentType, setPaymentType] = useState<"pix" | "credit_card">(
    "credit_card"
  );
  const [qrCode, _setQrCode] = useState<QrCodeI | undefined>(undefined);
  function setQrCode(
    newValue:
      | QrCodeI
      | undefined
      | ((qrCode: QrCodeI | undefined) => QrCodeI | undefined)
  ) {
    if (!newValue && qrCode) {
      (LadecoraDB as LadecoraDBI)
        .deleteItemByIndex("payments", qrCode.paymentId)
        .catch(console.log);
    }
    _setQrCode(newValue);
  }
  const [showVoucher, setShowVoucher] = useState<boolean>();
  const history = useHistory();

  const { register, control, setValue, getValues, watch } =
    useForm<CreditCardI>({
      reValidateMode: "onChange",
      mode: "onChange",
    });

  const isCheckoutPaymentHandler = useCallback((res: SocketNotificationI) => {
    const pix = getQueryStringValue("pix");
    if (pix && res?.notification?.data?.asaasPaymentId === pix) {
      successPix();
    }
  }, []);

  useEffect(() => {
    const unmount = () => {
      removeEventHandler("payment", isCheckoutPaymentHandler);
    };
    registerEventHandler("payment", isCheckoutPaymentHandler);

    const img = new Image();
    img.src = PIX_IMAGE;
    window[PIX_IMAGE] = img;

    const pix = getQueryStringValue("pix");

    if (pix && typeof pix === "string") {
      if (user?.notifications?.length) {
        const paymentNotification = user.notifications.find(
          (n) => n.data?.asaasPaymentId === pix
        );
        if (paymentNotification) {
          successPix();
          return unmount;
        }
      }
      (LadecoraDB as LadecoraDBI)
        .getItemByIndex<WaitPaymentI>("by_id", "payments", pix)
        .then((res) => {
          if (res) {
            setQrCode({
              paymentId: res.id,
              encodedImage: res.encodedImage,
              payload: res.payload,
              status: QrCodeStatusEnum.WAITING,
              timestamp: new Date().getTime(),
            });
            setPaymentType("pix");
          }
        })
        .finally(() => {
          setIsLoading(undefined);
        });
    }
    const p = getAllUrlParams();
    if (p.voucher) {
      setShowVoucher(true);
    }

    return unmount;
  }, []);

  useEffect(() => {
    if (showVoucher) {
      const p = getAllUrlParams();
      if (!voucher && p.voucher && total) {
        setValue("voucher", p.voucher);
        onVoucherAdd();
      }
    }
  }, [showVoucher, total]);

  const notifyPending = () =>
    toast.success("Recebemos seu pedido com sucesso.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notify = () =>
    toast.success("Compra realizada com sucesso!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  function successPix() {
    notify();
    setQrCode((qrCode: QrCodeI) => {
      return {
        ...qrCode,
        encodedImage: "",
        payload: "",
        status: QrCodeStatusEnum.DONE,
      };
    });
    setTimeout(() => {
      history.replace("/start");
    }, 2000);
  }
  function trackInputCreditCard(ev) {
    fireEvent(
      "event",
      "ladecora:checkout",
      "interacao:campo:pagamento",
      `preencheu:${ev.target.getAttribute("trackname")}`
    );
  }

  function onChargeClick(type: string) {
    if (type === "pix") {
      window.scrollTo(0, 0);
      setQrCode({
        encodedImage: "",
        paymentId: "",
        status: QrCodeStatusEnum.GENERATING,
        payload: "",
        timestamp: new Date().getTime(),
      });

      return pix({
        projectTemplates: projectTemplatesToBuy.map((pt) => {
          return { quantity: pt.quantity, hash: pt.hash };
        }),
        voucher:
          voucher && !voucher.asGift
            ? voucher._id
              ? voucher._id
              : { amount: voucher.amount, code: voucher.code }
            : "",
        gift: voucher?.asGift ? voucher.code : "",
      }).then((res) => {
        const pix = getQueryStringValue("pix");
        const tempsearch = new URLSearchParams(window.location.search);

        if (pix) {
          tempsearch.delete("pix");
        }

        const search = `${tempsearch.toString()}&pix=${res.paymentId}`;

        history.replace({
          pathname: window.location.pathname,
          search: search,
        });
        const timestamp = new Date().getTime();
        (LadecoraDB as LadecoraDBI)
          .addToDb<WaitPaymentI>("payments", {
            id: res.paymentId,
            encodedImage: `data:image/png;base64, ${res.encodedImage}`,
            payload: res.payload,
            timestamp,
          })
          .then(() => {
            setQrCode({
              encodedImage: `data:image/png;base64, ${res.encodedImage}`,
              paymentId: res.paymentId,
              status: QrCodeStatusEnum.WAITING,
              payload: res.payload,
              timestamp,
            });
          });

        return res;
      });
    }
    if (type === "credit_card") {
      const { number, cvc, name, expiryMonth, expiryYear, installments } =
        getValues();

      if (
        number !== "" &&
        cvc !== "" &&
        name !== "" &&
        expiryMonth !== "" &&
        expiryYear !== ""
      ) {
        const creditCard = {
          number: number.replace(/ /g, ""),
          ccv: cvc,
          holderName: name,
          expiryMonth: expiryMonth,
          expiryYear: expiryYear,
        };

        const finalPrice =
          installments && installments > 1
            ? {
                installments,
                price: total,
              }
            : total;

        setIsLoading("payment");

        charge(
          finalPrice,
          creditCard,
          projectTemplatesToBuy,
          voucher?._id ? voucher._id : null,
          voucher?.asGift ? voucher : null
        )
          .then((res) => {
            if (res.status === "PENDING") {
              notifyPending();

              setTimeout(() => {
                history.push("/environments");
              }, 1000);
            } else {
              notify();
              fireEvent("purchase", "ladecora:enhanced-ecommerce", "purchase", {
                noInteraction: "1",
                ecommerce: {
                  transaction_id: res.transactionId,
                  tax: 0,
                  shipping: 0,
                  currency: "BRL",
                  value: total.toFixed(2),
                  items: projectTemplatesToBuy.map(
                    (projectTemplate: EnvironmentI) => {
                      return {
                        item_name: sanitizeString(projectTemplate.title),
                        item_id: projectTemplate.hash,
                        currency: "BRL",
                        price: parseFloat(projectTemplate.price.toFixed(2)),
                        quantity: projectTemplate.quantity,
                        variant: projectTemplate.quantity,
                      };
                    }
                  ),
                },
              });

              setTimeout(() => {
                // history.replace("/environments-list");
                history.replace("/start");
              }, 2000);
            }
          })
          .catch(() => {
            setIsLoading(undefined);
          });
      } else {
        setIsLoading(undefined);
        toast.error("Por favor, preencha todos os campos corretamente.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      return false;
    }
  }

  function addGiftVoucher(voucher: string) {
    return validateGift(company.label, voucher)
      .then((res: any) => {
        if (res.error) return res;
        return {
          amount: res.data.value,
          typeValue: "fixed",
          code: voucher,
          asGift: true,
        };
      })
      .catch(() => {
        return Promise.resolve(false);
      });
  }

  function onVoucherAdd() {
    const throwError = () => {
      toast.error("Cupom de desconto não encontrado ou fora de validade", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsLoading(undefined);
      return;
    };

    const isLessThanMin = (voucher) => {
      if (
        company.label !== "ladecora" &&
        company.label !== "tok_stok" &&
        voucher &&
        !voucher.asGift
      ) {
        const [, totalWithDiscount] = getTotal(
          projectTemplatesToBuy,
          voucher,
          company.label
        );

        const totalNoPriceIncrease = projectTemplatesToBuy.reduce(
          (acc, c, i) => {
            if (i === projectTemplatesToBuy.length - 1) {
              return total - (acc + c.quantity) * company.priceIncrease;
            }
            return acc + c.quantity;
          },
          0
        );

        return (
          totalWithDiscount > 0 && totalWithDiscount < totalNoPriceIncrease
        );
      }
      return false;
    };

    if (!voucher) {
      setIsLoading("payment");

      const { voucher: voucherText } = getValues();

      if (!voucherText) {
        return throwError();
      }
      if (
        company.label !== "tok_stok" &&
        (voucherText === "ZERACOMISSAOWL" || voucherText === "VIVADECORA")
      ) {
        const discount = calculateVoucherNoComission();

        onVoucherChange({
          _id: "",
          name: voucherText,
          code: voucherText,
          company: company.label,
          amount: discount,
          typeValue: "fixed",
          isActive: true,
          userLimit: 0,
          limit: 0,
          asGift: false,
        });
        if (qrCode) {
          setQrCode(undefined);
        }
        setIsLoading(undefined);
        toast.success("Cupom de desconto aplicado com sucesso", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        if (
          company.label === "tok_stok" &&
          voucherText === "DECORA20" &&
          !checkCPFDECORA20(user.profile.document)
        ) {
          return throwError();
        }
        Promise.all<[Promise<VoucherI>, Promise<VoucherI>]>([
          addGiftVoucher(voucherText || "").catch(() => Promise.resolve()),
          getVoucherByCode(voucherText).catch(() => Promise.resolve()),
        ])
          .then((res) => {
            const [g, v] = res as any;
            if ((!g && !v) || (g?.error && v?.error)) {
              return throwError();
            }

            const voucher = v?.error ? g : v;
            if (isLessThanMin(voucher)) {
              return throwError();
            }
            onVoucherChange(voucher);
            if (!g?.error) {
              if (qrCode) {
                setQrCode(undefined);
              }
              onVoucherChange(g);
            }

            toast.success("Cupom de desconto aplicado com sucesso", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            setIsLoading(undefined);
          })
          .catch(() => setIsLoading(undefined));
      }
    } else {
      toast.error("Cupom de desconto já aplicado", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  function createFreeProject() {
    setIsLoading("gift");

    if (voucher?.asGift) {
      createProjectByHashAsGift(voucher?.code, projectTemplatesToBuy[0]["hash"])
        .then((res) => {
          setIsLoading(undefined);
          window.open(`/environments-list?show=${res._id}`, "_self");
        })
        .catch((err) => {
          toast.error(err.message);
          setIsLoading(undefined);
        });
    } else {
      charge(
        total,
        null,
        projectTemplatesToBuy.map((pt) => {
          return { ...pt, minPrice: pt.price };
        }),
        voucher ? voucher._id : null
      )
        .then((res) => {
          if (res.status === "PENDING") {
            notifyPending();

            setTimeout(() => {
              history.push("/environments");
            }, 1000);
          } else {
            notify();
            setTimeout(() => {
              history.replace("/environments-list");
            }, 2000);
          }
        })
        .catch(() => {
          setIsLoading(undefined);
        });
    }
  }

  function handleInputFocus(ev) {
    setValue("focus", ev.target.name);
  }
  function onPixClick() {
    onChargeClick("pix");
  }

  function onPaymentTypePix(): void {
    setPaymentType("pix");
  }
  function onPaymentTypeCreditCard(): void {
    setPaymentType("credit_card");
  }

  function onCreditCardClick(ev: React.MouseEvent<HTMLButtonElement>) {
    ev.stopPropagation();
    ev.preventDefault();
    onChargeClick("credit_card");
    return false;
  }

  function onShowVoucherToggle(): void {
    setShowVoucher(!showVoucher);
  }

  return (
    <>
      <>
        {projectTemplatesToBuy?.length && total > 0 ? (
          <div>
            <div style={{ fontSize: "1.2rem" }} className=" mb-5 mt-2 ml-5">
              <FormRadio
                name="payment_type"
                checked={paymentType === "credit_card"}
                onChange={onPaymentTypeCreditCard}
              >
                Pagar com cartão de crédito
              </FormRadio>
              <FormRadio
                name="payment_type"
                checked={paymentType === "pix"}
                onChange={onPaymentTypePix}
              >
                Pagar com PIX
              </FormRadio>
            </div>
            <Row>
              <div className="d-flex w-100">
                {showVoucher ? (
                  <Col className={styles.voucherInput}>
                    <InputGroup>
                      <input
                        {...register("voucher")}
                        className="form-control"
                        disabled={
                          !!voucher ||
                          !projectTemplatesToBuy?.length ||
                          !!isLoading
                        }
                        placeholder="Cupom de desconto"
                        type="text"
                        name="voucher"
                        onFocus={() => setValue("focus", "number")}
                      />
                      <InputGroupAddon type="append">
                        <Button
                          disabled={
                            !projectTemplatesToBuy?.length || !!isLoading
                          }
                          title="Adicionar"
                          theme="white"
                          onClick={onVoucherAdd}
                        >
                          <i className="material-icons">&#xe148;</i> Adicionar
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                ) : (
                  <button
                    className={styles.addVoucher}
                    onClick={onShowVoucherToggle}
                  >
                    Inserir cupom de desconto
                  </button>
                )}
              </div>
            </Row>
            {paymentType === "credit_card" ? (
              <div>
                <AnimatedCreditCard watch={watch} />

                <Form className="py-4 mx-auto" style={{ maxWidth: "350px" }}>
                  <Row form>
                    <Col lg="12" className="form-group">
                      <FormCardNumber
                        control={control}
                        name="number"
                        disabled={!projectTemplatesToBuy?.length || !!isLoading}
                        type="tel"
                        placeholder="Número cartão"
                        id="checkout-card-number"
                        className="form-control"
                        onFocus={handleInputFocus}
                        // @ts-ignore
                        trackname="cartao-numero" //eslint-disable-line
                        onBlur={trackInputCreditCard}
                      />
                    </Col>
                    <Col lg="12" className="form-group">
                      <input
                        {...register("name", {
                          required: "Informe o nome do cartão",
                        })}
                        type="text"
                        className="form-control"
                        disabled={!projectTemplatesToBuy?.length || !!isLoading}
                        id="checkout-card-name"
                        name="name"
                        placeholder="Nome no cartão"
                        required
                        onFocus={handleInputFocus}
                        // @ts-ignore
                        trackname="cartao-nome" //eslint-disable-line
                        onBlur={trackInputCreditCard}
                      />
                    </Col>
                    <Col lg="12">
                      <CreditCardExpiry
                        control={control}
                        disabled={!projectTemplatesToBuy?.length}
                      />
                    </Col>

                    <Col lg="12" className="form-group">
                      <FormCardCVC
                        control={control}
                        className="form-control"
                        disabled={!projectTemplatesToBuy?.length || !!isLoading}
                        type="tel"
                        name="cvc"
                        placeholder="CVV"
                        onFocus={handleInputFocus}
                        id="credit-card-input-cvc"
                        // @ts-ignore
                        trackname="cartao-cvv" //eslint-disable-line
                        onBlur={trackInputCreditCard}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="form-group">
                      <select
                        {...register("installments", { valueAsNumber: true })}
                        className="ml-auto form-control"
                        disabled={!projectTemplatesToBuy?.length || !!isLoading}
                        style={{ width: "auto" }}
                      >
                        {total > 0 &&
                          installmentsPrices.map((installment, index) => (
                            <option key={index} value={index + 1}>
                              {index + 1}x{" "}
                              {installment.toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                              })}
                            </option>
                          ))}
                      </select>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="form-group">
                      <Button
                        id="checkout-button-charge"
                        type="submit"
                        size="lg"
                        className="btn btn-custom-primary mt-3 w-100"
                        onClick={onCreditCardClick}
                        data-gtm-event-category="ladecora:home"
                        data-gtm-event-action="clique:botao:comprar"
                        data-gtm-event-label="comprar"
                        disabled={!projectTemplatesToBuy?.length || !!isLoading}
                        style={{
                          backgroundColor: company.color,
                          borderColor: company.color,
                          color: "white",
                        }}
                      >
                        {isLoading ? "Processando..." : "Realizar pagamento"}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            ) : null}

            {paymentType === "pix" ? (
              <Form>
                <Row>
                  <Col className={qrCode ? "d-none" : "d-block"}>
                    <img
                      className="w-100 pl-5 pr-5 mb-3"
                      alt=""
                      src={PIX_IMAGE}
                    />
                  </Col>
                  {qrCode ? (
                    <Col>
                      <QrCodeComp qrCode={qrCode} />
                    </Col>
                  ) : null}
                </Row>
                <Row>
                  <Col>
                    {!qrCode ? (
                      <Button
                        size="lg"
                        disabled={!projectTemplatesToBuy?.length}
                        className="btn btn-custom-primary mt-3 w-100"
                        style={{
                          backgroundColor: company.color,
                          borderColor: company.color,
                          color: "white",
                        }}
                        onClick={onPixClick}
                      >
                        Gerar QRCode
                      </Button>
                    ) : null}
                  </Col>
                </Row>
              </Form>
            ) : null}
          </div>
        ) : null}

        {projectTemplatesToBuy?.length && total === 0 ? (
          <>
            <div className="d-flex justify-content-center align-items-center">
              <span
                className="material-icons"
                style={{
                  fontSize: "70px",
                  color: "var(--newbackground-color)",
                }}
              >
                redeem
              </span>
            </div>
            <h5 className="text-center">
              Você ganhou um projeto de decoração online de presente!
            </h5>
            <div style={{ display: "grid" }}>
              <button
                className="btn btn-custom-primary mt-3"
                onClick={createFreeProject}
                disabled={isLoading === "gift"}
                style={{
                  backgroundColor: company.color,
                  borderColor: company.color,
                  color: "white",
                }}
              >
                {isLoading === "gift" ? "Salvando..." : "Continuar"}
              </button>
            </div>
          </>
        ) : null}
      </>
    </>
  );
}

export default PaymentInfo;
