import React from "react";
import { Form } from "shards-react";

const SidebarSearch = () => (
  <Form
    className="main-sidebar__search w-100 border-right d-sm-flex d-md-none d-lg-none"
    style={{ display: "flex", minHeight: "45px" }}
  />
);

export default SidebarSearch;
