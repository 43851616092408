import React, { useState, useEffect } from "react";

import {
  getAuthenticatedUserV2,
  getCookie,
  getNotifications,
} from "../../../actions/auth";
import { AuthProvider } from "./AuthContext";

const Authorization = ({ children }) => {
  const token = getCookie("token");
  const [state, setState] = useState({
    authenticated: !!token,
    doubleChecked: false,
    user: {},
    accessToken: token,
  });
  const [notifications, setNotifications] = useState([]);

  const authProviderValue = {
    ...state,
  };

  useEffect(() => {
    updateUser();
  }, []);

  useEffect(() => {
    if (state?.user?.notifications?.length) {
      setNotifications(state.user.notifications);
    }
  }, [state.user]);

  function updateUser() {
    setState({ ...state, doubleChecked: false });
    return getAuthenticatedUserV2()
      .then((user) => {
        if (user) {
          setState({
            user: user,
            token: getCookie("token"),
            authenticated: true,
            doubleChecked: true,
          });
        }
      })
      .catch(() => {
        setState({
          user: {},
          authenticated: false,
          doubleChecked: true,
        });
      });
  }

  async function updateUserNotifications() {
    return getNotifications().then((notifications) => {
      setNotifications(() => {
        return notifications;
      });
      return notifications;
    });
  }

  return (
    <AuthProvider
      value={{
        ...authProviderValue,
        updateUser,
        updateUserNotifications,
        notifications,
      }}
    >
      {state.doubleChecked && children}
    </AuthProvider>
  );
};

export default Authorization;
