import * as React from "react";
import PropTypes from "prop-types";

const FieldErrorPropTypes = {
  isValid: PropTypes.bool,
  message: PropTypes.string,
};

type FieldErrorTypes = PropTypes.InferProps<typeof FieldErrorPropTypes>;

const FieldError: React.FC<FieldErrorTypes> = ({ isValid, message }) => {
  return isValid ? (
    <div style={{ color: "red", fontSize: ".7rem" }}>{message}</div>
  ) : null;
};

export default FieldError;
