import React from "react";

import SidebarItem from "./SidebarItem";

function CollapsibleSidebarItem({
  isCollapsed,
  text,
  isLocked,
  onClick,
  items,
}) {
  return (
    <>
      <div
        className={`left-sidebar-item d-flex mb-2 p-2 ml-0 ${
          isLocked ? "" : "clickable"
        }`}
        onKeyDown={() => {
          //TODO
        }}
        role="button"
        tabIndex="0"
        onClick={() => !isLocked && onClick()}
      >
        {isCollapsed ? (
          <i
            className="material-icons"
            style={{
              fontSize: "1.3rem",
              color: "black",
              top: 0,
              marginRight: "0.3rem",
            }}
          >
            keyboard_arrow_down
          </i>
        ) : (
          <i
            className="material-icons"
            style={{
              fontSize: "1.3rem",
              color: "black",
              top: 0,
              marginRight: "0.3rem",
            }}
          >
            keyboard_arrow_right
          </i>
        )}{" "}
        {text}{" "}
        {isLocked ? (
          <i
            className="material-icons ml-auto"
            style={{
              fontSize: "1.3rem",
              color: "black",
              top: 0,
            }}
          >
            lock
          </i>
        ) : null}
      </div>
      {isCollapsed &&
        items.map((item, idx) => (
          <SidebarItem
            key={idx}
            onClick={item.onClick}
            isSelected={item.isSelected}
            isLocked={item.isLocked}
            isDone={item.isDone}
            text={item.text}
          />
        ))}
    </>
  );
}

CollapsibleSidebarItem.propTypes = {};

export default CollapsibleSidebarItem;
