import React, { useState, useEffect } from "react";
import classNames from "classnames";

const MultipleText = (props) => {
  const [inputList, setInputList] = useState([]);
  useEffect(() => {
    setInputList(props.node.node.input_list);
  }, []);

  const update = (label, score, input, isMultiselect) => {
    props.update({
      question: props.node.node.title,
      answer: label,
      score,
      input,
      goToNextStep: !isMultiselect,
      isMultiselect,
    });
  };

  const handleInputClick = (input) => {
    if (props.node.node.isMultiselect) {
      const list = inputList.map((item) => {
        // item["selected"] = false;
        return item.label === input.label
          ? { ...item, selected: !item.selected }
          : item;
      });
      const answers = list.filter((item) => item.selected);
      update(
        answers.map((l) => l.label),
        input.score,
        answers,
        true
      );
      props.node.node.input = list;
      setInputList(list);
    } else {
      // update(input.label, input.score, input);
      props.node.node.input = input;
      setInputList(
        inputList.map((item) => {
          item["selected"] = false;
          return item.label === input.label
            ? { ...item, selected: !item.selected }
            : item;
        })
      );
      update(input.label, input.score, input);
    }
  };

  return (
    <div>
      <label>{props.node.node.title}</label>
      <div className="d-flex flex-column align-items-stretch justify-content-center ml-auto mr-auto answer-container">
        {inputList.map((input, idx) => {
          const classes = classNames(
            input.selected && "answer-selected",
            "clickable",
            "answer"
          );

          return (
            <div
              onKeyDown={() => {
                //TODO
              }}
              role="button"
              tabIndex="0"
              key={idx}
              onClick={() => handleInputClick(input)}
              className={classes}
              style={{ justifyContent: "center" }}
            >
              {input.label}
            </div>
          );
        })}
      </div>
      {/* <props.stats step={props.index} {...props} /> */}
    </div>
  );
};
export default MultipleText;
