import React, { useState, useEffect } from "react";
import { Button } from "shards-react";

const Text = (props) => {
  const [text, setText] = useState("");

  useEffect(() => {
    const questionResponse = props.answeredQuestions.filter(
      (question) => question.question === props.node.node.title
    );
    if (questionResponse.length > 0) {
      setText(questionResponse[0].answer);
      props.update({
        question: props.node.node.title,
        answer: questionResponse[0].answer,
      });
    }
  }, []);
  const update = (data) => {
    setText(data.target.value);
    props.update({
      question: props.node.node.title,
      answer: data.target.value,
    });
  };
  return (
    <div>
      <label>{props.node.node.title}</label>
      <textarea
        placeholder="Digite aqui...."
        rows="5"
        type="text"
        className="form-control"
        onChange={update}
        value={text}
      />

      {/* <props.stats step={props.index} {...props} /> */}
    </div>
  );
};
export default Text;
