import React, { useState } from "react";
import Slider from "react-slick";
import { ShowLightbox } from "../common/ShowLightbox";

const VivadecoraInspirations = () => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };
  const [lightbox, setLightbox] = useState({
    open: false,
    index: 0,
    images: [],
  });

  const sendLightbox = (data, idx) => {
    if (Array.isArray(data)) {
      setLightbox({
        ...lightbox,
        images: data,
        open: true,
        index: idx,
      });
    } else {
      setLightbox({
        ...lightbox,
        images: [data],
        open: true,
        index: 0,
      });
    }
  };
  const listImg = [
    "https://s3.amazonaws.com/ladecora.platform/images/vivadecora_lp_1.png",
    "https://s3.amazonaws.com/ladecora.platform/images/vivadecora_lp_2.png",
    "https://s3.amazonaws.com/ladecora.platform/images/vivadecora_lp_3.png",
  ];

  const onBannerClick = (i) => {
    sendLightbox(listImg, i);
  };

  return (
    <>
      <h4>Marcas que inspiram</h4>
      <Slider {...settings}>
        {listImg.map((file, idx) => {
          return (
            <div key={idx}>
              <button
                onClick={() => onBannerClick(idx)}
                className="transparent w-100"
                style={{
                  paddingTop: "56.25%",
                  backgroundImage: `url("${file}")`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "center center",
                }}
              />
            </div>
          );
        })}
      </Slider>
      {lightbox.open && (
        <ShowLightbox lightbox={lightbox} setLightbox={setLightbox} />
      )}
    </>
  );
};

export default VivadecoraInspirations;
