import React from "react";
import styles from "./WhatYouGet.module.scss";

const WhatYouGet = () => {
  return (
    <>
      <h4 className="text-center">O que está incluso</h4>
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <div className={styles.left}>
            <div className={styles.textBottom}>
              <div style={{ lineHeight: 0 }}>Guia de decoração</div>
            </div>
          </div>
          <div className={styles.center}>
            <div className={styles.image1}>
              <div className={styles.textBottom}>
                <div style={{ lineHeight: 0 }}>Imagens 3D do seu ambiente</div>
              </div>
            </div>
            <div className={styles.image2}>
              <div className={styles.textBottom}>
                <div style={{ lineHeight: 0 }}>Lista de compras</div>
              </div>
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.textBottom}>
              <div style={{ lineHeight: 0 }}>Vantagens exclusivas</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhatYouGet;
