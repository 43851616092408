import React from "react";

const RenderLabels = ({ idx, labels }) => {
  return (
    <>
      {labels.length > 0 && labels[idx].length > 0 && (
        <div className="ml-3 detailImgProject">
          {labels[idx].map((elem, idx) => (
            <div key={idx} className="d-flex">
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  background: `${elem?.colorHex}`,
                }}
              />
              <p className="align-items-center w-100">{elem?.mobi?.name}</p>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export const showImageCaption = (labels) => {
  if (labels.length > 0) {
    let imagesCaption = [];
    labels.map((label) => {
      let tempImageCaption = [];
      if (label.length > 0) {
        label.map((elem) =>
          tempImageCaption.push(
            <div key={elem._id} className="d-flex ">
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  background: `${elem?.colorHex}`,
                }}
              />
              <p className="align-items-center  pl-2 w-100">
                {elem?.mobi?.name}
              </p>
            </div>
          )
        );
        imagesCaption.push(tempImageCaption);
      } else {
        imagesCaption.push([]);
      }
    });
    return imagesCaption;
  } else {
    return [];
  }
};

export default RenderLabels;
