import React, { useState } from "react";
import ReactDOM from "react-dom";

import Lightbox from "@ladecora/react-image-lightbox";

const ActionCaption = ({ lightbox }) => {
  const [open, setOpen] = useState(true);
  return (
    <>
      {lightbox.caption &&
      lightbox.caption.length > 0 &&
      lightbox.caption[lightbox.index] &&
      lightbox.caption[lightbox.index].length > 0 ? (
        <div className="d-flex justify-content-between w-100">
          <div className="w-100">
            {open && lightbox.caption[lightbox.index]}
          </div>
          <div className="w-100  d-flex justify-content-end">
            <button
              style={{
                marginTop: "-30px",
                background: "unset",
                border: "unset",
                position: "fixed",
                fontSize: "28pt",
              }}
              onClick={() => setOpen(!open)}
            >
              {open ? (
                <i
                  className="material-icons"
                  style={{ verticalAlign: "middle", color: "#ffffff" }}
                >
                  &#xe5cf;
                </i>
              ) : (
                <i
                  className="material-icons"
                  style={{ verticalAlign: "middle", color: "#ffffff" }}
                >
                  &#xe5ce;
                </i>
              )}
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
};

export const ShowLightbox = ({ lightbox, setLightbox, flipImage = false }) => {
  const renderCaption = (lightbox) => {
    const caption = document.querySelector(".ril__caption");
    const captionContent = document.querySelector(".ril__captionContent");
    caption.addEventListener("mousewheel", (e) => {
      e.stopPropagation && e.stopPropagation();
    });
    ReactDOM.render(<ActionCaption lightbox={lightbox} />, captionContent);
  };
  return (
    <Lightbox
      mainSrc={lightbox.images[lightbox.index]}
      onAfterOpen={() => renderCaption(lightbox)}
      onImageLoad={() => {
        let imgs = document.getElementsByClassName("ril-image-current");
        Array.prototype.forEach.call(imgs, (image) => {
          if (flipImage && !image.style["transform"].includes("scaleX(-1)")) {
            image.style["transform"] = `${image.style["transform"]} scaleX(-1)`;
          }
        });
      }}
      nextSrc={
        lightbox.images.length > 1
          ? lightbox.images[(lightbox.index + 1) % lightbox.images.length]
          : undefined
      }
      prevSrc={
        lightbox.images.length > 1
          ? lightbox.images[
              (lightbox.index + lightbox.images.length - 1) %
                lightbox.images.length
            ]
          : undefined
      }
      onMovePrevRequest={() => {
        const newLightbox = {
          ...lightbox,
          index:
            (lightbox.index + lightbox.images.length - 1) %
            lightbox.images.length,
        };
        setLightbox(newLightbox);
        renderCaption(newLightbox);
      }}
      onMoveNextRequest={() => {
        const newLightbox = {
          ...lightbox,
          index: (lightbox.index + 1) % lightbox.images.length,
        };
        setLightbox(newLightbox);
        renderCaption(newLightbox);
      }}
      reactModalStyle={{ overlay: { zIndex: 10000 } }}
      imageCaption={[]}
      onCloseRequest={() => setLightbox({ ...lightbox, open: false })}
    />
  );
}; /* eslint-disable-next-line */
