import { API } from "../config";
import { requestWrapper } from "../utils/api";
import { getCookie } from "./auth";

export const wantPix = (projectTemplatesToBuy) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/wantpix`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify({
        projectTemplatesToBuy,
      }),
    })
  );
};

export const brokenLink = (url) => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/log/broken-link`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify({
        url: url,
      }),
    })
  );
};
