import React, { useState, useEffect } from "react";

import PictureItem from "../../Enterprise/PictureItem";

import { BUCKET_URL } from "../../../config";

const MultipleImage = (props) => {
  const [inputList, setInputList] = useState([]);
  useEffect(() => {
    setInputList(props.node.node.input_list);
  }, []);

  const update = (answer, score, input) => {
    props.update({
      question: props.node.node.title,
      answer: answer,
      score,
      input,
      goToNextStep: !Array.isArray(answer),
    });
  };

  const handleInputClick = (input) => {
    if (props.node.node.isMultiselect) {
      const list = inputList.map((item) => {
        // item["selected"] = false;
        return item.image_path === input.image_path
          ? { ...item, selected: !item.selected }
          : item;
      });
      const answers = list.filter((item) => item.selected);
      update(
        answers.map((l) => l.image_path),
        input.score,
        answers,
        true
      );
      props.node.node.input = list;
      setInputList(list);
    } else {
      update(input.image_path, input.score, input);
      props.node.node.input = input;
      setInputList(
        inputList.map((item) => {
          item["selected"] = false;
          return item.image_path === input.image_path
            ? { ...item, selected: !item.selected }
            : item;
        })
      );
    }
  };

  return (
    <div>
      <label>{props.node.node.title}</label>

      <div
        className="d-flex align-items-center justify-content-center flex-row"
        style={{ flexWrap: "wrap", flex: 1 }}
      >
        {inputList.map((file, idx) => (
          <div key={idx}>
            <PictureItem
              isSelected={file.selected}
              height={"250px"}
              width={"250px"}
              src={
                file.image_path.includes(BUCKET_URL)
                  ? file.image_path
                  : `${BUCKET_URL}/${file.image_path}`
              }
              thumbnail={
                file.image_path.includes(BUCKET_URL)
                  ? file.image_path
                  : `${BUCKET_URL}/${file.image_path}`
              }
              idx={idx}
              onClick={() => handleInputClick(file)}
              text={file.label}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MultipleImage;
