import React, { useState, useContext, useEffect, createContext } from "react";
import { AuthContext } from "../common/Authorization/AuthContext";
// import { SocketIoContext } from "../shared/SocketIoContext";

export const NotificationContext = createContext({});

export const NotificationProvider = ({ children }) => {
  const { user } = useContext(AuthContext);

  const [notifications, setNotifications] = useState(
    user && user.notifications ? user.notifications : []
  );
  const value = { notifications, setNotifications };

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
};
