import React from "react";

function SidebarItem({ onClick, isSelected, isDone, text, isLocked }) {
  return (
    <div
      onKeyDown={() => {
        //TODO
      }}
      role="button"
      tabIndex="0"
      onClick={() => !isLocked && onClick()}
      className={`left-sidebar-item d-flex align-items-center justify-content-center mb-2 p-2 ${
        isLocked ? "" : "clickable"
      } ${isSelected ? "left-sidebar-item-selected" : ""}`}
    >
      {text}{" "}
      {isLocked ? (
        <i
          className="material-icons ml-auto"
          style={{
            fontSize: "1.3rem",
            color: "black",
            top: 0,
          }}
        >
          lock
        </i>
      ) : isDone ? (
        <i
          className="material-icons ml-auto sidebar-done-icon"
          style={{
            fontSize: "1.3rem",
            top: 0,
          }}
        >
          done
        </i>
      ) : (
        <i
          className="material-icons ml-auto"
          style={{
            fontSize: "1.3rem",
            color: "rgb(224, 121, 18)",
            top: 0,
          }}
        >
          info
        </i>
      )}
    </div>
  );
}

SidebarItem.propTypes = {};

export default SidebarItem;
