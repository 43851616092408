import React, { useState } from "react";

const ShowHidePasswordInput = ({
  name,
  placeholder,
  value,
  handleChange,
  getInputlValidations,
}) => {
  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  return (
    <div className="d-flex align-items-center inputShowHide">
      <input
        className={`form-control ${
          getInputlValidations && getInputlValidations(name)
        } `}
        required
        id={name}
        name={name}
        type={passwordType}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
      />
      {passwordType === "password" ? (
        <i
          style={{ cursor: "pointer" }}
          onClick={togglePassword}
          className="fa fa-eye-slash"
          aria-hidden="true"
        />
      ) : (
        <i
          style={{ cursor: "pointer" }}
          onClick={togglePassword}
          className="fa fa-eye"
          aria-hidden="true"
        />
      )}
    </div>
  );
};

export default ShowHidePasswordInput;
