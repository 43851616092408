import React, { useState } from "react";
import Slider from "react-slick";
import { CustomImageToSlide } from "../common/CustomImageToSlide";
import { ShowLightbox } from "../common/ShowLightbox";
import ReactStars from "react-rating-stars-component";

const Testimony = () => {
  const [lightbox, setLightbox] = useState({
    open: false,
    index: 0,
    images: [],
  });

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const sendLightbox = (data, idx) => {
    if (Array.isArray(data)) {
      setLightbox({
        ...lightbox,
        images: data,
        open: true,
        index: idx,
      });
    } else {
      setLightbox({
        ...lightbox,
        images: [data],
        open: true,
        index: 0,
      });
    }
  };

  const listImg = [
    {
      idB: 0,
      idA: 1,
      before: require("../../images/lp-img/antes1.png"),
      after: require("../../images/lp-img/depois1.png"),
      testimony:
        "Eu adorei o projeto. Pontuei tudo que eu gostava e foi seguido tudo certinho, com lindas sugestões . Solicitei algumas alterações na primeira revisão e o projeto ficou perfeito. Aproveitamento de espaço, iluminação, cores de parede e teto, piso, móveis, decoração, tudo fez parte do projeto e eu adorei! Com certeza, irei comprar mais alguns ambientes. Muito obrigado!! Minha sala ficará maravilhosa.",
      name: "Victor B. - PR",
    },
    {
      idB: 2,
      idA: 3,
      before: require("../../images/lp-img/antes2.png"),
      after: require("../../images/lp-img/depois2.png"),
      testimony:
        "Parabéns pelo trabalho. Fui surpreendido pelo projeto apresentado e pelo detalhamento do mesmo. Muito satisfeito com o atendimento online que foi claro, preciso e atencioso. Parabéns pelo projeto. Certamente farei de outro ambiente da minha casa! Obrigado !",
      name: "Bruno Ferreira -PB",
    },
    {
      idB: 4,
      idA: 5,
      before: require("../../images/lp-img/antes3.png"),
      after: require("../../images/lp-img/depois3.png"),
      testimony:
        "Gostei muito das sugestões. A equipe demonstra conhecimento e preparo, e o programa utilizado é muito bom. Souberam traduzir bem o que eu queria. O atendimento para tirar dúvidas também foi excelente! Sempre muito prestativos e atenciosos. Achei que é um serviço bacana e que vale a pena.",
      name: "Maria Eduarda - SP",
    },
  ];

  const getLisImg = () => {
    let result = [];
    listImg.forEach((item) => {
      result.push(item.before);
      result.push(item.after);
    });

    return result;
  };
  return (
    <>
      <h4>Fazemos a diferença na casa dos nossos clientes!</h4>

      <Slider {...settings}>
        {listImg.map((item, idx) => {
          return (
            <div key={idx} id="after-slider">
              <div id="imgs-slider">
                <div id="divBefore">
                  <h5>Antes</h5>
                  <CustomImageToSlide
                    file={item.before}
                    onclick={() => {
                      sendLightbox(getLisImg(), item.idB);
                    }}
                  />
                </div>
                <div id="divAfter">
                  <h5>Depois</h5>
                  <CustomImageToSlide
                    file={item.after}
                    onclick={() => {
                      sendLightbox(getLisImg(), item.idA);
                    }}
                  />
                </div>
              </div>
              <div id="rating">
                <h5>{item.name}</h5>
                <p>{`"${item.testimony}"`}</p>
                <ReactStars size={20} value={4} edit={false} />
              </div>
            </div>
          );
        })}
      </Slider>

      {lightbox.open && (
        <ShowLightbox lightbox={lightbox} setLightbox={setLightbox} />
      )}
    </>
  );
};

export default Testimony;
