import React, { useEffect, useState, useContext } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Button,
  ModalBody,
  ModalHeader,
  Modal,
} from "shards-react";
import { toast, ToastContainer } from "react-toastify";
import PhoneBR from "../components/common/PhoneBR";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import { PhoneNumberUtil } from "google-libphonenumber";
import { emailValidation, valida_cpf_cnpj } from "../utils/form_validation";

import { getUser, updateUser } from "../actions/user";
import { SystemContext } from "../components/SystemConfig/SystemContext";

import { resetUserPassword } from "../actions/auth";
import ShowHidePasswordInput from "../components/common/ShowHidePasswordInput";

const Profile = () => {
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    document: "",
    documentType: "",
    phone: "",
    city: "",
    number: "",
    postalCode: "",
    state: "",
    street: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [winWidth, setWinWidth] = useState(window.innerWidth);
  const [storedUser, setStoredUser] = useState(null);
  const [stateData, setStateData] = useState([]);
  const [viaCepData, setViaCepData] = useState({});
  const phoneUtil = PhoneNumberUtil.getInstance();
  const [showChangePassword, setShowChangePassword] = useState(false);
  const defaultValuePassword = {
    currentPassword: "",
    password: "",
    confirmPassword: "",
    error: false,
  };
  const [dataChangePassword, setDataChangePassword] =
    useState(defaultValuePassword);

  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      window.removeEventListener("resize", detectSize);
    };
  });

  const detectSize = () => {
    setWinWidth(window.innerWidth);
  };

  useEffect(() => {
    getUser().then((data) => {
      setStoredUser(data);
      let newUser = JSON.parse(JSON.stringify(user));

      newUser.firstName = data?.profile?.firstName;
      newUser.lastName = data?.profile?.lastName;
      newUser.document = data?.profile?.document;
      newUser.phone = data.profile.phone && formatPhone(data.profile.phone);
      newUser.email = data?.email;
      newUser.city = data?.address?.city;
      newUser.number = data?.address?.number;
      newUser.postalCode = data?.address?.postalCode;
      newUser.state = data?.address?.state;
      newUser.street = data?.address?.street;
      setUser(newUser);
    });
  }, []);

  useEffect(() => {
    user.postalCode.length > 0 &&
      fetchViacep(user.postalCode)
        .then((data) => {
          setViaCepData(data);
          setUser({
            ...user,
            city: data.localidade,
            postalCode: data.cep,
            state: data.uf,
            street: data.logradouro,
          });
        })
        .catch(() => {
          setUser({
            ...user,
            postalCode: "",
          });
          toast.error("O Cep informado não existente!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
  }, [user.postalCode]);

  const { password, confirmPassword, error, currentPassword } =
    dataChangePassword;

  const fetchViacep = (value) => {
    let cep = value.replace(/[-,' ']/g, "");
    let url = `http://viacep.com.br/ws/${cep}/json/`;

    return new Promise((resolve, reject) => {
      if (cep.length === 8) {
        fetch(url, { mode: "cors" })
          .then((res) => res.json())
          .then((data) => {
            if (Object.prototype.hasOwnProperty.call(data, "erro")) {
              reject(data);
            } else {
              resolve(data);
            }
          });
      }
    });
  };

  const fetchStateData = () => {
    let url = `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${user.state}/municipios`;

    if (user.state.length > 0) {
      fetch(url, {
        mode: "cors",
      })
        .then((res) => res.json())
        .then((data) => {
          setStateData(data);
        });
    }
  };
  useEffect(() => {
    fetchStateData();
  }, [user.state]);

  const { company } = useContext(SystemContext);

  const formatPhone = (value) => {
    try {
      value = phoneUtil.parse(value, "BR");
      if (!phoneUtil.isValidNumberForRegion(value, "BR")) return null;
      return phoneUtil.formatInOriginalFormat(value, "BR");
    } catch (err) {
      return null;
    }
  };

  const onInputChange = (event) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    });
  };

  function required(value) {
    return !!value;
  }

  const validPostalCode = () => {
    let cep = user.postalCode.replace(/[-,' ']/g, "");
    return cep.length === 8;
  };

  const validCityName = () => {
    return (
      stateData.find(
        (elem) => elem.nome.toLowerCase() === user.city.toLowerCase()
      ) != undefined
    );
  };

  const validState = (value) => {
    const regex = /\d/;
    return (
      viaCepData?.uf &&
      !regex.test(value) &&
      viaCepData.uf.toLowerCase() === user.state.toLowerCase()
    );
  };

  const formValidations = {
    firstName: { validators: [required], isValid: true },
    lastName: { validators: [required], isValid: true },
    email: { validators: [required, emailValidation], isValid: true },
    document: { validators: [required, valida_cpf_cnpj], isValid: true },
    phone: { validators: [required, phoneValidation], isValid: true },
    city: { validators: [required, validCityName], isValid: true },
    number: { validators: [required], isValid: true },
    postalCode: { validators: [required, validPostalCode], isValid: true },
    state: { validators: [required, validState], isValid: true },
    street: { validators: [required], isValid: true },
  };

  function applyValidationsToForm(validations) {
    let keys = Object.keys(formValidations);
    keys.forEach((key) => {
      formValidations[key].isValid = validations[key];
      if (!validations[key]) {
        user[key] = "";

        let fild = "";
        switch (key) {
          case "firstName":
          case "lastName":
            fild = "Nome";
            break;
          case "email":
            fild = "Email";
            break;
          case "document":
            fild = "CPF/CNPJ";
            break;
          case "phone":
            fild = "Telefone";
            break;
          case "postalCode":
            fild = "CEP";
            break;
          case "street":
            fild = "Rua";
            break;
          case "number":
            fild = "Número";
            break;
          case "city":
            fild = "Cidade";
            break;
          case "state":
            fild = "UF";
            break;
        }

        toast.error(`O dado inserido em ${fild} está incorreto!`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setIsLoading(false);
      }
    });
    setUser({
      ...user,
    });
  }

  function validate() {
    let fieldNames = Object.keys(formValidations);
    let validations = fieldNames.reduce((acc, current) => {
      acc[current] = isFieldValid(current);
      return acc;
    }, {});
    applyValidationsToForm(validations);
  }

  function isFieldValid(field) {
    let value = user[field];
    let fieldValidations = formValidations[field].validators;
    let result = fieldValidations.reduce((isValid, nextValidation) => {
      return isValid && nextValidation(value);
    }, true);
    return result;
  }

  const confirmProfileValidation = (event) => {
    setIsLoading(true);
    event.preventDefault();
    validate();

    if (isProfileValid()) {
      storedUser.email = user.email;
      storedUser.profile.phone = `+55${user.phone.replace(/\D/g, "")}`;
      storedUser.profile.document = user.document.replace(/[^0-9]/g, "");
      storedUser.profile.documentType = user.documentType;

      if (
        `${storedUser.profile.firstName} ${storedUser.profile.lastName}` !==
        user.name
      ) {
        storedUser.profile.firstName = user.firstName;
        storedUser.profile.lastName = user.lastName;
      }
      storedUser.address.city = user.city;
      storedUser.address.number = user.number;
      storedUser.address.postalCode = user.postalCode;
      storedUser.address.state = user.state;
      storedUser.address.street = user.street;

      updateUser(storedUser._id, storedUser)
        .then(() => {
          setIsLoading(false);
          toast.success("Dados atualizado com sucesso!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error("Ocorreu um erro, por favor, tente novamente", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          console.log(err);
        });
    }
  };

  function isProfileValid() {
    let keys = Object.keys(formValidations);
    return keys.reduce((isValid, key) => {
      return isValid && formValidations[key].isValid;
    }, true);
  }

  function phoneValidation(value) {
    try {
      value = phoneUtil.parse(value, "BR");
      return phoneUtil.isValidNumberForRegion(value, "BR");
    } catch (err) {
      return false;
    }
  }

  const handleChangePassword = (event) => {
    event.preventDefault();
    setDataChangePassword({
      ...dataChangePassword,
      error: false,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmitChangePassword = (event) => {
    event.preventDefault();
    setIsLoading(true);

    resetUserPassword({
      id: storedUser._id,
      currentPassword,
      password,
      confirmPassword,
    }).then((data) => {
      if (data.error) {
        setIsLoading(false);
        setDataChangePassword({
          ...dataChangePassword,
          error: data.error,
        });
      } else {
        setIsLoading(false);
        toast.success("Senha Atualizada com sucesso!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setDataChangePassword(defaultValuePassword);
        setShowChangePassword(false);
      }
    });
  };
  const showError = () =>
    error ? <div className="alert alert-danger">{error}</div> : "";
  return (
    <Container fluid className="main-content-container ">
      <ToastContainer />
      <Row>
        <Col lg="12">
          <Card
            small
            style={{
              padding: "7%",
            }}
          >
            <CardBody className="p-0 pb-3">
              <Form onSubmit={confirmProfileValidation}>
                <div
                  className={
                    winWidth > 1085 ? "d-flex justify-content-around" : ""
                  }
                >
                  <div className="mb-3" style={{ Width: "100%" }}>
                    <h6
                      className="m-0 mb-4"
                      style={{ fontSize: "22px", color: company.color }}
                    >
                      Dados pessoais
                    </h6>
                    <div className="form-group">
                      <label htmlFor="input_email">Email</label>
                      <input
                        size="45"
                        type="text"
                        className="form-control w-100"
                        required
                        disabled
                        onChange={onInputChange}
                        name="email"
                        value={user.email}
                        aria-describedby="emailHelp"
                        placeholder="Digite seu email"
                        id="input_email"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="input-first-name">Primeiro nome</label>
                      <input
                        size="45"
                        id="input-first-name"
                        type="text"
                        className="form-control"
                        required
                        onChange={onInputChange}
                        name="firstName"
                        value={user.firstName}
                        aria-describedby="firstName"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="input-last-name">Último nome</label>
                      <input
                        size="45"
                        id="input-last-name"
                        type="text"
                        className="form-control"
                        required
                        onChange={onInputChange}
                        name="lastName"
                        value={user.lastName}
                        aria-describedby="lastName"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="input-cpf-cnpj">CPF/CNPJ</label>
                      <CpfCnpj
                        size="45"
                        id="input-cpf-cnpj"
                        type="text"
                        className="form-control"
                        required
                        onChange={(document, type) => {
                          setUser({
                            ...user,
                            document: document.target.value,
                            documentType: type,
                          });
                        }}
                        name="document"
                        value={user.document}
                        aria-describedby="document"
                        placeholder="999.999.999-99"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="input-phone">Telefone</label>
                      <PhoneBR
                        size="45"
                        id="input-phone"
                        required
                        value={user.phone}
                        onChange={(phone) => {
                          setUser({ ...user, phone: phone });
                        }}
                        placeholder="Informe seu telefone"
                        autoComplete="phone"
                        name="phone"
                        className="form-control"
                      />
                    </div>
                    {company.label !== "eztec" && company.label !== "tok_stok" && (
                      <div>
                        <Button
                          style={{
                            backgroundColor: company.color,
                            borderColor: company.color,
                            color: "white",
                          }}
                          onClick={() => setShowChangePassword(true)}
                        >
                          Alterar senha
                        </Button>
                      </div>
                    )}
                  </div>
                  <div>
                    <h6
                      className="m-0 mb-4"
                      style={{ fontSize: "22px", color: company.color }}
                    >
                      Endereço
                    </h6>
                    <div className="form-group">
                      <label htmlFor="input-postalCode">CEP</label>
                      <input
                        size="45"
                        maxLength="9"
                        id="input-postalCode"
                        type="text"
                        className="form-control"
                        required
                        onChange={onInputChange}
                        name="postalCode"
                        value={user.postalCode}
                        aria-describedby="postalCode"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="input_street">Rua</label>
                      <input
                        size="45"
                        type="text"
                        className="form-control"
                        required
                        onChange={onInputChange}
                        name="street"
                        value={user.street}
                        aria-describedby="street"
                        id="input_street"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="input-number">Número</label>
                      <input
                        size="45"
                        id="input-number"
                        type="number"
                        className="form-control"
                        required
                        onChange={onInputChange}
                        name="number"
                        value={user.number}
                        aria-describedby="number"
                      />
                    </div>
                    <div className="d-flex justify-content-around">
                      <div className="form-group mr-1">
                        <label htmlFor="input-city">Cidade</label>
                        <input
                          id="input-city"
                          type="text"
                          className="form-control"
                          required
                          onChange={onInputChange}
                          name="city"
                          value={user.city}
                          aria-describedby="citty"
                        />
                      </div>
                      <div className="form-group ml-1">
                        <label htmlFor="input-state">UF</label>
                        <input
                          maxLength="2"
                          id="input-state"
                          type="text"
                          className="form-control"
                          required
                          onChange={onInputChange}
                          name="state"
                          value={user.state}
                          aria-describedby="state"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-custom-primary mt-3"
                    disabled={isLoading}
                    style={{
                      width: "200px",
                      backgroundColor: company.color,
                      borderColor: company.color,
                      color: "white",
                    }}
                  >
                    {"Alterar dados"}
                  </button>
                </div>
              </Form>
              <Modal
                centered={true}
                open={showChangePassword}
                size="md"
                toggle={() => {
                  setShowChangePassword(false);
                  setDataChangePassword(defaultValuePassword);
                }}
              >
                <ModalHeader
                  toggle={() => {
                    setShowChangePassword(false);
                    setDataChangePassword(defaultValuePassword);
                  }}
                >
                  Alteração de Senha
                </ModalHeader>
                <ModalBody>
                  <Form onSubmit={handleSubmitChangePassword}>
                    <FormGroup>
                      <label htmlFor="currentPassword">Senha atual</label>
                      <ShowHidePasswordInput
                        name="currentPassword"
                        placeholder="Informe sua senha atual"
                        value={currentPassword}
                        handleChange={handleChangePassword}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label htmlFor="password">Nova senha</label>
                      <ShowHidePasswordInput
                        name="password"
                        placeholder="Informe sua nova senha"
                        value={password}
                        handleChange={handleChangePassword}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label htmlFor="confirmPassword">Confirmar senha</label>
                      <ShowHidePasswordInput
                        name="confirmPassword"
                        placeholder="Confirmar a nova senha"
                        value={confirmPassword}
                        handleChange={handleChangePassword}
                      />
                    </FormGroup>
                    {showError()}
                    <Button
                      pill
                      theme="accent"
                      className="d-table mx-auto"
                      type="submit"
                    >
                      {"Alterar senha"}
                    </Button>
                  </Form>
                </ModalBody>
              </Modal>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Profile;
