import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardImg,
  CardTitle,
  Button,
  Row,
  Col,
} from "shards-react";
import { useHistory } from "react-router-dom";

const HowItWorksLp = ({ company }) => {
  const history = useHistory();
  return (
    <>
      <h4>Como funciona</h4>
      <Row>
        <CardSteps
          header={1}
          src={"https://ladecora.com.br/wp-content/uploads/2022/08/relax.png"}
          title={"Escolha o ambiente"}
          text={
            "Selecione um ou mais ambientes para iniciarmos a transformação!"
          }
        />
        <CardSteps
          header={2}
          src={"https://ladecora.com.br/wp-content/uploads/2022/08/choose.png"}
          title={"Mande suas referências"}
          text={
            "Vamos valorizar seu estilo de decoração! Envie fotos, informações e medidas do seu ambiente para o nosso time!"
          }
        />
        <CardSteps
          header={3}
          src={"https://ladecora.com.br/wp-content/uploads/2022/08/home.png"}
          title={"Veja o seu projeto 3D"}
          text={
            "Nossos arquitetos vão usar as informações enviadas para montar um projeto personalizado para você em até 5 dias!"
          }
        />
        {company.lp?.howItWorks?.length ? (
          <CardSteps
            header={4}
            src={
              "https://ladecora.com.br/wp-content/uploads/2022/08/living-room.png"
            }
            title={company.lp.howItWorks[0].title}
            text={company.lp.howItWorks[0].description}
          />
        ) : (
          <CardSteps
            header={4}
            src={
              "https://ladecora.com.br/wp-content/uploads/2022/08/living-room.png"
            }
            title={`Conte com a ${company.name} para a decoração`}
            text={`Junto com seu projeto você recebe a lista de produtos ${company.name} para agilizar sua compra!`}
          />
        )}
      </Row>
      <Button
        theme="accent"
        size="lg"
        onClick={() => {
          history.push(`/environments${history.location.search}`);
        }}
      >
        Quero começar
      </Button>
    </>
  );
};

const CardSteps = ({ header, src, title, text }) => {
  return (
    <Col sm={12} md={4} lg={2}>
      <Card>
        <CardHeader>{header}</CardHeader>
        <CardImg src={src} />
        <CardBody>
          <CardTitle>{title}</CardTitle>
          <p>{text}</p>
        </CardBody>
      </Card>
    </Col>
  );
};

export default HowItWorksLp;
