import React, { ChangeEvent, useMemo } from "react";
import PropTypes from "prop-types";
import {
  InputTypes,
  InputDefaultPropTypes,
} from "../../utils/constants/inputDefaultPropTypes";
import { InferPropTypes } from "./InferPropTypes";
import { clearPhone } from "../../utils/form_utils";

const PhoneBRPropTypes = {
  onChange: PropTypes.func.isRequired,
};

const PhoneBRDefaultProps = {
  value: "",
};

const MASK = "(99) 99999-9999";
const MAX_LENGTH = 11;
const PhoneBR: React.FC<
  InputTypes &
    InferPropTypes<typeof PhoneBRPropTypes, typeof PhoneBRDefaultProps>
> = (props) => {
  const { value, onChange } = props;
  const maskedValue = useMemo(() => {
    if (value) {
      let result = "";

      let inc = 0;
      Array.from(clearPhone(value, MAX_LENGTH, true)).forEach((letter) => {
        let nextChars = "";
        while (!MASK[inc].match(/[0-9]/)) {
          nextChars += MASK[inc];
          inc++;
        }
        inc++;

        result += nextChars;
        result += letter;
      });
      return result;
    }
    return "";
  }, [value]);

  function onLocalChange(ev: ChangeEvent<HTMLInputElement>) {
    const value = clearPhone(ev.target.value, MAX_LENGTH);

    const nextLength = value.length;

    if (nextLength > MAX_LENGTH) return;

    onChange(value);
  }

  return (
    <input {...props} type="tel" value={maskedValue} onChange={onLocalChange} />
  );
};

PhoneBR.propTypes = { ...InputDefaultPropTypes, ...PhoneBRPropTypes };

export default PhoneBR;
