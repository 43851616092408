export interface CompanyI {
  email: {
    logOrigin: string;
    defaultOrigin: string;
    appName: string;
    emailHeaderImage: string;
  };
  trello: {
    key: string;
    token: string;
    startListId: string;
    boardId: string;
  };
  tracking: {
    facebook: string;
  };
  externalLoginUrl: string;
  _id: string;
  name: string;
  label: string;
  appHost: string;
  host: string;
  isMain: boolean;
  priceIncrease: number;
  updatedAt: string;
  pdf: {
    cover: string;
    thanks: string;
  };
  logo: string;
  color: string;
  navBarColor: string;
  recommendation: {
    voucherPrice: string;
    cashback: string;
    active: boolean;
  };
  enabled: boolean;
  projectTemplateOverrides: any[];
  favIcon: string;
}

export interface VoucherI {
  _id: string;
  name: string;
  code: string;
  company: string;
  amount: number;
  typeValue: string;
  isActive: boolean;
  userLimit: number;
  limit: number;
  createdAt?: string;
  asGift: boolean;
}

export interface QrCodeI {
  encodedImage: string;
  paymentId: string;
  status: QrCodeStatus;
  payload: string;
  timestamp: number;
}

export interface CreditCardI {
  cvc: string;
  expiryMonth: string;
  expiryYear: string;
  focus: string;
  name: string;
  number: string;
  installments?: number;
  voucher?: string;
}

export interface UserNotificationI {
  _id: string;
  linkId: string;
  isDismissable: boolean;
  title: string;
  description: string;
  time: string;
  isRead: boolean;
  showInBanner: boolean;
  isImportant: boolean;
  bannerContent: string;
  showInBannerRefreshTime: boolean;
  theme: string;
  data?: {
    paymentId: string;
    asaasPaymentId: string;
  };
}

export interface SocketNotificationI {
  room: string;
  project: string | null | undefined;
  notification: UserNotificationI;
}

export const QrCodeStatusEnum = {
  DONE: "DONE",
  VALIDATING: "VALIDATING",
  GENERATING: "GENERATING",
  WAITING: "WAITING",
} as const;

export type QrCodeStatus =
  (typeof QrCodeStatusEnum)[keyof typeof QrCodeStatusEnum];
