import React from "react";
import { Container, Button, Row, Col } from "shards-react";
import { Link, useHistory } from "react-router-dom";

const NavbarLp = ({
  logo,
  name,
  label,
  mainRef,
  priceRef,
  projectsDoneRef,
  howItWorksRef,
  isNavExpanded,
  setIsNavExpanded,
  scrollToTarget,
}) => {
  const history = useHistory();
  return (
    <Container>
      <nav className="navigation">
        <div>
          <img
            style={{
              maxWidth: "250px",
            }}
            className="d-table mx-auto"
            alt={name}
            src={logo}
          />
        </div>
        <button
          className="hamburger"
          onClick={() => {
            setIsNavExpanded(!isNavExpanded);
          }}
        >
          {isNavExpanded ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#ffffff"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="white"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                clipRule="evenodd"
              />
            </svg>
          )}
        </button>
        <div
          className={
            isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
          }
        >
          <ul>
            <li>
              <Link
                onClick={() => {
                  if (label === "vivadecora") {
                    window.open("https://vivadecorapro.com.br/", "_self");
                  } else {
                    scrollToTarget(mainRef);
                  }
                }}
              >
                Página Inicial
              </Link>
            </li>
            <li>
              <Link onClick={() => scrollToTarget(priceRef)}>Preços</Link>
            </li>
            <li>
              <Link onClick={() => scrollToTarget(projectsDoneRef)}>
                Projetos Realizados
              </Link>
            </li>
            <li>
              <Button
                theme="accent"
                size="md"
                outline
                onClick={() => {
                  history.push(`/login${history.location.search}`);
                }}
              >
                Entrar
              </Button>
            </li>
          </ul>
        </div>
      </nav>
      <Row>
        <Col>
          <div
            className={`${
              label === "vivadecora"
                ? "content-after-nav-vivadecora"
                : "content-after-nav"
            }`}
          >
            <div className="content-after-nav-child shadow">
              <h3>Projeto de decoração online rápido, prático e acessível</h3>
              <p>Tudo o que você precisa para transformar a sua decoração!</p>
              <div className="benefit-content">
                <ul>
                  <li>✓ Projetos residenciais e comerciais</li>
                  <li>✓ 100% online</li>
                  <li>✓ Tudo no seu tempo</li>
                  <li>✓ Detalhamento de marcenaria</li>
                </ul>

                <ul>
                  <li>✓ Projetos Personalizados</li>
                  <li>✓ Ajuda de profissionais</li>
                  <li>✓ Imagens 3D realista</li>
                  <li>✓ Lista de compras</li>
                </ul>
              </div>
              <Button
                theme="accent"
                size="lg"
                onClick={() => {
                  scrollToTarget(howItWorksRef);
                }}
              >
                Como funciona
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default NavbarLp;
