import React, { useEffect, useContext, useState } from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import Faq from "react-faq-component";
import { SystemContext } from "../components/SystemConfig/SystemContext";

const FaqPlataforma = () => {
  const { company } = useContext(SystemContext);
  const [price, setPrice] = useState("");

  useEffect(() => {
    setPrice(
      (299 + company.priceIncrease).toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      })
    );
  }, []);

  const data = {
    rows: [
      {
        title: `Como funciona a ${company.name}?`,
        content: `Após a compra do ambiente, serão liberados os seguintes acessos ao cliente: <br />
        Cronograma com etapas de envio de informações para a assessoria:
        <ul>
          <li>Questionário 1: Envio de fotos do local e planta do ambiente; </li>
          <li>Questionário 2: Estilos e preferências. </li>
        </ul>
        Após o envio de todas as informações necessárias, nossos decoradores desenvolvem o seu ambiente personalizado. Você também poderá descrever as suas solicitações à parte, para que tenhamos todas as suas referências para entregarmos um ambiente com a sua cara.
        `,
      },
      {
        title: `Como faço para comprar o projeto?`,
        content: `Você pode selecionar o ambiente que deseja decorar e seguir para o carrinho de compras, onde será necessário concluir seu cadastro na plataforma, assim será direcionado para a compra via cartão de crédito ou PIX. Após a confirmação de compra, o ambiente selecionado será liberado na sua plataforma para início da jornada de decoração.`,
      },
      {
        title:
          "Eu enviei as fotos erradas nos questionários iniciais do ambiente. É possível corrigir as imagens enviadas?",
        content: `
        Sim, você pode revisar seu questionário antes do início da assessoria. Se tiver dificuldade poderá nos solicitar ajuda pelo SAC.
        `,
      },
      {
        title:
          "Tenho fotos de um ambiente pronto que gostaria de usar como inspiração. Onde posso enviar estas imagens?",
        content: `
        Sim, no processo de atendimento será solicitado o envio de imagens de inspiração.
        `,
      },
      {
        title: `Já utilizei o serviço ${company.name} e gostaria de ter o mesmo decorador em meu próximo ambiente. É possível escolher o profissional que vai realizar o serviço?`,
        content: `
       Não é possível escolher o decorador que irá realizar o serviço, porém nosso time é treinado com uma metodologia única que garante a satisfação do cliente.
        `,
      },
      {
        title: "O que está incluso no ambiente final?",
        content: `
        Após a conclusão da etapa de envio de informações, nossos decoradores criam o seu ambiente personalizado e, assim que ele estiver pronto, o sistema disponibiliza as imagens 3D para a sua aprovação. <br />
        Com a aprovação do 3D, nossa equipe monta todo o material técnico, que contém: <br />
        - Posicionamento  da decoração; <br />
        - Posicionamento  de luminárias; <br />
        - Sugestão de  acabamentos de paredes; <br />
        - Ambiente decorado com imagens em  3D; <br />
        - Tabelas técnicas de móveis, decoração, luminárias, papel de parede, cortinas, persianas, tapetes e toda a decoração; <br />
        - Considerações para executar a decoração; <br />
        - Dicas úteis e personalizadas de decoração; <br />
        `,
      },
      {
        title:
          "Qual é o formato dos arquivos que fazem parte do material técnico e das imagens em 3D? É necessário ter algum programa especial para abri-los?",
        content: `
        Não é necessário ter programa especial para abrir, pois as imagens serão enviadas em formato JPG e o material técnico em formato PDF.
        `,
      },
      {
        title:
          "É possível abrir as imagens 3D e o material técnico no meu celular?",
        content: `
        Sim, nosso sistema é responsivo para celular.
        `,
      },
      {
        title:
          "Estou recebendo uma mensagem de erro ao tentar abrir as imagens 3D e/ou o material técnico. O que eu faço?",
        content: `
        Poderá fechar o sistema e abrir novamente para atualização. Caso o problema persista favor entrar em contato via SAC e informar o erro.
        `,
      },
      {
        title: `Para quem o serviço ${company.name} é indicado?`,
        content: `
        O ${company.name} é indicado para todos que sonham em ter um ambiente decorado, profissional, prático e acessível. As assessorias são contratadas por ambiente e você tem a liberdade de realizar a decoração de acordo com o seu tempo, com o auxílio total de nossa assessoria em 3D e um material técnico explicativo para seguir passo a passo.
        `,
      },

      {
        title: "Eu quero reformar, este serviço me atende?",
        content: `
        O ${company.name} é um serviço exclusivamente de decoração online. Ele não abrange modificações estruturais, hidráulicas ou elétricas com aumento de carga. Portanto, caso você busque uma reforma, você deve contratar um arquiteto ou engenheiro local para a execução de um projeto de reforma e nos enviar a planta aprovada para desenvolvermos a decoração.
        `,
      },
      {
        title: "Quais os tipos de ambientes que o serviço abrange?",
        content: `
        O ${company.name} pode ser utilizado em qualquer canto da sua casa que você deseje decorar! Nossos decoradores estão à disposição para entregar assessorias de salas de estar, salas de jantar, quartos, cozinhas, banheiros e qualquer outro cômodo que você tenha em mente. Você tem a oportunidade de explicar tudo o que precisa no início da assessoria para que nós possamos entregar algo do jeito que você deseja.
        `,
      },
      {
        title: "O serviço também é válido para áreas externas?",
        content: `
        Sim, nossos decoradores também realizam o serviço de decoração para áreas externas, como varandas e quintais, por exemplo.
        `,
      },
      {
        title: "Qual é o prazo de entrega do assessoria completa?",
        content: `
        Após a conclusão da etapa de envio de informações para o início da assessoria, o prazo de entrega é de 5 (cinco) dias úteis para envio do projeto 3D. Após a aprovação dele pelo cliente, há um prazo de 3 (três) dias úteis para envio do material técnico.
        `,
      },
      {
        title: "É possível solicitar uma revisão da assessoria?",
        content: `
        Sim, você pode solicitar até 2 (duas) revisões em até 20 (vinte) dias corridos após envio da primeira versão. Caso o cliente não solicite nenhuma revisão dentro do prazo estipulado, a assessoria será considerada aprovada pelo sistema e o material técnico será enviado em 3 (três) dias úteis.
        `,
      },
      {
        title:
          "Eu aprovei o ambiente 3D, mas gostaria de solicitar uma revisão. É possível voltar atrás?",
        content: `
        Não. Depois que o cliente aprova as imagens 3D, nossos decoradores já iniciam a elaboração do material técnico, o que impede qualquer nova alteração. Poderá ser feita solicitação de revisão extra no valor de R$150,00 via SAC.
        `,
      },
      {
        title:
          "Eu gostaria de aproveitar alguns móveis que já tenho. Como posso incluí-los na assessoria?",
        content: `
        Para aproveitar seus móveis atuais, você só precisa nos enviar as fotos e medidas de todos os objetos que deseja manter e vamos considerá-los na elaboração da assessoria.
        `,
      },
      {
        title: `Eu posso contratar o serviço ${company.name} nas lojas físicas?`,
        content: `
        No momento, o serviço está apenas disponível em nosso site.
        `,
      },
      {
        title: `Qual é o custo do serviço ${company.name}`,
        content: `
        O serviço possui um valor padrão de ${price} por ambiente.
        `,
      },
      {
        title:
          "O tipo e o tamanho do ambiente influenciam no preço do serviço?",
        content: `
        Não, o valor de ${price} é válido para qualquer cômodo, independentemente do tamanho.
        `,
      },
      {
        title:
          "Como é calculado o custo da assessoria para ambientes integrados?",
        content: `
        No caso de ambientes integrados, os valores serão cobrados separadamente de acordo com o ambiente e a função do mesmo. Por exemplo:
        <ul>
        <li>Cozinha com sala de jantar e sala de estar = 3 ambientes;</li>
        <li>Sala de jantar com sala de estar = 2 ambientes;</li>
        <li>Quarto com closet = 2 ambientes;</li>
        <li>Cozinha com área de serviço = 2 ambientes.</li>
        </ul>
        `,
      },
      {
        title: "Ainda não encontrou o que você estava buscando?",
        content: `
        Para outras dúvidas relacionadas ao serviço ${company.name}, basta entrar em contato pelo SAC.
        `,
      },
      {
        title:
          "Precisa decorar mais de 10 ambientes ao mesmo tempo? Temos algumas regras especiais para prazos de envio e revisões, confira:",
        content: `
        <ul>
        <li>Até 10 assessorias: prazo de envio da proposta inicial de 5 (cinco) dias úteis (considerando envio de informações na mesma data) e prazo para revisões de até 3 (três) dias úteis</li>
        <li>De 10 a 20 assessorias: prazo de envio da proposta inicial de até 7 (sete)  dias úteis (considerando envio de informações na mesma data) e prazo para revisões de até 5 (cinco) dias úteis</li>
        <li>De 20 a 30 assessorias: prazo de envio da proposta inicial de até 9 (nove)  dias úteis (considerando envio de informações na mesma data) e prazo para revisões de até 7 (sete) dias úteis</li>
        <li>A cada acréscimo de 10 assessorias os prazos aumentam em 2 dias úteis para envio inicial e para revisões conforme a regra acima.</li>
 </ul>

        `,
      },
      {
        title: "Gostaria de cancelar a compra do meu projeto, é possível?",
        content: `
        Se o desejo de cancelamento ocorrer em até 7 dias da compra e o projeto não tiver sido iniciado, fazemos o estorno da compra de forma a garantir o direito legal de arrependimento. Caso o projeto tenha sido iniciado e não fique satisfeito com o projeto enviado, temos as 2 revisões para o alinhamento das expectativas para assim garantir a sua satisfação com relação ao projeto recebido. Se mesmo após a utilização das 2 revisões ainda estiver insatisfeito com o projeto, formalizaremos o cancelamento da compra e a devolução do investimento.
        `,
      },
    ],
  };

  const styles = {
    rowContentColor: "#686868",
    rowTitleColor: "#3C3C3E",
    rowContentPaddingLeft: "10px",
    rowContentPaddingRight: "10px",
    rowContentPaddingTop: "10px",
    rowTitleTextSize: "16px",
    rowContentTextSize: "15px",
  };

  return (
    <Container fluid className="main-content-container px-4">
      <Row className="page-header py-4">
        <Col>
          <PageTitle title="FAQ" className="text-sm-left mb-3" />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card small className="mb-4">
            <CardHeader className="border-bottom" />
            <CardBody className="p-0 pb-3">
              <Col />
              <Col>
                <Faq data={data} styles={styles} />
              </Col>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default FaqPlataforma;
