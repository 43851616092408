import { EventEmitter } from "events";

import Dispatcher from "./dispatcher";
import Constants from "./constants";
import getSidebarNavItems from "../data/sidebar-nav-items";
import getSidebarOffNavItems from "../data/sidebar-off-nav-items";
import { getLocalStorage } from "../actions/auth";

let _store = {
  menuVisible: false,
  isCheckingPayment: false,
  isFirstConnection: true,
  lastNotificationsUpdate: new Date().getTime(),
  paymentsQueue: [],
  navItems: getSidebarNavItems(),
  offNavItems: getSidebarOffNavItems(),
  notificationsToBeDismissed: [],
};

class Store extends EventEmitter {
  constructor() {
    super();

    this.registerToActions = this.registerToActions.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.toggleSidebarDropdown = this.toggleSidebarDropdown.bind(this);

    Dispatcher.register(this.registerToActions.bind(this));
  }

  registerToActions({ actionType, payload }) {
    switch (actionType) {
      case Constants.TOGGLE_SIDEBAR:
        this.toggleSidebar();
        break;
      case Constants.TOGGLE_SIDEBAR_DROPDOWN:
        this.toggleSidebarDropdown(payload);
        break;
      case Constants.IS_CHECKING_PAYMENT:
        this.toggleCheckingPayment(payload);
        break;
      default:
    }
  }

  toggleSidebar() {
    _store.menuVisible = !_store.menuVisible;
    this.emit(Constants.CHANGE);
  }

  toggleCheckingPayment(value) {
    _store.isCheckingPayment = value;
  }

  isCheckingPayment() {
    return _store.isCheckingPayment;
  }

  toggleSidebarDropdown(item) {
    const newStore = { ..._store };
    const { navItems, offNavItems } = newStore;
    const hasUser = !!JSON.parse(getLocalStorage("user"));

    let navGroupIdx = null;
    let navItemIdx = null;

    openNavItem(hasUser ? navItems : offNavItems);

    _store = newStore;
    this.emit(Constants.CHANGE);

    function openNavItem(list) {
      list.forEach((navItem, _idx) => {
        const __idx = navItem.items.findIndex((i) => i.title === item.title);
        if (__idx !== -1) {
          navGroupIdx = _idx;
          navItemIdx = __idx;
        }
      });

      list[navGroupIdx].items[navItemIdx].open =
        !list[navGroupIdx].items[navItemIdx].open;

      list = list.map((i) => {
        i.items = i.items.map((_i, idx) => {
          if (idx !== navItemIdx) {
            _i.open = false;
          }
          return _i;
        });
        return i;
      });
    }
  }

  getMenuState() {
    return _store.menuVisible;
  }

  getSidebarItems() {
    return _store.navItems;
  }

  getSidebarOffItems() {
    return _store.offNavItems;
  }

  addChangeListener(callback) {
    this.on(Constants.CHANGE, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(Constants.CHANGE, callback);
  }

  getNotificationsStatus() {
    return {
      isFirstConnection: _store.isFirstConnection,
      lastNotificationsUpdate: _store.lastNotificationsUpdate,
    };
  }

  setNotificationsStatus(isFirstConnection, lastNotificationsUpdate) {
    _store.isFirstConnection = isFirstConnection;
    _store.lastNotificationsUpdate = lastNotificationsUpdate;
  }
  getNotificationsToBeDismissed() {
    return _store.notificationsToBeDismissed;
  }

  setNotificationsToBeDismissed(notificationId) {
    if (notificationId) {
      _store.notificationsToBeDismissed.push(notificationId);
    } else {
      _store.notificationsToBeDismissed = [];
    }
  }
}

export default new Store();
