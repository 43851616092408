import { createContext } from "react";

const systemContext = createContext({
  companyKey: "",
  company: null,
  getCompanyLabel: () => {},
  LadecoraDB: undefined,
});

export const SystemProvider = systemContext.Provider;
export const SystemContext = systemContext;
