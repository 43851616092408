import { API } from "../config";
import { getCookie } from "./auth";
import { requestWrapper } from "../utils/api";

export const getAllProjects = () => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/projects`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const getAllProjectsV2 = () => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/projectsV2`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const countProjectByUser = () => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/projects-count`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const getProject = (hash) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project/${hash}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const sendData = (fileLinks, projectId, folder, step) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project/upload/${folder}/${projectId}/${step}`, {
      method: "POST",
      body: JSON.stringify(fileLinks),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
        "content-type": "application/json",
      },
    })
  );
};

export const updateAnswers = (projectId, answers) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project-answers/${projectId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(answers),
    })
  );
};

export const updateProject = (projectId, project) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project/${projectId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(project),
    })
  );
};

export const updateLogDates = (projectId, status) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project/log-dates/${projectId}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify({ nameValuePair: status }),
    })
  );
};

export const approveReview = (id, review) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project/${id}/update_review`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify({ review }),
    })
  );
};

export const getProjectsByEnterpriseFilters = (id, filter) => {
  const token = getCookie("token");
  const controller = new AbortController();
  const signal = controller.signal;

  const promise = requestWrapper(
    fetch(`${API}/project/enterprise/${id}/list`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(filter),
      signal,
    })
  );
  promise.cancel = () => controller.abort();
  return promise;
};

export const updateCommentInProjectReview = (
  projectId,
  review,
  fileId,
  data
) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project/${projectId}/${review}/update-comment/${fileId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(data),
    })
  );
};

export const updateProjectLikedMobi = (projectId, idMobi, liked) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project-update-liked-mobi/${projectId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify({ idMobi, liked }),
    })
  );
};
export const getProductListWithFielLiked = (projectId, productList) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/getProductListWith-liked-field/${projectId}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify({ productList }),
    })
  );
};

export const createProjectByHashAsGift = (label, hash) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project/${label}/${hash}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const updateProjectStep = (projectId, step, data) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project/${projectId}/update_by_step/${step}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(data),
    })
  );
};

export const updateProjectName = (projectId, name) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project/${projectId}/update_by_step/project_name`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify({ name }),
    })
  );
};

export const updateProjectQuestionary = (projectId, answers) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project/${projectId}/update_by_step/questionary`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify({ answers }),
    })
  );
};

export const updateProjectLearningEnvironment = (
  projectId,
  learningEnvironment
) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project/${projectId}/update_by_step/learning_environment`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify({ learningEnvironment }),
    })
  );
};

export const updateProjectInspiration = (projectId, inspirations) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project/${projectId}/update_by_step/inspirations`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify({ inspirations }),
    })
  );
};

export const updateProjectFurnitureMeasures = (
  projectId,
  furnitureMeasures
) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project/${projectId}/update_by_step/furnitures_measures`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify({ furnitureMeasures }),
    })
  );
};

export const updateProjectSubmit = (projectId) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project/${projectId}/update_by_step/submit`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const getProjectV2 = (projectId) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/projectV2/${projectId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};
