//Create a floating button component, with the whatsapp icon, and a text bubble above it saying "Precisa de ajuda?"

import React, { useEffect, useMemo, useState } from "react";

import styles from "./styles.module.scss";

export function WhatsButton() {
  const TIMEOUT = 12000;
  const [show, setShow] = useState(false);
  const fn = useMemo(() => {
    return setTimeout(() => {
      setShow(true);
    }, TIMEOUT);
  }, []);

  useEffect(() => {
    return () => {
      clearTimeout(fn);
    };
  }, []);

  const goToWhatsapp = () => {
    window.open(
      "https://wa.me/5547999322228?text=Ol%C3%A1,%20estou%20com%20d%C3%BAvidas%20sobre%20a%20minha%20compra%20de%20projetos%20de%20decora%C3%A7%C3%A3o%20da%20La%20Decora.%20Gostaria%20de%20orienta%C3%A7%C3%A3o.",
      "_blank"
    );
  };
  return show ? (
    <>
      <button className={styles.floatingButton} onClick={goToWhatsapp}>
        <img
          style={{ width: "100%" }}
          src="https://s3.amazonaws.com/ladecora.platform/images/whatsapp.png"
          alt=""
        />
      </button>

      <div className={styles.floatingContainer}>
        <p>Precisa de ajuda para comprar seu projeto?</p>
      </div>
    </>
  ) : null;
}
