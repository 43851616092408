import { API, APP_ID } from "../config";
import { getCookie } from "./auth";
import { requestWrapper } from "../utils/api";

export const getAllQuestions = () => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/questions?company=${APP_ID}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const getDefaultQuestion = () => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/question/default`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const getQuestionByHash = (hash, company) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/question-by-hash?hash=${hash}&company=${company}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};
