import React, { ChangeEvent } from "react";
import { Control, Controller } from "react-hook-form";
import { InputTypes } from "../../utils/constants/inputDefaultPropTypes";
import FieldError from "../common/FieldError";
import { CreditCardI } from "./types";
import Payment from "payment";

export const FormCardNumber: React.FC<
  InputTypes & { control: Control<CreditCardI, any> }
> = (props) => {
  const { control } = props;
  const customProps = { ...props, control: undefined };

  function clear(value: string) {
    return value.replace(/[^0-9]/g, "");
  }
  return (
    <Controller
      control={control}
      name="number"
      defaultValue=""
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        function onLocalChange(ev: ChangeEvent<HTMLInputElement>) {
          let value = clear(ev.target.value);
          value = Payment.fns.formatCardNumber(value);

          const nextLength = value.length;

          if (nextLength > 22) return;

          onChange(value);
        }

        return (
          <>
            <input {...customProps} onChange={onLocalChange} value={value} />

            <FieldError isValid={!!error} message={error?.message} />
          </>
        );
      }}
    />
  );
};
