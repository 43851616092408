import React, { useState, useEffect } from "react";

import { SystemProvider } from "./SystemContext";
import { DEFAULT_COMPANY } from "../../config";

import { Helmet } from "react-helmet";

import { getTheme } from "../../actions/company";
import * as timeago from "timeago.js";
import { getDb } from "../../services/db";
import getLocales from "../../locales";

const SystemConfig = ({ children }) => {
  const COMPANY_KEY = window.location.hostname.split(".")[0];
  const [companyRequest] = useState(new Promise((data) => data));
  const [isLoading, setIsLoading] = useState(true);

  const [LadecoraDB, setLadecoraDB] = useState();
  const [config, setConfig] = useState(null);
  const IS_DEFAULT =
    COMPANY_KEY === DEFAULT_COMPANY ||
    COMPANY_KEY === "localhost" ||
    COMPANY_KEY === "app" ||
    COMPANY_KEY === "dev-app";

  function getCompanyLabel() {
    if (
      window.location.host.replace("www.", "") === "remaxdecorafranquia.com.br"
    ) {
      return "remaxfranquia";
    }
    if (window.location.host.replace("www.", "") === "remaxdecora.com.br") {
      return "remaxdecora";
    }
    if (window.location.host.replace("www.", "") === "ezdecora.eztec.com.br") {
      return "eztec";
    }
    return IS_DEFAULT ? DEFAULT_COMPANY : COMPANY_KEY;
  }

  function getCompany() {
    return companyRequest;
  }

  useEffect(() => {
    getDb().then(setLadecoraDB);

    getTheme(window.location.origin).then((data) => {
      setIsLoading(false);
      if (data.label === "ladecora") {
        data.favIcon = "/favicon.png";
      }
      companyRequest.then(data);
      localStorage.setItem("company", JSON.stringify(data));

      getLocales(data.label).then((locales) => {
        setConfig({
          LOCALES: locales,
          company: data,
        });
      });

      document.documentElement.style.setProperty(
        "--newbackground-color",
        data.color
      );
    });
  }, []);

  configLocale();

  function configLocale() {
    const locale = function (number, index) {
      // number: the time ago / time in number;
      // index: the index of array below;
      // totalSec: total seconds between date to be formatted and today's date;
      return [
        ["agora mesmo", "agora"],
        ["há %s segundos", "em %s segundos"],
        ["há um minuto", "em um minuto"],
        ["há %s minutos", "em %s minutos"],
        ["há uma hora", "em uma hora"],
        ["há %s horas", "em %s horas"],
        ["há um dia", "em um dia"],
        ["há %s dias", "em %s dias"],
        ["há uma semana", "em uma semana"],
        ["há %s semanas", "em %s semanas"],
        ["há um mês", "em um mês"],
        ["há %s meses", "em %s meses"],
        ["há um ano", "em um ano"],
        ["há %s anos", "em %s anos"],
      ][index];
    };

    timeago.register("pt_BR", locale);
  }

  return (
    <SystemProvider
      value={{
        getCompanyLabel: getCompanyLabel,
        getCompany: getCompany,
        // company: company,
        isDefault: IS_DEFAULT,
        LadecoraDB: LadecoraDB,
        ...config,
        // LOCALES: LOCALES,
      }}
    >
      <Helmet>
        <title>{`${
          config && config.company.name ? config.company.name + " |" : ""
        } Dashboard`}</title>
        {config && !isLoading && config.company && config.company.favIcon ? (
          <link rel="icon" href={config.company.favIcon} type="image/x-icon" />
        ) : null}
      </Helmet>
      {config && LadecoraDB && children}
    </SystemProvider>
  );
};

export default SystemConfig;
