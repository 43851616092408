import React, { ChangeEvent } from "react";
import { Control, Controller } from "react-hook-form";
import { InputTypes } from "../../utils/constants/inputDefaultPropTypes";
import FieldError from "../common/FieldError";
import { CreditCardI } from "./types";

export const FormCardCVC: React.FC<
  InputTypes & { control: Control<CreditCardI, any> }
> = (props) => {
  const { control } = props;
  const customProps = { ...props, control: undefined };

  function clear(value: string) {
    return value.replace(/[^0-9]/g, "");
  }
  return (
    <Controller
      control={control}
      name="cvc"
      defaultValue=""
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        function onLocalChange(ev: ChangeEvent<HTMLInputElement>) {
          const value = clear(ev.target.value);
          if (value.match(/^\d{0,4}$/)) {
            onChange(value);
          }
        }

        return (
          <>
            <input {...customProps} onChange={onLocalChange} value={value} />

            <FieldError isValid={!!error} message={error?.message} />
          </>
        );
      }}
    />
  );
};
