import React, { useState, useEffect, useContext } from "react";
import { Row, Card, Col, Fade, Button } from "shards-react";
import DropzoneArea from "../../file-manager-cards/DropzoneArea";
import ImgTuto1 from "../../../images/learning-env-tutorial/01.jpeg";
import ImgTuto2 from "../../../images/learning-env-tutorial/02.jpeg";
import ImgTuto3 from "../../../images/learning-env-tutorial/03.jpeg";
import ImgTuto4 from "../../../images/learning-env-tutorial/04.jpeg";
import ImgTuto5 from "../../../images/learning-env-tutorial/05.jpeg";

import { sendData, updateProject } from "../../../actions/project";
import { ShowLightbox } from "../../common/ShowLightbox";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { download, copyObject } from "../../../utils/util";
import AspectRatioPicture from "../../common/AspectRatioPicture";
import { getImgFullLink } from "./helper";
import Slider from "react-slick";
import { SystemContext } from "../../SystemConfig/SystemContext";

const LearningEnvironment = (props) => {
  const { LOCALES } = useContext(SystemContext);
  const [files, setFiles] = useState([]);
  const [text, setText] = useState();
  const [lightbox, setLightbox] = useState({
    open: false,
    index: 0,
    images: [],
    caption: [],
  });
  const imgTutorial = [ImgTuto1, ImgTuto2, ImgTuto3, ImgTuto4, ImgTuto5];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    if (props.project.learningEnvironment?.files?.length > 0) {
      // setCountFiles(props.project.learningEnvironment.files.length);
      setFiles([...props.project.learningEnvironment.files]);
    }
    if (props.project.learningEnvironment?.details) {
      let details = props.project.learningEnvironment.details;
      setText(details);
    }
  }, [props.project]);

  const sendLightbox = (data, idx, flipImage = false, caption = []) => {
    if (Array.isArray(data)) {
      setLightbox({
        ...lightbox,
        images: data,
        open: true,
        index: idx,
        caption,
      });
    } else {
      setLightbox({
        ...lightbox,
        images: [data],
        open: true,
        index: 0,
        caption,
      });
    }
  };

  const removeImg = (idx) => {
    const newFiles = [...files];
    newFiles.splice(idx, 1);

    setFiles(newFiles);
  };

  const nextStep = () => {
    if (files.length > 0) {
      const rollback = {
        learningEnvironment: copyObject(props.project.learningEnvironment),
      };

      const isPristine =
        props.project.learningEnvironment.files.length === files.length &&
        props.project.learningEnvironment.files.reduce((acc, file, i) => {
          return acc && file == files[i];
        }, true);
      props
        .updateStepCompleted(
          {
            learningEnvironment: {
              files: files,
              details: text,
            },
            _id: props.project._id,
          },
          isPristine
        )

        .catch(() => {
          props.project.learningEnvironment = rollback.learningEnvironment;
          props.project.completedSteps.pop();
        });
    } else {
      toast.error(
        `Para continuarmos com a assessoria,  precisamos que envie o desenho com medidas do ambiente!`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  async function onFileUpload(fileLinks) {
    fileLinks = fileLinks.map((fileLink) => {
      return {
        ...fileLink,
        source: fileLink.source.match(/([^/]*)$/)[0],
        thumbnail: fileLink.thumbnail
          ? fileLink.thumbnail.match(/([^/]*)$/)[0]
          : null,
      };
    });

    sendData(fileLinks, props.project._id, "customer", "learningEnvironment")
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });

    setFiles([...files, ...fileLinks]);
  }

  return (
    <React.Fragment>
      <ToastContainer />

      {lightbox.open && (
        <ShowLightbox lightbox={lightbox} setLightbox={setLightbox} />
      )}
      <Row>
        <Col>
          <div className="text-center p-2 mb-4">
            <h4>
              Este passo é muito importante para o sucesso do seu{" "}
              {LOCALES.PROJECT} de decoração!
            </h4>
            <h5>
              Por favor, nos envie fotos do local e a planta baixa do espaço.
              Caso não tenha a planta baixa, temos um tutorial para ajudá-lo a
              medir o ambiente.
            </h5>
            {/* eslint-disable-next-line */}
            <video
              width="70%"
              controls
              style={{
                display: "block",
                margin: "0 auto",
                outline: "none",
                border: "3px solid #EEE",
                cursor: "pointer",
              }}
            >
              <source
                src={
                  "https://s3.amazonaws.com/ladecora.platform/video/medicao.mp4"
                }
                type="video/mp4"
              />
            </video>
            <Slider {...settings}>
              {imgTutorial.map((file, idx) => {
                return (
                  <div
                    key={idx}
                    className="d-flex justify-content-center align-items-center carousel-container"
                  >
                    {/* eslint-disable-next-line */}
                    <img
                      alt=""
                      style={{ cursor: "pointer", maxWidth: "100%" }}
                      className="rounded img-fluid carousel-image"
                      src={file}
                      onClick={() => sendLightbox(imgTutorial, idx)}
                    />
                  </div>
                );
              })}
            </Slider>
          </div>

          <h6>
            No campo abaixo faça o upload das fotos do seu ambiente. Lembramos
            que para dar continuidade precisamos que envie o desenho com medidas
            do ambiente!
            <br />
            Enviar arquivos nas extensões: png, jpg, jpeg, pdf, dwg, skp ou heic
          </h6>
          <DropzoneArea
            projectId={props.project._id}
            step="learningEnvironment"
            callbackHandleDrop={onFileUpload}
          />
          {files.length ? (
            <Fade in={!!files.length} theme="accent">
              Arquivos enviados: <b>{files.length}</b>
            </Fade>
          ) : null}
          <br />
          {files && files.length > 0 && (
            <div className="user-details__user-data border-top border-bottom p-4">
              <Row>
                <Col>
                  <h6 className="text-center m-0 mt-2">Arquivos</h6>
                </Col>
              </Row>
              <Row>
                {files.map((img, idx) => {
                  return (
                    <Col lg="3" sm="6" key={idx}>
                      <Card className="card-file">
                        <AspectRatioPicture
                          height="200px"
                          width="200px"
                          isDeletable={true}
                          src={getImgFullLink(img.source, props.project._id)}
                          thumbnail={
                            img.thumbnail
                              ? getImgFullLink(img.thumbnail, props.project._id)
                              : null
                          }
                          name={img.name}
                          title={img.name}
                          dowloadPdf={() => {
                            download(
                              getImgFullLink(img.source, props.project._id)
                            );
                          }}
                          onDeleteClick={() => {
                            removeImg(idx);
                          }}
                        />
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </div>
          )}

          <br />
        </Col>
      </Row>
      <div className="d-flex flex-column  fixed-right-botton wizard-footer">
        <div className="d-flex flex-row align-items-center">
          <button
            type="button"
            className=" ml-4 btn btn-accent"
            onClick={() => props.goBack(2)}
            disabled={props.project.forcedStep?.length}
          >
            Voltar etapa
          </button>

          <button
            type="button"
            className="ml-auto mr-4  btn btn-accent"
            onClick={nextStep}
          >
            Próxima etapa
          </button>
        </div>
        {props.showSummary ? (
          <div className="d-flex align-items-center justify-content-center">
            <Button
              theme="success"
              type="button"
              size="lg"
              className="mt-3"
              onClick={props.onClose}
            >
              Revisar informações
            </Button>
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );
};
export default LearningEnvironment;
