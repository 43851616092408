import { API } from "../config";
import { getCookie } from "../actions/auth";
import { requestWrapper } from "../utils/api";

export const sendMessage = (
  senderId,
  message,
  receiver,
  category,
  opt = {}
) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/message/send`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify({
        user: senderId,
        text: message,
        project: receiver,
        category,
        timestamp: new Date(),
        ...opt,
      }),
    })
  );
};

export const getChatMessages = (projectId, category) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/message/${projectId}/all?category=${category}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const readMessages = (projectId, type) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/message/${projectId}/${type}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const uploadFile = (file, projectId, kind, source) => {
  const token = getCookie("token");
  const formData = new FormData();
  if (file) {
    formData.append("file", file);
    formData.append("kind", kind);
    formData.append("source", source);
    formData.append("timestamp", new Date());
    formData.append("folder", "chat");
  } else {
    throw new Error("No file");
  }

  return requestWrapper(
    fetch(`${API}/message/upload/${projectId}`, {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const deleteMessage = (messageId) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/message/${messageId}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};
