import React from "react";
import { Collapse, Button } from "shards-react";

import NotificationBackdrop from "./NotificationBackdrop";

function NotificationContainer({ children, onDismiss, show, onDismissAll }) {
  return (
    <>
      {show && <NotificationBackdrop onClick={onDismiss} />}
      <Collapse
        open={show}
        onClick={() => {}}
        style={{
          zIndex: 1200,
        }}
        className="dropdown-menu dropdown-menu-small unclickable"
      >
        <div>
          <div
            style={{
              fontSize: "13px",
              color: "black",
              padding: "8px",
              backgroundColor: "#dfe6e9",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div className="d-inline">Notificações</div>
            <div className="d-inline">
              <Button className="ml-auto" theme="accent" onClick={onDismissAll}>
                Limpar tudo
              </Button>
            </div>
          </div>
        </div>

        <div style={{ overflowY: "auto", maxHeight: "300px" }}>{children}</div>
      </Collapse>
    </>
  );
}

NotificationContainer.propTypes = {};

export default NotificationContainer;
