import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import AspectRatioPicture from "./AspectRatioPicture";

import useResize from "./useResize";

function FlexSlider({ items, opt, onClick }) {
  useResize({ filter });
  const [maxGroupsSize, setMaxGroupSize] = useState(1);
  const [groups, setGroups] = useState([]);
  const [myRef, setMyRef] = useState({});
  const [sliderIndex, setSliderIndex] = useState(0);

  useEffect(() => {
    if (myRef) {
      showResult();
    }
  }, [myRef]);

  useEffect(() => {
    if (maxGroupsSize) {
      setGroups(
        items.reduce((acc, item, i) => {
          if (i % maxGroupsSize === 0) {
            acc.push([item]);
          } else {
            acc[acc.length - 1].push(item);
          }
          return acc;
        }, [])
      );
    }
  }, [maxGroupsSize, items]);

  function filter() {
    showResult();
    return true;
  }

  function showResult() {
    if (myRef.clientWidth) {
      const result = Math.max(Math.floor(myRef.clientWidth / opt.minWidth), 1);
      if (result !== maxGroupsSize) {
        setMaxGroupSize(result);
      }
    }
  }

  function onNextSlideClick() {
    const nextIndex = (sliderIndex + 1) % groups.length;
    setSliderIndex(nextIndex);
  }

  function onPreviousSlideClick() {
    const nextIndex =
      ((sliderIndex === 0 ? groups.length : sliderIndex) - 1) % groups.length;
    setSliderIndex(nextIndex);
  }

  return (
    <div className="d-flex align-items-center w-100">
      {groups.length !== 1 ? (
        <span
          onKeyDown={() => {
            //TODO
          }}
          role="button"
          tabIndex="0"
          className="material-icons clickable"
          style={{ fontSize: "60px" }}
          onClick={onPreviousSlideClick}
        >
          keyboard_arrow_left
        </span>
      ) : null}
      <div ref={setMyRef} className="w-100 overflow-hidden ">
        {!!groups.length &&
          groups.map((group, gi) => {
            return (
              <div key={gi}>
                <div className="w-100 d-flex justify-content-center">
                  <div
                    className={`${sliderIndex !== gi ? "d-none" : "d-flex "}`}
                    key={gi}
                  >
                    {group.map((suggestion, i) => {
                      return (
                        <div
                          className="ml-2 mr-2"
                          key={`${suggestion.name}-${i}`}
                        >
                          <div
                            onKeyDown={() => {
                              //TODO
                            }}
                            role="button"
                            tabIndex="0"
                            className={`mb-3 clickable hover-highlight picture-item `}
                            onClick={() => onClick(suggestion)}
                          >
                            <div className="p-2">
                              <AspectRatioPicture
                                height="250px"
                                width="250px"
                                src={
                                  suggestion.render.decorationPanel &&
                                  suggestion.render.decorationPanel.length
                                    ? suggestion.render.decorationPanel[0]
                                        .source
                                    : ""
                                }
                                thumbnail={
                                  suggestion.render.decorationPanel &&
                                  suggestion.render.decorationPanel.length
                                    ? suggestion.render.decorationPanel[0]
                                        .thumbnail
                                    : ""
                                }
                                name={
                                  suggestion.render.decorationPanel &&
                                  suggestion.render.decorationPanel.length
                                    ? suggestion.render.decorationPanel[0]
                                        .source
                                    : ""
                                }
                                title={""}
                                isLoading={false}
                                isUploading={false}
                                isDeletable={false}
                                fullScreenView={false}
                              />
                            </div>
                            <hr className="m-0" />
                            <div
                              className="text-center py-2 picture-item-bottom-text"
                              style={{ width: "250px", minHeight: "64px" }}
                            >
                              {`${suggestion.enterprise.environment} - ${suggestion.enterprise.style}`}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      {groups.length !== 1 ? (
        <span
          onKeyDown={() => {
            //TODO
          }}
          role="button"
          tabIndex="0"
          style={{ fontSize: "60px" }}
          className="material-icons clickable"
          onClick={onNextSlideClick}
        >
          navigate_next
        </span>
      ) : null}
    </div>
  );
}

FlexSlider.propTypes = {
  opt: PropTypes.object,
};

export default FlexSlider;
