import React, { useState, useEffect, useContext } from "react";
import { FormInput, Button, Col, Row } from "shards-react";
import { SystemContext } from "../../SystemConfig/SystemContext";
import styles from "./SetNameProject.module.scss";

const SetNameProject = (props) => {
  const { LOCALES } = useContext(SystemContext);
  const [isLoading, setIsLoading] = useState(false);

  const nextStep = () => {
    const name = props.project.name;

    setIsLoading(true);
    props
      .updateStepCompleted(
        {
          name: nameProject,
          completedSteps: props.project.completedSteps,
          _id: props.project._id,
        },
        nameProject === name
      )

      .catch(() => {
        setIsLoading(false);
        props.project.name = name;
        props.project.completedSteps.pop();
      });
  };
  const [nameProject, setNameProject] = useState("");
  useEffect(() => {
    if (props.project.name) {
      let name = props.project.name;
      setNameProject(name);
    }
  }, []);
  return (
    <div className={`projectNameRow ${styles.container}`}>
      <label htmlFor="set-name-project-name-input">
        {LOCALES.PROJECT_STEP_1}
      </label>

      <div className="d-flex align-items-start">
        <FormInput
          maxLength={28}
          id="set-name-project-name-input"
          type="text"
          placeholder={`Informe o ${LOCALES.PROJECT_STEP_1}`}
          value={nameProject}
          onChange={(e) => {
            setNameProject(e.target.value);
          }}
        />
        <Button
          theme="accent"
          onClick={nextStep}
          disabled={isLoading}
          className="ml-2"
        >
          Salvar
        </Button>
      </div>

      <div className="d-flex flex-row align-items-center fixed-right-botton wizard-footer">
        <button
          type="button"
          className=" ml-4 btn btn-accent"
          onClick={() => {}}
          disabled={true}
        >
          Voltar etapa
        </button>
        <button
          type="button"
          className="ml-auto mr-4 btn btn-accent"
          onClick={nextStep}
        >
          Próxima etapa
        </button>
      </div>
    </div>
  );
};
export default SetNameProject;
