import React from "react";
import { Row, Col, FormGroup, FormTextarea, Button } from "shards-react";
import { getQueryStringValue } from "../../../actions/auth";
import { CustomImageToSlide } from "../../common/CustomImageToSlide";

function ReviewImage(props) {
  const {
    file,
    key,
    onImageClick,
    disabled,
    onChange,
    link,
    customImg,
    setModalComment,
  } = props;

  const shareId = getQueryStringValue("shareId");

  return (
    <div key={key}>
      {customImg ? (
        <CustomImageToSlide file={link} onclick={onImageClick} />
      ) : (
        <div className="d-flex justify-content-center align-items-center carousel-container">
          {/* eslint-disable-next-line */}
          <img
            alt=""
            style={{ cursor: "pointer", maxWidth: "100%" }}
            className="rounded  ml-auto mr-auto carousel-image"
            src={link}
            onClick={onImageClick}
          />
        </div>
      )}

      {!disabled ? (
        <Row className="review-comments-outsideModal">
          <Col md="6" className="mt-2 like-comment">
            <FormGroup>
              <label htmlFor="review-image-like-comments-input">
                O que você gostou?
              </label>
              <FormTextarea
                id="review-image-like-comments-input"
                value={file.likeComments}
                onChange={(e) => {
                  onChange(file, {
                    dislikeComments: file.dislikeComments,
                    likeComments: e.target.value,
                  });
                }}
                rows="4"
                className="form-control mt-2"
                placeholder="Comente aqui o que você gostou referente a esta imagem."
              />
            </FormGroup>
          </Col>
          <Col md="6" className="mt-2 dislike-comment">
            <FormGroup>
              <label htmlFor="review-image-dislike-comments-input">
                O que você não gostou?
              </label>
              <FormTextarea
                id="review-image-dislike-comments-input"
                value={file.dislikeComments}
                onChange={(e) => {
                  onChange(file, {
                    likeComments: file.likeComments,
                    dislikeComments: e.target.value,
                  });
                }}
                rows="4"
                className="form-control mt-2"
                placeholder="Comente aqui suas críticas referentes a esta imagem."
              />
            </FormGroup>
          </Col>
        </Row>
      ) : (
        <>
          {(file.likeComments || file.dislikeComments) && !shareId && (
            <Button
              theme="accent"
              className=""
              onClick={() =>
                setModalComment({
                  show: true,
                  likeComments: file.likeComments,
                  dislikeComments: file.dislikeComments,
                })
              }
            >
              Ver Comentário
            </Button>
          )}
        </>
      )}
    </div>
  );
}

ReviewImage.propTypes = {};

export default ReviewImage;
