import React from "react";
import { Button, Card, CardBody } from "shards-react";

const DecorationGuide = ({ action }) => {
  return (
    <Card style={{ backgroundColor: "#f7f7f7" }}>
      <CardBody>
        <div className="banner-guide">
          <div style={{ maxWidth: "350px" }}>
            <h3>Guia de decoração</h3>
            <p>
              Preparamos um PDF com ainda mais informações para fazer com que
              seus próximos passos sejam ainda mais fáceis
            </p>
          </div>
          <div className="d-flex flex-column align-items-center">
            <div
              style={{
                top: 0,
                backgroundImage: `url("${require("../../images/decoration_guide.png")}")`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center center",
                height: "84px",
                width: "84px",
              }}
            />
            <div>
              <Button
                title={"Baixar PDF"}
                theme="accent"
                size="md"
                className="mt-3"
                onClick={action}
                style={{ width: "150px" }}
              >
                Ver tudo!
              </Button>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default DecorationGuide;
