import React, { useEffect, useContext } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import Routes from "./routes";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/main.scss";

import Authorization from "./components/common/Authorization/Authorization";
import { SocketIoProvider } from "./components/shared/SocketIoContext";
import SystemConfig from "./components/SystemConfig/index";
import { SystemContext } from "./components/SystemConfig/SystemContext";

function App() {
  let subdomain = window.location.hostname.split(".")[0];

  useEffect(() => {
    const fontUrl =
      subdomain === "leroymerlinprojeta"
        ? "https://s3.amazonaws.com/ladecora.platform/fonts/LeroyMerlinSans-Regular.otf"
        : "https://s3.amazonaws.com/other.platform/fonts/OtherFont-Regular.otf";

    const style = document.createElement("style");
    style.innerHTML = `
      @font-face {
        font-family: "margem";
        font-style: normal;
        font-weight: 400;
        src: url("${fontUrl}");
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, [subdomain]);
  return (
    <Router basename={process.env.REACT_APP_BASENAME || ""}>
      <SystemConfig>
        <Authorization>
          <SocketIoProvider>
            <Routes />
          </SocketIoProvider>
        </Authorization>
      </SystemConfig>
    </Router>
  );
}

App.displayName = "ladecora_dashboard";
export default App;
