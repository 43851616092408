import { API, PRODUCTION } from "../config";
import { getCookie } from "../actions/auth";
import { requestWrapper } from "../utils/api";

export const loadPictureInMemory = async (files) => {
  const IMAGE_TYPES = ["image/png", "image/jpeg", "image/jpg"];
  const promises = [];
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    if (file.size > 10000000) {
      throw new Error("Arquivo não pode ser maior que 10mb");
    }
    const kind = IMAGE_TYPES.includes(file.type) ? "photo" : "file";

    promises.push(
      new Promise((success) => {
        const reader = new FileReader();
        reader.addEventListener(
          "load",
          function () {
            success({
              uri: reader.result,
              kind,
              file,
            });
          },
          false
        );

        if (file) {
          reader.readAsDataURL(file);
        }
      })
    );
  }

  return Promise.all(promises);
};

export const uploadFile = async (params) => {
  const { path, file } = params;
  const token = getCookie("token");

  const res = await requestWrapper(
    fetch(`${API}/file/upload_link`, {
      method: "POST",
      headers: {
        accept: "application/json",
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify({
        path,
        fileName: file.name,
      }),
    })
  );

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "multipart/form-data");
  const formData = new FormData();
  formData.append("file", file);

  await fetch(res.signedUrl, {
    method: "PUT",
    body: file,
    headers: myHeaders,
    mode: "cors",
  });

  const IMAGE_TYPES = [
    "image/png",
    "image/jpeg",
    "image/jpg",
    "image/heic",
    "image/jfif",
  ];
  let fileType = file.type;
  if (!fileType) {
    const t = file.name.split(".");
    fileType = `image/${t[t.length - 1]}`;
  }

  const kind = IMAGE_TYPES.includes(fileType) ? "photo" : "file";

  if (kind === "photo") {
    try {
      const convertedPhotos = await fetch(
        `https://thumb-generator.ladecora.com.br/${
          PRODUCTION ? "prod" : "dev"
        }/run`,
        {
          method: "POST",
          body: JSON.stringify({ path: res.key }),
          mode: "cors",
        }
      );
      return await convertedPhotos.json();
    } catch (err) {
      return {
        fullSize: res.key,
        thumbnail: res.key,
      };
    }
  } else {
    return { file: res.key };
  }
};

export const deleteFile = async (path) => {
  const token = getCookie("token");

  return await requestWrapper(
    fetch(`${API}/file/delete_by_link`, {
      method: "DELETE",
      headers: {
        accept: "application/json",
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify({
        path,
      }),
    })
  );
};
