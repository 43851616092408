import { BUCKET_URL } from "./../../../config";

export const getImgFullLink = function (source, projectId) {
  return (
    BUCKET_URL +
    "/projects/" +
    projectId +
    "/customer/" +
    source.replace(".HEIC", ".jpeg").replace(".heic", ".jpeg")
  );
};
