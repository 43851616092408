import React, { useMemo, useState } from "react";
import PropTypes, { InferProps } from "prop-types";
import HTMLFieldError from "./FieldError";
import {
  InputDefaultPropTypes,
  InputTypes,
} from "../../utils/constants/inputDefaultPropTypes";

const InputPropTypes = {
  options: PropTypes.shape({
    error: PropTypes.oneOfType([
      PropTypes.shape({ message: PropTypes.string }),
    ]),
    register: PropTypes.func.isRequired,
    form: PropTypes.object,
  }).isRequired,
};

const Input: React.FC<InputTypes & InferProps<typeof InputPropTypes>> = (
  props
) => {
  const { name, className, style, options } = props;
  const { error, register, form } = options;
  const customProps = {
    ...props,
    options: undefined,
    className: undefined,
    style: undefined,
  };

  const classNameCustom = useMemo(() => {
    return className ? `${className} form-control` : "form-control";
  }, [className]);


  const [passwordType, setPasswordType] = useState("password");

 const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <div className="d-flex flex-column">
      {customProps.type === "password" ?(
           <div className="d-flex align-items-center inputShowHide">
      <input
          {...customProps}
        {...register(name, form)}
        className={classNameCustom}
        style={{
          borderColor: error ? "red" : undefined,
          ...style,
        }}
        type={passwordType}  
      />
      {passwordType === "password" ? (
        <i
          style={{ cursor: "pointer" }}
          onClick={togglePassword}
          className="fa fa-eye-slash"
          aria-hidden="true"
        />
      ) : (
        <i
          style={{ cursor: "pointer" }}
          onClick={togglePassword}
          className="fa fa-eye"
          aria-hidden="true"
        />
      )}
    </div>
      ):(
       <input
        {...customProps}
        {...register(name, form)}
        className={classNameCustom}
        style={{
          borderColor: error ? "red" : undefined,
          ...style,
        }}
      /> )}
   
      <HTMLFieldError isValid={!!error} message={error?.message} />
    </div>
  );
};

Input.propTypes = { ...InputPropTypes, ...InputDefaultPropTypes };

export default Input;
