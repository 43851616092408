import { API } from "../config";

export const authByForeignToken = async (company: string, token: string) => {
  return fetch(`${API}/foreign_auth/${company}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ token }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};
