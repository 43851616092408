import React, { useContext, useState, useEffect } from "react";
import { Launcher } from "@ladecora/react-chat-elements";
import "@ladecora/react-chat-elements/lib/react-chat-elements.css";

import { toast } from "react-toastify";

import { download } from "../../utils/util";
import { AuthContext } from "../common/Authorization/AuthContext";
import { SocketIoContext } from "../shared/SocketIoContext";
import { SystemContext } from "../SystemConfig/SystemContext";
import { formatBytes } from "../../utils/sanitize";

import {
  sendMessage,
  getChatMessages,
  deleteMessage,
} from "../../actions/message";
import { uploadFile } from "../../services/file";
import { BUCKET_URL } from "../../config";

const Chat = ({
  senderId,
  onNewMessages,
  show,
  onChatToggle,
  chatOpened,
  readOnly,
}) => {
  const { user } = useContext(AuthContext);
  const { socket } = useContext(SocketIoContext);

  const [messages, setMessages] = useState([]);
  const [badgeCount, setBadgeCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [, setState] = useState();
  const [textBubble, setTextBubble] = useState(
    "Olá! Você possui alguma dúvida sobre seu ambiente?"
  );
  const { company } = useContext(SystemContext);

  const userFullName = `${user.profile.firstName} ${user.profile.lastName}`;

  const onUpdate = () => {
    setState({});
  };

  const onUserNewMessage = (message) => {
    const time = message.time;
    const newMessages = [
      ...messages,
      {
        ...message,
        position: message.author === "me" ? "right" : "left",
        type: "text",
        text: message.data.text,
        date: message.time,
      },
    ];
    setMessages(newMessages);
    sendMessage(user._id, message.data.text, senderId, "preview").then(
      (res) => {
        setMessages([
          ...newMessages.filter((m) => m.time !== time),
          {
            ...res,
            author: res.type === "user" ? "me" : "them",
            type: "text",
            deletable: true,
            time: new Date(res.createdAt),
            text: res.text,
          },
        ]);
        // Send message to socket
        const data = {
          username: `${user._id}`,
          room: `${senderId}`,
          message: message.data.text,
        };
        socket.emit("message", data);
      }
    );
  };

  const reloadChat = () => {
    getChatMessages(senderId, "preview").then((res) => {
      const messageList = res.sort((a, b) => {
        let d1 = new Date(a.createdAt);
        let d2 = new Date(b.createdAt);
        if (d1 > d2) return 1;
        if (d2 > d1) return -1;
        return 0;
      });

      if (messageList.length) {
        onNewMessages();
        if (
          messageList.some(
            (message) => !message.isRead && message.type === "system"
          )
        ) {
          onChatToggle(true);
        }
      }

      setMessages(
        [
          {
            type: "system",
            text: `Olá ${userFullName}! 😃 \n
            Meu nome é Kelly e sou designer de interiores da equipe ${company?.name}, acabei de enviar seu ambiente e espero que você goste. 
            
            Estou a disposição para tirar suas dúvidas e organizar com você suas ideias para que você possa solicitar uma revisão caso ache necessário. Obrigada pela atenção. `,
          },
        ].concat(
          messageList.map((message) => {
            return {
              ...message,
              link: message.link,
              author: message.type === "system" ? "them" : "me",
              type: message.kind,
              data: {
                height: 250,
                width: 250,
                size: formatBytes(message.size),
                uri: message.thumbnail ? message.thumbnail : message.link,
                status: {
                  click: false,
                  loading: 0,
                },
              },
              time: new Date(message.createdAt),
              text: message.text,
              deletable:
                message.type === "user" &&
                checkSmsTime(message.createdAt) &&
                true,
            };
          })
        )
      );

      const badgeCount = messageList.filter(
        (message) => !message.isRead && message.type === "system"
      ).length;

      setBadgeCount(badgeCount);
    });
  };

  const checkSmsTime = (smsTime) => {
    const data = new Date(smsTime);
    data.setHours(data.getHours() + 1);

    return Date.now() <= data;
  };

  const onDeleteMessage = (messageToDelete) => {
    deleteMessage(messageToDelete._id)
      .then(() => {
        setMessages(
          messages.filter((message) => message._id !== messageToDelete._id)
        );
        const data = {
          username: `${user._id}`,
          room: `${senderId}`,
          message: messageToDelete.text,
        };
        socket.emit("message", data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onFilesSelected = (files) => {
    const promises = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.size > 10000000) {
        toast.error("Arquivo não pode ser maior que 10mb");
        return;
      }

      const kind = ["image/png", "image/jpeg", "image/jpg"].includes(file.type)
        ? "photo"
        : "file";
      if (file.type.match(/video/)) {
        toast.error("O formato do arquivo é inválido.");
        return;
      }

      const newMessage = {
        _id: `${new Date().getTime()}${i}`,
        author: "me",
        type: kind,
        deletable: false,
        date: new Date(),
        text: file.name,
        data: {
          height: 250,
          width: 250,
          size: formatBytes(file.size),
          uri: "",
          status: {
            click: kind === "photo",
            loading: 0,
            isSending: true,
            download: kind === "file",
          },
        },
      };
      promises.push(
        new Promise((success) => {
          const reader = new FileReader();
          reader.addEventListener(
            "load",
            function () {
              newMessage.data.uri = reader.result;
              success({
                message: newMessage,
                kind,
                file,
              });
            },
            false
          );

          if (file) {
            reader.readAsDataURL(file);
          }
        })
      );
    }
    Promise.all(promises).then((rawFiles) => {
      const newMessages = messages.concat(
        rawFiles.map((result) => result.message)
      );
      setMessages(newMessages);

      rawFiles.map((rawFile) => {
        const { file, message } = rawFile;
        let { kind } = rawFile;

        let fileType = file.type;
        if (!fileType) {
          const t = file.name.split(".");
          fileType = `image/${t[t.length - 1]}`;
        }

        if (fileType === "image/heic") {
          kind = "photo";
        }
        return uploadFile({
          path: `projects/${senderId}/chat`,
          file: file,
        }).then((uploadedFile) => {
          const link = uploadedFile.fullSize
            ? `${BUCKET_URL}/${uploadedFile.fullSize}`
            : `${BUCKET_URL}/${uploadedFile.file}`;
          const thumbnail = uploadedFile.thumbnail
            ? `${BUCKET_URL}/${uploadedFile.thumbnail}`
            : "";

          return sendMessage(user._id, "", senderId, "preview", {
            link: link,
            size: file.size,
            thumbnail: thumbnail,
            text: file.name,
            kind,
            project: senderId,
          }).then((m) => {
            message.data.status.click = false;
            message.data.status.download = false;
            message.data.status.isSending = false;
            message.deletable = true;
            message.type = m.kind;
            message._id = m._id;
            message.data.uri = m.link;
            message.time = new Date(m.createdAt);
            message.link = m.link;
            message.createdAt = new Date(m.createdAt);

            // Send message to socket
            const data = {
              username: `${user._id}`,
              room: `${senderId}`,
              message: "File Send",
            };
            socket.emit("message", data);

            onUpdate();
          });
        });
      });
    });
  };

  useEffect(() => {
    if (chatOpened) {
      const interval = setInterval(() => {
        setMessages((prevMessages) => {
          return prevMessages.map((message) => ({
            ...message,
            deletable:
              message.author === "me" &&
              checkSmsTime(message.createdAt) &&
              true,
          }));
        });
      }, 300000);

      return () => clearInterval(interval);
    }
  }, [chatOpened]);

  useEffect(() => {
    reloadChat();

    socket.emit("select_room", {
      username: `${user._id}`,
      room: `${senderId}`,
    });

    socket.on("message", (data) => {
      if (data.username !== user._id) {
        reloadChat();
      }
    });

    return () => {
      socket.off("message");
    };
  }, []);

  return show ? (
    <Launcher
      readOnly={readOnly}
      textBubble={textBubble}
      onTextBubbleClose={() => setTextBubble("")}
      agentProfile={{
        teamName: "Kelly - Designer de interiores",
        description: `Iremos responder em até 1 dia útil.`,
        imageUrl:
          "https://s3.amazonaws.com/ladecora.platform/images/chat_image.png",
      }}
      isOpen={isOpen || chatOpened}
      onFilesSelected={onFilesSelected}
      onDownload={(message) => {
        download(message.link, message.text);
      }}
      markdown={true}
      onMessageWasSent={onUserNewMessage}
      messageList={messages}
      newMessagesCount={badgeCount}
      showEmoji={false}
      placeholder="Escreva sua mensagem..."
      handleClick={() => {
        if (!isOpen && !chatOpened) {
          setTextBubble("");
        }
        setIsOpen(!(isOpen || chatOpened));
        onChatToggle(!(isOpen || chatOpened));
      }}
      onDeleteClick={(a) => {
        onDeleteMessage(a);
      }}
    />
  ) : null;
};

export default Chat;
