import React from "react";
import BounceLoader from "react-spinners/BounceLoader";
import { CompanyI, EnvironmentI } from "@ladecora/ladecora-utils";
import styles from "./PaymentSummary.module.scss";

interface CheckoutType {
  company: CompanyI;
  projectTemplatesToBuy: EnvironmentI[];
  total: number;
  fullTotal: number;
}

function PaymentSummary({
  projectTemplatesToBuy,
  total,
  fullTotal,
}: CheckoutType) {
  const getDiscount = () => {
    const totalAfterDiscount = total;
    if (fullTotal !== totalAfterDiscount) {
      return (
        <>
          <s id="PaymentSummaryTotal">
            De:{" "}
            {fullTotal.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}
            <br />
          </s>
          <h5 id="PaymentSummaryTotalWithDiscount">
            Por:{" "}
            {totalAfterDiscount.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}
          </h5>
        </>
      );
    }
    return (
      <h5 id="PaymentSummaryTotal">
        {fullTotal.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        })}
      </h5>
    );
  };
  return (
    <>
      {projectTemplatesToBuy?.length ? (
        <>
          <div>
            {projectTemplatesToBuy.map((projectTemplate, idx) => (
              <div
                key={idx}
                className="d-flex mb-1"
                style={{
                  backgroundColor: "#8080801c",
                  padding: "8px",
                  borderRadius: "5px",
                }}
              >
                <img
                  alt=""
                  style={{
                    width: "80px",
                    height: "80px",
                    borderRadius: "20px",
                  }}
                  src={projectTemplate.image}
                />
                <div className="w-100 d-flex flex-column">
                  <span
                    className="mb-2"
                    style={{ textAlign: "right", color: "black" }}
                  >
                    {projectTemplate.title}
                  </span>
                  <span className="ml-auto">
                    Quantidade: {projectTemplate.quantity}
                  </span>
                  <span className="ml-auto mt-auto">
                    Valor:{" "}
                    <strong style={{ color: "green" }}>
                      {projectTemplate.price.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </strong>
                  </span>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-4 d-flex">
            <span className="ml-auto">
              Total: <br />
              {getDiscount()}
            </span>
          </div>
        </>
      ) : (
        <div className="text-center w-100">
          <div className={styles.loader}>
            <BounceLoader />
          </div>
        </div>
      )}
    </>
  );
}

export default PaymentSummary;
