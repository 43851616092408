import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { PRODUCTION } from "./config";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

if (PRODUCTION) {
  Sentry.init({
    dsn: "https://3fb888e5127d45ef89e67197eb57068c@o529902.ingest.sentry.io/5648870",
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(<App />, document.getElementById("root"));
