import React, { useContext, useEffect } from "react";
import { Stepper, Step, StepLabel, makeStyles } from "@material-ui/core";
import { SystemContext } from "../../SystemConfig/SystemContext";

const StepsForm = ({ activeStep, project }) => {
  const { LOCALES } = useContext(SystemContext);

  useEffect(() => {
    const divStepper = document.querySelector("#manStepper");
    divStepper.addEventListener("load", scrollToSteps());

    return () => {
      divStepper.removeEventListener("load", scrollToSteps);
    };
  }, []);

  const { forcedStep, completedSteps } = project;

  const pendingStep = forcedStep
    ? forcedStep.filter((elem) => elem.isPending === true)
    : [];
  const pendingStepList = [];

  pendingStep.length &&
    pendingStep.map((elem) => {
      pendingStepList.push(parseInt(elem.key));
    });

  const useStyles = makeStyles(() => ({
    root: {
      width: "100%",
      fontFamily: "margem",
      "& .MuiPaper-root": { backgroundColor: "unset" },
      "& .MuiStepIcon-active": { color: "#e1b12c" },
      "& .MuiStepIcon-completed": { color: "#0a775a" },
      "& .Mui-disabled .MuiStepIcon-root": { color: "#656f6c" },
    },
  }));

  const styles = useStyles();

  const steps = [
    "Perguntas do perfil",
    LOCALES.PROJECT_STEP_1,
    "Questionário do ambiente",
    "Conhecendo seu espaço",
    "Móveis e medidas",
    "Inspiração",
    "Confirmação",
  ];

  const pendingStepCheck = (index) => {
    if (pendingStepList.length) {
      if (pendingStepList.some((elem) => elem === index)) {
        return true;
      }
    }
    return false;
  };

  const completedStepsCheck = (index) => {
    if (completedSteps.some((elem) => elem === index)) {
      return true;
    }
    return false;
  };

  const getStepTextStyle = (index) => {
    if (activeStep === index) {
      return {
        color: "black",
        fontSize: "12px",
      };
    }
    return {
      color: "rgba(0, 0, 0, 0.54)",
      fontSize: "12px",
    };
  };

  const scrollToSteps = () => {
    const divStep = document.querySelectorAll(".Step");
    const divManStepper = document.querySelector("#manStepper");
    let positionToScroll;

    for (let i = 0; i < divStep.length; i++) {
      let positions = divStep[i].getBoundingClientRect();
      if (i === activeStep) {
        positionToScroll = positions.x;
      }
    }

    if (divManStepper.scrollWidth > divManStepper.clientWidth) {
      divManStepper.scrollTo(positionToScroll, 0);
    }
  };

  return (
    <div id="manStepper" className={styles.root} style={{ overflow: "auto" }}>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((step, index) => (
          <Step
            className="Step"
            key={index}
            completed={
              pendingStepCheck(index) === false && index < activeStep
                ? true
                : pendingStepCheck(index) === false &&
                  completedStepsCheck(index !== activeStep && index) === true
                ? true
                : false
            }
            disabled={
              pendingStepCheck(index) === true && index > activeStep
                ? true
                : false
            }
          >
            <StepLabel>
              <span style={getStepTextStyle(index)}>{step}</span>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};
export default StepsForm;
