import React, { useState } from "react";
import Slider from "react-slick";
import { CustomImageToSlide } from "../common/CustomImageToSlide";
import { ShowLightbox } from "../common/ShowLightbox";

const ProjectsDone = () => {
  const [lightbox, setLightbox] = useState({
    open: false,
    index: 0,
    images: [],
  });
  const settings = {
    className: "center slider variable-width",
    centerMode: true,
    infinite: true,
    centerPadding: "8px",
    slidesToShow: 1,
    speed: 500,
    dots: true,
    variableWidth: true,
  };
  const listImg = [
    "https://ladecora.com.br/wp-content/uploads/2022/08/04-768x768.png",
    "https://ladecora.com.br/wp-content/uploads/2022/08/11-768x448.png",
    "https://ladecora.com.br/wp-content/uploads/2022/08/1-768x448.png",
    "https://ladecora.com.br/wp-content/uploads/2022/08/002-768x448.png",
    "https://ladecora.com.br/wp-content/uploads/2022/08/2-768x448.png",
  ];

  const sendLightbox = (data, idx) => {
    if (Array.isArray(data)) {
      setLightbox({
        ...lightbox,
        images: data,
        open: true,
        index: idx,
      });
    } else {
      setLightbox({
        ...lightbox,
        images: [data],
        open: true,
        index: 0,
      });
    }
  };

  return (
    <>
      <h4>Projeto personalizado para você!</h4>
      <Slider {...settings}>
        {listImg.map((file, idx) => {
          return (
            <div key={idx}>
              <CustomImageToSlide
                file={file}
                onclick={() => {
                  sendLightbox(
                    listImg.map((img) => img),
                    idx
                  );
                }}
              />
            </div>
          );
        })}
      </Slider>
      {lightbox.open && (
        <ShowLightbox lightbox={lightbox} setLightbox={setLightbox} />
      )}
    </>
  );
};

export default ProjectsDone;
