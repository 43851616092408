import React, {
  MouseEvent,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import PropTypes from "prop-types";
import { InferProps } from "prop-types";
import { FormGroup, Button } from "shards-react";
import { RegisterValuesI } from "./types";
import Loader from "react-loader-spinner";
import { useForm } from "react-hook-form";
import { updateUser } from "../../actions/user";

import Input from "../common/Input";
import FormCpfCnpj from "./FormCpfCnpj";
import FormPhoneBR from "./FormPhoneBR";
import { AuthContext } from "../common/Authorization/AuthContext";
import styles from "./ShortForm.module.scss";
import { clearPhone } from "../../utils/form_utils";
import Terms from "../common/Terms";
import { toast } from "react-toastify";

type Modify<T, R> = Omit<T, keyof R> & R;

const ShortFormPropTypes = {
  onNext: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
};

type ShortFormType = InferProps<typeof ShortFormPropTypes>;
type ShortFormTypeExtended = Modify<
  ShortFormType,
  {
    onNext(): void;
  }
>;

const ShortForm = ({ onNext, userId }: ShortFormTypeExtended): ReactElement => {
  const { user, updateUser: updateState } = useContext(AuthContext) as any;
  const [isLoading, setIsLoading] = useState(false);
  const [showTerms, setShowTerms] = useState<boolean>(false);
  const {
    register,
    control,
    setValue,
    trigger,
    getValues,
    formState: { errors, isValid },
  } = useForm<RegisterValuesI>({
    reValidateMode: "onChange",
    mode: "onChange",
  });

  useEffect(() => {
    if (user?._id) {
      setValue("email", user.email);
      setValue("firstName", user.profile.firstName);
      setValue("lastName", user.profile.lastName);
      setValue("document", user.profile.document);
      setValue("phone", clearPhone(user.profile.phone, 11, true));
      trigger();
    }
  }, [user]);

  function toggleModalTerms(ev: MouseEvent): boolean {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }

    setShowTerms(!showTerms);
    return false;
  }

  function confirmProfileValidation(data: RegisterValuesI): Promise<any> {
    return updateUser(userId, {
      profile: {
        firstName: data.firstName,
        lastName: data.lastName,
        phone: data.phone,
        document: data.document,
      },
    });
  }

  function onContinueClick() {
    setIsLoading(true);
    confirmProfileValidation(getValues())
      .then(() => {
        // It would be better to just update the Authorization state, BUT
        // This update will destroy and recreate all components inside Authorization Context to avoid rewriting other components.
        updateState();
        // onNext();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Ocorreu um erro, por favor, tente novamente", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setIsLoading(false);
      });
  }

  return (
    <>
      <div>
        <form>
          <FormGroup>
            <Input
              disabled={true}
              type="email"
              placeholder="E-mail"
              autoComplete="email"
              name="email"
              options={{
                form: {
                  required: "Digite seu email",
                  validate: {
                    email: (value: string) => {
                      return value.match(
                        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                      )
                        ? true
                        : "Email inválido";
                    },
                  },
                },
                register: register,
                error: errors.email,
              }}
            />
          </FormGroup>
          <div className={styles.nameFields}>
            <FormGroup>
              <Input
                id="register-first-name-input"
                type="text"
                placeholder="Nome"
                autoComplete="firstName"
                className="mr-2"
                name="firstName"
                options={{
                  form: {
                    required: "Digite seu nome",
                  },
                  register,
                  error: errors.firstName,
                }}
              />
            </FormGroup>
            <FormGroup>
              <Input
                id="register-last-name-input"
                required
                type="text"
                placeholder="Sobrenome"
                autoComplete="lastName"
                name="lastName"
                options={{
                  form: {
                    required: "Digite seu sobrenome",
                  },
                  register,
                  error: errors.lastName,
                }}
              />
            </FormGroup>
          </div>

          <FormGroup>
            <FormCpfCnpj control={control} />
          </FormGroup>
          <FormGroup>
            <FormPhoneBR control={control} />
          </FormGroup>

          <div style={{ lineHeight: 1 }}>
            {/*  eslint-disable */}
            Ao fazer seu cadastro, você concorda com os{" "}
            <a onClick={toggleModalTerms} href="" tabIndex={-1}>
              Termos de Uso e Política de Privacidade
            </a>
            .{/*  eslint-disable */}
          </div>

          <Button
            style={{ backgroundColor: "#33666d", borderColor: "#33666d" }}
            className="d-table mx-auto mt-4"
            size="lg"
            disabled={isLoading || !isValid}
            theme="accent"
            onClick={onContinueClick}
          >
            {isLoading && <Loader type="Rings" />}
            {!isLoading && "Continuar"}
          </Button>
        </form>
      </div>
      <Terms show={showTerms} toggle={toggleModalTerms} />
    </>
  );
};

ShortForm.propTypes = ShortFormPropTypes;

export default ShortForm;
