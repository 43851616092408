import React, { useContext, useState, useEffect } from "react";
import { Button } from "shards-react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { AuthContext } from "../components/common/Authorization/AuthContext";
import { getUserEnterpriseAnswer } from "../actions/userEnterprise";
import ReactTable from "react-table";
import dateFormat from "dateformat";
import Loader from "react-loader-spinner";

const EnterpriseAnswers = () => {
  const { user } = useContext(AuthContext);
  const [answers, setAnswers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  useEffect(() => {
    getUserEnterpriseAnswer(user._id).then((data) => {
      setAnswers(data.answers);
      setIsLoading(false);
    });
  }, []);

  const tableColumns = [
    {
      Header: "Empreendimento",
      accessor: "enterprise.name",
      className: "text-center",
    },
    { Header: "Planta Baixa", accessor: "bluePrint", className: "text-center" },
    {
      Header: "Data",
      acessor: "createdAt",
      className: "text-center",
      Cell: (row) =>
        dateFormat(new Date(row.original.createdAt), "dd/mm/yyyy HH:MM:ss"),
    },
    {
      Header: "Ver respostas",
      className: "text-center",
      Cell: (row) => (
        <div className="d-table mx-auto btn-group-sm btn-group">
          <Button
            onClick={() =>
              history.replace(
                `/enterprise/${row.original.enterprise._id}?idAnswers=${row.original._id}`
              )
            }
            type="button"
            className="btn btn-white"
            title="Ver resposta"
          >
            <i className="material-icons">textsms</i>
          </Button>
        </div>
      ),
    },
  ];

  const returnTable = () => {
    if (answers.length > 0) {
      return (
        <ReactTable
          columns={tableColumns}
          data={answers}
          showPageSizeOptions={false}
          resizable={false}
          pageSize={10}
          previousText="Anterior"
          nextText="Próxima"
          pageText="Página"
          ofText="de"
        />
      );
    } else {
      return (
        <center>
          <h4>Você ainda não respondeu nenhum questionário</h4>
          <Button
            onClick={() => {
              history.replace("/enterprises");
            }}
            theme="accent"
            outline
          >
            Responder
          </Button>
        </center>
      );
    }
  };
  return (
    <Container fluid className="main-content-container px-4">
      <Row className="page-header py-4">
        <Col>
          <PageTitle title="Meus Projetos" className="text-sm-left mb-3" />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card small className="mb-4">
            <CardHeader />
            <CardBody
              className={`p-0 pb-3 ${
                isLoading && "align-items-center d-flex justify-content-center"
              }`}
            >
              {isLoading ? (
                <Loader
                  width="100px"
                  height="100px"
                  type="Rings"
                  color="#212529"
                />
              ) : (
                returnTable()
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default EnterpriseAnswers;
