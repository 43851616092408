import React from "react";

import { Row, Col, Card, CardBody, Button } from "shards-react";

const Banner = ({ company }) => {
  const phone = company.phone || "5547999322228";
  const goToWhatsapp = () => {
    window.open(
      `https://wa.me/5547999322228?text=Ol%C3%A1,%20gostaria%20de%20ajuda%20para%20comprar%20projetos%20de%20decora%C3%A7%C3%A3o%20da%20${company.name}.%20`,
      "_blank"
    );
  };
  return (
    <Row className="mb-4">
      <Col>
        <Card>
          <CardBody className="text-center">
            <h4>Precisa de ajuda para comprar seu projeto?</h4>
            <Button theme="accent" size="lg" onClick={goToWhatsapp}>
              Fale com nosso vendedor
            </Button>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default Banner;
