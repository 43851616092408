import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  FormInput,
  Button,
} from "shards-react";
import Loader from "react-loader-spinner";
import { forgotPassword } from "../actions/auth";
import { SystemContext } from "../components/SystemConfig/SystemContext";
import Logo from "../components/common/Logo";

function ForgotPassword() {
  const { company } = useContext(SystemContext);
  const [values, setValues] = useState({
    email: "",
    loading: false,
    error: false,
    message: "",
  });
  const { email, loading, error, message } = values;

  const handleChange = (name) => (e) => {
    setValues({ ...values, error: false, [name]: e.target.value });
  };

  const showError = () =>
    error ? <div className="alert alert-danger">{error}</div> : "";
  const showMessage = () =>
    message ? <div className="alert alert-info">{message}</div> : "";

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setValues({ ...values, loading: true });

    forgotPassword(email, company.label).then((data) => {
      if (data.error) {
        setValues({ ...values, error: data.error });
      } else {
        setValues({
          ...values,
          message: `${data.msg} Estamos redirecionando você para a página de login em 5 segundos`,
          loading: true,
        });
        setTimeout(() => {
          window.open(`/login`, "_self");
          setValues({
            ...values,
            email: "",
            loading: false,
            error: false,
            message: "",
          });
        }, 5000);
      }
    });
  };

  return (
    <Container fluid className="main-content-container h-100 px-4">
      <Row noGutters className="h-100">
        <Col lg="3" md="5" className="auth-form mx-auto my-auto">
          <Card>
            <CardBody>
              {/* Logo */}

              <Logo
                props={{
                  style: {
                    padding: "30px",
                    paddingBottom: "20px",
                    maxWidth: "215px",
                  },
                  className: "auth-form__logo d-table mx-auto mb-3",
                  alt: "La Decora",
                }}
                defaultProps={{
                  style: {
                    // backgroundColor: company.color,
                    padding: "30px",
                    paddingBottom: "20px",
                    maxWidth: "215px",
                  },
                  className: "auth-form__logo d-table mx-auto mb-3",
                  alt: "La Decora - Register Template",
                }}
              />

              {/* Title */}
              <h5 className="auth-form__title text-center mb-4">
                Redefinir senha
              </h5>

              {/* Form Fields */}
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <label htmlFor="exampleInputEmail1">Email</label>
                  <FormInput
                    type="email"
                    placeholder="Informe seu email"
                    autoComplete="email"
                    value={email}
                    onChange={handleChange("email")}
                  />
                  <small className="form-text text-muted text-center">
                    Você receberá um email com um token exclusivo
                  </small>
                </FormGroup>
                {showError()}
                {showMessage()}
                <Button
                  pill
                  theme="accent"
                  className="d-table mx-auto"
                  type="submit"
                >
                  {loading && (
                    <Loader
                      width="30px"
                      height="15px"
                      type="Rings"
                      color="#ffffff"
                    />
                  )}
                  {!loading && "Redefinir senha"}
                </Button>
              </Form>
            </CardBody>
          </Card>

          {/* Meta Details */}
          <div className="auth-form__meta d-flex mt-4">
            <Link to="/login" className="mx-auto">
              Voltar ao login
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default ForgotPassword;
