import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Navbar, NavbarBrand } from "shards-react";

import { Dispatcher, Constants } from "../../../flux";
import { SystemContext } from "../../SystemConfig/SystemContext";

const SidebarMainNavbar = () => {
  const { isDefault, company } = useContext(SystemContext);

  const LOGO_LINK = isDefault
    ? require("../../../images/La_Decora_principal.jpeg")
    : company.logo;

  function handleToggleSidebar() {
    Dispatcher.dispatch({
      actionType: Constants.TOGGLE_SIDEBAR,
    });
  }

  return (
    <div className="main-navbar">
      <Navbar
        className="align-items-stretch bg-white flex-md-nowrap border-bottom p-0"
        type="light"
      >
        <NavbarBrand
          className="w-100 mr-0"
          href="/"
          style={{
            lineHeight: "20px",
            backgroundColor: company.navBarColor,
          }}
        >
          <div className="d-table m-auto">
            <img
              id="main-logo"
              className="d-inline-block align-top mr-1"
              src={LOGO_LINK}
              alt="Ladecora"
              style={{ maxHeight: "50px", maxWidth: "170px" }}
            />
          </div>
        </NavbarBrand>

        {/* eslint-disable-next-line */}
        <a
          style={
            isDefault ? { color: "white", border: "none" } : { color: "black" }
          }
          className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
          onClick={handleToggleSidebar}
        >
          <i className="material-icons">&#xE5C4;</i>
        </a>
      </Navbar>
      <hr className="my-0" />
    </div>
  );
};

SidebarMainNavbar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool,
};

SidebarMainNavbar.defaultProps = {
  hideLogoText: false,
};

export default SidebarMainNavbar;
