import React from "react";
import { Row, Card, CardBody, Col, Button, Container } from "shards-react";

import { useHistory } from "react-router-dom";
import PageTitle from "../../common/PageTitle";

const Start = () => {
  const history = useHistory();

  return (
    <Container fluid className="main-content-container px-4">
      <Row className="page-header py-4">
        <Col>
          <PageTitle
            title={`Inicio da assessoria`}
            subtitle="Visão Geral"
            md="12"
            className="ml-sm-auto mr-sm-auto"
          />
        </Col>
      </Row>
      <Row style={{ height: "70vh" }}>
        <Col className="w-100" style={{ height: "100%" }}>
          <Card className="text-center w-100" style={{ height: "100%" }}>
            <CardBody className="d-flex align-items-center justify-content-center flex-column">
              <h3>Estamos animados para iniciar a sua assessoria </h3>
              <br />
              <Button
                theme="accent"
                pill
                size="lg"
                onClick={() => {
                  history.replace("/environments-list");
                }}
              >
                Responder questionário
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Start;
