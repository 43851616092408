import React, { useState, useEffect, useMemo } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  FormTextarea,
  CardBody,
  FormInput,
  FormSelect,
  Button,
  Form,
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { getAllProjects } from "../actions/project";
import DropzoneFiles from "../components/common/DropzoneFiles";
import { sendMessageSuport, uploadFileAsana } from "../actions/support";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import { getQueryStringValue } from "../actions/auth";

const Support = () => {
  const [message, setMessage] = useState({
    subject: "",
    detail: "",
    project: "",
  });
  const [files, setFiles] = useState([]);
  const [projects, setProjects] = useState([]);
  const [sending, setSending] = useState(false);

  const history = useHistory();
  const project = useMemo(() => {
    const value =
      history.location.search &&
      getQueryStringValue("project", history.location.search);
    if (value) {
      return value;
    } else return undefined;
  }, [history.location.search]);

  useEffect(() => {
    getAllProjects().then((res) => {
      if (res.length > 0) {
        setProjects(res);
      }
    });
    window.scrollTo(0, 0);
  }, []);

  const onChange = (text, type) => {
    setMessage({ ...message, [type]: text });
  };

  const send = (e) => {
    e.preventDefault();
    setSending(true);
    sendMessageSuport({
      ...message,
      project: project || message.project,
    })
      .then((res) => {
        let idTask = res.idTask;
        if (files.length > 0) {
          console.log(files);
          Promise.all(
            files.map(async (file) => {
              await uploadFileAsana(idTask, file.file);
            })
          ).then(() => {
            setFiles([]);
          });
        }
        toast.success("Mensagem enviada com sucesso!");
        setSending(false);
        setMessage({
          subject: "",
          detail: "",
          project: "",
        });
      })
      .catch((err) => {
        toast.error("Erro ao enviar a mensagem!");
        setSending(false);
        console.log(err);
      });
    return false;
  };

  return (
    <Container fluid className="main-content-container px-4">
      <ToastContainer />
      <Row className="page-header py-4">
        <Col>
          <PageTitle title="Central de ajuda" className="text-sm-left mb-3" />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card small className="mb-4">
            <CardBody className="p-3 pb-3">
              <Form onSubmit={send}>
                <Col>
                  <div className="form-group">
                    <label htmlFor="support-subject-input">Assunto</label>
                    <FormInput
                      id="support-subject-input"
                      value={message.subject}
                      onChange={(e) => onChange(e.target.value, "subject")}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="support-project-input">Assessoria</label>
                    <FormSelect
                      id="support-project-input"
                      value={project || message.project}
                      disabled={!!project}
                      onChange={(e) => onChange(e.target.value, "project")}
                      required
                    >
                      <option value="">Selecione</option>
                      {projects.map((project) => (
                        <option value={project._id} key={project._id}>
                          {project.name}
                        </option>
                      ))}
                    </FormSelect>
                  </div>

                  <div className="form-group">
                    <label htmlFor="support-detail-input">Detalhe</label>
                    <FormTextarea
                      id="support-detail-input"
                      value={message.detail}
                      rows="6"
                      onChange={(e) => onChange(e.target.value, "detail")}
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="support-files">Arquivos</label>
                    <DropzoneFiles
                      isMultiple={true}
                      items={files}
                      onChange={(files) => {
                        setFiles(files);
                      }}
                      isLoading={false}
                    />{" "}
                  </div>
                  <div className="form-group">
                    <center>
                      <Button
                        theme="accent"
                        outline
                        size="lg"
                        disabled={sending}
                        type="submit"
                      >
                        Enviar
                      </Button>
                    </center>
                  </div>
                </Col>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Support;
