import React from "react";
import ReactDOM from "react-dom";
import PropTypes, { InferProps } from "prop-types";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "shards-react";

const TermsPropTypes = {
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

type TermsTypes = InferProps<typeof TermsPropTypes>;

const Terms: React.FC<TermsTypes> = ({ show, toggle }) => {
  return ReactDOM.createPortal(
    <Modal backdrop={true} size="lg" open={show} animation toggle={toggle}>
      <ModalHeader toggle={toggle}>
        Termos de Uso e Política de Privacidade
      </ModalHeader>
      <ModalBody style={{ maxHeight: "60vh", overflow: "auto" }}>
        <h4>Termos de Uso</h4>
        <embed
          width="100%"
          height="400"
          src={`https://s3.amazonaws.com/ladecora.platform/terms/TERMOS_DE_USO.pdf#toolbar=0`}
        />
        <h4>Política de Privacidade</h4>
        <embed
          width="100%"
          height="400"
          src={`https://s3.amazonaws.com/ladecora.platform/terms/POLITICA_DE_PRIVACIDADE.pdf#toolbar=0`}
        />
      </ModalBody>
      <ModalFooter>
        <Button size="lg" theme="accent" onClick={toggle}>
          Aceito
        </Button>
      </ModalFooter>
    </Modal>,
    document.body
  );
};

Terms.propTypes = TermsPropTypes;

export default Terms;
