export const download = function (fileURL, fileName) {
  // for non-IE
  if (!window.ActiveXObject) {
    var save = document.createElement("a");
    save.href = fileURL;
    save.target = "_self";
    var filename = fileURL.substring(fileURL.lastIndexOf("/") + 1);
    save.download = fileName || filename;
    if (
      navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
      navigator.userAgent.search("Chrome") < 0
    ) {
      document.location = save.href;
      // window event not working here
    } else {
      var evt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: false,
      });
      save.dispatchEvent(evt);
      (window.URL || window.webkitURL).revokeObjectURL(save.href);
    }
  }

  // for IE < 11
  else if (!!window.ActiveXObject && document.execCommand) {
    var _window = window.open(fileURL, "_blank");
    _window.document.close();
    _window.document.execCommand("SaveAs", true, fileName || fileURL);
    _window.close();
  }
};

export const copyObject = function (value) {
  if (typeof value === "object") {
    return JSON.parse(JSON.stringify(value));
  }
  return value;
};

export const showReviewProjects = (project) => {
  let stepsToShow = {
    preview: true,
    firstReview: true,
    secondReview: true,
  };
  if (
    project.status === "waiting_quality" ||
    project.status === "reviewing_quality"
  ) {
    if (
      project.finalProject &&
      project.finalProject.secondReview &&
      project.finalProject.secondReview.files &&
      project.finalProject.secondReview.files.length > 0
    ) {
      stepsToShow.secondReview = false;
      return stepsToShow;
    }
    if (
      project.finalProject &&
      project.finalProject.firstReview &&
      project.finalProject.firstReview.files &&
      project.finalProject.firstReview.files.length > 0
    ) {
      stepsToShow.secondReview = false;
      stepsToShow.firstReview = false;
      return stepsToShow;
    }
    if (
      project.finalProject &&
      project.finalProject.preview &&
      project.finalProject.preview.files &&
      project.finalProject.preview.files.length > 0
    ) {
      stepsToShow.secondReview = false;
      stepsToShow.firstReview = false;
      stepsToShow.preview = false;
      return stepsToShow;
    }
  }
  return stepsToShow;
};

export const getAllUrlParams = () => {
  if (window.location.search) {
    let result = {},
      tmp = [];
    let items = window.location.search.substr(1).split("&");
    for (let index = 0; index < items.length; index++) {
      tmp = items[index].split("=");
      result[tmp[0]] = decodeURIComponent(tmp[1]);
    }
    return result;
  } else {
    return "";
  }
};
