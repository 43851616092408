import React, { ChangeEvent, useMemo } from "react";
import PropTypes, { InferProps } from "prop-types";

import {
  InputTypes,
  InputDefaultPropTypes,
} from "../../utils/constants/inputDefaultPropTypes";

const CpfCnpjPropTypes = {
  onChange: PropTypes.func.isRequired,
};

const CpfCnpj: React.FC<InputTypes & InferProps<typeof CpfCnpjPropTypes>> = (
  props
) => {
  const { value, onChange } = props;
  const TYPES = {
    CPF: "999.999.999-999",
    CNPJ: "99.999.999/9999-99",
  };
  const MAX_LENGTH = clear(TYPES.CNPJ).length;

  const maskedValue = useMemo(() => {
    if (value) {
      const mask = getMask(clear(value));

      return applyMask(value, TYPES[mask]);
    }
    return "";
  }, [value]);

  function onLocalChange(ev: ChangeEvent<HTMLInputElement>) {
    const value = clear(ev.target.value);
    const mask = getMask(value);

    const nextLength = value.length;

    if (nextLength > MAX_LENGTH) return;

    onChange({ value, type: mask });
  }

  function getMask(value) {
    return value.length > 11 ? "CNPJ" : "CPF";
  }

  function applyMask(value: string, mask: string) {
    let result = "";

    let inc = 0;
    Array.from(value).forEach((letter, index) => {
      if (!mask[index + inc].match(/[0-9]/)) {
        result += mask[index + inc];
        inc++;
      }
      result += letter;
    });
    return result;
  }

  function clear(value: string) {
    return value.replace(/[^0-9]/g, "");
  }

  return (
    <input {...props} type="tel" value={maskedValue} onChange={onLocalChange} />
  );
};

CpfCnpj.propTypes = { ...InputDefaultPropTypes, ...CpfCnpjPropTypes };

export default CpfCnpj;
