import React, { useState, useEffect } from "react";
import StepWizard from "react-step-wizard";
import "./wizard.scss";
import { Row, Card, CardBody, Col, Button, CardHeader } from "shards-react";

import "react-quill/dist/quill.snow.css";
import { copyObject } from "../../../utils/util";
import { useHistory, useLocation } from "react-router-dom";

import { walkAtTree, getFlatData } from "../../Enterprise/questionary-service";
import StepChanger from "../../common/StepChanger";
import ConfirmationButton from "../../common/ConfirmationButton";

const Wizard = (props) => {
  const STEP = 3;
  const [allForm, setAllForm] = useState([]);
  const history = useHistory();

  const treeData = props.project.questionTemplate.template;
  const [flatData, setFlatData] = useState();
  const [isSaving, setIsSaving] = useState(false);

  const [answers, setAnswers] = useState([]);
  const [answer, setAnswer] = useState();
  const { hash } = useLocation();
  const [isPristine, setIsPristine] = useState(true);
  const [sync, setSync] = useState();

  useEffect(() => {
    if (sync) {
      nextStep();
    }
  }, [sync]);

  useEffect(() => {
    goToStep(1);
  }, []);

  useEffect(() => {
    const fd = getFlatData(treeData);
    setAnswers(props.project.answers);
    setFlatData(fd);
    walkAtTree(
      treeData,
      fd,
      {
        stats: StepChanger,
        flatData: fd,
        update: updateForm,
        answers: answers,
        answeredQuestions: props.project.answers,
        projectId: props.project._id,
        ns: () => setSync({}),
      },
      setAllForm
    );
  }, [props.project]);

  function goToStep(i) {
    // history.replace(`/my_project/${props.project._id}#step${i}`);
    window.location.hash = `step${i}`;
  }

  const updateForm = (data) => {
    setAnswer(data);
  };

  useEffect(() => {
    if (!answer) return;

    let form = answers.find((element) =>
      element.question === answer.question ? true : false
    );
    if (!form) {
      answers.push(answer);
    } else {
      answers.forEach((element) => {
        if (element.question === answer.question) {
          element.answer = answer.answer;
          element.input = answer.input;
        }
      });
    }
    setAnswers([...answers]);
    setAnswer(null);
    if (answer.goToNextStep) nextStep();
  }, [answer]);

  function onSave() {
    window.scrollTo(0, 0);
    const rollback = { answers: copyObject(props.project.answers) };

    setIsSaving(true);
    props
      .updateStepCompleted(
        {
          answers: answers,
          _id: props.project._id,
        },
        isPristine
      )
      .then(() => {
        window.location.hash = "";
        history.replace(`/my_project/${props.project._id}`);
      })
      .catch(() => {
        props.project.answers = rollback.answers;
        props.project.completedSteps.pop();
      });
  }

  function nextStep() {
    setIsPristine(false);
    const stepN = parseInt(hash.replace("#step", "") - 1);
    if (flatData.length - 1 === stepN) {
      return onSave();
    }
    window.scrollTo(0, 0);
    const node = flatData[stepN];

    if (node.node.input) {
      if (node.node.input.action) {
        goToStep(parseInt(node.node.input.action) + 1);
      } else {
        let parentNode = flatData.find(
          (element) => element.treeIndex === node.path[0]
        );
        let nextStep = !parentNode.childCount
          ? node.path[0] + 2
          : parentNode.childCount + node.path[0] + 2;
        goToStep(nextStep);
      }
    } else {
      let parentNode = flatData.find(
        (element) => element.treeIndex === node.path[0]
      );
      //MUDADO APENAS O 2 para 1
      // let nextStep = !parentNode.childCount
      //   ? node.path[0] + 2
      //   : parentNode.childCount + node.path[0] + 2;
      let nextStep = !parentNode.childCount
        ? node.path[0] + 2
        : parentNode.childCount + node.path[0] + 2;
      goToStep(nextStep);
    }
  }

  function previousStep() {
    if (hash === "#step1") {
      props.onPrevious(1);
      return;
    }
    window.scrollTo(0, 0);
    answers.pop();
    history.goBack();
  }

  return (
    <>
      <Card small className="mt-3 ml-auto mr-auto">
        <CardHeader className="p-0 pt-2 pr-2">
          <ConfirmationButton
            text="Você tem certeza que deseja fechar o questionário?"
            onClick={props.goBack}
            style={{
              fontSize: "22px",
              cursor: "pointer",
              float: "right",
              border: "none",
              backgroundColor: "transparent",
              color: "#3d5170",
            }}
            theme="white"
          >
            <i aria-hidden="true" className="fa fa-times" />
          </ConfirmationButton>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              {!!allForm.length && (
                <div className="mx-3 questionary">
                  <StepWizard isHashEnabled transitions>
                    {allForm}
                  </StepWizard>
                </div>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>

      <div className="d-flex flex-column  fixed-right-botton wizard-footer">
        <div className="d-flex flex-row align-items-center">
          <button
            type="button"
            className=" ml-4 btn btn-accent"
            onClick={previousStep}
            disabled={hash === "#step1" && props.project.forcedStep?.length}
          >
            {hash === `#step1` ? "Voltar etapa" : "Voltar"}
          </button>
          <button
            type="button"
            className="ml-auto mr-4 btn btn-accent"
            onClick={nextStep}
            disabled={isSaving}
          >
            {flatData && hash === `#step${flatData.length}`
              ? "Finalizar"
              : "Próximo"}
          </button>
        </div>
        {props.showSummary ? (
          <div className="d-flex align-items-center justify-content-center">
            <Button
              theme="success"
              type="button"
              size="lg"
              className="mt-3"
              onClick={props.onClose}
            >
              Revisar informações
            </Button>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Wizard;
