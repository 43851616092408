import React from "react";
import { Button, Row, Col } from "shards-react";

const HelpToBuy = ({ company }) => {
  const phone = company.phone || "5547999322228";
  return (
    <>
      <Row>
        <Col>
          <img
            src="https://ladecora.com.br/wp-content/uploads/2023/04/Banco-de-projetos-1024x256.png"
            alt="help-to-buy"
            style={{ width: "100%" }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <h3>Precisa de ajuda para comprar seu projeto?</h3>
        </Col>
      </Row>
      <Button
        theme="accent"
        size="lg"
        onClick={() => {
          window.open(
            `https://api.whatsapp.com/send/?phone=${phone}&text=Ol%C3%A1%2C+gostaria+de+saber+mais+sobre+os+projetos+de+decora%C3%A7%C3%A3o+da+${company.name}%21&type=phone_number&app_absent=0`,
            "_blank"
          );
        }}
      >
        Falar com um vendedor
      </Button>
    </>
  );
};

export default HelpToBuy;
