import React, { useContext } from "react";

import Logo from "../common/Logo";
import { SystemContext } from "../SystemConfig/SystemContext";
import { useHistory } from "react-router-dom";

function LeftSidebar({ children, show }) {
  const history = useHistory();

  const { company } = useContext(SystemContext);
  return (
    <div
      className={`left-sidebar d-flex flex-column ${
        show ? "left-sidebar-transition" : ""
      }`}
    >
      <div
        onKeyDown={() => {
          //TODO
        }}
        role="button"
        tabIndex="0"
        className="d-flex align-items-center justify-content-center h-100"
        style={{
          height: "2.5rem",
          maxHeight: "2.5rem",
        }}
        onClick={() => {
          history.replace(`/enterprises`);
        }}
      >
        <Logo
          props={{
            style: {
              height: "100%",
              maxWidth: "215px",
            },
            className: "auth-form__logo d-table mx-auto",
            alt: "La Decora - Register Template",
          }}
          defaultProps={{
            style: {
              backgroundColor: company.color,
              maxWidth: "215px",
            },
            className: "auth-form__logo d-table mx-auto",
            alt: "La Decora - Register Template",
          }}
        />
      </div>
      <div className="overflow-y-auto">{children}</div>
    </div>
  );
}

LeftSidebar.propTypes = {};

export default LeftSidebar;
