import { API } from "../config";
import { requestWrapper } from "../utils/api";
import { getCookie } from "./auth";

export const getEnterprises = (company) => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/enterprises/${company}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const getEnterprise = (id) => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/enterprise/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};
