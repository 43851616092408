function accentsTidy(s) {
  let r = s.toLowerCase();
  const non_asciis = {
    a: "[àáâãäå]",
    ae: "æ",
    c: "ç",
    e: "[èéêë]",
    i: "[ìíîï]",
    n: "ñ",
    o: "[òóôõö]",
    oe: "œ",
    u: "[ùúûűü]",
    y: "[ýÿ]",
  };
  for (let i in non_asciis) {
    r = r.replace(new RegExp(non_asciis[i], "g"), i);
  }
  return r;
}

exports.sanitizeString = function (str) {
  if (typeof str === "string") {
    return accentsTidy(
      str
        .toLowerCase()
        .replace(/[^0-9a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024F\s]+/, "")
        .replace(/\s+/g, " ")
        .trim()
        .replace(/ /g, "-")
    );
  } else {
    return str;
  }
};

exports.getDescendantProp = function (obj, desc) {
  var arr = desc.split(".");
  while (arr.length && (obj = obj[arr.shift()]));
  return obj;
};

exports.getCompanyUrlParams = function (params) {
  let paramsStr = "";
  if (params) {
    paramsStr = Object.keys(params).reduce((acc, current, index) => {
      return `${acc}${index === 0 ? "" : "&"}${current}=${params[current]}`;
    }, "?");
  }
  return paramsStr;
};

exports.formatBytes = function (a, b = 2) {
  if (0 === a) return "0 Bytes";
  const c = 0 > b ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1024));
  return (
    parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
    " " +
    ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
  );
};
