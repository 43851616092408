export interface WaitPaymentI {
  id: string;
  encodedImage: string;
  payload: string;
  timestamp: number;
}

export interface LadecoraDBI {
  addToDb<T>(objectStoreName: string, item: T): Promise<T>;
  getItemByIndex<T>(
    indexName: string,
    objectStoreName: string,
    key: string
  ): Promise<T | undefined>;
  getAllByIndex<T>(
    indexName: string,
    objectStoreName?: string
  ): Promise<T[] | undefined>;
  deleteItemByIndex(objectStoreName: string, key: string): Promise<undefined>;
}

async function createDB(dbName = "ladecora"): Promise<IDBDatabase> {
  return new Promise((s) => {
    const request = indexedDB.open(dbName, 1);
    request.onupgradeneeded = () => {
      const db = request.result;
      const paymentsStore = db.createObjectStore("payments", {
        keyPath: "id",
      });
      paymentsStore.createIndex("by_id", "id", { unique: true });
    };

    request.onsuccess = () => {
      s(request.result);
    };
  });
}

class LadecoraDB implements LadecoraDBI {
  private db: IDBDatabase;
  constructor(db: IDBDatabase) {
    this.db = db;
  }

  addToDb<T>(objectStoreName: "payments", item: T): Promise<T> {
    return new Promise((resolve, reject) => {
      try {
        const tx = this.db.transaction(objectStoreName, "readwrite");
        const store = tx.objectStore(objectStoreName);
        const request = store.put(item);
        request.onsuccess = function () {
          return resolve(item);
        };
      } catch (err) {
        return reject(err);
      }
    });
  }

  getItemByIndex<T>(
    indexName: string,
    objectStoreName: string,
    key: string
  ): Promise<T | undefined> {
    return new Promise((resolve, reject) => {
      try {
        const tx = this.db.transaction(objectStoreName, "readonly");
        const store = tx.objectStore(objectStoreName);
        const index = store.index(indexName);

        const request = index.get(key);
        request.onsuccess = function () {
          const matching = request.result;
          if (matching !== undefined) {
            return resolve(matching);
          } else {
            return resolve(undefined);
          }
        };
      } catch (err) {
        return reject(err);
      }
    });
  }

  deleteItemByIndex(objectStoreName: string, key: string): Promise<undefined> {
    return new Promise((resolve, reject) => {
      try {
        const tx = this.db.transaction(objectStoreName, "readwrite");
        const store = tx.objectStore(objectStoreName);
        const request = store.delete(key);

        request.onsuccess = function () {
          return resolve(undefined);
        };
      } catch (err) {
        return reject(err);
      }
    });
  }

  getAllByIndex<T>(objectStoreName: string): Promise<T[] | undefined> {
    return new Promise((resolve, reject) => {
      try {
        const tx = this.db.transaction(objectStoreName, "readonly");
        const store = tx.objectStore(objectStoreName);
        const request = store.getAll();

        request.onsuccess = function () {
          const matching = request.result;
          if (matching !== undefined) {
            return resolve(matching);
          } else {
            return resolve(undefined);
          }
        };
      } catch (err) {
        return reject(err);
      }
    });
  }
}

async function getDb(): Promise<LadecoraDB> {
  return createDB().then((db) => {
    return new LadecoraDB(db);
  });
}

export { getDb };
