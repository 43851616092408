import React, { MouseEvent, useContext, useState } from "react";
import { FormGroup, Button } from "shards-react";
import { RegisterValuesI } from "./types";
import Loader from "react-loader-spinner";
import { useForm } from "react-hook-form";
import Input from "../common/Input";
import FormCpfCnpj from "./FormCpfCnpj";
import FormPhoneBR from "./FormPhoneBR";
import {
  signup,
  authenticate,
  isAuth,
  getQueryStringValue,
} from "../../actions/auth";
import { SystemContext } from "../SystemConfig/SystemContext";
import { toast } from "react-toastify";
import Terms from "../common/Terms";

const RegisterForm: React.FC = () => {
  const {
    register,
    control,
    getValues,
    formState: { errors, isValid },
  } = useForm<RegisterValuesI>({
    reValidateMode: "onChange",
    mode: "onChange",
  });
  const [isLoading, setIsLoading] = useState(false);
  const { getCompanyLabel } = useContext(SystemContext) as {
    getCompanyLabel: any;
  };
  const [showTerms, setShowTerms] = useState(false);

  function toggleModalTerms(ev: MouseEvent): boolean {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }
    setShowTerms(!showTerms);
    return false;
  }

  function clear(value) {
    return value.replace(/[^0-9]/g, "");
  }

  function validateUrl() {
    const projectTemplatesQtd = getQueryStringValue("qty");
    let projectTemplatesHashes = getQueryStringValue("environment");

    if (typeof projectTemplatesHashes == "string") {
      projectTemplatesHashes = [projectTemplatesHashes];
    }

    return (
      projectTemplatesHashes &&
      projectTemplatesHashes.length > 0 &&
      projectTemplatesQtd &&
      projectTemplatesHashes.length === projectTemplatesQtd.length
    );
  }

  const onSubmit = (e) => {
    e.preventDefault();
    if (isValid) {
      setIsLoading(true);
      const values = getValues();
      signup({
        ...values,
        email: values.email.toLowerCase(),
        document: clear(values.document),
        phone: `+55${clear(values.phone)}`,
        company: getCompanyLabel(),
      })
        .then((res) => {
          setTimeout(() => {
            authenticate(res, () => {
              if (isAuth()) {
                if (validateUrl()) {
                  window.open(`/checkout${window.location.search}`, "_self");
                } else {
                  window.open(`/environments`, "_self");
                }
              }
            });
          }, 2000);
          // setLoading(false);
          // setSuccess(true);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
          toast.error(err.message, {
            position: "top-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
    return false;
  };

  return (
    <>
      <div>
        <form onSubmit={onSubmit}>
          <FormGroup>
            <Input
              type="email"
              placeholder="E-mail"
              autoComplete="email"
              name="email"
              options={{
                form: { required: "Digite seu email" },
                register,
                error: errors.email,
              }}
            />
          </FormGroup>
          <FormGroup>
            <Input
              type="password"
              placeholder="Senha"
              autoComplete="password"
              name="password"
              options={{
                form: {
                  required: "Digite uma senha de no mínimo 6 dígitos",
                  validate: {
                    minLength: (value: string) => {
                      return value.length >= 6
                        ? true
                        : "A senha precisa ter no mínimo 6 dígitos";
                    },
                  },
                },
                register,
                error: errors.password,
              }}
            />
          </FormGroup>
          <FormGroup>
            <Input
              type="password"
              placeholder="Confirmação da senha"
              autoComplete="confirmPassword"
              name="confirmPassword"
              options={{
                form: {
                  validate: (value, formValues: RegisterValuesI) => {
                    return value === formValues.password
                      ? true
                      : "As senhas devem ser iguais";
                  },
                },
                register,
                error: errors.confirmPassword,
              }}
            />
          </FormGroup>
          <FormGroup>
            <Input
              id="register-first-name-input"
              type="text"
              placeholder="Nome"
              autoComplete="firstName"
              className="mr-2"
              name="firstName"
              options={{
                form: {
                  required: "Digite seu nome",
                },
                register,
                error: errors.firstName,
              }}
            />
          </FormGroup>
          <FormGroup>
            <Input
              id="register-last-name-input"
              required
              type="text"
              placeholder="Sobrenome"
              autoComplete="lastName"
              name="lastName"
              options={{
                form: {
                  required: "Digite seu sobrenome",
                },
                register,
                error: errors.lastName,
              }}
            />
          </FormGroup>

          <FormGroup>
            <FormCpfCnpj control={control} />
          </FormGroup>
          <FormGroup>
            <FormPhoneBR control={control} />
          </FormGroup>

          <div style={{ lineHeight: 1 }}>
            {/*  eslint-disable */}
            Ao fazer seu cadastro, você concorda com os{" "}
            <a onClick={toggleModalTerms} href="" tabIndex={-1}>
              Termos de Uso e Política de Privacidade
            </a>
            .{/*  eslint-disable */}
          </div>

          <Button
            style={{ backgroundColor: "#33666d", borderColor: "#33666d" }}
            className="d-table mx-auto mt-4"
            type="submit"
            theme="accent"
            size="lg"
            onClick={onSubmit}
            disabled={isLoading || !isValid}
          >
            {isLoading && <Loader type="Rings" />}
            {!isLoading && "Criar Conta"}
          </Button>
        </form>
      </div>
      <Terms show={showTerms} toggle={toggleModalTerms} />
    </>
  );
};

export default RegisterForm;
