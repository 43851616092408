import React, { createContext, useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";
import { AuthContext } from "../common/Authorization/AuthContext";
import { Store } from "../../flux";

const socketInstance = io("https://socket.ladecora.com.br", {
  secure: true,
  autoConnect: false,
});

export const SocketIoContext = createContext({});

export const SocketIoProvider = ({ children }) => {
  const { user, updateUserNotifications } = useContext(AuthContext);
  const [eventHandlers, setEventHandlers] = useState({});

  const socket = socketInstance.connect();

  useEffect(() => {
    if (user?._id) {
      socket.on("connect", () => {
        const { isFirstConnection, lastNotificationsUpdate } =
          Store.getNotificationsStatus();
        //Only update notifications if the socket disconnects at least once
        if (!isFirstConnection) {
          updateUserNotifications()
            .then((res) => {
              const newNotifications = res.filter(
                (notification) =>
                  new Date(
                    notification?.time ? notification?.time : ""
                  ).getTime() > lastNotificationsUpdate
              );

              if (newNotifications?.length) {
                setEventHandlers((eventHandlers) => {
                  for (let i = 0; i < newNotifications.length; i++) {
                    const isPaymentNotification =
                      newNotifications[i]?.data?.asaasPaymentId;
                    if (
                      eventHandlers["payment"]?.length &&
                      isPaymentNotification
                    ) {
                      const handlers = eventHandlers["payment"];
                      for (let i = 0; i < handlers.length; i++) {
                        handlers[i]({
                          room: user._id,
                          project: null,
                          notification: newNotifications[i],
                        });
                      }
                    } else {
                      handleProject(eventHandlers, {
                        room: user._id,
                        project: newNotifications[i].project,
                        notification: newNotifications[i],
                      });
                    }
                  }
                  Store.setNotificationsStatus(false, new Date().getTime());

                  socket.emit("notification_room", {
                    room: `${user._id}`,
                  });

                  return eventHandlers;
                });
              } else {
                Store.setNotificationsStatus(false, new Date().getTime());

                socket.emit("notification_room", {
                  room: `${user._id}`,
                });
              }
            })
            .catch(console.log);
        } else {
          Store.setNotificationsStatus(false, new Date().getTime());
          socket.emit("notification_room", {
            room: `${user._id}`,
          });
        }
      });

      socket.on("notification", (res) => {
        const isValid = res.room === user._id && res.notification;
        const isPaymentNotification =
          isValid && res.notification?.data?.asaasPaymentId;
        const isProject = isValid && res.notification?.project;

        setEventHandlers((eventHandlers) => {
          //If received a payment notification, and the pix component is opened, execute only 'payments' handlers.
          if (isPaymentNotification) {
            if (eventHandlers["payment"]?.length) {
              const handlers = eventHandlers["payment"];
              for (let i = 0; i < handlers.length; i++) {
                handlers[i](res);
              }
            } else {
              handleProject(eventHandlers, res);
            }
            handleNotification(eventHandlers, res);
          } else if (isProject) {
            handleProject(eventHandlers, res);
            handleNotification(eventHandlers, res);
          }
          return eventHandlers;
        });
      });

      return () => {
        socket.off("connect");
        socket.off("notification");
      };
    }
  }, [user]);

  function handleProject(eventHandlers, notification) {
    if (eventHandlers["project"]?.length) {
      const handlers = eventHandlers["project"];
      for (let i = 0; i < handlers.length; i++) {
        handlers[i](notification);
      }
    }
  }

  function handleNotification(eventHandlers, notification) {
    if (eventHandlers["notification"]?.length) {
      const handlers = eventHandlers["notification"];
      for (let i = 0; i < handlers.length; i++) {
        handlers[i](notification);
      }
    }
  }

  function registerEventHandler(name, fn) {
    setEventHandlers((eventHandlers) => {
      const handlers = eventHandlers[name];
      if (handlers && handlers.length) {
        return {
          ...eventHandlers,
          [name]: [...eventHandlers[name], fn],
        };
      } else {
        return {
          ...eventHandlers,
          [name]: [fn],
        };
      }
    });
  }

  function removeEventHandler(name, fn) {
    setEventHandlers((eventHandlers) => {
      if (eventHandlers[name]?.length) {
        eventHandlers[name] = eventHandlers[name].filter((f) => f != fn);
        return { ...eventHandlers };
      }
      return eventHandlers;
    });
  }

  return (
    <SocketIoContext.Provider
      value={{
        socket,
        registerEventHandler,
        removeEventHandler,
      }}
    >
      {children}
    </SocketIoContext.Provider>
  );
};
