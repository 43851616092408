import React from "react";
// import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import classNames from "classnames";
import Loader from "react-loader-spinner";
import { Row, Col } from "shards-react";

import { loadPictureInMemory } from "../../services/file";
import AspectRatioPicture from "../common/AspectRatioPicture";

function DropzoneFiles({ onChange, isLoading, isMultiple, items = [] }) {
  const renderedFiles = items.reduce((acc, file, i) => {
    if (i % 3 === 0) {
      acc.push([file]);
    } else {
      acc[acc.length - 1].push(file);
    }
    return acc;
  }, []);

  return (
    <>
      <div className="d-flex flex-row justify-content-center align-items-center clickable">
        <Dropzone
          multiple={isMultiple}
          disabled={isLoading}
          onDrop={async (acceptedFiles) => {
            const newFiles = await loadPictureInMemory(acceptedFiles);
            onChange([...items, ...newFiles]);
          }}
        >
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div
                {...getRootProps()}
                className={classNames("dropzone", {
                  "dropzone--isActive": isDragActive,
                })}
              >
                {/* TODO */}

                {/* eslint-disable-next-line */}
                {false ? (
                  <Loader
                    width="15px"
                    height="15px"
                    type="Rings"
                    color="black"
                  />
                ) : (
                  <>
                    <input {...getInputProps()} />
                    <span>
                      {isDragActive
                        ? "Arrasta e solte seus arquivos aqui"
                        : "Clique aqui para fazer um upload"}
                    </span>
                  </>
                )}
              </div>
            );
          }}
        </Dropzone>
      </div>

      <div>
        {renderedFiles.map((group, i) => {
          return (
            <Row key={i}>
              <Col
                xs="12"
                className="d-flex flex-row justify-content-center align-items-center mt-3"
              >
                {group.map((img, j) => {
                  return (
                    <div key={j} className="ml-1 mr-1">
                      <AspectRatioPicture
                        height="200px"
                        width="200px"
                        src={img.link || img.uri}
                        name={img.name || img.file.name}
                        title={img.name || img.file.name}
                        onDeleteClick={() => {
                          if (img.file) {
                            const index = i * 3 + j;
                            let newList = items.filter(
                              (file, i) => i !== index
                            );
                            onChange([...newList]);
                          } else {
                            let newList = items.filter(
                              (item) => item.link !== img.link
                            );

                            onChange([...newList]);
                          }
                        }}
                      />
                    </div>
                  );
                })}
              </Col>
            </Row>
          );
        })}
      </div>
    </>
  );
}

// DropzonePicture.propTypes = {};

export default DropzoneFiles;
