import cookie from "js-cookie";
import { API } from "../config";
import { requestWrapper } from "../utils/api";
import qs from "query-string";

export const validateTokenTokStok = (token) => {
  return fetch(`${API}/validate/${token}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const validateAdminAccessToken = (token) => {
  return fetch(`${API}/validate/admin-access/${token}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ token: token }),
  })
    .then((response) => {
      console.log(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const validateTokenTokStokDev = (token) => {
  return fetch(`${API}/validate/dev/${token}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      console.log(response.json());
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const signup = (user) => {
  console.log(user);
  return fetch(`${API}/signup`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json();
    })
    .then((res) => {
      if (res.error) {
        throw new Error(res.error);
      }
      return res;
    });
};

export const signin = (user, company) => {
  return requestWrapper(
    fetch(`${API}/signin?company=${company}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    }),
    [400, 401]
  );
};

export const signinGoogle = (token, company) => {
  return requestWrapper(
    fetch(`${API}/signin_google?company=${company}`, {
      method: "POST",
      credentials: "include",
      redirect: "follow",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token }),
    }),
    [400, 401]
  );
};

export const signinFacebook = (obj, company) => {
  return requestWrapper(
    fetch(`${API}/signin_facebook?company=${company}`, {
      method: "POST",
      credentials: "include",
      redirect: "follow",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    }),
    [400, 401]
  );
};

export const localSignout = () => {
  removeCookie("token");
  removeLocalStorage("user");
  removeLocalStorage("isAdminAccess");
};

export const signout = () => {
  localSignout();

  return fetch(`${API}/signout`, {
    method: "GET",
  });
};

// set cookie
export const setCookie = (key, value) => {
  cookie.set(key, value, {
    expires: 10,
  });
};

export const removeCookie = (key) => {
  cookie.remove(key, {
    expires: 1,
  });
};
// get cookie
export const getCookie = (key) => {
  return cookie.get(key);
};
// localstorage
export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorage = (key) => {
  return localStorage.getItem(key);
};

export const removeLocalStorage = (key) => {
  localStorage.removeItem(key);
};
// autheticate user by pass data to cookie and localstorage
export const authenticate = (data, next, isAdminAccess = false) => {
  setCookie("token", data.token);
  setLocalStorage("user", data.user);
  isAdminAccess && setLocalStorage("isAdminAccess", isAdminAccess);
  next();
};

export const isAuth = () => {
  const cookieChecked = getCookie("token");
  if (cookieChecked) {
    if (localStorage.getItem("user")) {
      return JSON.parse(localStorage.getItem("user"));
    } else {
      return false;
    }
  }
};

export const forgotPassword = (email, company) => {
  return fetch(`${API}/forgot`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, company }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const resetPassword = (resetInfo) => {
  return fetch(`${API}/reset/${resetInfo.token}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      password: resetInfo.password,
      confirm: resetInfo.confirmPassword,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};
export const resetUserPassword = (resetInfo) => {
  return fetch(`${API}/user/${resetInfo.id}/reset-password`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      password: resetInfo.password,
      confirm: resetInfo.confirmPassword,
      currentPassword: resetInfo.currentPassword,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getQueryStringValue = (
  key,
  queryString = window.location.search
) => {
  const values = qs.parse(queryString);
  return values[key];
};

export const getAuthenticatedUser = () => {
  const cookieChecked = getCookie("token");
  const stringifyedUser = localStorage.getItem("user");
  if (cookieChecked && stringifyedUser) {
    return JSON.parse(stringifyedUser);
  }
  return null;
};

export const getAuthenticatedUserV2 = () => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/user`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const authMiddleware = (res) => {
  if (res && res.status === 401) {
    signout().then(() => window.location.reload());
    return Promise.resolve(null);
  }
  return res;
};

export const authWithToken = (token) => {
  return requestWrapper(
    fetch(`${API}/auth_token`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token }),
    })
  );
};

export const getNotifications = () => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/user/notifications`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};
