import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import ExternalRedirect from "../ExternalRedirect";

import { AuthContext } from "./AuthContext";

const PrivateRoute = ({ route, index, component, redirectPath }) => {
  const authContext = useContext(AuthContext);

  return authContext.authenticated ? (
    <Route
      key={index}
      path={route.path}
      exact={route.exact}
      component={component}
    />
  ) : redirectPath ? (
    <ExternalRedirect path={redirectPath} />
  ) : (
    <Redirect to="/login" />
  );
};

export default PrivateRoute;
