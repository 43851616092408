import React from "react";
import colors from "../../utils/colors";

const CollapseButton = ({ collapsed, onClick, theme }) => {
  return (
    <div className="col-12 col-sm-4">
      <button
        onClick={onClick}
        aria-controls="example-collapse-texts"
        aria-expanded={collapsed}
        style={{
          backgroundColor: "transparent",
          padding: "0px",
          border: "none",
          color: "#3D5170",
          marginRight: "8px",
          display: "flex",
          fontSize: "1.5rem",
        }}
      >
        <i className="material-icons">
          {collapsed ? "keyboard_arrow_down" : "keyboard_arrow_right"}
        </i>
        <div>Finalizados</div>
      </button>
    </div>
  );
};

export default CollapseButton;
