import React, { Component, useEffect, useContext } from "react";
import GoogleAnalytics from "react-ga";

import TagManager from "react-gtm-module";
import { useHistory } from "react-router-dom";
import { getLocalStorage } from "./actions/auth";
import { TAG_MANAGER, GA4 } from "./config";
import { SystemContext } from "./components/SystemConfig/SystemContext";

GoogleAnalytics.initialize(process.env.REACT_APP_GAID || "UA-102113790-1");
GoogleAnalytics.ga("create", "UA-102113790-1", "auto", {
  name: "LaDecoraPlataforma",
});

const withTracker = (WrappedComponent, options = {}) => {
  const { company } = useContext(SystemContext);

  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      if (window.scrollY !== 0) {
        window.scrollTo(0, 0);
      }
    });
    return () => {
      unlisten();
    };
  }, []);

  const trackPage = (page) => {
    if (process.env.NODE_ENV !== "production") {
      return;
    }

    if (company && company.tracking && company.tracking.tagManager) {
      const tagManagerArgs = {
        gtmId: company.tracking.tagManager,
      };
      TagManager.initialize(tagManagerArgs);
    }

    GoogleAnalytics.set({
      page,
      ...options,
    });
    GoogleAnalytics.pageview(page);

    GoogleAnalytics.ga("LaDecoraPlataforma.send", "pageview", { page: page });

    // const gaId = getCookie("_ga");

    const user = JSON.parse(getLocalStorage("user"));
    const tagManagerArgs = {
      gtmId: TAG_MANAGER,
      dataLayer: {
        dimension1: user && user._id ? user._id : undefined,
        dimension2: GA4,
        dimension3: TAG_MANAGER,
      },
    };

    TagManager.initialize(tagManagerArgs);
  };

  const BASENAME = process.env.REACT_APP_BASENAME || "";

  // eslint-disable-next-line
  const HOC = class extends Component {
    componentDidMount() {
      // eslint-disable-next-line
      const page = this.props.location.pathname + this.props.location.search;
      trackPage(`${BASENAME}${page}`);
    }

    componentDidUpdate(prevProps) {
      const currentPage =
        prevProps.location.pathname + prevProps.location.search;
      const nextPage =
        this.props.location.pathname + this.props.location.search;

      if (currentPage !== nextPage) {
        trackPage(`${BASENAME}${nextPage}`);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
};

export default withTracker;
