import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "shards-react";
import { download } from "../../../utils/util";
import AspectRatioPicture from "../../common/AspectRatioPicture";
import DropzoneArea from "../../file-manager-cards/DropzoneArea";
import { BUCKET_URL } from "../../../config";
import { deleteFile } from "../../../services/file";
const Upload = (props) => {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    update(files, undefined, files);
  }, [files]);

  const update = (answer, score, input) => {
    props.update({
      question: props.node.node.title,
      answer: answer,
      score,
      input,
      goToNextStep: !Array.isArray(answer),
    });
  };

  const onUpload = (newFiles) => {
    newFiles = files.concat(newFiles);
    props.node.node.input = newFiles;

    setFiles(newFiles);
  };

  return (
    <div>
      <label>{props.node.node.title}</label>
      <Card small className="mb-3">
        <DropzoneArea
          projectId={props.projectId}
          callbackHandleDrop={onUpload}
        />
      </Card>
      {files?.length ? (
        <div>
          Arquivos enviados: <b>{files.length}</b>
        </div>
      ) : null}
      {files && files.length > 0 ? (
        <div className="user-details__user-data border-top border-bottom p-4">
          <Row>
            <Col>
              <h6 className="text-center m-0 mt-2">Arquivos</h6>
            </Col>
          </Row>
          <Row>
            {files.map((img, idx) => {
              return (
                <Col lg="3" sm="6" key={idx}>
                  <Card className="card-file">
                    <AspectRatioPicture
                      height="200px"
                      width="200px"
                      isDeletable={true}
                      src={`${BUCKET_URL}/${img.source}`}
                      thumbnail={
                        img.thumbnail ? `${BUCKET_URL}/${img.thumbnail}` : null
                      }
                      name={img.name}
                      title={img.name}
                      dowloadPdf={() => {
                        download(`${BUCKET_URL}/${img.source}`);
                      }}
                      onDeleteClick={() => {
                        setFiles(
                          files.filter((f, i) => {
                            const shouldDelete = i === idx;
                            shouldDelete &&
                              deleteFile(f.source) &&
                              deleteFile(f.thumbnail);
                            return !shouldDelete;
                          })
                        );
                      }}
                    />
                  </Card>
                </Col>
              );
            })}
          </Row>
        </div>
      ) : null}
    </div>
  );
};
export default Upload;
