import React, { useState } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { Button } from "shards-react";
import Loader from "react-loader-spinner";
import Lightbox from "@ladecora/react-image-lightbox";

function AspectRatioPicture({
  src,
  thumbnail,
  height,
  width,
  name,
  title,
  onDeleteClick,
  onImageView,
  isLoading,
  isUploading,
  isDeletable,
  fullScreenView,
  dowloadPdf,
}) {
  const link = thumbnail || src;
  const isPicture = name
    ? name.toLowerCase().match("(.jpeg|.png|.jpg|.jfif|.heic|.gif|.webp)")
    : "false";
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);

  return (
    <>
      {fullScreenView && isLightboxOpen
        ? ReactDOM.createPortal(
            <Lightbox
              mainSrc={src}
              reactModalStyle={{ overlay: { zIndex: 10000 } }}
              onCloseRequest={() => {
                setIsLightboxOpen(false);
                onImageView(true);
              }}
            />,
            document.body
          )
        : null}

      <div>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            borderTopLeftRadius: "0.625rem",
            borderTopRightRadius: "0.625rem",
            position: "relative",
            width: width,
            height: height,
            padding: "2px",
          }}
        >
          {isUploading ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                backgroundColor: "grey",
                opacity: "0.7",
              }}
            >
              <Loader width="60px" height="60px" type="Rings" color="black" />
            </div>
          ) : null}
          {isPicture ? (
            <div
              onKeyDown={() => {
                //TODO
              }}
              role="button"
              tabIndex="0"
              alt=""
              className="ml-auto mr-auto clickable div-image"
              onClick={() => {
                if (!fullScreenView) return;
                onImageView(false);
                setIsLightboxOpen(true);
              }}
              style={{
                top: 0,
                backgroundImage: `url("${
                  isPicture
                    ? link
                    : name.toLowerCase().match("(.pdf)")
                    ? require("../../images/pdf.png")
                    : require("../../images/file.png")
                }")`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center center",
                height: isPicture ? "100%" : "64px",
                width: isPicture ? "100%" : "64px",
              }}
            />
          ) : (
            <div
              onKeyDown={() => {
                //TODO
              }}
              role="button"
              tabIndex="0"
              alt=""
              className="ml-auto mr-auto"
              onClick={() => {
                dowloadPdf();
              }}
              style={{
                top: 0,
                cursor: "pointer",
                backgroundImage: `url("${
                  name.toLowerCase().match("(.pdf)")
                    ? require("../../images/pdf.png")
                    : require("../../images/file.png")
                }")`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundPosition: "center center",
                height: "64px",
                width: "64px",
              }}
            />
          )}
        </div>
        {title ? (
          <div style={{ textAlign: "center" }}>
            <span
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "inline-block",
                maxWidth: "180px",
                width: width,
              }}
            >
              {title}
            </span>
          </div>
        ) : null}

        {isDeletable ? (
          <div>
            <Button
              className="w-100"
              theme="danger"
              onClick={onDeleteClick}
              disabled={isLoading || isUploading}
              type="button"
            >
              Excluir
            </Button>
          </div>
        ) : null}
      </div>
    </>
  );
}

AspectRatioPicture.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  alt: PropTypes.string,
  onDeleteClick: PropTypes.func,
  isLoading: PropTypes.bool,
  isUploading: PropTypes.bool,
  name: PropTypes.string,
  title: PropTypes.string,
  isDeletable: PropTypes.bool,
  onImageView: PropTypes.func,
  fullScreenView: PropTypes.bool,
  dowloadPdf: PropTypes.func,
};

AspectRatioPicture.defaultProps = {
  isDeletable: true,
  isLoading: false,
  isUploading: false,
  fullScreenView: true,
  onImageView: () => {},
};

export default AspectRatioPicture;
