/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { useState, useContext } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  FormInput,
  Button,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "shards-react";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import {
  valida_cpf_cnpj,
  required,
  emailValidation,
  phoneValidation,
  equalsTo,
} from "../utils/form_validation";
import { getQueryStringValue } from "../actions/auth";

import CpfCnpj from "../components/common/CpfCnpj";
import PhoneBR from "../components/common/PhoneBR";
import { signup, authenticate, isAuth } from "../actions/auth";

import { SystemContext } from "../components/SystemConfig/SystemContext";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Logo from "../components/common/Logo";
import ShowHidePasswordInput from "../components/common/ShowHidePasswordInput";

function Register({ setShowRegisteComponent }) {
  const { getCompanyLabel } = useContext(SystemContext);
  const [success, setSuccess] = useState(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    termsUse: false,
    firstName: "",
    lastName: "",
    document: "",
    documentType: "",
    phone: "",
  });

  const [loading, setLoading] = useState(false);
  const {
    email,
    firstName,
    lastName,
    document,
    phone,
    password,
    confirmPassword,
  } = values;

  const [formValidations, setFormValidations] = useState({
    firstName: { validators: [required], isValid: true },
    lastName: { validators: [required], isValid: true },
    email: { validators: [required, emailValidation], isValid: true },
    document: { validators: [required, valida_cpf_cnpj], isValid: true },
    documentType: { validators: [required], isValid: true },
    phone: { validators: [required, phoneValidation], isValid: true },
    password: {
      validators: [required, equalsTo("confirmPassword")],
      isValid: true,
      postValidate: (isValid) => {
        formValidations.confirmPassword.isValid = isValid;
        formValidations.confirmPassword.error = !isValid;
        setFormValidations({ ...formValidations });
      },
    },
    confirmPassword: {
      validators: [required, equalsTo("password")],
      isValid: true,
      postValidate: (isValid) => {
        formValidations.password.isValid = isValid;
        formValidations.password.error = !isValid;
        setFormValidations({ ...formValidations });
      },
    },
  });

  const onSubmit = (e) => {
    e.preventDefault();
    validate();

    if (isFormValid()) {
      setLoading(true);
      signup({
        ...values,
        email: values.email.toLowerCase(),
        document: clear(values.document),
        phone: `+55${clear(values.phone)}`,
        company: getCompanyLabel(),
      })
        .then((res) => {
          setTimeout(() => {
            authenticate(res, () => {
              if (isAuth()) {
                if (validateUrl()) {
                  window.open(`/checkout${window.location.search}`, "_self");
                } else {
                  window.open(`/environments`, "_self");
                }
              }
            });
          }, 2000);

          setLoading(false);
          setSuccess(true);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          toast.error(err.message, {
            position: "top-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
    return false;
  };

  const handleChange = (name) => (e) => {
    setValues({ ...values, [name]: e.target.value });
    if (isInputValid(name, e.target.value)) {
      formValidations[name].error = false;
    } else {
      formValidations[name].error = true;
    }
    setFormValidations({ ...formValidations });
  };

  function isInputValid(field, value) {
    let fieldValidations = formValidations[field].validators;
    let result = fieldValidations.reduce((isValid, nextValidation) => {
      return isValid && nextValidation(value, values);
    }, true);
    if (formValidations[field].postValidate) {
      formValidations[field].postValidate(result);
    }
    return result;
  }

  function isFieldValid(field) {
    let value = values[field];
    let fieldValidations = formValidations[field].validators;
    let result = fieldValidations.reduce((isValid, nextValidation) => {
      return isValid && nextValidation(value, values);
    }, true);
    return result;
  }

  function isFormValid() {
    let keys = Object.keys(formValidations);
    return keys.reduce((isValid, key) => {
      return isValid && formValidations[key].isValid;
    }, true);
  }

  function validate() {
    let fieldNames = Object.keys(formValidations);
    let validations = fieldNames.reduce((acc, current) => {
      acc[current] = isFieldValid(current);
      return acc;
    }, {});
    applyValidationsToForm(validations);
  }

  function applyValidationsToForm(validations) {
    let keys = Object.keys(formValidations);
    keys.forEach((key) => {
      formValidations[key].isValid = validations[key];
      if (!validations[key]) {
        formValidations[key].error = true;
      }
    });
    setValues({
      ...values,
    });
  }

  function getHtmlValidations(key) {
    return typeof formValidations[key].error === "undefined"
      ? { neutral: "true" }
      : {
          [formValidations[key].error ? "invalid" : "valid"]: true,
        };
  }

  const getInputlValidations = (key) => {
    return formValidations[key].error === undefined
      ? ""
      : formValidations[key].error
      ? "is-invalid"
      : "is-valid";
  };

  function clear(value) {
    return value.replace(/[^0-9]/g, "");
  }

  const [modalTerms, setModalTerms] = useState(false);

  const toggleModalTerms = (e) => {
    e && e.preventDefault();
    setModalTerms(!modalTerms);
    return false;
  };

  function validateUrl() {
    const projectTemplatesQtd = getQueryStringValue("qty");
    let projectTemplatesHashes = getQueryStringValue("environment");

    if (typeof projectTemplatesHashes == "string") {
      projectTemplatesHashes = [projectTemplatesHashes];
    }

    return (
      projectTemplatesHashes &&
      projectTemplatesHashes.length > 0 &&
      projectTemplatesQtd &&
      projectTemplatesHashes.length === projectTemplatesQtd.length
    );
  }

  return (
    <Container
      fluid
      className="main-content-container h-100  pt-5"
      style={{
        paddingLeft: "0px",
        paddingRight: "0px",
      }}
    >
      <ToastContainer />
      <Row noGutters className="h-100">
        <Col lg="6" md="12" className="auth-form mx-auto my-auto">
          <Card>
            <CardBody>
              {/* Logo */}
              {setShowRegisteComponent ? null : (
                <Logo
                  props={{
                    style: {
                      padding: "30px",
                      paddingBottom: "20px",
                      maxWidth: "215px",
                    },
                    className: "auth-form__logo d-table mx-auto mb-3",
                    alt: "La Decora - Register Template",
                  }}
                  defaultProps={{
                    style: {
                      // backgroundColor: company.color,
                      padding: "30px",
                      paddingBottom: "20px",
                      maxWidth: "215px",
                    },
                    className: "auth-form__logo d-table mx-auto mb-3",
                    alt: "La Decora - Register Template",
                  }}
                />
              )}
              {/* <img
                style={{
                  backgroundColor: company.color,
                  padding: "30px",
                  paddingBottom: "20px",
                  maxWidth: "215px",
                }}
                className="auth-form__logo d-table mx-auto mb-3"
                src={require("../images/La_Decora_principal.jpeg")}
                alt="La Decora - Register Template"
              /> */}

              {/* Title */}
              <h5 className="auth-form__title text-center mb-4">
                Criar uma nova conta
              </h5>

              {/* Form Fields */}
              <Form onSubmit={onSubmit}>
                <FormGroup>
                  <FormInput
                    required
                    type="email"
                    placeholder="Informe seu email"
                    autoComplete="email"
                    value={email}
                    style={{ textTransform: "lowercase" }}
                    onChange={handleChange("email")}
                    {...getHtmlValidations("email")}
                  />
                  <FormFeedback valid />
                  <FormFeedback invalid={"true"}>Email inválido</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <ShowHidePasswordInput
                    name="password"
                    placeholder="Senha"
                    value={password}
                    handleChange={handleChange("password")}
                    getInputlValidations={getInputlValidations}
                  />
                  <FormFeedback valid />
                  <FormFeedback invalid="true">
                    As senhas devem ser iguais
                  </FormFeedback>
                </FormGroup>
                <FormGroup>
                  <ShowHidePasswordInput
                    name="password"
                    placeholder="Confirmar senha"
                    value={confirmPassword}
                    handleChange={handleChange("confirmPassword")}
                    getInputlValidations={getInputlValidations}
                  />
                  <FormFeedback valid />
                  <FormFeedback invalid="true">
                    As senhas devem ser iguais
                  </FormFeedback>
                </FormGroup>
                <Row form className="form-group">
                  <Col md="6" className="form-group">
                    <FormInput
                      id="register-first-name-input"
                      required
                      type="text"
                      placeholder="Informe seu nome"
                      autoComplete="firstName"
                      value={firstName}
                      onChange={handleChange("firstName")}
                      {...getHtmlValidations("firstName")}
                    />
                    <FormFeedback valid />
                    <FormFeedback invalid="true">Nome inválido</FormFeedback>
                  </Col>
                  <Col md="6">
                    <FormInput
                      id="register-last-name-input"
                      required
                      type="text"
                      placeholder="Digite seu sobrenome"
                      autoComplete="lastName"
                      value={lastName}
                      onChange={handleChange("lastName")}
                      {...getHtmlValidations("lastName")}
                    />
                    <FormFeedback valid />
                    <FormFeedback invalid="true">
                      Sobrenome inválido
                    </FormFeedback>
                  </Col>
                </Row>

                <FormGroup>
                  <CpfCnpj
                    id="register-cpf-cnpj-input"
                    required
                    value={document}
                    onChange={(document) => {
                      setValues({
                        ...values,
                        document: document.value,
                        documentType: document.type,
                      });
                      if (isInputValid("document", document.value)) {
                        formValidations.document.error = false;
                      } else {
                        formValidations.document.error = true;
                      }
                      setFormValidations({ ...formValidations });
                    }}
                    placeholder="Informe seu CPF/CNPJ"
                    autoComplete="document"
                    className="form-control"
                    {...getHtmlValidations("document")}
                  />
                  <FormFeedback valid />
                  <FormFeedback invalid="true">CPF/CNPJ inválido</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <PhoneBR
                    id="register-phone-input"
                    required
                    value={phone}
                    onChange={(phone) => {
                      setValues({
                        ...values,
                        phone: phone,
                      });
                      if (isInputValid("phone", phone)) {
                        formValidations.phone.error = false;
                      } else {
                        formValidations.phone.error = true;
                      }
                      setFormValidations({ ...formValidations });
                    }}
                    placeholder="Informe seu telefone"
                    autoComplete="phone"
                    name="phone"
                    className="form-control"
                    {...getHtmlValidations("phone")}
                  />
                  <FormFeedback valid />
                  <FormFeedback invalid="true">Telefone inválido</FormFeedback>
                </FormGroup>

                <div>
                  {/*  eslint-disable */}
                  Ao fazer seu cadastro, você concorda com os{" "}
                  <a onClick={toggleModalTerms} href="" tabIndex="-1">
                    Termos de Uso e Política de Privacidade
                  </a>
                  .{/*  eslint-disable */}
                </div>

                <Button
                  theme="accent"
                  className="d-table mx-auto mt-4"
                  type="submit"
                  size="lg"
                  disabled={loading}
                >
                  {loading && (
                    <Loader
                      width="30px"
                      height="15px"
                      type="Rings"
                      color="#ffffff"
                    />
                  )}
                  {!loading && "Criar Conta"}
                </Button>
                <div
                  className="auth-form__meta  mt-4"
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {setShowRegisteComponent ? (
                    <Button
                      onClick={() => {
                        setShowRegisteComponent(false);
                      }}
                      theme="accent"
                      outline
                      className="d-table mx-auto"
                      type="submit"
                      size="sm"
                      disabled={loading}
                    >
                      Login
                    </Button>
                  ) : (
                    <Link
                      to="/login"
                      style={{ fontSize: "16px", color: "#1b3539" }}
                      className="d-block mb-2"
                    >
                      <Button
                        theme="accent"
                        outline
                        className="d-table mx-auto"
                        type="submit"
                        size="sm"
                        disabled={loading}
                      >
                        Login
                      </Button>
                    </Link>
                  )}
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal open={success} animation toggle={() => {}}>
        <ModalHeader toggle={() => {}}>
          <span role="img" aria-label="hey">
            👋
          </span>{" "}
          Seja bem-vindo!
        </ModalHeader>
        <ModalBody>Você será redirecionado para o sistema</ModalBody>
      </Modal>

      <Modal
        backdrop={true}
        size="lg"
        open={modalTerms}
        animation
        toggle={toggleModalTerms}
      >
        <ModalHeader toggle={toggleModalTerms}>
          Termos de Uso e Política de Privacidade
        </ModalHeader>
        <ModalBody style={{ maxHeight: "60vh", overflow: "auto" }}>
          <h4>Termos de Uso</h4>
          <embed
            width="100%"
            height="400"
            src={`https://s3.amazonaws.com/ladecora.platform/terms/TERMOS_DE_USO.pdf#toolbar=0`}
          />
          <h4>Política de Privacidade</h4>
          <embed
            width="100%"
            height="400"
            src={`https://s3.amazonaws.com/ladecora.platform/terms/POLITICA_DE_PRIVACIDADE.pdf#toolbar=0`}
          />
        </ModalBody>
        <ModalFooter>
          <Button size="lg" theme="accent" onClick={toggleModalTerms}>
            Aceito
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
}

export default Register;
