import { API } from "../config";
import { getCookie } from "../actions/auth";

import { requestWrapper } from "../utils/api";

export const getAllVouchers = () => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/voucher/all`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const getVoucher = (id) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/voucher/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const getVoucherByCode = (code) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/voucher/code/${code}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    }),
    []
  );
};
