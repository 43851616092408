import React from "react";
import { QrCodeI, QrCodeStatusEnum } from "./types";
import { Button } from "shards-react";
import { toast } from "react-toastify";
import { Clipboard } from "../../services/clipboard";
import BounceLoader from "react-spinners/BounceLoader";

interface QrCodeCompI {
  qrCode: QrCodeI | undefined;
}

const QrCodeComp: React.FC<QrCodeCompI> = ({ qrCode }) => {
  async function onCopyPixCode() {
    Clipboard.copy(qrCode?.payload);
    toast.success("Código copiado com sucesso!");
  }
  return (
    <>
      <div className="text-center" style={{ fontSize: "26px" }}>
        {qrCode?.status === QrCodeStatusEnum.DONE ? (
          <div style={{ color: "green" }}>Pagamento realizado com sucesso!</div>
        ) : null}
        {qrCode?.status === QrCodeStatusEnum.WAITING ? (
          <div>Aguardando pagamento</div>
        ) : null}
        {qrCode?.status === QrCodeStatusEnum.GENERATING ? (
          <div>Gerando QRCode...</div>
        ) : null}
      </div>
      <div className="d-flex align-items-center justify-content-center flex-column">
        {qrCode?.encodedImage ? (
          <img width="250px" height="250px" src={qrCode?.encodedImage} alt="" />
        ) : null}
        {qrCode?.status === QrCodeStatusEnum.DONE ? <BounceLoader /> : null}
        {qrCode?.payload ? (
          <Button
            theme="white"
            className="mb-3"
            style={{ fontSize: "18px" }}
            onClick={onCopyPixCode}
          >
            Copiar Código
          </Button>
        ) : null}
      </div>
    </>
  );
};

export default QrCodeComp;
