import { useCallback, useState } from "react";
import ReactDOM from "react-dom";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalHeader } from "shards-react";
import imgSelvvva from "../images/selvvva.png";
import imgDwood from "../images/dwood.png";
import imgMoriah from "../images/moriah.png";
import imgPersianet from "../images/persianet.png";

import { Clipboard } from "./clipboard";

const useVouchersModal = () => {
  const vouchers = [
    {
      link: "https://s.afilio.com.br/?id=63d7f5139535b500016501f7",
      logo: {
        link: "https://s3.amazonaws.com/ladecora.platform/images/tok%26stok.png",

        maxWidth: "200px",
      },
      name: "PRODECO7",
      discount: "7%",
    },
    {
      link: "https://www.sunhouse.com.br/",
      logo: {
        link: "https://s3.amazonaws.com/ladecora.platform/whitelabel/sunhousedecora/logo?v=1687976231656",
        maxWidth: "170px",
      },
      name: "PRODECO8",
      discount: "8%",
    },
    {
      link: "https://www.kzabela.com.br",
      logo: {
        link: "https://s3.amazonaws.com/ladecora.platform/whitelabel/kzabeladecora/logo?v=1687976257485",
        maxWidth: "170px",
      },
      name: "PRODECO10",
    },
    {
      link: "https://www.amofadas.com/",
      logo: {
        link: "https://s3.amazonaws.com/ladecora.platform/whitelabel/amofadasdecora/logo?v=1687975515128",
        maxWidth: "170px",
      },
      name: "PRODECO10",
    },
    {
      link: "https://www.moriahtapetes.com.br/",
      logo: {
        link: imgMoriah,
        maxWidth: "170px",
      },
      name: "LADECORA",
      discount: "12%",
    },
    {
      link: "https://www.madesa.com/",

      logo: {
        link: "https://s3.amazonaws.com/ladecora.platform/images/madesa.png",
        maxWidth: "170px",
      },
      name: "ARQUI",
    },
    {
      link: "https://www.ammoquadros.com.br/",
      logo: {
        link: "https://s3.amazonaws.com/ladecora.platform/images/ammo_quadros.png",
        maxWidth: "130px",
      },
      name: "PRODECO10",
    },
    {
      link: "https://moveismoveu.com/",
      logo: {
        link: "https://s3.amazonaws.com/ladecora.platform/images/moveu.png",
        maxWidth: "130px",
      },
      name: "PRODECO10",
    },

    {
      link: "https://www.selvvva.com/",
      logo: {
        link: imgSelvvva,
        maxWidth: "80px",
      },
      name: "PRODECO10",
    },
    {
      link: "https://www.persianet.com.br/",
      logo: {
        link: imgPersianet,
        maxWidth: "170px",
      },
      name: "PRODECO5",
      discount: "5%",
    },
    {
      link: "https://www.instagram.com/dwoodbrasil/",
      logo: {
        link: imgDwood,
        maxWidth: "80px",
      },
      name: "PRODECO10",
    },
  ];

  const [showVouchersModal, setShowVouchersModal] = useState(false);

  const copyToClipboard = useCallback((name) => {
    Clipboard.copy(name);
    toast.success("Cupom copiado!", {
      position: "top-center",
      autoClose: 1200,
    });
  }, []);

  function toggleVouchersModal() {
    if (!showVouchersModal) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    setShowVouchersModal(!showVouchersModal);
  }

  function goTo(link) {
    return () => window.open(link, "_blank");
  }

  const Component = ReactDOM.createPortal(
    <Modal
      open={showVouchersModal}
      animation={true}
      toggle={toggleVouchersModal}
      size="lg"
      centered
    >
      <ModalHeader
        toggle={toggleVouchersModal}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Garanta nossos cupons exclusivos
      </ModalHeader>
      <ModalBody style={{ padding: "5px" }}>
        <div className="vouchers-modal">
          {vouchers.map((voucher, i) => {
            const onCopyToClipboardClick = () => copyToClipboard(voucher.name);
            const discount = voucher.discount ? voucher.discount : "10%";
            return (
              <div key={i}>
                <div>
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td>
                          <div>
                            <center>
                              <div>
                                <h5>Válido para compras no site</h5>
                                <button
                                  className="transparent"
                                  onClick={goTo(voucher.link)}
                                >
                                  <img
                                    src={voucher.logo.link}
                                    alt=""
                                    style={{ maxWidth: voucher.logo.maxWidth }}
                                  />
                                </button>
                                <div className="mb-2">
                                  {discount} de desconto em produtos
                                </div>
                              </div>

                              <div>
                                <div style={{ fontSize: "1.2rem" }}>
                                  Utilize o cupom
                                </div>
                                <div>
                                  <button
                                    className="transparent"
                                    onClick={onCopyToClipboardClick}
                                  >
                                    <h3 className="voucher-text">
                                      <i
                                        style={{ fontSize: "1.2rem" }}
                                        className="material-icons mr-2"
                                      >
                                        content_copy
                                      </i>
                                      <span>{voucher.name}</span>
                                    </h3>
                                  </button>
                                </div>
                              </div>
                            </center>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <hr />
              </div>
            );
          })}
        </div>
      </ModalBody>
    </Modal>,
    document.body
  );

  return { Component, showVouchersModal, toggleVouchersModal };
};

export default useVouchersModal;
