const listCPF = [
  {
    doc: "02941757090",
  },
  {
    doc: "01805273205",
  },
  {
    doc: "30628719892",
  },
  {
    doc: "25224068843",
  },
  {
    doc: "09446590477",
  },
  {
    doc: "09777412495",
  },
  {
    doc: "78866308153",
  },
  {
    doc: "46290885049",
  },
  {
    doc: "03935720300",
  },
  {
    doc: "29456383806",
  },
  {
    doc: "35132781870",
  },
  {
    doc: "12714676820",
  },
  {
    doc: "26187048865",
  },
  {
    doc: "05134221537",
  },
  {
    doc: "03802813502",
  },
  {
    doc: "63709074568",
  },
  {
    doc: "04968058519",
  },
  {
    doc: "35678255851",
  },
  {
    doc: "02430263319",
  },
  {
    doc: "17704683829",
  },
  {
    doc: "13082232850",
  },
  {
    doc: "10218592655",
  },
  {
    doc: "40152591818",
  },
  {
    doc: "45526619818",
  },
  {
    doc: "00332479510",
  },
  {
    doc: "02812596406",
  },
  {
    doc: "27056652859",
  },
  {
    doc: "25529359825",
  },
  {
    doc: "03015415158",
  },
  {
    doc: "05725669483",
  },
  {
    doc: "89712099172",
  },
  {
    doc: "03947788479",
  },
  {
    doc: "07934985975",
  },
  {
    doc: "17748504865",
  },
  {
    doc: "34767017866",
  },
  {
    doc: "08761627666",
  },
  {
    doc: "16542756701",
  },
  {
    doc: "04020630585",
  },
  {
    doc: "03471881506",
  },
  {
    doc: "01034203398",
  },
  {
    doc: "36516184801",
  },
  {
    doc: "45085755871",
  },
  {
    doc: "09373091417",
  },
  {
    doc: "46696175830",
  },
  {
    doc: "04630952195",
  },
  {
    doc: "45402762805",
  },
  {
    doc: "08605613998",
  },
  {
    doc: "00808796836",
  },
  {
    doc: "02530811300",
  },
  {
    doc: "09102941945",
  },
  {
    doc: "11147874425",
  },
  {
    doc: "33530877883",
  },
  {
    doc: "22264955848",
  },
  {
    doc: "05708690569",
  },
  {
    doc: "40568741315",
  },
  {
    doc: "61038582326",
  },
  {
    doc: "02008586103",
  },
  {
    doc: "04390941518",
  },
  {
    doc: "05663714642",
  },
  {
    doc: "33421724873",
  },
  {
    doc: "41237274842",
  },
  {
    doc: "39233688879",
  },
  {
    doc: "14684422607",
  },
  {
    doc: "46399095867",
  },
  {
    doc: "23032156840",
  },
  {
    doc: "14202488717",
  },
  {
    doc: "43201331880",
  },
  {
    doc: "06896699507",
  },
  {
    doc: "32530086818",
  },
  {
    doc: "03058448309",
  },
  {
    doc: "09064211876",
  },
  {
    doc: "27494464816",
  },
  {
    doc: "04490139527",
  },
  {
    doc: "19988812884",
  },
  {
    doc: "34478420831",
  },
  {
    doc: "37116879897",
  },
  {
    doc: "02213669546",
  },
  {
    doc: "29786864808",
  },
  {
    doc: "17281664893",
  },
  {
    doc: "31242354875",
  },
  {
    doc: "47939477862",
  },
  {
    doc: "92261922949",
  },
  {
    doc: "36621397828",
  },
  {
    doc: "31579562809",
  },
  {
    doc: "05470989950",
  },
  {
    doc: "05850392750",
  },
  {
    doc: "16665114805",
  },
  {
    doc: "48977834880",
  },
  {
    doc: "32646443803",
  },
  {
    doc: "40319898814",
  },
  {
    doc: "69795762249",
  },
  {
    doc: "17106333875",
  },
  {
    doc: "09104930738",
  },
  {
    doc: "34313450858",
  },
  {
    doc: "36791919876",
  },
  {
    doc: "32070032817",
  },
  {
    doc: "15531324839",
  },
  {
    doc: "41205484817",
  },
  {
    doc: "10263348881",
  },
  {
    doc: "95922253387",
  },
  {
    doc: "04668280470",
  },
  {
    doc: "04637777941",
  },
  {
    doc: "39773686809",
  },
  {
    doc: "38384692831",
  },
  {
    doc: "35918688846",
  },
  {
    doc: "02754596674",
  },
  {
    doc: "89836995404",
  },
  {
    doc: "18550547875",
  },
  {
    doc: "70252636481",
  },
  {
    doc: "11853775495",
  },
  {
    doc: "46990002824",
  },
  {
    doc: "35073752813",
  },
  {
    doc: "11065719671",
  },
  {
    doc: "35799292839",
  },
  {
    doc: "08241886403",
  },
  {
    doc: "39417380893",
  },
  {
    doc: "05515643771",
  },
  {
    doc: "03194197118",
  },
  {
    doc: "40741019809",
  },
  {
    doc: "13783755646",
  },
  {
    doc: "00995574103",
  },
  {
    doc: "40272560855",
  },
  {
    doc: "36773894883",
  },
  {
    doc: "12906673650",
  },
  {
    doc: "40070305889",
  },
  {
    doc: "39155222870",
  },
  {
    doc: "05930621756",
  },
  {
    doc: "09864417916",
  },
  {
    doc: "10477336418",
  },
  {
    doc: "02186084546",
  },
  {
    doc: "01362643289",
  },
  {
    doc: "01896070175",
  },
  {
    doc: "33270675895",
  },
  {
    doc: "08716768426",
  },
  {
    doc: "05342895170",
  },
  {
    doc: "10712200630",
  },
  {
    doc: "18637825168",
  },
  {
    doc: "31285109899",
  },
  {
    doc: "04212009366",
  },
  {
    doc: "08220717467",
  },
  {
    doc: "13520755637",
  },
  {
    doc: "07457233903",
  },
  {
    doc: "40748024816",
  },
  {
    doc: "11400802610",
  },
  {
    doc: "07208835390",
  },
  {
    doc: "09159822937",
  },
  {
    doc: "37516889865",
  },
  {
    doc: "36864695883",
  },
  {
    doc: "41208445871",
  },
  {
    doc: "06886586521",
  },
  {
    doc: "42205958801",
  },
  {
    doc: "45495860870",
  },
  {
    doc: "08218700684",
  },
  {
    doc: "31648847889",
  },
  {
    doc: "52595122860",
  },
  {
    doc: "33820346899",
  },
  {
    doc: "07565082589",
  },
  {
    doc: "29351406806",
  },
  {
    doc: "07092648955",
  },
  {
    doc: "02624892281",
  },
  {
    doc: "13281749720",
  },
  {
    doc: "07268944519",
  },
  {
    doc: "49303888804",
  },
  {
    doc: "04243500339",
  },
  {
    doc: "40128614846",
  },
  {
    doc: "09798396707",
  },
  {
    doc: "01607907437",
  },
  {
    doc: "12675559636",
  },
  {
    doc: "12023980739",
  },
  {
    doc: "41456542850",
  },
  {
    doc: "07215899101",
  },
  {
    doc: "13042275965",
  },
  {
    doc: "11291862617",
  },
  {
    doc: "08957605410",
  },
  {
    doc: "52159583863",
  },
  {
    doc: "50486204880",
  },
  {
    doc: "37553962899",
  },
  {
    doc: "09946208610",
  },
  {
    doc: "10439526400",
  },
  {
    doc: "19573281856",
  },
  {
    doc: "37334005856",
  },
  {
    doc: "30901554863",
  },
  {
    doc: "31056848839",
  },
  {
    doc: "70089567463",
  },
  {
    doc: "11990712770",
  },
  {
    doc: "52339836808",
  },
  {
    doc: "49096011805",
  },
  {
    doc: "35750741869",
  },
  {
    doc: "12278208608",
  },
  {
    doc: "30727117807",
  },
  {
    doc: "39002432801",
  },
  {
    doc: "78364590472",
  },
  {
    doc: "35660866816",
  },
  {
    doc: "01418363006",
  },
  {
    doc: "11642314641",
  },
  {
    doc: "10555852652",
  },
  {
    doc: "25600455836",
  },
  {
    doc: "11787921700",
  },
  {
    doc: "41371433828",
  },
  {
    doc: "79675220600",
  },
  {
    doc: "40316956830",
  },
  {
    doc: "43378104805",
  },
  {
    doc: "29365339880",
  },
  {
    doc: "08743709737",
  },
  {
    doc: "40531689883",
  },
  {
    doc: "03870142413",
  },
  {
    doc: "06068916170",
  },
  {
    doc: "12494037905",
  },
  {
    doc: "00556036026",
  },
  {
    doc: "51441519220",
  },
  {
    doc: "29457935802",
  },
  {
    doc: "97492965249",
  },
  {
    doc: "22272632882",
  },
  {
    doc: "64874338372",
  },
  {
    doc: "69575460472",
  },
  {
    doc: "12682929400",
  },
  {
    doc: "02611318506",
  },
  {
    doc: "03369713624",
  },
  {
    doc: "40871276801",
  },
  {
    doc: "33950715886",
  },
  {
    doc: "09658074790",
  },
  {
    doc: "47903950819",
  },
  {
    doc: "35788220866",
  },
  {
    doc: "37701068813",
  },
  {
    doc: "09053062793",
  },
  {
    doc: "28772932899",
  },
  {
    doc: "02613686138",
  },
  {
    doc: "40593325850",
  },
  {
    doc: "73162329272",
  },
  {
    doc: "06126648994",
  },
  {
    doc: "10713749741",
  },
  {
    doc: "13202198700",
  },
  {
    doc: "03224378573",
  },
  {
    doc: "00959703128",
  },
  {
    doc: "29187002825",
  },
  {
    doc: "39617044811",
  },
  {
    doc: "42336195895",
  },
  {
    doc: "12124247603",
  },
  {
    doc: "03799812113",
  },
  {
    doc: "07420657661",
  },
  {
    doc: "22392684847",
  },
  {
    doc: "15174235890",
  },
  {
    doc: "29101784803",
  },
  {
    doc: "03848451930",
  },
  {
    doc: "03859853120",
  },
  {
    doc: "41559408847",
  },
  {
    doc: "46093657825",
  },
  {
    doc: "30690762895",
  },
  {
    doc: "07642557620",
  },
  {
    doc: "46584690814",
  },
  {
    doc: "17592211807",
  },
  {
    doc: "47591497839",
  },
  {
    doc: "08765372980",
  },
  {
    doc: "41003625878",
  },
  {
    doc: "20003979806",
  },
  {
    doc: "38771361847",
  },
  {
    doc: "01023145782",
  },
  {
    doc: "18379952812",
  },
  {
    doc: "00410100609",
  },
  {
    doc: "81362145572",
  },
  {
    doc: "01456328069",
  },
  {
    doc: "80079464971",
  },
  {
    doc: "30405168845",
  },
  {
    doc: "35436640850",
  },
  {
    doc: "47635442848",
  },
  {
    doc: "48570721811",
  },
  {
    doc: "35606662876",
  },
  {
    doc: "16729001747",
  },
  {
    doc: "11785010603",
  },
  {
    doc: "09918584416",
  },
  {
    doc: "42631571843",
  },
  {
    doc: "45421213803",
  },
  {
    doc: "85166596072",
  },
  {
    doc: "01847272193",
  },
  {
    doc: "37071554803",
  },
  {
    doc: "48020233830",
  },
  {
    doc: "03563258732",
  },
  {
    doc: "41707774846",
  },
  {
    doc: "38042608888",
  },
  {
    doc: "08133064775",
  },
  {
    doc: "14323944799",
  },
  {
    doc: "09388216873",
  },
  {
    doc: "23055309863",
  },
  {
    doc: "22778894829",
  },
  {
    doc: "46869282811",
  },
  {
    doc: "04680835028",
  },
  {
    doc: "70143639137",
  },
  {
    doc: "02863255355",
  },
  {
    doc: "06507986356",
  },
  {
    doc: "61937745309",
  },
  {
    doc: "28030347863",
  },
  {
    doc: "03212620340",
  },
  {
    doc: "04396943377",
  },
  {
    doc: "32675178349",
  },
  {
    doc: "44818473871",
  },
  {
    doc: "03290584526",
  },
  {
    doc: "48186884874",
  },
  {
    doc: "04916463994",
  },
  {
    doc: "33855436819",
  },
  {
    doc: "01526119323",
  },
  {
    doc: "42471533825",
  },
  {
    doc: "42638813893",
  },
  {
    doc: "02367321531",
  },
  {
    doc: "82934444520",
  },
  {
    doc: "34696604845",
  },
  {
    doc: "17954147827",
  },
  {
    doc: "46148797801",
  },
  {
    doc: "05814556498",
  },
  {
    doc: "70209650168",
  },
  {
    doc: "07347377854",
  },
  {
    doc: "02118902948",
  },
  {
    doc: "11714958892",
  },
  {
    doc: "12776382766",
  },
  {
    doc: "04198287538",
  },
  {
    doc: "13448666629",
  },
  {
    doc: "34727173867",
  },
  {
    doc: "48242372896",
  },
  {
    doc: "54837634915",
  },
  {
    doc: "14349942874",
  },
  {
    doc: "06252062316",
  },
  {
    doc: "45088734808",
  },
  {
    doc: "05190236250",
  },
  {
    doc: "37226463830",
  },
  {
    doc: "02815436159",
  },
  {
    doc: "42726873871",
  },
  {
    doc: "43002741850",
  },
  {
    doc: "40872674827",
  },
  {
    doc: "39097580889",
  },
  {
    doc: "41867844850",
  },
  {
    doc: "41307333893",
  },
  {
    doc: "00880154080",
  },
  {
    doc: "14016503680",
  },
  {
    doc: "11002383609",
  },
  {
    doc: "02790414033",
  },
  {
    doc: "44301742883",
  },
  {
    doc: "07833035645",
  },
  {
    doc: "06949007612",
  },
  {
    doc: "90513860100",
  },
  {
    doc: "75684292453",
  },
  {
    doc: "22931494801",
  },
  {
    doc: "43979263878",
  },
  {
    doc: "06203754773",
  },
  {
    doc: "42826601806",
  },
  {
    doc: "23075211869",
  },
  {
    doc: "15565288762",
  },
  {
    doc: "04322605524",
  },
  {
    doc: "11577805631",
  },
  {
    doc: "11740502680",
  },
  {
    doc: "06068213501",
  },
  {
    doc: "02861024046",
  },
  {
    doc: "07192691488",
  },
  {
    doc: "07576993910",
  },
  {
    doc: "86242955563",
  },
  {
    doc: "11655264842",
  },
  {
    doc: "40886359813",
  },
  {
    doc: "14365822705",
  },
  {
    doc: "09270357406",
  },
  {
    doc: "42386424812",
  },
  {
    doc: "43067916883",
  },
  {
    doc: "50708529810",
  },
  {
    doc: "40212865862",
  },
  {
    doc: "10184170796",
  },
  {
    doc: "05839930954",
  },
  {
    doc: "44954742862",
  },
  {
    doc: "28930692826",
  },
  {
    doc: "05813504800",
  },
  {
    doc: "39166232813",
  },
  {
    doc: "03249391913",
  },
  {
    doc: "21291976809",
  },
  {
    doc: "22984591852",
  },
  {
    doc: "42168460841",
  },
  {
    doc: "08848899986",
  },
  {
    doc: "42250067864",
  },
  {
    doc: "12062742630",
  },
  {
    doc: "15577282756",
  },
  {
    doc: "44925512803",
  },
  {
    doc: "47018245850",
  },
  {
    doc: "50070737843",
  },
  {
    doc: "41968748865",
  },
  {
    doc: "45106603838",
  },
  {
    doc: "44227070864",
  },
  {
    doc: "39613379851",
  },
  {
    doc: "04475973104",
  },
  {
    doc: "01756464499",
  },
  {
    doc: "33449115898",
  },
  {
    doc: "04990702328",
  },
  {
    doc: "02254970690",
  },
  {
    doc: "02017464503",
  },
  {
    doc: "45828357832",
  },
  {
    doc: "09218913697",
  },
  {
    doc: "39250956835",
  },
  {
    doc: "16066909738",
  },
  {
    doc: "03275324160",
  },
  {
    doc: "49121070857",
  },
  {
    doc: "05115541173",
  },
  {
    doc: "14632361747",
  },
  {
    doc: "10995672466",
  },
  {
    doc: "42705429859",
  },
  {
    doc: "49381062803",
  },
  {
    doc: "47445584889",
  },
  {
    doc: "38691730846",
  },
  {
    doc: "38600977843",
  },
  {
    doc: "50280792859",
  },
  {
    doc: "01649406185",
  },
  {
    doc: "08796903724",
  },
  {
    doc: "35565952816",
  },
  {
    doc: "38906339860",
  },
  {
    doc: "08503572761",
  },
  {
    doc: "05582730176",
  },
  {
    doc: "12294329732",
  },
  {
    doc: "39081101811",
  },
  {
    doc: "10661578712",
  },
  {
    doc: "12172210692",
  },
  {
    doc: "10538800739",
  },
  {
    doc: "11743967411",
  },
  {
    doc: "12124977679",
  },
  {
    doc: "08071924652",
  },
  {
    doc: "38295906879",
  },
  {
    doc: "13736059604",
  },
  {
    doc: "05187464965",
  },
  {
    doc: "05261306523",
  },
  {
    doc: "39318082894",
  },
  {
    doc: "45864358878",
  },
  {
    doc: "40786521821",
  },
  {
    doc: "16339910750",
  },
  {
    doc: "35271986870",
  },
  {
    doc: "05896704763",
  },
  {
    doc: "33804535879",
  },
  {
    doc: "44167854880",
  },
  {
    doc: "37621311800",
  },
  {
    doc: "42336194813",
  },
  {
    doc: "08805597481",
  },
  {
    doc: "36257490847",
  },
  {
    doc: "45217314818",
  },
  {
    doc: "09881680654",
  },
  {
    doc: "42628092859",
  },
  {
    doc: "47311530857",
  },
  {
    doc: "49094846802",
  },
  {
    doc: "15782418710",
  },
  {
    doc: "37539085843",
  },
  {
    doc: "40398090840",
  },
  {
    doc: "09094058677",
  },
  {
    doc: "11291297642",
  },
  {
    doc: "44945575878",
  },
  {
    doc: "15935648725",
  },
  {
    doc: "40988696835",
  },
  {
    doc: "11285449460",
  },
  {
    doc: "11697696759",
  },
  {
    doc: "45763405811",
  },
  {
    doc: "07483390514",
  },
  {
    doc: "46677695808",
  },
  {
    doc: "43646584807",
  },
  {
    doc: "36123885847",
  },
  {
    doc: "14267515670",
  },
  {
    doc: "07855914943",
  },
  {
    doc: "04114066985",
  },
  {
    doc: "42790846880",
  },
  {
    doc: "01648119077",
  },
  {
    doc: "05012331052",
  },
  {
    doc: "03562783019",
  },
  {
    doc: "09993612600",
  },
  {
    doc: "27356100800",
  },
  {
    doc: "28521452861",
  },
  {
    doc: "08708941700",
  },
  {
    doc: "35034299801",
  },
  {
    doc: "13485140600",
  },
  {
    doc: "39173058874",
  },
  {
    doc: "11731449780",
  },
  {
    doc: "08784896736",
  },
  {
    doc: "09894874401",
  },
  {
    doc: "32120922896",
  },
  {
    doc: "11617715492",
  },
  {
    doc: "04122673658",
  },
  {
    doc: "04259967789",
  },
  {
    doc: "28121904854",
  },
  {
    doc: "39816490870",
  },
  {
    doc: "07104897739",
  },
  {
    doc: "05825361383",
  },
  {
    doc: "09845600417",
  },
  {
    doc: "47705268817",
  },
  {
    doc: "30968341802",
  },
  {
    doc: "41115415859",
  },
  {
    doc: "12380046794",
  },
  {
    doc: "13932190637",
  },
  {
    doc: "41478856831",
  },
  {
    doc: "11084320770",
  },
  {
    doc: "42616801817",
  },
  {
    doc: "73202622404",
  },
  {
    doc: "05943464522",
  },
  {
    doc: "04347873107",
  },
  {
    doc: "16267003892",
  },
  {
    doc: "45112078871",
  },
  {
    doc: "02996305019",
  },
  {
    doc: "00663048303",
  },
  {
    doc: "17267630723",
  },
  {
    doc: "32579510869",
  },
  {
    doc: "13626722708",
  },
  {
    doc: "36965768835",
  },
  {
    doc: "31916099890",
  },
  {
    doc: "06628701662",
  },
  {
    doc: "40150377827",
  },
  {
    doc: "40364494867",
  },
  {
    doc: "08397011447",
  },
  {
    doc: "38276352847",
  },
  {
    doc: "05574233258",
  },
  {
    doc: "34325495800",
  },
  {
    doc: "41526974827",
  },
  {
    doc: "05559787612",
  },
  {
    doc: "10092207642",
  },
  {
    doc: "05681187152",
  },
  {
    doc: "41068006838",
  },
  {
    doc: "14413231635",
  },
  {
    doc: "45904892870",
  },
  {
    doc: "15959161729",
  },
  {
    doc: "09212746705",
  },
  {
    doc: "02225228876",
  },
  {
    doc: "31103518895",
  },
  {
    doc: "03498547429",
  },
  {
    doc: "05556008159",
  },
  {
    doc: "81490518134",
  },
  {
    doc: "02867642450",
  },
  {
    doc: "18545415877",
  },
  {
    doc: "34246390879",
  },
  {
    doc: "26442129870",
  },
  {
    doc: "29629506807",
  },
  {
    doc: "34974144847",
  },
  {
    doc: "44948585874",
  },
  {
    doc: "43094832830",
  },
  {
    doc: "11995812676",
  },
  {
    doc: "28104378821",
  },
  {
    doc: "01100097031",
  },
  {
    doc: "34204514820",
  },
  {
    doc: "27270318824",
  },
  {
    doc: "70159657474",
  },
  {
    doc: "28963910881",
  },
  {
    doc: "99889250349",
  },
  {
    doc: "69610029272",
  },
  {
    doc: "09802131695",
  },
  {
    doc: "39580479801",
  },
  {
    doc: "34145258878",
  },
  {
    doc: "33570791866",
  },
  {
    doc: "54765307972",
  },
  {
    doc: "14092334800",
  },
  {
    doc: "01328176592",
  },
  {
    doc: "32862670820",
  },
  {
    doc: "25999519845",
  },
  {
    doc: "04914346508",
  },
  {
    doc: "13250122890",
  },
  {
    doc: "17507587762",
  },
  {
    doc: "13038678775",
  },
  {
    doc: "27950396806",
  },
  {
    doc: "36312806880",
  },
  {
    doc: "06407427509",
  },
  {
    doc: "03859833448",
  },
  {
    doc: "00905085302",
  },
  {
    doc: "31187807893",
  },
  {
    doc: "31931656894",
  },
  {
    doc: "94816875115",
  },
  {
    doc: "78111668520",
  },
  {
    doc: "45873544816",
  },
  {
    doc: "09994744488",
  },
  {
    doc: "08478760717",
  },
  {
    doc: "45503356888",
  },
  {
    doc: "21618999885",
  },
  {
    doc: "22425759883",
  },
  {
    doc: "32877592804",
  },
  {
    doc: "39057758830",
  },
  {
    doc: "36402780898",
  },
  {
    doc: "44091698824",
  },
  {
    doc: "90829476504",
  },
  {
    doc: "01513594311",
  },
  {
    doc: "34860145879",
  },
  {
    doc: "28246607839",
  },
  {
    doc: "05281424700",
  },
  {
    doc: "26000511809",
  },
  {
    doc: "09325918684",
  },
  {
    doc: "22053150807",
  },
  {
    doc: "47978676803",
  },
  {
    doc: "86227547581",
  },
  {
    doc: "09660746881",
  },
  {
    doc: "22798532809",
  },
  {
    doc: "07143594679",
  },
  {
    doc: "00206862180",
  },
  {
    doc: "76767590215",
  },
  {
    doc: "41605812854",
  },
  {
    doc: "31110575858",
  },
  {
    doc: "13504651865",
  },
  {
    doc: "08052016452",
  },
  {
    doc: "02840453142",
  },
  {
    doc: "07991760790",
  },
  {
    doc: "80402224515",
  },
  {
    doc: "03052740580",
  },
  {
    doc: "18611757793",
  },
  {
    doc: "38212758800",
  },
  {
    doc: "18540946700",
  },
  {
    doc: "14141046779",
  },
  {
    doc: "01282976389",
  },
  {
    doc: "41461512875",
  },
  {
    doc: "26592409819",
  },
  {
    doc: "21776747860",
  },
  {
    doc: "42694173829",
  },
  {
    doc: "25856126866",
  },
  {
    doc: "30110567811",
  },
  {
    doc: "06707801970",
  },
  {
    doc: "28829889822",
  },
  {
    doc: "31333933835",
  },
  {
    doc: "39637916830",
  },
  {
    doc: "47234239820",
  },
  {
    doc: "35453859814",
  },
  {
    doc: "60969863314",
  },
  {
    doc: "02915648360",
  },
  {
    doc: "02223068588",
  },
  {
    doc: "08050919552",
  },
  {
    doc: "47683948845",
  },
  {
    doc: "07671925594",
  },
  {
    doc: "08658680428",
  },
  {
    doc: "08931200900",
  },
  {
    doc: "33586262884",
  },
  {
    doc: "94313830391",
  },
  {
    doc: "95894217504",
  },
  {
    doc: "12885059656",
  },
  {
    doc: "11833337905",
  },
  {
    doc: "01346781176",
  },
  {
    doc: "11873748655",
  },
  {
    doc: "49249160801",
  },
  {
    doc: "04227790085",
  },
  {
    doc: "82367477000",
  },
  {
    doc: "14455590722",
  },
  {
    doc: "35440850899",
  },
  {
    doc: "07139891303",
  },
  {
    doc: "16790824774",
  },
  {
    doc: "02122444509",
  },
  {
    doc: "16098025860",
  },
  {
    doc: "09166415977",
  },
  {
    doc: "40033062870",
  },
  {
    doc: "45055952857",
  },
  {
    doc: "39117364876",
  },
  {
    doc: "21689856858",
  },
  {
    doc: "30996446842",
  },
  {
    doc: "34493708808",
  },
  {
    doc: "01675626308",
  },
  {
    doc: "43975203803",
  },
  {
    doc: "13752512792",
  },
  {
    doc: "41918555818",
  },
  {
    doc: "42218466821",
  },
  {
    doc: "02038839182",
  },
  {
    doc: "40469601833",
  },
  {
    doc: "28477749809",
  },
  {
    doc: "08434758466",
  },
  {
    doc: "42587575869",
  },
  {
    doc: "32095238869",
  },
  {
    doc: "02874622346",
  },
  {
    doc: "40621282847",
  },
  {
    doc: "01108661009",
  },
  {
    doc: "01612753540",
  },
  {
    doc: "09120061706",
  },
  {
    doc: "12178841490",
  },
  {
    doc: "49099413807",
  },
  {
    doc: "06040146192",
  },
  {
    doc: "09739352944",
  },
  {
    doc: "47031448832",
  },
  {
    doc: "37386415800",
  },
  {
    doc: "07349239963",
  },
  {
    doc: "04936699144",
  },
  {
    doc: "13678382746",
  },
  {
    doc: "42133006842",
  },
  {
    doc: "39572514865",
  },
  {
    doc: "08835003962",
  },
  {
    doc: "40116974869",
  },
  {
    doc: "43947113803",
  },
  {
    doc: "11381207405",
  },
  {
    doc: "36472380821",
  },
  {
    doc: "35344120877",
  },
  {
    doc: "43682857869",
  },
  {
    doc: "07381338335",
  },
  {
    doc: "38875827800",
  },
  {
    doc: "30630793832",
  },
  {
    doc: "03139194536",
  },
  {
    doc: "15131056632",
  },
  {
    doc: "91921996153",
  },
  {
    doc: "10601407482",
  },
  {
    doc: "40860576892",
  },
  {
    doc: "07917060870",
  },
  {
    doc: "42554914885",
  },
  {
    doc: "08462714435",
  },
  {
    doc: "16181545751",
  },
  {
    doc: "15725605760",
  },
  {
    doc: "36598898811",
  },
  {
    doc: "39866902803",
  },
  {
    doc: "29830780864",
  },
  {
    doc: "13637426633",
  },
  {
    doc: "41262688809",
  },
  {
    doc: "37141552871",
  },
  {
    doc: "05988442790",
  },
  {
    doc: "10415441463",
  },
  {
    doc: "31860156851",
  },
  {
    doc: "31729492819",
  },
  {
    doc: "11942346743",
  },
  {
    doc: "12002841608",
  },
  {
    doc: "40034873899",
  },
  {
    doc: "04164779330",
  },
  {
    doc: "07861553450",
  },
  {
    doc: "04216928129",
  },
  {
    doc: "07497170686",
  },
  {
    doc: "10702917656",
  },
  {
    doc: "06285187304",
  },
  {
    doc: "76617424034",
  },
  {
    doc: "01836950039",
  },
  {
    doc: "41299838812",
  },
  {
    doc: "10597242607",
  },
  {
    doc: "04980707513",
  },
  {
    doc: "09774453646",
  },
  {
    doc: "39979595876",
  },
  {
    doc: "92135382353",
  },
  {
    doc: "02671251351",
  },
  {
    doc: "45578510884",
  },
  {
    doc: "43776524863",
  },
  {
    doc: "35118574811",
  },
  {
    doc: "60862616360",
  },
  {
    doc: "30392305810",
  },
  {
    doc: "38923380865",
  },
  {
    doc: "32330147830",
  },
  {
    doc: "47809383841",
  },
  {
    doc: "89284330106",
  },
  {
    doc: "05854779900",
  },
  {
    doc: "41089277881",
  },
  {
    doc: "26758214893",
  },
  {
    doc: "87209829091",
  },
  {
    doc: "44524493875",
  },
  {
    doc: "08758807705",
  },
  {
    doc: "14392889769",
  },
  {
    doc: "29295588819",
  },
  {
    doc: "13092183746",
  },
  {
    doc: "38822307895",
  },
  {
    doc: "37526252878",
  },
  {
    doc: "37382106870",
  },
  {
    doc: "05102986643",
  },
  {
    doc: "40117389803",
  },
  {
    doc: "08728539427",
  },
  {
    doc: "03076259580",
  },
  {
    doc: "08174404961",
  },
  {
    doc: "36481387825",
  },
  {
    doc: "35713571831",
  },
  {
    doc: "35951226821",
  },
  {
    doc: "12435191693",
  },
  {
    doc: "07541914436",
  },
  {
    doc: "35817102854",
  },
  {
    doc: "15692032708",
  },
  {
    doc: "94931844200",
  },
  {
    doc: "09369238905",
  },
  {
    doc: "38649112811",
  },
  {
    doc: "29444341879",
  },
  {
    doc: "03159552950",
  },
  {
    doc: "07013670324",
  },
  {
    doc: "45023940845",
  },
  {
    doc: "07141100946",
  },
  {
    doc: "06995876466",
  },
  {
    doc: "33958308880",
  },
  {
    doc: "37037172898",
  },
  {
    doc: "37395233875",
  },
  {
    doc: "34180999803",
  },
  {
    doc: "11081845708",
  },
  {
    doc: "39670583870",
  },
  {
    doc: "06313510356",
  },
  {
    doc: "04874783392",
  },
  {
    doc: "08453161682",
  },
  {
    doc: "94324000778",
  },
  {
    doc: "41236456823",
  },
  {
    doc: "28729851823",
  },
  {
    doc: "63317834404",
  },
  {
    doc: "24708473893",
  },
  {
    doc: "31342234812",
  },
  {
    doc: "33858060895",
  },
  {
    doc: "35012885813",
  },
  {
    doc: "02323755854",
  },
  {
    doc: "12828706613",
  },
  {
    doc: "08026592689",
  },
  {
    doc: "35182441835",
  },
  {
    doc: "17770337720",
  },
  {
    doc: "50995099839",
  },
  {
    doc: "29900323807",
  },
  {
    doc: "18365583763",
  },
  {
    doc: "35164572877",
  },
  {
    doc: "48442311890",
  },
  {
    doc: "06192444706",
  },
  {
    doc: "22593682809",
  },
  {
    doc: "25620341801",
  },
  {
    doc: "21578501806",
  },
  {
    doc: "84589698404",
  },
  {
    doc: "31496287843",
  },
  {
    doc: "04456170557",
  },
  {
    doc: "40154886874",
  },
  {
    doc: "29884694826",
  },
  {
    doc: "09271335716",
  },
  {
    doc: "03096225303",
  },
  {
    doc: "16062158762",
  },
  {
    doc: "07486386607",
  },
  {
    doc: "00450930327",
  },
  {
    doc: "21976323886",
  },
  {
    doc: "09793150777",
  },
  {
    doc: "25457738833",
  },
  {
    doc: "09656198859",
  },
  {
    doc: "54137543372",
  },
  {
    doc: "28309947879",
  },
  {
    doc: "02358986194",
  },
  {
    doc: "90196740134",
  },
  {
    doc: "49043862134",
  },
  {
    doc: "14987734788",
  },
  {
    doc: "47610644803",
  },
  {
    doc: "11251083633",
  },
  {
    doc: "29801293802",
  },
  {
    doc: "04965231120",
  },
  {
    doc: "16995968832",
  },
  {
    doc: "16377925816",
  },
  {
    doc: "09276166459",
  },
  {
    doc: "36535718808",
  },
  {
    doc: "53417020182",
  },
  {
    doc: "63627639120",
  },
  {
    doc: "31121437877",
  },
  {
    doc: "33363319860",
  },
  {
    doc: "09743130411",
  },
  {
    doc: "60521835356",
  },
  {
    doc: "87546191220",
  },
  {
    doc: "14342717816",
  },
  {
    doc: "99095262472",
  },
  {
    doc: "10967784913",
  },
  {
    doc: "05708357398",
  },
  {
    doc: "07317954900",
  },
  {
    doc: "21789122813",
  },
  {
    doc: "09695617760",
  },
  {
    doc: "38348393889",
  },
  {
    doc: "30639558844",
  },
  {
    doc: "07932457530",
  },
  {
    doc: "09788912958",
  },
  {
    doc: "32214838805",
  },
  {
    doc: "30387061835",
  },
  {
    doc: "37520544842",
  },
  {
    doc: "44889982833",
  },
  {
    doc: "40661493830",
  },
  {
    doc: "28750679821",
  },
  {
    doc: "10489574700",
  },
  {
    doc: "36464256874",
  },
  {
    doc: "11117832929",
  },
  {
    doc: "02638570184",
  },
  {
    doc: "10567681424",
  },
  {
    doc: "03411552840",
  },
  {
    doc: "22928948821",
  },
  {
    doc: "33356907883",
  },
  {
    doc: "14518111877",
  },
  {
    doc: "27478463851",
  },
  {
    doc: "30194344835",
  },
  {
    doc: "22920681842",
  },
  {
    doc: "39226977801",
  },
  {
    doc: "43312219892",
  },
  {
    doc: "10370529782",
  },
  {
    doc: "18740659852",
  },
  {
    doc: "01496618513",
  },
  {
    doc: "80675220572",
  },
  {
    doc: "28295802810",
  },
  {
    doc: "28701345842",
  },
  {
    doc: "09679982475",
  },
  {
    doc: "07834302647",
  },
  {
    doc: "33122618800",
  },
  {
    doc: "12965779884",
  },
  {
    doc: "17740976806",
  },
  {
    doc: "11206453796",
  },
  {
    doc: "03779778378",
  },
  {
    doc: "03954104440",
  },
  {
    doc: "03973860874",
  },
  {
    doc: "04407982136",
  },
  {
    doc: "11835462707",
  },
  {
    doc: "07444562407",
  },
  {
    doc: "09092424617",
  },
  {
    doc: "04655050829",
  },
  {
    doc: "40286256886",
  },
  {
    doc: "38073849879",
  },
  {
    doc: "30228669847",
  },
  {
    doc: "16089395889",
  },
  {
    doc: "44937496875",
  },
  {
    doc: "31394457847",
  },
  {
    doc: "11616828803",
  },
  {
    doc: "71238760082",
  },
  {
    doc: "06618423473",
  },
  {
    doc: "08966861750",
  },
  {
    doc: "22599420830",
  },
  {
    doc: "10554441497",
  },
  {
    doc: "09848612629",
  },
  {
    doc: "15396558733",
  },
  {
    doc: "43652436830",
  },
  {
    doc: "22325722856",
  },
  {
    doc: "03457433933",
  },
  {
    doc: "01489105212",
  },
  {
    doc: "13512674771",
  },
  {
    doc: "45210245802",
  },
  {
    doc: "22820478859",
  },
  {
    doc: "18374780827",
  },
  {
    doc: "13505852708",
  },
  {
    doc: "99708922900",
  },
  {
    doc: "04360689748",
  },
  {
    doc: "08261315576",
  },
  {
    doc: "38606634883",
  },
  {
    doc: "41279337800",
  },
  {
    doc: "00489470670",
  },
  {
    doc: "18439995792",
  },
  {
    doc: "60810738317",
  },
  {
    doc: "28439664850",
  },
  {
    doc: "15385593745",
  },
  {
    doc: "17816427700",
  },
  {
    doc: "60289661340",
  },
  {
    doc: "17754098806",
  },
  {
    doc: "05986429605",
  },
  {
    doc: "35727607809",
  },
  {
    doc: "07739509906",
  },
  {
    doc: "08644337602",
  },
  {
    doc: "19156429738",
  },
  {
    doc: "35231776858",
  },
  {
    doc: "58043896704",
  },
  {
    doc: "06796871901",
  },
  {
    doc: "31633440893",
  },
  {
    doc: "34056756856",
  },
  {
    doc: "39308764852",
  },
  {
    doc: "09393967423",
  },
  {
    doc: "28130445808",
  },
  {
    doc: "34766888871",
  },
  {
    doc: "12522851756",
  },
  {
    doc: "17685879735",
  },
  {
    doc: "29040046883",
  },
  {
    doc: "50651936810",
  },
  {
    doc: "48350353805",
  },
  {
    doc: "14017869602",
  },
  {
    doc: "46199126890",
  },
  {
    doc: "14447428504",
  },
  {
    doc: "30733008801",
  },
  {
    doc: "36237397825",
  },
  {
    doc: "05713930637",
  },
  {
    doc: "43669092862",
  },
  {
    doc: "05062813964",
  },
  {
    doc: "01192202104",
  },
  {
    doc: "46151109104",
  },
  {
    doc: "10113325746",
  },
  {
    doc: "42450482806",
  },
  {
    doc: "51458314391",
  },
  {
    doc: "23495784888",
  },
  {
    doc: "75900688887",
  },
  {
    doc: "27357704866",
  },
  {
    doc: "35261345880",
  },
  {
    doc: "33082220860",
  },
  {
    doc: "17012804777",
  },
  {
    doc: "41539227812",
  },
  {
    doc: "81195923520",
  },
  {
    doc: "33937292845",
  },
  {
    doc: "12432144651",
  },
  {
    doc: "04519866014",
  },
  {
    doc: "35297477859",
  },
  {
    doc: "10706965795",
  },
  {
    doc: "50413383881",
  },
  {
    doc: "30851387810",
  },
  {
    doc: "13145937731",
  },
  {
    doc: "33327241856",
  },
  {
    doc: "22697727841",
  },
  {
    doc: "36589371814",
  },
  {
    doc: "05663456767",
  },
  {
    doc: "01172229090",
  },
  {
    doc: "36753417800",
  },
  {
    doc: "01629415529",
  },
  {
    doc: "49832955858",
  },
  {
    doc: "04541544105",
  },
  {
    doc: "27248597821",
  },
  {
    doc: "05894027730",
  },
  {
    doc: "42035576822",
  },
  {
    doc: "06154098731",
  },
  {
    doc: "27549245827",
  },
  {
    doc: "45012796802",
  },
  {
    doc: "02103254619",
  },
  {
    doc: "07091557514",
  },
  {
    doc: "26876207854",
  },
  {
    doc: "22256319806",
  },
  {
    doc: "83787755187",
  },
  {
    doc: "31052984878",
  },
  {
    doc: "00117495638",
  },
  {
    doc: "11376940418",
  },
  {
    doc: "02554759502",
  },
  {
    doc: "81474288049",
  },
  {
    doc: "28561516801",
  },
  {
    doc: "99327724372",
  },
  {
    doc: "33146564831",
  },
  {
    doc: "26924183875",
  },
  {
    doc: "41864228857",
  },
  {
    doc: "06155549974",
  },
  {
    doc: "12976915806",
  },
  {
    doc: "21898528896",
  },
  {
    doc: "03790490903",
  },
  {
    doc: "40262350840",
  },
  {
    doc: "07343547704",
  },
  {
    doc: "34806794805",
  },
  {
    doc: "03387606796",
  },
  {
    doc: "44980713874",
  },
  {
    doc: "40535298838",
  },
  {
    doc: "37183853845",
  },
  {
    doc: "30259099864",
  },
  {
    doc: "00933583931",
  },
  {
    doc: "33900020817",
  },
  {
    doc: "44722366802",
  },
  {
    doc: "00257712305",
  },
  {
    doc: "01181392152",
  },
  {
    doc: "50325302553",
  },
  {
    doc: "39881436850",
  },
  {
    doc: "08889679697",
  },
  {
    doc: "43817014880",
  },
  {
    doc: "33393405870",
  },
  {
    doc: "06489527979",
  },
  {
    doc: "30464488800",
  },
  {
    doc: "09031867640",
  },
  {
    doc: "41947672843",
  },
  {
    doc: "41806428830",
  },
  {
    doc: "10232239452",
  },
  {
    doc: "04960290102",
  },
  {
    doc: "32738992803",
  },
  {
    doc: "22428203894",
  },
  {
    doc: "58433694120",
  },
  {
    doc: "06704245600",
  },
  {
    doc: "33854268807",
  },
  {
    doc: "11779473745",
  },
  {
    doc: "04607267147",
  },
  {
    doc: "35928444826",
  },
  {
    doc: "08819503514",
  },
  {
    doc: "35564638816",
  },
  {
    doc: "03306811505",
  },
  {
    doc: "42771279835",
  },
  {
    doc: "12023304954",
  },
  {
    doc: "02091896225",
  },
  {
    doc: "41401939805",
  },
  {
    doc: "04126095250",
  },
  {
    doc: "08714542978",
  },
  {
    doc: "36812906875",
  },
  {
    doc: "14141610690",
  },
  {
    doc: "46592282827",
  },
  {
    doc: "14227620767",
  },
  {
    doc: "37525765880",
  },
  {
    doc: "46412165843",
  },
  {
    doc: "17140512779",
  },
  {
    doc: "41617623806",
  },
  {
    doc: "36960289831",
  },
  {
    doc: "03108012142",
  },
  {
    doc: "01312700440",
  },
  {
    doc: "03672168173",
  },
  {
    doc: "41823645895",
  },
  {
    doc: "40795672861",
  },
  {
    doc: "39636611866",
  },
  {
    doc: "23067555821",
  },
  {
    doc: "02232919196",
  },
  {
    doc: "02794385240",
  },
  {
    doc: "35949645820",
  },
  {
    doc: "45907383844",
  },
  {
    doc: "37419066892",
  },
  {
    doc: "06025238340",
  },
  {
    doc: "41635630835",
  },
  {
    doc: "41984025821",
  },
  {
    doc: "29108043809",
  },
  {
    doc: "39988486812",
  },
  {
    doc: "00851543260",
  },
  {
    doc: "03876140447",
  },
  {
    doc: "27371984889",
  },
  {
    doc: "05129046188",
  },
  {
    doc: "15777649785",
  },
  {
    doc: "07747285663",
  },
  {
    doc: "33952325813",
  },
  {
    doc: "07918630858",
  },
  {
    doc: "01707460620",
  },
  {
    doc: "00669876526",
  },
  {
    doc: "28330984800",
  },
  {
    doc: "34448346838",
  },
  {
    doc: "27232932898",
  },
  {
    doc: "04012481517",
  },
  {
    doc: "44169777867",
  },
  {
    doc: "10152622950",
  },
  {
    doc: "03531460307",
  },
  {
    doc: "09650572678",
  },
  {
    doc: "30876353839",
  },
  {
    doc: "90777611015",
  },
  {
    doc: "01323824073",
  },
  {
    doc: "30837394864",
  },
  {
    doc: "04950107151",
  },
  {
    doc: "41999143825",
  },
  {
    doc: "00539298107",
  },
  {
    doc: "27232993846",
  },
  {
    doc: "38745315892",
  },
  {
    doc: "38276847811",
  },
  {
    doc: "36387383889",
  },
  {
    doc: "42032022800",
  },
  {
    doc: "29355671881",
  },
  {
    doc: "32128844878",
  },
  {
    doc: "32688851870",
  },
  {
    doc: "32354243855",
  },
  {
    doc: "22115875850",
  },
  {
    doc: "11361035625",
  },
  {
    doc: "45038579833",
  },
  {
    doc: "11475698941",
  },
  {
    doc: "18281856718",
  },
  {
    doc: "16723118707",
  },
  {
    doc: "35525162806",
  },
  {
    doc: "37017404882",
  },
  {
    doc: "35961224813",
  },
  {
    doc: "35642421808",
  },
  {
    doc: "39798130880",
  },
  {
    doc: "47808995835",
  },
  {
    doc: "86172408577",
  },
  {
    doc: "29976903820",
  },
  {
    doc: "06576629419",
  },
  {
    doc: "42408226880",
  },
  {
    doc: "12131231784",
  },
  {
    doc: "03760349714",
  },
  {
    doc: "22084346843",
  },
  {
    doc: "09352097432",
  },
  {
    doc: "22988296839",
  },
  {
    doc: "56353553153",
  },
  {
    doc: "00315146699",
  },
  {
    doc: "21432204807",
  },
  {
    doc: "10468843957",
  },
  {
    doc: "48156578821",
  },
  {
    doc: "02144246009",
  },
  {
    doc: "12067781995",
  },
  {
    doc: "27320915880",
  },
  {
    doc: "04209620343",
  },
  {
    doc: "04229259344",
  },
  {
    doc: "63459183349",
  },
  {
    doc: "60694190578",
  },
  {
    doc: "34971951890",
  },
  {
    doc: "65263278187",
  },
  {
    doc: "16091650807",
  },
  {
    doc: "01173226281",
  },
  {
    doc: "08945620451",
  },
  {
    doc: "12436459622",
  },
  {
    doc: "02399790103",
  },
  {
    doc: "07465222311",
  },
  {
    doc: "10478861702",
  },
  {
    doc: "51870959353",
  },
  {
    doc: "02694574025",
  },
  {
    doc: "89189698134",
  },
  {
    doc: "05933639303",
  },
  {
    doc: "03842638108",
  },
  {
    doc: "13798800766",
  },
  {
    doc: "35259179889",
  },
  {
    doc: "31465273832",
  },
  {
    doc: "12412429847",
  },
  {
    doc: "05120407536",
  },
  {
    doc: "46153723888",
  },
  {
    doc: "06283386350",
  },
  {
    doc: "01173691790",
  },
  {
    doc: "05421336956",
  },
  {
    doc: "43720046800",
  },
  {
    doc: "51801722846",
  },
  {
    doc: "32538869803",
  },
  {
    doc: "09646421741",
  },
  {
    doc: "48664683867",
  },
  {
    doc: "09804022478",
  },
  {
    doc: "14932955782",
  },
  {
    doc: "42044311860",
  },
  {
    doc: "09453147958",
  },
  {
    doc: "48820102838",
  },
  {
    doc: "39666382822",
  },
  {
    doc: "12125382938",
  },
  {
    doc: "36836513857",
  },
  {
    doc: "45586307863",
  },
  {
    doc: "13354950617",
  },
  {
    doc: "41262225884",
  },
  {
    doc: "49354429807",
  },
  {
    doc: "87484943053",
  },
  {
    doc: "43225248851",
  },
  {
    doc: "14724686782",
  },
  {
    doc: "08217594937",
  },
  {
    doc: "04421840181",
  },
  {
    doc: "39163384809",
  },
  {
    doc: "03018428250",
  },
  {
    doc: "19733059765",
  },
  {
    doc: "40776784803",
  },
  {
    doc: "41857970870",
  },
  {
    doc: "47296538821",
  },
  {
    doc: "33952045888",
  },
  {
    doc: "40454792875",
  },
  {
    doc: "86198973573",
  },
  {
    doc: "47738114800",
  },
  {
    doc: "46008317807",
  },
  {
    doc: "12045164993",
  },
  {
    doc: "48453551839",
  },
  {
    doc: "22843993806",
  },
  {
    doc: "41949571807",
  },
  {
    doc: "15058814714",
  },
  {
    doc: "42742678816",
  },
  {
    doc: "46577790876",
  },
  {
    doc: "60556868307",
  },
  {
    doc: "38155426858",
  },
  {
    doc: "51473562821",
  },
  {
    doc: "47129471875",
  },
  {
    doc: "07572950531",
  },
  {
    doc: "43939290807",
  },
  {
    doc: "35623350836",
  },
  {
    doc: "05913253736",
  },
  {
    doc: "00179216350",
  },
  {
    doc: "11490855688",
  },
  {
    doc: "61812865350",
  },
  {
    doc: "06675651404",
  },
  {
    doc: "81594232253",
  },
  {
    doc: "01516916573",
  },
  {
    doc: "37072314881",
  },
  {
    doc: "10151096910",
  },
  {
    doc: "01486347029",
  },
  {
    doc: "16116172784",
  },
  {
    doc: "12191751458",
  },
  {
    doc: "06387573475",
  },
  {
    doc: "02791468099",
  },
  {
    doc: "17697505843",
  },
  {
    doc: "01428190180",
  },
  {
    doc: "03543165755",
  },
  {
    doc: "05846407650",
  },
  {
    doc: "40056559801",
  },
  {
    doc: "11425416659",
  },
  {
    doc: "35365459806",
  },
  {
    doc: "47033306873",
  },
  {
    doc: "01491239069",
  },
  {
    doc: "10734183402",
  },
  {
    doc: "03028896559",
  },
  {
    doc: "98285262200",
  },
  {
    doc: "13037260840",
  },
  {
    doc: "11263568670",
  },
  {
    doc: "08711205776",
  },
  {
    doc: "05409007530",
  },
  {
    doc: "32104263840",
  },
  {
    doc: "31179569806",
  },
  {
    doc: "08934001810",
  },
  {
    doc: "28484522857",
  },
  {
    doc: "71171268416",
  },
  {
    doc: "02576214401",
  },
  {
    doc: "47631034826",
  },
  {
    doc: "45315148823",
  },
  {
    doc: "44828342893",
  },
  {
    doc: "02074348673",
  },
  {
    doc: "09698121676",
  },
  {
    doc: "42283156823",
  },
  {
    doc: "05641871940",
  },
  {
    doc: "08683442497",
  },
  {
    doc: "39094895838",
  },
  {
    doc: "02383333026",
  },
  {
    doc: "02871039607",
  },
  {
    doc: "34949249886",
  },
  {
    doc: "26900150802",
  },
  {
    doc: "38154545810",
  },
  {
    doc: "09186758632",
  },
  {
    doc: "04340707503",
  },
  {
    doc: "71717447104",
  },
  {
    doc: "04045172939",
  },
  {
    doc: "04397628980",
  },
  {
    doc: "22451041889",
  },
  {
    doc: "72745762168",
  },
  {
    doc: "23078673847",
  },
  {
    doc: "23841441890",
  },
  {
    doc: "45845878845",
  },
  {
    doc: "44201690873",
  },
  {
    doc: "08639287651",
  },
  {
    doc: "22352190800",
  },
  {
    doc: "29659718829",
  },
  {
    doc: "37165520899",
  },
  {
    doc: "39666168820",
  },
  {
    doc: "03523159113",
  },
  {
    doc: "03198612527",
  },
  {
    doc: "60885405323",
  },
  {
    doc: "06730887907",
  },
  {
    doc: "05788468531",
  },
  {
    doc: "12036603750",
  },
  {
    doc: "28630436840",
  },
  {
    doc: "01354033590",
  },
  {
    doc: "40068483821",
  },
  {
    doc: "07300147976",
  },
  {
    doc: "47860511878",
  },
  {
    doc: "34923892870",
  },
  {
    doc: "07616446604",
  },
  {
    doc: "40493973826",
  },
  {
    doc: "35900107892",
  },
  {
    doc: "43665108802",
  },
  {
    doc: "06653023982",
  },
  {
    doc: "10110862635",
  },
  {
    doc: "12474261907",
  },
  {
    doc: "04249732002",
  },
  {
    doc: "36035187803",
  },
  {
    doc: "05813187146",
  },
  {
    doc: "48851472807",
  },
  {
    doc: "47220299893",
  },
  {
    doc: "49820102855",
  },
  {
    doc: "43481252870",
  },
  {
    doc: "41212055888",
  },
  {
    doc: "33406285805",
  },
  {
    doc: "40847287807",
  },
  {
    doc: "42843784816",
  },
  {
    doc: "86419221560",
  },
  {
    doc: "03376688257",
  },
  {
    doc: "06913283156",
  },
  {
    doc: "12314001702",
  },
  {
    doc: "36580500808",
  },
  {
    doc: "43461928828",
  },
  {
    doc: "46630378873",
  },
  {
    doc: "51111740879",
  },
  {
    doc: "47808501859",
  },
  {
    doc: "05367721346",
  },
  {
    doc: "06333722340",
  },
  {
    doc: "41578816807",
  },
  {
    doc: "39085717825",
  },
  {
    doc: "07945708528",
  },
  {
    doc: "42698070838",
  },
  {
    doc: "39180642802",
  },
  {
    doc: "25352740862",
  },
  {
    doc: "12668709709",
  },
  {
    doc: "42506238824",
  },
  {
    doc: "34827105898",
  },
  {
    doc: "04643065907",
  },
  {
    doc: "50367638827",
  },
  {
    doc: "42528868804",
  },
  {
    doc: "16098726817",
  },
  {
    doc: "06029169688",
  },
  {
    doc: "41350630802",
  },
  {
    doc: "70166991457",
  },
  {
    doc: "37026383850",
  },
  {
    doc: "48738799812",
  },
  {
    doc: "29537376850",
  },
  {
    doc: "08866359939",
  },
  {
    doc: "72591943672",
  },
  {
    doc: "94955131387",
  },
  {
    doc: "11447007603",
  },
  {
    doc: "55601944768",
  },
  {
    doc: "13300396883",
  },
  {
    doc: "26512252829",
  },
  {
    doc: "06154105614",
  },
  {
    doc: "40345055837",
  },
  {
    doc: "02074179200",
  },
  {
    doc: "27524233817",
  },
  {
    doc: "49752643825",
  },
  {
    doc: "99818957172",
  },
  {
    doc: "47452519809",
  },
  {
    doc: "10679161643",
  },
  {
    doc: "41063479894",
  },
  {
    doc: "46761146801",
  },
  {
    doc: "09794833959",
  },
  {
    doc: "49199615886",
  },
  {
    doc: "41542225892",
  },
  {
    doc: "38069734825",
  },
  {
    doc: "03546859537",
  },
  {
    doc: "41058903870",
  },
  {
    doc: "08754887909",
  },
  {
    doc: "12560656760",
  },
  {
    doc: "38016554822",
  },
  {
    doc: "50880729880",
  },
  {
    doc: "21634341848",
  },
  {
    doc: "07530492365",
  },
  {
    doc: "04524473564",
  },
  {
    doc: "44286737870",
  },
  {
    doc: "33069745873",
  },
  {
    doc: "85533351191",
  },
  {
    doc: "10428045677",
  },
  {
    doc: "13514600643",
  },
  {
    doc: "39904820848",
  },
  {
    doc: "45818120899",
  },
  {
    doc: "38084755870",
  },
  {
    doc: "05614943633",
  },
  {
    doc: "14726997777",
  },
  {
    doc: "85647012000",
  },
  {
    doc: "06536520114",
  },
  {
    doc: "10575288620",
  },
  {
    doc: "05556862567",
  },
  {
    doc: "12402894644",
  },
  {
    doc: "10934889643",
  },
  {
    doc: "61042020396",
  },
  {
    doc: "02840554330",
  },
  {
    doc: "92634060491",
  },
  {
    doc: "07401218470",
  },
  {
    doc: "05532212481",
  },
  {
    doc: "63493110197",
  },
  {
    doc: "05517313701",
  },
  {
    doc: "56038194568",
  },
  {
    doc: "70333380150",
  },
  {
    doc: "47224318841",
  },
  {
    doc: "05126707140",
  },
  {
    doc: "41110398808",
  },
  {
    doc: "42610820801",
  },
  {
    doc: "10593736702",
  },
  {
    doc: "42572980819",
  },
  {
    doc: "47940210890",
  },
  {
    doc: "12657917674",
  },
  {
    doc: "04344250524",
  },
  {
    doc: "16018894700",
  },
  {
    doc: "46446530803",
  },
  {
    doc: "43171287803",
  },
  {
    doc: "50198791852",
  },
  {
    doc: "40946986800",
  },
  {
    doc: "01023133261",
  },
  {
    doc: "03823680536",
  },
  {
    doc: "09658563805",
  },
  {
    doc: "35339336875",
  },
  {
    doc: "06860740418",
  },
  {
    doc: "06577659451",
  },
  {
    doc: "09141961625",
  },
  {
    doc: "02064844295",
  },
  {
    doc: "47897460894",
  },
  {
    doc: "46131105804",
  },
  {
    doc: "31172137838",
  },
  {
    doc: "04221506300",
  },
  {
    doc: "48190862863",
  },
  {
    doc: "47852610803",
  },
  {
    doc: "38028494854",
  },
  {
    doc: "41868643816",
  },
  {
    doc: "16979622706",
  },
  {
    doc: "86916718072",
  },
  {
    doc: "42743929880",
  },
  {
    doc: "51297452801",
  },
  {
    doc: "47804119801",
  },
  {
    doc: "39673333882",
  },
  {
    doc: "08750410512",
  },
  {
    doc: "06415366580",
  },
  {
    doc: "16005478770",
  },
  {
    doc: "30878223886",
  },
  {
    doc: "70868904481",
  },
  {
    doc: "12298877688",
  },
  {
    doc: "04254077726",
  },
  {
    doc: "01686871279",
  },
  {
    doc: "39265291800",
  },
  {
    doc: "05316305442",
  },
  {
    doc: "74300024472",
  },
  {
    doc: "16999173702",
  },
  {
    doc: "20390302805",
  },
  {
    doc: "04181517330",
  },
  {
    doc: "03951582146",
  },
  {
    doc: "01582793018",
  },
  {
    doc: "09218692585",
  },
  {
    doc: "10671583409",
  },
  {
    doc: "11352672430",
  },
  {
    doc: "42435630808",
  },
  {
    doc: "70293907170",
  },
  {
    doc: "08928025451",
  },
  {
    doc: "00382384202",
  },
  {
    doc: "62408247390",
  },
  {
    doc: "10801563488",
  },
  {
    doc: "44443576819",
  },
  {
    doc: "86322001272",
  },
  {
    doc: "06914905975",
  },
  {
    doc: "09832982626",
  },
  {
    doc: "03183583445",
  },
  {
    doc: "12659194882",
  },
  {
    doc: "02439990035",
  },
  {
    doc: "06706557676",
  },
  {
    doc: "66047161391",
  },
  {
    doc: "34507683874",
  },
  {
    doc: "44773338890",
  },
  {
    doc: "44677417806",
  },
  {
    doc: "34170450884",
  },
  {
    doc: "79360955515",
  },
  {
    doc: "01051561590",
  },
  {
    doc: "12339123640",
  },
  {
    doc: "10234738448",
  },
  {
    doc: "37014516809",
  },
  {
    doc: "80646565591",
  },
  {
    doc: "07069828438",
  },
  {
    doc: "87400189034",
  },
  {
    doc: "10849935709",
  },
  {
    doc: "28387926876",
  },
  {
    doc: "05216624783",
  },
  {
    doc: "02472564120",
  },
  {
    doc: "43239154897",
  },
  {
    doc: "42301924874",
  },
  {
    doc: "00768904579",
  },
  {
    doc: "04897896509",
  },
  {
    doc: "54786096253",
  },
  {
    doc: "07678403640",
  },
  {
    doc: "37978606848",
  },
  {
    doc: "22242921800",
  },
  {
    doc: "11563865602",
  },
  {
    doc: "32205750801",
  },
  {
    doc: "28297926885",
  },
  {
    doc: "37761957894",
  },
  {
    doc: "02099511098",
  },
  {
    doc: "35951085829",
  },
  {
    doc: "91461200091",
  },
  {
    doc: "05252258112",
  },
  {
    doc: "46915191810",
  },
  {
    doc: "01203900511",
  },
  {
    doc: "60663981301",
  },
  {
    doc: "00019094361",
  },
  {
    doc: "28054599897",
  },
  {
    doc: "41032371803",
  },
  {
    doc: "07550616329",
  },
  {
    doc: "10059010401",
  },
  {
    doc: "89482786068",
  },
  {
    doc: "05282257319",
  },
  {
    doc: "34782797800",
  },
  {
    doc: "81371721068",
  },
  {
    doc: "32481118871",
  },
  {
    doc: "50389100846",
  },
  {
    doc: "36461160809",
  },
  {
    doc: "04740431343",
  },
  {
    doc: "10360957463",
  },
  {
    doc: "41701165856",
  },
  {
    doc: "42650692820",
  },
  {
    doc: "45454937851",
  },
  {
    doc: "12915774757",
  },
  {
    doc: "33101573858",
  },
  {
    doc: "02363414136",
  },
  {
    doc: "07110491579",
  },
  {
    doc: "38116318888",
  },
  {
    doc: "38887306869",
  },
  {
    doc: "33537833857",
  },
  {
    doc: "34753291820",
  },
  {
    doc: "16582491784",
  },
  {
    doc: "86406035020",
  },
  {
    doc: "37847978814",
  },
  {
    doc: "02038442541",
  },
  {
    doc: "10509951716",
  },
  {
    doc: "00414449142",
  },
  {
    doc: "46555423811",
  },
  {
    doc: "12650059745",
  },
  {
    doc: "15353049799",
  },
  {
    doc: "37107287893",
  },
  {
    doc: "79308139249",
  },
  {
    doc: "41342943805",
  },
  {
    doc: "38748225835",
  },
  {
    doc: "40182905861",
  },
  {
    doc: "11870675711",
  },
  {
    doc: "50859777855",
  },
  {
    doc: "10987520903",
  },
  {
    doc: "41502284855",
  },
  {
    doc: "41940797870",
  },
  {
    doc: "16115272874",
  },
  {
    doc: "12590161611",
  },
  {
    doc: "10507976479",
  },
  {
    doc: "97548324200",
  },
  {
    doc: "11594867607",
  },
  {
    doc: "10585292779",
  },
  {
    doc: "10447639730",
  },
  {
    doc: "47004633845",
  },
  {
    doc: "00792137906",
  },
  {
    doc: "39310945885",
  },
  {
    doc: "11651912610",
  },
  {
    doc: "10603308651",
  },
  {
    doc: "17560019790",
  },
  {
    doc: "46075939822",
  },
  {
    doc: "87344130068",
  },
  {
    doc: "86425234091",
  },
  {
    doc: "46738083807",
  },
  {
    doc: "40879932805",
  },
  {
    doc: "06097498543",
  },
  {
    doc: "04145129016",
  },
  {
    doc: "44149516820",
  },
  {
    doc: "42947809831",
  },
  {
    doc: "37607465863",
  },
  {
    doc: "46450391804",
  },
  {
    doc: "40801966876",
  },
  {
    doc: "40055117830",
  },
  {
    doc: "38070739819",
  },
  {
    doc: "09752233481",
  },
  {
    doc: "00974355976",
  },
  {
    doc: "16008842707",
  },
  {
    doc: "42631336844",
  },
  {
    doc: "12665263661",
  },
  {
    doc: "42462899812",
  },
  {
    doc: "05996351188",
  },
  {
    doc: "41055028838",
  },
  {
    doc: "45653928879",
  },
  {
    doc: "11944677410",
  },
  {
    doc: "16059764827",
  },
  {
    doc: "44692943491",
  },
  {
    doc: "12591884935",
  },
  {
    doc: "32629510808",
  },
  {
    doc: "46753155856",
  },
  {
    doc: "25202227272",
  },
  {
    doc: "32202937862",
  },
  {
    doc: "92748805534",
  },
  {
    doc: "08911251836",
  },
  {
    doc: "10770118810",
  },
  {
    doc: "16698188883",
  },
  {
    doc: "40158561805",
  },
  {
    doc: "86012010087",
  },
  {
    doc: "04595620454",
  },
  {
    doc: "52718997800",
  },
  {
    doc: "05418953206",
  },
  {
    doc: "32891561848",
  },
  {
    doc: "02942271990",
  },
  {
    doc: "08543681405",
  },
  {
    doc: "25918010025",
  },
  {
    doc: "49532823883",
  },
  {
    doc: "05838044110",
  },
  {
    doc: "05401626126",
  },
  {
    doc: "70469887141",
  },
  {
    doc: "70219554480",
  },
  {
    doc: "09344473498",
  },
  {
    doc: "22470149843",
  },
  {
    doc: "35884152892",
  },
  {
    doc: "34657925806",
  },
  {
    doc: "06837454684",
  },
  {
    doc: "48987338835",
  },
  {
    doc: "41251435874",
  },
  {
    doc: "12549351690",
  },
  {
    doc: "44682763806",
  },
  {
    doc: "14286316661",
  },
  {
    doc: "44206163855",
  },
  {
    doc: "67645330406",
  },
  {
    doc: "11232740969",
  },
  {
    doc: "14196985670",
  },
  {
    doc: "01742846246",
  },
  {
    doc: "19011569741",
  },
  {
    doc: "38743355854",
  },
  {
    doc: "09984185958",
  },
  {
    doc: "17224631710",
  },
  {
    doc: "86147438581",
  },
  {
    doc: "02994376202",
  },
  {
    doc: "05939233163",
  },
  {
    doc: "94354499215",
  },
  {
    doc: "86606760330",
  },
  {
    doc: "32029855820",
  },
  {
    doc: "66745497972",
  },
  {
    doc: "02748272552",
  },
  {
    doc: "03554374571",
  },
  {
    doc: "34813092870",
  },
  {
    doc: "36525095875",
  },
  {
    doc: "92047017149",
  },
  {
    doc: "06825014846",
  },
  {
    doc: "08125116974",
  },
  {
    doc: "87927640291",
  },
  {
    doc: "15883818625",
  },
  {
    doc: "39432398889",
  },
  {
    doc: "39544025804",
  },
  {
    doc: "41194499805",
  },
  {
    doc: "09419104400",
  },
  {
    doc: "32002720827",
  },
  {
    doc: "17273104701",
  },
  {
    doc: "00929454065",
  },
  {
    doc: "10183180976",
  },
  {
    doc: "02851691309",
  },
  {
    doc: "37931315804",
  },
  {
    doc: "01530081300",
  },
  {
    doc: "06523598500",
  },
  {
    doc: "10883483432",
  },
  {
    doc: "00453159222",
  },
  {
    doc: "14185320612",
  },
  {
    doc: "99607093291",
  },
  {
    doc: "00375019170",
  },
  {
    doc: "14020613745",
  },
  {
    doc: "11995754706",
  },
  {
    doc: "39703394809",
  },
  {
    doc: "09604719602",
  },
  {
    doc: "40912160802",
  },
  {
    doc: "70153125659",
  },
  {
    doc: "14568290767",
  },
  {
    doc: "38622061817",
  },
  {
    doc: "37710685846",
  },
  {
    doc: "02189924596",
  },
  {
    doc: "33866189877",
  },
  {
    doc: "04510842929",
  },
  {
    doc: "03932865502",
  },
  {
    doc: "06675915320",
  },
  {
    doc: "76369056391",
  },
  {
    doc: "12600808701",
  },
  {
    doc: "12632223407",
  },
  {
    doc: "02575780055",
  },
  {
    doc: "69550425134",
  },
  {
    doc: "02812474807",
  },
  {
    doc: "42056165899",
  },
  {
    doc: "07284271507",
  },
  {
    doc: "70904961478",
  },
  {
    doc: "04474181654",
  },
  {
    doc: "13300931860",
  },
  {
    doc: "70163221227",
  },
  {
    doc: "41769502858",
  },
  {
    doc: "60010924515",
  },
  {
    doc: "07160994620",
  },
  {
    doc: "32491527812",
  },
  {
    doc: "17750337830",
  },
  {
    doc: "85684627587",
  },
  {
    doc: "83970681391",
  },
  {
    doc: "26328765835",
  },
  {
    doc: "02576069398",
  },
  {
    doc: "02246569451",
  },
  {
    doc: "35105906861",
  },
  {
    doc: "04661706455",
  },
  {
    doc: "70943405408",
  },
  {
    doc: "09632348460",
  },
  {
    doc: "09044778862",
  },
  {
    doc: "16467395743",
  },
  {
    doc: "07755107345",
  },
  {
    doc: "13579422731",
  },
  {
    doc: "09522231479",
  },
  {
    doc: "40491165889",
  },
  {
    doc: "25595462809",
  },
  {
    doc: "05149724670",
  },
  {
    doc: "37952039881",
  },
  {
    doc: "18554897838",
  },
  {
    doc: "77078500768",
  },
  {
    doc: "03656435138",
  },
  {
    doc: "60747558337",
  },
  {
    doc: "66410827334",
  },
  {
    doc: "05421335550",
  },
  {
    doc: "01300181478",
  },
  {
    doc: "61516660897",
  },
  {
    doc: "12845578474",
  },
  {
    doc: "16380727795",
  },
  {
    doc: "34598442808",
  },
  {
    doc: "02739765401",
  },
  {
    doc: "60637625587",
  },
  {
    doc: "31335410805",
  },
  {
    doc: "03800023610",
  },
  {
    doc: "92512364534",
  },
  {
    doc: "32597285898",
  },
  {
    doc: "47037270482",
  },
  {
    doc: "03985065403",
  },
  {
    doc: "11149020474",
  },
  {
    doc: "10581884884",
  },
  {
    doc: "13583268784",
  },
  {
    doc: "70518297420",
  },
  {
    doc: "09342512402",
  },
  {
    doc: "00127896406",
  },
  {
    doc: "03733738560",
  },
  {
    doc: "47085100397",
  },
  {
    doc: "07048078316",
  },
  {
    doc: "22163967883",
  },
  {
    doc: "39519332839",
  },
  {
    doc: "65150350397",
  },
  {
    doc: "85490849304",
  },
  {
    doc: "10535265476",
  },
  {
    doc: "85828355589",
  },
  {
    doc: "39727709877",
  },
  {
    doc: "11576338622",
  },
  {
    doc: "81444150197",
  },
  {
    doc: "10517784416",
  },
  {
    doc: "73480169104",
  },
  {
    doc: "12793550698",
  },
  {
    doc: "47998689854",
  },
  {
    doc: "10412594684",
  },
  {
    doc: "08910655631",
  },
  {
    doc: "04804677496",
  },
  {
    doc: "08283325760",
  },
  {
    doc: "11964444713",
  },
  {
    doc: "06291649594",
  },
  {
    doc: "16498513675",
  },
  {
    doc: "70314927425",
  },
  {
    doc: "16181199748",
  },
  {
    doc: "03738270167",
  },
  {
    doc: "12044844656",
  },
  {
    doc: "19121990760",
  },
  {
    doc: "47908182844",
  },
  {
    doc: "38196916850",
  },
  {
    doc: "06664602997",
  },
  {
    doc: "00764039393",
  },
  {
    doc: "38714875896",
  },
  {
    doc: "00781749611",
  },
  {
    doc: "42441053876",
  },
  {
    doc: "45440235809",
  },
  {
    doc: "32431912892",
  },
  {
    doc: "09956462446",
  },
  {
    doc: "08612975638",
  },
  {
    doc: "43168602833",
  },
  {
    doc: "15909024752",
  },
  {
    doc: "15571096721",
  },
  {
    doc: "06185839636",
  },
  {
    doc: "09329244920",
  },
  {
    doc: "17988666762",
  },
  {
    doc: "33773997809",
  },
  {
    doc: "29790894864",
  },
  {
    doc: "00553063901",
  },
  {
    doc: "04507634393",
  },
  {
    doc: "03839003601",
  },
  {
    doc: "39548816830",
  },
  {
    doc: "31306448816",
  },
  {
    doc: "07349460589",
  },
  {
    doc: "11534495606",
  },
  {
    doc: "40766296806",
  },
  {
    doc: "23591533823",
  },
  {
    doc: "17334625706",
  },
  {
    doc: "12984435630",
  },
  {
    doc: "02721032178",
  },
  {
    doc: "40404970826",
  },
  {
    doc: "29660185855",
  },
  {
    doc: "18798351850",
  },
  {
    doc: "02356835318",
  },
  {
    doc: "00784360286",
  },
  {
    doc: "36432688825",
  },
  {
    doc: "52309274291",
  },
  {
    doc: "39794651877",
  },
  {
    doc: "06460944598",
  },
  {
    doc: "10459317806",
  },
  {
    doc: "79036430534",
  },
  {
    doc: "04304026780",
  },
  {
    doc: "43598996888",
  },
  {
    doc: "06124859513",
  },
  {
    doc: "40252454855",
  },
  {
    doc: "36679033837",
  },
  {
    doc: "49457387856",
  },
  {
    doc: "10969677901",
  },
  {
    doc: "16596128709",
  },
  {
    doc: "85875015004",
  },
  {
    doc: "05736479419",
  },
  {
    doc: "00242922228",
  },
  {
    doc: "45969065803",
  },
  {
    doc: "42329056850",
  },
  {
    doc: "33374933866",
  },
  {
    doc: "48967603878",
  },
  {
    doc: "14326804475",
  },
  {
    doc: "03807956476",
  },
  {
    doc: "36018085879",
  },
  {
    doc: "00666230064",
  },
  {
    doc: "02229427709",
  },
  {
    doc: "45651541888",
  },
  {
    doc: "07425196786",
  },
  {
    doc: "70071546111",
  },
  {
    doc: "50605849846",
  },
  {
    doc: "03272268383",
  },
  {
    doc: "01855010100",
  },
  {
    doc: "07411148440",
  },
  {
    doc: "17745697875",
  },
  {
    doc: "13699267660",
  },
  {
    doc: "41797561880",
  },
  {
    doc: "10596340907",
  },
  {
    doc: "08126221704",
  },
  {
    doc: "27581348881",
  },
  {
    doc: "27302757895",
  },
  {
    doc: "06482014496",
  },
  {
    doc: "47548213867",
  },
  {
    doc: "09066533986",
  },
  {
    doc: "47436820885",
  },
  {
    doc: "12822103607",
  },
  {
    doc: "12522904701",
  },
  {
    doc: "04729874455",
  },
  {
    doc: "37831069828",
  },
  {
    doc: "14256969705",
  },
  {
    doc: "02710477394",
  },
  {
    doc: "35725763854",
  },
  {
    doc: "30263066819",
  },
  {
    doc: "01257215019",
  },
  {
    doc: "31322501807",
  },
  {
    doc: "13580548603",
  },
  {
    doc: "86624008091",
  },
  {
    doc: "49349126818",
  },
  {
    doc: "38302424846",
  },
  {
    doc: "38954258859",
  },
  {
    doc: "12522891979",
  },
  {
    doc: "86738704020",
  },
  {
    doc: "09878477916",
  },
  {
    doc: "17153690760",
  },
  {
    doc: "09122328467",
  },
  {
    doc: "05512274511",
  },
  {
    doc: "34335788851",
  },
  {
    doc: "38141776819",
  },
  {
    doc: "26752632812",
  },
  {
    doc: "36217513862",
  },
  {
    doc: "86528475172",
  },
  {
    doc: "02608268390",
  },
  {
    doc: "44216338879",
  },
  {
    doc: "09074383424",
  },
  {
    doc: "43031178890",
  },
  {
    doc: "60778085392",
  },
  {
    doc: "11272806430",
  },
  {
    doc: "12292657678",
  },
  {
    doc: "36753868801",
  },
  {
    doc: "11755452608",
  },
  {
    doc: "44979949886",
  },
  {
    doc: "15338596717",
  },
  {
    doc: "43150158818",
  },
  {
    doc: "43976922897",
  },
  {
    doc: "45897789800",
  },
  {
    doc: "02829896505",
  },
  {
    doc: "43470754802",
  },
  {
    doc: "48519521800",
  },
  {
    doc: "02620478537",
  },
  {
    doc: "03674724146",
  },
  {
    doc: "18545023871",
  },
  {
    doc: "12890895742",
  },
  {
    doc: "01921780169",
  },
  {
    doc: "39540913802",
  },
  {
    doc: "34777319806",
  },
  {
    doc: "05468594374",
  },
  {
    doc: "00977208958",
  },
  {
    doc: "43733994841",
  },
  {
    doc: "37203197840",
  },
  {
    doc: "28720480814",
  },
  {
    doc: "14415549713",
  },
  {
    doc: "35809157890",
  },
  {
    doc: "37808475823",
  },
  {
    doc: "43500602860",
  },
  {
    doc: "08351245925",
  },
  {
    doc: "05490927739",
  },
  {
    doc: "03592091140",
  },
  {
    doc: "41024190803",
  },
  {
    doc: "15564557808",
  },
  {
    doc: "09797544613",
  },
  {
    doc: "11655323601",
  },
  {
    doc: "10322555795",
  },
  {
    doc: "35135058842",
  },
  {
    doc: "12164683625",
  },
  {
    doc: "05485425119",
  },
  {
    doc: "26607949878",
  },
  {
    doc: "04269684178",
  },
  {
    doc: "03389629300",
  },
  {
    doc: "03642686621",
  },
  {
    doc: "01341949508",
  },
  {
    doc: "07314915563",
  },
  {
    doc: "74689860297",
  },
  {
    doc: "35409140800",
  },
  {
    doc: "01660273676",
  },
  {
    doc: "10822963760",
  },
  {
    doc: "09254410900",
  },
  {
    doc: "38530723805",
  },
  {
    doc: "09916071713",
  },
  {
    doc: "22879391806",
  },
  {
    doc: "44125906823",
  },
  {
    doc: "06326419590",
  },
  {
    doc: "07917010422",
  },
  {
    doc: "30318961814",
  },
  {
    doc: "09309562650",
  },
  {
    doc: "22467589803",
  },
  {
    doc: "40329113801",
  },
  {
    doc: "13164327692",
  },
  {
    doc: "42613161884",
  },
  {
    doc: "96911158591",
  },
  {
    doc: "06148013703",
  },
  {
    doc: "12494384702",
  },
  {
    doc: "04933813175",
  },
  {
    doc: "04704040941",
  },
  {
    doc: "36185551802",
  },
  {
    doc: "22952388890",
  },
  {
    doc: "47408554882",
  },
  {
    doc: "36547454827",
  },
  {
    doc: "09805563766",
  },
  {
    doc: "06658558345",
  },
  {
    doc: "01282723251",
  },
  {
    doc: "04067003897",
  },
  {
    doc: "14364967645",
  },
  {
    doc: "03090052059",
  },
  {
    doc: "37738905832",
  },
  {
    doc: "42921122820",
  },
  {
    doc: "05391836107",
  },
  {
    doc: "02950491111",
  },
  {
    doc: "44004049865",
  },
  {
    doc: "05245251108",
  },
  {
    doc: "15777528724",
  },
  {
    doc: "37378967807",
  },
  {
    doc: "46564075855",
  },
  {
    doc: "39781239832",
  },
  {
    doc: "29041393897",
  },
  {
    doc: "06781086170",
  },
  {
    doc: "31613923880",
  },
  {
    doc: "41972493817",
  },
  {
    doc: "39782773875",
  },
  {
    doc: "35490227869",
  },
  {
    doc: "36561030893",
  },
  {
    doc: "01096147041",
  },
  {
    doc: "06717529601",
  },
  {
    doc: "00569264138",
  },
  {
    doc: "10172089646",
  },
  {
    doc: "31975319818",
  },
  {
    doc: "04752450321",
  },
  {
    doc: "31247107892",
  },
  {
    doc: "44471715895",
  },
  {
    doc: "04085098306",
  },
  {
    doc: "40047854804",
  },
  {
    doc: "13161703650",
  },
  {
    doc: "12694619665",
  },
  {
    doc: "44205962878",
  },
  {
    doc: "07517087983",
  },
  {
    doc: "98982079300",
  },
  {
    doc: "02581741023",
  },
  {
    doc: "14790156605",
  },
  {
    doc: "34875775857",
  },
  {
    doc: "38265777876",
  },
  {
    doc: "40480129835",
  },
  {
    doc: "02706877260",
  },
  {
    doc: "44189223859",
  },
  {
    doc: "48358534825",
  },
  {
    doc: "42322305804",
  },
  {
    doc: "37419562873",
  },
  {
    doc: "09539028973",
  },
  {
    doc: "10581110943",
  },
  {
    doc: "45022466899",
  },
  {
    doc: "47271089845",
  },
  {
    doc: "70462323188",
  },
  {
    doc: "36562585899",
  },
  {
    doc: "43665472873",
  },
  {
    doc: "53150776830",
  },
  {
    doc: "11423481488",
  },
  {
    doc: "04992856543",
  },
  {
    doc: "60743437306",
  },
  {
    doc: "38277846894",
  },
  {
    doc: "13439344789",
  },
  {
    doc: "13172952690",
  },
  {
    doc: "03873314177",
  },
  {
    doc: "42693206804",
  },
  {
    doc: "38276954812",
  },
  {
    doc: "16975020752",
  },
  {
    doc: "41493402854",
  },
  {
    doc: "46410698836",
  },
  {
    doc: "04067990162",
  },
  {
    doc: "49384223875",
  },
  {
    doc: "17116069798",
  },
  {
    doc: "45895960839",
  },
  {
    doc: "05477299525",
  },
  {
    doc: "39212156843",
  },
  {
    doc: "15120393730",
  },
  {
    doc: "49529180845",
  },
  {
    doc: "15741538703",
  },
  {
    doc: "35322421840",
  },
  {
    doc: "46262739807",
  },
  {
    doc: "46988671854",
  },
  {
    doc: "38656031813",
  },
  {
    doc: "39298453850",
  },
  {
    doc: "29358474823",
  },
  {
    doc: "14175456660",
  },
  {
    doc: "03920893000",
  },
  {
    doc: "44853151850",
  },
  {
    doc: "41899031839",
  },
  {
    doc: "39598862844",
  },
  {
    doc: "42085009808",
  },
  {
    doc: "04531642180",
  },
  {
    doc: "47999213802",
  },
  {
    doc: "44010462850",
  },
  {
    doc: "42675293881",
  },
  {
    doc: "45673862806",
  },
  {
    doc: "14836395763",
  },
  {
    doc: "12831997429",
  },
  {
    doc: "39181856806",
  },
  {
    doc: "15571016710",
  },
  {
    doc: "41283199882",
  },
  {
    doc: "02352883652",
  },
  {
    doc: "42900048818",
  },
  {
    doc: "12944066633",
  },
  {
    doc: "44165843857",
  },
  {
    doc: "28225578880",
  },
  {
    doc: "09853272865",
  },
  {
    doc: "04256791604",
  },
  {
    doc: "31576972852",
  },
  {
    doc: "13492240810",
  },
  {
    doc: "12312309670",
  },
  {
    doc: "16882013861",
  },
  {
    doc: "20629572895",
  },
  {
    doc: "43831889821",
  },
  {
    doc: "02821992416",
  },
  {
    doc: "22233963806",
  },
  {
    doc: "29358572833",
  },
  {
    doc: "15936841855",
  },
  {
    doc: "30721701850",
  },
  {
    doc: "32709216833",
  },
  {
    doc: "45579753802",
  },
  {
    doc: "13145934805",
  },
  {
    doc: "28704111893",
  },
  {
    doc: "02930884541",
  },
  {
    doc: "07765693760",
  },
  {
    doc: "26415794809",
  },
  {
    doc: "29540573866",
  },
  {
    doc: "24857517817",
  },
  {
    doc: "41149677880",
  },
  {
    doc: "18389590840",
  },
  {
    doc: "91423252500",
  },
  {
    doc: "35915098843",
  },
  {
    doc: "09664574716",
  },
  {
    doc: "71071094483",
  },
  {
    doc: "07894577502",
  },
  {
    doc: "08409980746",
  },
  {
    doc: "18134719830",
  },
  {
    doc: "86080512734",
  },
  {
    doc: "99980304391",
  },
  {
    doc: "64533506534",
  },
  {
    doc: "70598496459",
  },
  {
    doc: "16369558869",
  },
  {
    doc: "02332268502",
  },
  {
    doc: "48664564850",
  },
  {
    doc: "08834647726",
  },
  {
    doc: "05033629484",
  },
  {
    doc: "19353407869",
  },
  {
    doc: "47916200899",
  },
  {
    doc: "47821958859",
  },
  {
    doc: "06983577361",
  },
  {
    doc: "22590001851",
  },
  {
    doc: "40878388893",
  },
  {
    doc: "43444135807",
  },
  {
    doc: "12090569760",
  },
  {
    doc: "04965880102",
  },
  {
    doc: "41222144832",
  },
  {
    doc: "12924633761",
  },
  {
    doc: "04108849019",
  },
  {
    doc: "12439014974",
  },
  {
    doc: "18341573822",
  },
  {
    doc: "04506357086",
  },
  {
    doc: "33772138802",
  },
  {
    doc: "40439445000",
  },
  {
    doc: "01228735131",
  },
  {
    doc: "35797816857",
  },
  {
    doc: "05865251490",
  },
  {
    doc: "28445765833",
  },
  {
    doc: "05185869364",
  },
  {
    doc: "08528436667",
  },
  {
    doc: "08713013777",
  },
  {
    doc: "28594756801",
  },
  {
    doc: "12679497759",
  },
  {
    doc: "45429704857",
  },
  {
    doc: "29323069876",
  },
  {
    doc: "61336221305",
  },
  {
    doc: "08053201997",
  },
  {
    doc: "38763074818",
  },
  {
    doc: "07502653619",
  },
  {
    doc: "13828631606",
  },
  {
    doc: "07026519662",
  },
  {
    doc: "38913871807",
  },
  {
    doc: "17627646790",
  },
  {
    doc: "42373541807",
  },
  {
    doc: "05252390738",
  },
  {
    doc: "30549324860",
  },
  {
    doc: "30167331884",
  },
  {
    doc: "17087810804",
  },
  {
    doc: "14006207751",
  },
  {
    doc: "12229449796",
  },
  {
    doc: "06358261436",
  },
  {
    doc: "12913868746",
  },
  {
    doc: "27466127800",
  },
  {
    doc: "12341039740",
  },
  {
    doc: "02365546382",
  },
  {
    doc: "37884502828",
  },
  {
    doc: "87184877004",
  },
  {
    doc: "01322406294",
  },
  {
    doc: "47003455862",
  },
  {
    doc: "36502525890",
  },
  {
    doc: "17557653718",
  },
  {
    doc: "27388227890",
  },
  {
    doc: "31315987864",
  },
  {
    doc: "01888515341",
  },
  {
    doc: "32748912829",
  },
  {
    doc: "11616587423",
  },
  {
    doc: "32542248842",
  },
  {
    doc: "42317828810",
  },
  {
    doc: "29182385890",
  },
  {
    doc: "32878027809",
  },
  {
    doc: "00906347300",
  },
  {
    doc: "44743015871",
  },
  {
    doc: "38228970862",
  },
  {
    doc: "06769767600",
  },
  {
    doc: "36963788871",
  },
  {
    doc: "02277726010",
  },
  {
    doc: "38282563846",
  },
  {
    doc: "07998983418",
  },
  {
    doc: "60038595397",
  },
  {
    doc: "14585376682",
  },
  {
    doc: "35066223827",
  },
  {
    doc: "23095883854",
  },
  {
    doc: "06314354846",
  },
  {
    doc: "08618799459",
  },
  {
    doc: "03045991408",
  },
  {
    doc: "23427208826",
  },
  {
    doc: "03339523320",
  },
  {
    doc: "18686655777",
  },
  {
    doc: "42937891896",
  },
  {
    doc: "07698063500",
  },
  {
    doc: "36141059899",
  },
  {
    doc: "37457109838",
  },
  {
    doc: "39704630840",
  },
  {
    doc: "06439371533",
  },
  {
    doc: "52673774300",
  },
  {
    doc: "08127954667",
  },
  {
    doc: "43122630869",
  },
  {
    doc: "15634347840",
  },
  {
    doc: "04014492460",
  },
  {
    doc: "43443696880",
  },
  {
    doc: "04711888160",
  },
  {
    doc: "03436410705",
  },
  {
    doc: "09219532670",
  },
  {
    doc: "32538991870",
  },
  {
    doc: "11496617711",
  },
  {
    doc: "06547414680",
  },
  {
    doc: "35607311864",
  },
  {
    doc: "38037970809",
  },
  {
    doc: "37993456800",
  },
  {
    doc: "75812827087",
  },
  {
    doc: "00029693608",
  },
  {
    doc: "13589448652",
  },
  {
    doc: "07460603783",
  },
  {
    doc: "40848918860",
  },
  {
    doc: "09354495745",
  },
  {
    doc: "05114182307",
  },
  {
    doc: "35482771873",
  },
  {
    doc: "32222053889",
  },
  {
    doc: "41751374831",
  },
  {
    doc: "85856681991",
  },
  {
    doc: "05154592751",
  },
  {
    doc: "19611788851",
  },
  {
    doc: "29335145831",
  },
  {
    doc: "21716350883",
  },
  {
    doc: "22708098829",
  },
  {
    doc: "31168109825",
  },
  {
    doc: "28925562871",
  },
  {
    doc: "27375925852",
  },
  {
    doc: "99624575134",
  },
  {
    doc: "02727652364",
  },
  {
    doc: "07391610674",
  },
  {
    doc: "26848656801",
  },
  {
    doc: "25817150816",
  },
  {
    doc: "32218600803",
  },
  {
    doc: "28899835870",
  },
  {
    doc: "03438367424",
  },
  {
    doc: "08074639738",
  },
  {
    doc: "03918918475",
  },
  {
    doc: "68257686549",
  },
  {
    doc: "94468508787",
  },
  {
    doc: "62119605300",
  },
  {
    doc: "04650303605",
  },
  {
    doc: "27869645800",
  },
  {
    doc: "03911175132",
  },
  {
    doc: "45465218800",
  },
  {
    doc: "05652240776",
  },
  {
    doc: "32451036893",
  },
  {
    doc: "30619635886",
  },
  {
    doc: "10961251450",
  },
  {
    doc: "32170760873",
  },
  {
    doc: "03752358092",
  },
  {
    doc: "07445706461",
  },
  {
    doc: "03558445678",
  },
  {
    doc: "08043292655",
  },
  {
    doc: "36373785823",
  },
  {
    doc: "04535074844",
  },
  {
    doc: "04245861770",
  },
  {
    doc: "50558122817",
  },
  {
    doc: "70994811268",
  },
  {
    doc: "18543112800",
  },
  {
    doc: "42771462877",
  },
  {
    doc: "12440040657",
  },
  {
    doc: "80495125504",
  },
  {
    doc: "09831276701",
  },
  {
    doc: "04694633686",
  },
  {
    doc: "33652137833",
  },
  {
    doc: "01490012079",
  },
  {
    doc: "95257039168",
  },
  {
    doc: "42030045861",
  },
  {
    doc: "14591606651",
  },
  {
    doc: "05330619114",
  },
  {
    doc: "40535925859",
  },
  {
    doc: "15892660729",
  },
  {
    doc: "34524839836",
  },
  {
    doc: "17195521850",
  },
  {
    doc: "06119843507",
  },
  {
    doc: "25843326802",
  },
  {
    doc: "02665564343",
  },
  {
    doc: "14615929684",
  },
  {
    doc: "37583490871",
  },
  {
    doc: "07581212882",
  },
  {
    doc: "90543025187",
  },
  {
    doc: "01105198766",
  },
  {
    doc: "16570934848",
  },
  {
    doc: "07750701656",
  },
  {
    doc: "02928066551",
  },
  {
    doc: "45941408315",
  },
  {
    doc: "22820588875",
  },
  {
    doc: "06095374740",
  },
  {
    doc: "29996555844",
  },
  {
    doc: "22652322893",
  },
  {
    doc: "19688616885",
  },
  {
    doc: "41415697809",
  },
  {
    doc: "06351584507",
  },
  {
    doc: "07500678371",
  },
  {
    doc: "12135127515",
  },
  {
    doc: "62850202568",
  },
  {
    doc: "02736867807",
  },
  {
    doc: "14695874830",
  },
  {
    doc: "11484307844",
  },
  {
    doc: "31326229826",
  },
  {
    doc: "63799561315",
  },
  {
    doc: "29674635823",
  },
  {
    doc: "40341635855",
  },
  {
    doc: "01861681364",
  },
  {
    doc: "07409019310",
  },
  {
    doc: "81751508900",
  },
  {
    doc: "45524264869",
  },
  {
    doc: "00910038732",
  },
  {
    doc: "05314924439",
  },
  {
    doc: "39560507893",
  },
  {
    doc: "06537815479",
  },
  {
    doc: "46516366837",
  },
  {
    doc: "39274733836",
  },
  {
    doc: "33892391890",
  },
  {
    doc: "07307597926",
  },
  {
    doc: "86034812372",
  },
  {
    doc: "31981854835",
  },
  {
    doc: "26288549886",
  },
  {
    doc: "63128748004",
  },
  {
    doc: "12590259697",
  },
  {
    doc: "35004469814",
  },
  {
    doc: "58981047391",
  },
  {
    doc: "88003078334",
  },
  {
    doc: "41637446810",
  },
  {
    doc: "07604008496",
  },
  {
    doc: "10115400451",
  },
  {
    doc: "13797164700",
  },
  {
    doc: "25659548830",
  },
  {
    doc: "01106064348",
  },
  {
    doc: "11073564908",
  },
  {
    doc: "06732207458",
  },
  {
    doc: "07095101361",
  },
  {
    doc: "02932460331",
  },
  {
    doc: "28817036315",
  },
  {
    doc: "11023705761",
  },
  {
    doc: "00927812924",
  },
  {
    doc: "04240881188",
  },
  {
    doc: "08195469841",
  },
  {
    doc: "88948552287",
  },
  {
    doc: "01590776305",
  },
  {
    doc: "23294881819",
  },
  {
    doc: "13297148810",
  },
  {
    doc: "40714023850",
  },
  {
    doc: "49872430837",
  },
  {
    doc: "93187068120",
  },
  {
    doc: "06760749103",
  },
  {
    doc: "42121538828",
  },
  {
    doc: "05549048701",
  },
  {
    doc: "41140091832",
  },
  {
    doc: "46576627831",
  },
  {
    doc: "05485721788",
  },
  {
    doc: "46571891824",
  },
  {
    doc: "04191744941",
  },
  {
    doc: "12193064792",
  },
  {
    doc: "37363637881",
  },
  {
    doc: "41906128200",
  },
  {
    doc: "03847832107",
  },
  {
    doc: "09895773927",
  },
  {
    doc: "48580862833",
  },
  {
    doc: "04809442373",
  },
  {
    doc: "35659909884",
  },
  {
    doc: "34194993888",
  },
  {
    doc: "40261693832",
  },
  {
    doc: "04907135130",
  },
  {
    doc: "11559857617",
  },
  {
    doc: "04552762699",
  },
  {
    doc: "82440603520",
  },
  {
    doc: "24922377883",
  },
  {
    doc: "44030611842",
  },
  {
    doc: "85255653004",
  },
  {
    doc: "06796801440",
  },
  {
    doc: "02856917160",
  },
  {
    doc: "60074604520",
  },
  {
    doc: "01600304044",
  },
  {
    doc: "26145314800",
  },
  {
    doc: "03633854363",
  },
  {
    doc: "13328211896",
  },
  {
    doc: "33808215860",
  },
  {
    doc: "01105546390",
  },
  {
    doc: "33132760838",
  },
  {
    doc: "13864666708",
  },
  {
    doc: "45368992866",
  },
  {
    doc: "45300869848",
  },
  {
    doc: "16367083740",
  },
  {
    doc: "42655600835",
  },
  {
    doc: "45573671863",
  },
  {
    doc: "49744306882",
  },
  {
    doc: "18004608728",
  },
  {
    doc: "15038936776",
  },
  {
    doc: "14323251670",
  },
  {
    doc: "49907998877",
  },
  {
    doc: "16058602718",
  },
  {
    doc: "45898476806",
  },
  {
    doc: "49915979860",
  },
  {
    doc: "09582715936",
  },
  {
    doc: "17648178770",
  },
  {
    doc: "41071466852",
  },
  {
    doc: "45514503836",
  },
  {
    doc: "13247047727",
  },
  {
    doc: "10204120977",
  },
  {
    doc: "04996612142",
  },
  {
    doc: "10225801671",
  },
  {
    doc: "08096814982",
  },
  {
    doc: "47882148814",
  },
  {
    doc: "40993030807",
  },
  {
    doc: "15033046713",
  },
  {
    doc: "48129609835",
  },
  {
    doc: "51170374808",
  },
  {
    doc: "14070841601",
  },
  {
    doc: "60910141371",
  },
  {
    doc: "45522376830",
  },
  {
    doc: "05882289408",
  },
  {
    doc: "01897288093",
  },
  {
    doc: "46852524892",
  },
  {
    doc: "05372880902",
  },
  {
    doc: "30398589801",
  },
  {
    doc: "12996756703",
  },
  {
    doc: "93275331787",
  },
  {
    doc: "01162908548",
  },
  {
    doc: "07189992405",
  },
  {
    doc: "32477017888",
  },
  {
    doc: "16193340629",
  },
  {
    doc: "85916382200",
  },
  {
    doc: "06807552173",
  },
  {
    doc: "08369278779",
  },
  {
    doc: "05990292694",
  },
  {
    doc: "14463752779",
  },
  {
    doc: "41739946804",
  },
  {
    doc: "09857137474",
  },
  {
    doc: "49125720805",
  },
  {
    doc: "11544297688",
  },
  {
    doc: "23009653808",
  },
  {
    doc: "08330048918",
  },
  {
    doc: "30351342869",
  },
  {
    doc: "35861438854",
  },
  {
    doc: "03200742267",
  },
  {
    doc: "40703961870",
  },
  {
    doc: "39503075831",
  },
  {
    doc: "16561927729",
  },
  {
    doc: "40785916806",
  },
  {
    doc: "04858404307",
  },
  {
    doc: "01190944367",
  },
  {
    doc: "40684999854",
  },
  {
    doc: "10586299483",
  },
  {
    doc: "01116445344",
  },
  {
    doc: "38927367820",
  },
  {
    doc: "14038519643",
  },
  {
    doc: "07418033990",
  },
  {
    doc: "40153669802",
  },
  {
    doc: "13343721794",
  },
  {
    doc: "28191063824",
  },
  {
    doc: "17061370793",
  },
  {
    doc: "40682824801",
  },
  {
    doc: "31505122805",
  },
  {
    doc: "14902312778",
  },
  {
    doc: "09069451603",
  },
  {
    doc: "10362033951",
  },
  {
    doc: "03529961450",
  },
  {
    doc: "00263256596",
  },
  {
    doc: "45487370818",
  },
  {
    doc: "02263196951",
  },
  {
    doc: "70739324144",
  },
  {
    doc: "06298604758",
  },
  {
    doc: "26991734870",
  },
  {
    doc: "41820842843",
  },
  {
    doc: "17224610712",
  },
  {
    doc: "21516204840",
  },
  {
    doc: "18331225775",
  },
  {
    doc: "48035050125",
  },
  {
    doc: "42807256830",
  },
  {
    doc: "43692890842",
  },
  {
    doc: "18324470735",
  },
  {
    doc: "47330838829",
  },
  {
    doc: "42017323802",
  },
  {
    doc: "10827421478",
  },
  {
    doc: "01386559440",
  },
  {
    doc: "76792188204",
  },
  {
    doc: "03774053375",
  },
  {
    doc: "43830362870",
  },
  {
    doc: "16538555764",
  },
  {
    doc: "01249484057",
  },
  {
    doc: "40331965879",
  },
  {
    doc: "01440168504",
  },
  {
    doc: "45543482884",
  },
  {
    doc: "35601806861",
  },
  {
    doc: "42169258809",
  },
  {
    doc: "38810175859",
  },
  {
    doc: "38069871814",
  },
  {
    doc: "44062043858",
  },
  {
    doc: "40912210842",
  },
  {
    doc: "11115647792",
  },
  {
    doc: "41883501806",
  },
  {
    doc: "44112338864",
  },
  {
    doc: "12341549608",
  },
  {
    doc: "37668487808",
  },
  {
    doc: "03664803086",
  },
  {
    doc: "10752327674",
  },
  {
    doc: "39125655892",
  },
  {
    doc: "71558259481",
  },
  {
    doc: "40365156833",
  },
  {
    doc: "04392381374",
  },
  {
    doc: "41903322839",
  },
  {
    doc: "12505509476",
  },
  {
    doc: "44314742852",
  },
  {
    doc: "36566444890",
  },
  {
    doc: "14009952725",
  },
  {
    doc: "40382812808",
  },
  {
    doc: "02451087188",
  },
  {
    doc: "12048989608",
  },
  {
    doc: "43447332824",
  },
  {
    doc: "47026054880",
  },
  {
    doc: "43581238802",
  },
  {
    doc: "67201822004",
  },
  {
    doc: "48965994896",
  },
  {
    doc: "12526537436",
  },
  {
    doc: "49100280801",
  },
  {
    doc: "35302049864",
  },
  {
    doc: "52002549818",
  },
  {
    doc: "37008683839",
  },
  {
    doc: "42285563884",
  },
  {
    doc: "13344542648",
  },
  {
    doc: "41121308805",
  },
  {
    doc: "35394523894",
  },
  {
    doc: "03454224306",
  },
  {
    doc: "13164141408",
  },
  {
    doc: "04091459200",
  },
  {
    doc: "41351717880",
  },
  {
    doc: "01378862414",
  },
  {
    doc: "35416107863",
  },
  {
    doc: "49386932830",
  },
  {
    doc: "08405303405",
  },
  {
    doc: "01858125383",
  },
  {
    doc: "12092125605",
  },
  {
    doc: "10773927611",
  },
  {
    doc: "40609467883",
  },
  {
    doc: "09865777665",
  },
  {
    doc: "05151154524",
  },
  {
    doc: "15355269710",
  },
  {
    doc: "02619824516",
  },
  {
    doc: "07392002163",
  },
  {
    doc: "08501714828",
  },
  {
    doc: "09355454767",
  },
  {
    doc: "06126580826",
  },
  {
    doc: "18550379808",
  },
  {
    doc: "70746147422",
  },
  {
    doc: "70234021101",
  },
  {
    doc: "31996583859",
  },
  {
    doc: "51602628572",
  },
  {
    doc: "70114319421",
  },
  {
    doc: "42008983811",
  },
  {
    doc: "12078507644",
  },
  {
    doc: "14340390640",
  },
  {
    doc: "25604215856",
  },
  {
    doc: "01293388696",
  },
  {
    doc: "04905987300",
  },
  {
    doc: "01138706558",
  },
  {
    doc: "30431032890",
  },
  {
    doc: "07072637435",
  },
  {
    doc: "13004783738",
  },
  {
    doc: "10488725720",
  },
  {
    doc: "15291106700",
  },
  {
    doc: "42806695899",
  },
  {
    doc: "16806213863",
  },
  {
    doc: "14922453806",
  },
  {
    doc: "98720090587",
  },
  {
    doc: "36963774811",
  },
  {
    doc: "00795608900",
  },
  {
    doc: "41675849803",
  },
  {
    doc: "16098670765",
  },
  {
    doc: "10588954438",
  },
  {
    doc: "13388421706",
  },
  {
    doc: "85629677004",
  },
  {
    doc: "03954236516",
  },
  {
    doc: "40522905854",
  },
  {
    doc: "02270998006",
  },
  {
    doc: "41285526821",
  },
  {
    doc: "03166922040",
  },
  {
    doc: "41399920855",
  },
  {
    doc: "23018196805",
  },
  {
    doc: "40943358892",
  },
  {
    doc: "10563255692",
  },
  {
    doc: "16369538752",
  },
  {
    doc: "06812871109",
  },
  {
    doc: "30844387819",
  },
  {
    doc: "03062857463",
  },
  {
    doc: "08344334942",
  },
  {
    doc: "82134790334",
  },
  {
    doc: "63407264100",
  },
  {
    doc: "32298061823",
  },
  {
    doc: "30264352874",
  },
  {
    doc: "41749635844",
  },
  {
    doc: "33933505879",
  },
  {
    doc: "05276947546",
  },
  {
    doc: "17945417809",
  },
  {
    doc: "66270340349",
  },
  {
    doc: "08457439618",
  },
  {
    doc: "03110348020",
  },
  {
    doc: "06276498580",
  },
  {
    doc: "34312815836",
  },
  {
    doc: "21997734850",
  },
  {
    doc: "02778397183",
  },
  {
    doc: "39578675844",
  },
  {
    doc: "06910037693",
  },
  {
    doc: "01891285262",
  },
  {
    doc: "16981010747",
  },
  {
    doc: "22667870808",
  },
  {
    doc: "15249333842",
  },
  {
    doc: "13986253882",
  },
  {
    doc: "33220475809",
  },
  {
    doc: "10446687766",
  },
  {
    doc: "37020565808",
  },
  {
    doc: "06361289621",
  },
  {
    doc: "01477876618",
  },
  {
    doc: "44875457812",
  },
  {
    doc: "13655819773",
  },
  {
    doc: "15744987703",
  },
  {
    doc: "30291880860",
  },
  {
    doc: "08674867758",
  },
  {
    doc: "22473600848",
  },
  {
    doc: "03453320913",
  },
  {
    doc: "31012979822",
  },
  {
    doc: "21996105515",
  },
  {
    doc: "00473689774",
  },
  {
    doc: "59828412772",
  },
  {
    doc: "39287542899",
  },
  {
    doc: "03568358557",
  },
  {
    doc: "00085506389",
  },
  {
    doc: "63481324391",
  },
  {
    doc: "46843417809",
  },
  {
    doc: "41022694871",
  },
  {
    doc: "42068602890",
  },
  {
    doc: "42536459845",
  },
  {
    doc: "39177918894",
  },
  {
    doc: "14273697718",
  },
  {
    doc: "15846454747",
  },
  {
    doc: "45459420830",
  },
  {
    doc: "11417363657",
  },
  {
    doc: "42375979893",
  },
  {
    doc: "42739218800",
  },
  {
    doc: "48232472804",
  },
  {
    doc: "22537898877",
  },
  {
    doc: "10529131480",
  },
  {
    doc: "38886198825",
  },
  {
    doc: "86439715020",
  },
  {
    doc: "07737856633",
  },
  {
    doc: "09702883440",
  },
  {
    doc: "07827470947",
  },
  {
    doc: "05261986133",
  },
  {
    doc: "06406694686",
  },
  {
    doc: "08467079690",
  },
  {
    doc: "42413949810",
  },
  {
    doc: "10351351752",
  },
  {
    doc: "35013127840",
  },
  {
    doc: "05590460573",
  },
  {
    doc: "51327993880",
  },
  {
    doc: "35915898882",
  },
  {
    doc: "35219236814",
  },
  {
    doc: "41582945845",
  },
  {
    doc: "64512347372",
  },
  {
    doc: "05659939414",
  },
  {
    doc: "04705030419",
  },
  {
    doc: "13434116702",
  },
  {
    doc: "06195533599",
  },
  {
    doc: "09611162774",
  },
  {
    doc: "29042716860",
  },
  {
    doc: "37339341803",
  },
  {
    doc: "32003325816",
  },
  {
    doc: "06507389188",
  },
  {
    doc: "09130092701",
  },
  {
    doc: "39884645892",
  },
  {
    doc: "03595689399",
  },
  {
    doc: "13290181766",
  },
  {
    doc: "13210668728",
  },
  {
    doc: "03883765546",
  },
  {
    doc: "42778884882",
  },
  {
    doc: "39903536836",
  },
  {
    doc: "05660604773",
  },
  {
    doc: "39759604850",
  },
  {
    doc: "40287255859",
  },
  {
    doc: "10931285771",
  },
  {
    doc: "10386046646",
  },
  {
    doc: "31062722876",
  },
  {
    doc: "12249813752",
  },
  {
    doc: "47756064844",
  },
  {
    doc: "01445025507",
  },
  {
    doc: "12915302669",
  },
  {
    doc: "39698649840",
  },
  {
    doc: "07606127905",
  },
  {
    doc: "09069448998",
  },
  {
    doc: "39212337805",
  },
  {
    doc: "31162638842",
  },
  {
    doc: "13060834601",
  },
  {
    doc: "07697084716",
  },
  {
    doc: "09509357600",
  },
  {
    doc: "08559220950",
  },
  {
    doc: "10133074960",
  },
  {
    doc: "10925845604",
  },
  {
    doc: "84896957091",
  },
  {
    doc: "37790679864",
  },
  {
    doc: "42194398899",
  },
  {
    doc: "08988992652",
  },
  {
    doc: "08141295950",
  },
  {
    doc: "51385084820",
  },
  {
    doc: "82809402000",
  },
  {
    doc: "03777828580",
  },
  {
    doc: "07207175400",
  },
  {
    doc: "02221793099",
  },
  {
    doc: "33990368842",
  },
  {
    doc: "51711686875",
  },
  {
    doc: "12685970746",
  },
  {
    doc: "85117471504",
  },
  {
    doc: "10028409612",
  },
  {
    doc: "16488095706",
  },
  {
    doc: "46050352810",
  },
  {
    doc: "01897137559",
  },
  {
    doc: "41000751899",
  },
  {
    doc: "48427881860",
  },
  {
    doc: "36474111836",
  },
  {
    doc: "41062404831",
  },
  {
    doc: "16484135797",
  },
  {
    doc: "23218913861",
  },
  {
    doc: "22815701855",
  },
  {
    doc: "09212484905",
  },
  {
    doc: "96661054387",
  },
  {
    doc: "01087084890",
  },
  {
    doc: "42725459818",
  },
  {
    doc: "45266833882",
  },
  {
    doc: "10090803779",
  },
  {
    doc: "04760934499",
  },
  {
    doc: "43958654886",
  },
  {
    doc: "39765836856",
  },
  {
    doc: "40749816880",
  },
  {
    doc: "46927636803",
  },
  {
    doc: "00577961578",
  },
  {
    doc: "41418754811",
  },
  {
    doc: "40422358835",
  },
  {
    doc: "05677826707",
  },
  {
    doc: "10005304776",
  },
  {
    doc: "37213098802",
  },
  {
    doc: "37654052804",
  },
  {
    doc: "36247281803",
  },
  {
    doc: "42960040856",
  },
  {
    doc: "02457287162",
  },
  {
    doc: "04753906108",
  },
  {
    doc: "02846953007",
  },
  {
    doc: "35349072803",
  },
  {
    doc: "34327051829",
  },
  {
    doc: "38667458856",
  },
  {
    doc: "03450053337",
  },
  {
    doc: "15512679775",
  },
  {
    doc: "09892042433",
  },
  {
    doc: "09281743604",
  },
  {
    doc: "45524237896",
  },
  {
    doc: "07102218605",
  },
  {
    doc: "43566406830",
  },
  {
    doc: "14060475629",
  },
  {
    doc: "36970232840",
  },
  {
    doc: "34031115806",
  },
  {
    doc: "35048088883",
  },
  {
    doc: "31752170873",
  },
  {
    doc: "03996502370",
  },
  {
    doc: "13749591890",
  },
  {
    doc: "02315588995",
  },
  {
    doc: "06609729646",
  },
  {
    doc: "11363281810",
  },
  {
    doc: "60249242168",
  },
  {
    doc: "07589852821",
  },
  {
    doc: "34915918807",
  },
  {
    doc: "08753645782",
  },
  {
    doc: "02835658530",
  },
  {
    doc: "76724980534",
  },
  {
    doc: "76078582615",
  },
  {
    doc: "01706571160",
  },
  {
    doc: "08640659737",
  },
  {
    doc: "07490040450",
  },
  {
    doc: "09952707754",
  },
  {
    doc: "00490359086",
  },
  {
    doc: "15084637786",
  },
  {
    doc: "00701515040",
  },
  {
    doc: "40478905882",
  },
  {
    doc: "43645871845",
  },
  {
    doc: "31665022892",
  },
  {
    doc: "47464507819",
  },
  {
    doc: "04550619120",
  },
  {
    doc: "42356552804",
  },
  {
    doc: "12861212640",
  },
  {
    doc: "12285911726",
  },
  {
    doc: "45710423882",
  },
  {
    doc: "34219639837",
  },
  {
    doc: "66080460300",
  },
  {
    doc: "04612532910",
  },
  {
    doc: "30264652835",
  },
  {
    doc: "02949908608",
  },
  {
    doc: "32245449886",
  },
  {
    doc: "38236857832",
  },
  {
    doc: "46664827840",
  },
  {
    doc: "06970503607",
  },
  {
    doc: "29013932843",
  },
  {
    doc: "10742273601",
  },
  {
    doc: "16991857866",
  },
  {
    doc: "15392960839",
  },
  {
    doc: "13350160743",
  },
  {
    doc: "11480269670",
  },
  {
    doc: "40165358831",
  },
  {
    doc: "43047550808",
  },
  {
    doc: "11357777728",
  },
  {
    doc: "31929684860",
  },
  {
    doc: "31270259806",
  },
  {
    doc: "27640087830",
  },
  {
    doc: "64682692100",
  },
  {
    doc: "13378146796",
  },
  {
    doc: "14570048790",
  },
  {
    doc: "03344283006",
  },
  {
    doc: "16118473897",
  },
  {
    doc: "08660821742",
  },
  {
    doc: "17260192844",
  },
  {
    doc: "07758526942",
  },
  {
    doc: "09970721720",
  },
  {
    doc: "05714811321",
  },
  {
    doc: "28285139808",
  },
  {
    doc: "08231236775",
  },
  {
    doc: "33277745287",
  },
  {
    doc: "42139396855",
  },
  {
    doc: "01806020041",
  },
  {
    doc: "11514374412",
  },
  {
    doc: "35026454852",
  },
  {
    doc: "27148821886",
  },
  {
    doc: "95623124534",
  },
  {
    doc: "44860879848",
  },
  {
    doc: "86772414004",
  },
  {
    doc: "37079064856",
  },
  {
    doc: "17150039774",
  },
  {
    doc: "60191712302",
  },
  {
    doc: "07111280300",
  },
  {
    doc: "07248567574",
  },
  {
    doc: "28044151800",
  },
  {
    doc: "18121604885",
  },
  {
    doc: "11004054700",
  },
  {
    doc: "10087807742",
  },
  {
    doc: "00640017509",
  },
  {
    doc: "90847288749",
  },
  {
    doc: "03027938797",
  },
  {
    doc: "07671704741",
  },
  {
    doc: "06039180420",
  },
  {
    doc: "02275605231",
  },
  {
    doc: "07804250908",
  },
  {
    doc: "30461410842",
  },
  {
    doc: "05051521428",
  },
  {
    doc: "32494438837",
  },
  {
    doc: "02168471096",
  },
  {
    doc: "35546708808",
  },
  {
    doc: "37128163832",
  },
  {
    doc: "00949305111",
  },
  {
    doc: "01768321140",
  },
  {
    doc: "12247063748",
  },
  {
    doc: "27509018803",
  },
  {
    doc: "01215967535",
  },
  {
    doc: "44125021805",
  },
  {
    doc: "04585393412",
  },
  {
    doc: "43222193819",
  },
  {
    doc: "35542686826",
  },
  {
    doc: "37546747813",
  },
  {
    doc: "38236266800",
  },
  {
    doc: "09853544881",
  },
  {
    doc: "05563734307",
  },
  {
    doc: "09560643703",
  },
  {
    doc: "09243088777",
  },
  {
    doc: "36727052840",
  },
  {
    doc: "35577764840",
  },
  {
    doc: "08912910752",
  },
  {
    doc: "23123446883",
  },
  {
    doc: "22786463890",
  },
  {
    doc: "29569163860",
  },
  {
    doc: "39706049827",
  },
  {
    doc: "15316444655",
  },
  {
    doc: "05395614281",
  },
  {
    doc: "02303691621",
  },
  {
    doc: "35149133825",
  },
  {
    doc: "02945813165",
  },
  {
    doc: "37059029893",
  },
  {
    doc: "05523488992",
  },
  {
    doc: "02374871010",
  },
  {
    doc: "48023306855",
  },
  {
    doc: "05612125976",
  },
  {
    doc: "10965413780",
  },
  {
    doc: "14607607860",
  },
  {
    doc: "12009380460",
  },
  {
    doc: "04975878666",
  },
  {
    doc: "03104952612",
  },
  {
    doc: "05826629312",
  },
  {
    doc: "70087310430",
  },
  {
    doc: "08089652980",
  },
  {
    doc: "14006747780",
  },
  {
    doc: "07976637380",
  },
  {
    doc: "41764210883",
  },
  {
    doc: "05260784642",
  },
  {
    doc: "04440996540",
  },
  {
    doc: "27212786810",
  },
  {
    doc: "62901435149",
  },
  {
    doc: "02510675133",
  },
  {
    doc: "01389763099",
  },
  {
    doc: "06895732608",
  },
  {
    doc: "18009277894",
  },
  {
    doc: "05736126176",
  },
  {
    doc: "49117302854",
  },
  {
    doc: "38352303368",
  },
  {
    doc: "00400370530",
  },
  {
    doc: "25167684862",
  },
  {
    doc: "86616560104",
  },
  {
    doc: "02837163716",
  },
  {
    doc: "61199820350",
  },
  {
    doc: "62053809191",
  },
  {
    doc: "12697754725",
  },
  {
    doc: "79151175304",
  },
  {
    doc: "01985651947",
  },
  {
    doc: "10218598777",
  },
  {
    doc: "01318275539",
  },
  {
    doc: "05448375413",
  },
  {
    doc: "13558085884",
  },
  {
    doc: "45901805534",
  },
  {
    doc: "15229779888",
  },
  {
    doc: "32812703873",
  },
  {
    doc: "11465319409",
  },
  {
    doc: "32930965819",
  },
  {
    doc: "40146570600",
  },
  {
    doc: "09482761936",
  },
  {
    doc: "08793895836",
  },
  {
    doc: "12024768482",
  },
  {
    doc: "01817598708",
  },
  {
    doc: "77357531449",
  },
  {
    doc: "05564753488",
  },
  {
    doc: "29073453895",
  },
  {
    doc: "09694831989",
  },
  {
    doc: "17096199888",
  },
  {
    doc: "02169803300",
  },
  {
    doc: "56663986600",
  },
  {
    doc: "01227180462",
  },
  {
    doc: "14193991792",
  },
  {
    doc: "02456172302",
  },
  {
    doc: "55342375894",
  },
  {
    doc: "50157575810",
  },
  {
    doc: "42546097873",
  },
  {
    doc: "39907474878",
  },
  {
    doc: "10526738723",
  },
  {
    doc: "42785169832",
  },
  {
    doc: "47933360866",
  },
  {
    doc: "13638021602",
  },
  {
    doc: "11505921686",
  },
  {
    doc: "02506630317",
  },
  {
    doc: "09978033475",
  },
  {
    doc: "40420672800",
  },
  {
    doc: "21893660893",
  },
  {
    doc: "16162435709",
  },
  {
    doc: "12231752603",
  },
  {
    doc: "38530662830",
  },
  {
    doc: "08790549643",
  },
  {
    doc: "32828235840",
  },
  {
    doc: "32893769829",
  },
  {
    doc: "07436529323",
  },
  {
    doc: "70577972499",
  },
  {
    doc: "41232691844",
  },
  {
    doc: "29437564825",
  },
  {
    doc: "38858334841",
  },
  {
    doc: "27055734886",
  },
  {
    doc: "13003794728",
  },
  {
    doc: "40557035899",
  },
  {
    doc: "01448321271",
  },
  {
    doc: "07524742355",
  },
  {
    doc: "21863579842",
  },
  {
    doc: "90836510100",
  },
  {
    doc: "14608651897",
  },
  {
    doc: "17334984862",
  },
  {
    doc: "04382232929",
  },
  {
    doc: "44480518894",
  },
  {
    doc: "46409493835",
  },
  {
    doc: "00436090236",
  },
  {
    doc: "47734994865",
  },
  {
    doc: "04228259138",
  },
  {
    doc: "86658484015",
  },
  {
    doc: "06020265986",
  },
  {
    doc: "48266097879",
  },
  {
    doc: "07728385500",
  },
  {
    doc: "36354256802",
  },
  {
    doc: "75583275715",
  },
  {
    doc: "11015089496",
  },
  {
    doc: "24679206829",
  },
  {
    doc: "41552247805",
  },
  {
    doc: "46741614877",
  },
  {
    doc: "06597589114",
  },
  {
    doc: "07940422871",
  },
  {
    doc: "66856426020",
  },
  {
    doc: "10028212401",
  },
  {
    doc: "13926644893",
  },
  {
    doc: "03232981410",
  },
  {
    doc: "16894709840",
  },
  {
    doc: "11200334973",
  },
  {
    doc: "33679238851",
  },
  {
    doc: "35270350874",
  },
  {
    doc: "44664240805",
  },
  {
    doc: "44189568830",
  },
  {
    doc: "25876868817",
  },
  {
    doc: "40904939863",
  },
  {
    doc: "18544624820",
  },
  {
    doc: "35717219857",
  },
  {
    doc: "33870967803",
  },
  {
    doc: "03631375646",
  },
  {
    doc: "37585808895",
  },
  {
    doc: "27225839845",
  },
  {
    doc: "15355745776",
  },
  {
    doc: "88700666068",
  },
  {
    doc: "09515576881",
  },
  {
    doc: "11836532636",
  },
  {
    doc: "02196340847",
  },
  {
    doc: "22878341848",
  },
  {
    doc: "11672740754",
  },
  {
    doc: "40113009852",
  },
  {
    doc: "30509233856",
  },
  {
    doc: "18137359800",
  },
  {
    doc: "35243703818",
  },
  {
    doc: "13071395825",
  },
  {
    doc: "15846401899",
  },
  {
    doc: "27278694876",
  },
  {
    doc: "16046016800",
  },
  {
    doc: "00938280554",
  },
  {
    doc: "38317831876",
  },
  {
    doc: "91794145591",
  },
  {
    doc: "05538207960",
  },
  {
    doc: "71220003620",
  },
  {
    doc: "41812531800",
  },
  {
    doc: "02673319670",
  },
  {
    doc: "11359980865",
  },
  {
    doc: "48927463803",
  },
  {
    doc: "40594121809",
  },
  {
    doc: "42092298810",
  },
  {
    doc: "07001946656",
  },
  {
    doc: "86428126567",
  },
  {
    doc: "39973569806",
  },
  {
    doc: "41757361820",
  },
  {
    doc: "51533319898",
  },
  {
    doc: "02239757027",
  },
  {
    doc: "39216694807",
  },
  {
    doc: "40996453806",
  },
  {
    doc: "34758820864",
  },
  {
    doc: "03522317009",
  },
  {
    doc: "32578678839",
  },
  {
    doc: "31380876800",
  },
  {
    doc: "10042844622",
  },
  {
    doc: "05595298308",
  },
  {
    doc: "37606900841",
  },
  {
    doc: "03085400184",
  },
  {
    doc: "11164748408",
  },
  {
    doc: "33624643860",
  },
  {
    doc: "41198227885",
  },
  {
    doc: "05342494508",
  },
  {
    doc: "09971103419",
  },
  {
    doc: "09364623614",
  },
  {
    doc: "23593157810",
  },
  {
    doc: "44639486812",
  },
  {
    doc: "10861944461",
  },
  {
    doc: "10784944601",
  },
  {
    doc: "05202823533",
  },
  {
    doc: "04133062167",
  },
  {
    doc: "38516000885",
  },
  {
    doc: "14488974678",
  },
  {
    doc: "14290236782",
  },
  {
    doc: "39843169840",
  },
  {
    doc: "12862054763",
  },
  {
    doc: "07843336613",
  },
  {
    doc: "02259561608",
  },
  {
    doc: "37257002819",
  },
  {
    doc: "00173331211",
  },
  {
    doc: "15140355701",
  },
  {
    doc: "36268636899",
  },
  {
    doc: "48932635870",
  },
  {
    doc: "60651448352",
  },
  {
    doc: "05207969188",
  },
  {
    doc: "02849371181",
  },
  {
    doc: "02246276942",
  },
  {
    doc: "25413061803",
  },
  {
    doc: "37395867882",
  },
  {
    doc: "12298408777",
  },
  {
    doc: "41900068826",
  },
  {
    doc: "28120120809",
  },
  {
    doc: "09515840490",
  },
  {
    doc: "81501277391",
  },
  {
    doc: "04862049958",
  },
  {
    doc: "46502683894",
  },
  {
    doc: "47221910855",
  },
  {
    doc: "41968163808",
  },
  {
    doc: "38145418801",
  },
  {
    doc: "05579072929",
  },
  {
    doc: "31298167809",
  },
  {
    doc: "16039305665",
  },
  {
    doc: "34868567829",
  },
  {
    doc: "44704802825",
  },
  {
    doc: "45796502808",
  },
  {
    doc: "43743320819",
  },
  {
    doc: "09757677639",
  },
  {
    doc: "97715590797",
  },
  {
    doc: "10041610423",
  },
  {
    doc: "46837009800",
  },
  {
    doc: "11692299484",
  },
  {
    doc: "02698999012",
  },
  {
    doc: "86412547576",
  },
  {
    doc: "43250589829",
  },
  {
    doc: "15891298732",
  },
  {
    doc: "03038808059",
  },
  {
    doc: "02830699297",
  },
  {
    doc: "42384227831",
  },
  {
    doc: "44055618859",
  },
  {
    doc: "44004853826",
  },
  {
    doc: "43155785845",
  },
  {
    doc: "47646956856",
  },
  {
    doc: "10090291778",
  },
  {
    doc: "39100914827",
  },
  {
    doc: "10384746470",
  },
  {
    doc: "42095098846",
  },
  {
    doc: "46659707859",
  },
  {
    doc: "02525468147",
  },
  {
    doc: "22314588886",
  },
  {
    doc: "40838061842",
  },
  {
    doc: "06874314416",
  },
  {
    doc: "30166375888",
  },
  {
    doc: "35810370845",
  },
  {
    doc: "03293052550",
  },
  {
    doc: "05946146351",
  },
  {
    doc: "48397553886",
  },
  {
    doc: "11089020902",
  },
  {
    doc: "34775871897",
  },
  {
    doc: "44228576818",
  },
  {
    doc: "21883639816",
  },
  {
    doc: "35824853827",
  },
  {
    doc: "40591351862",
  },
  {
    doc: "09835372675",
  },
  {
    doc: "03180858109",
  },
  {
    doc: "14502953717",
  },
  {
    doc: "12309945722",
  },
  {
    doc: "09429454927",
  },
  {
    doc: "05242776480",
  },
  {
    doc: "04829447141",
  },
  {
    doc: "36412323848",
  },
  {
    doc: "01377252213",
  },
  {
    doc: "11632312743",
  },
  {
    doc: "44359928840",
  },
  {
    doc: "34571770820",
  },
  {
    doc: "41242366890",
  },
  {
    doc: "10348240660",
  },
  {
    doc: "16615738783",
  },
  {
    doc: "35545580875",
  },
  {
    doc: "41027750800",
  },
  {
    doc: "39092491837",
  },
  {
    doc: "32086634803",
  },
  {
    doc: "35301055895",
  },
  {
    doc: "07796745966",
  },
  {
    doc: "14070121773",
  },
  {
    doc: "31846603897",
  },
  {
    doc: "35647815871",
  },
  {
    doc: "47754534835",
  },
  {
    doc: "34439796885",
  },
  {
    doc: "08581105475",
  },
  {
    doc: "14375324708",
  },
  {
    doc: "11573932760",
  },
  {
    doc: "17360233739",
  },
  {
    doc: "02244733360",
  },
  {
    doc: "08276187781",
  },
  {
    doc: "09357723676",
  },
  {
    doc: "12209020476",
  },
  {
    doc: "10864241402",
  },
  {
    doc: "16317792739",
  },
  {
    doc: "03352950024",
  },
  {
    doc: "46244163842",
  },
  {
    doc: "35210470830",
  },
  {
    doc: "07763003189",
  },
  {
    doc: "31038627842",
  },
  {
    doc: "39178206847",
  },
  {
    doc: "10636800776",
  },
  {
    doc: "04861197180",
  },
  {
    doc: "01611932181",
  },
  {
    doc: "32515513883",
  },
  {
    doc: "46803226832",
  },
  {
    doc: "40480530866",
  },
  {
    doc: "30495462802",
  },
  {
    doc: "12500598799",
  },
  {
    doc: "39966303871",
  },
  {
    doc: "39417961861",
  },
  {
    doc: "06621004508",
  },
  {
    doc: "45543209878",
  },
  {
    doc: "42731833823",
  },
  {
    doc: "08211452740",
  },
  {
    doc: "09834309694",
  },
  {
    doc: "44524495819",
  },
  {
    doc: "01009692321",
  },
  {
    doc: "27915145894",
  },
  {
    doc: "06396055678",
  },
  {
    doc: "04460810190",
  },
  {
    doc: "14695441640",
  },
  {
    doc: "35941914865",
  },
  {
    doc: "02860264302",
  },
  {
    doc: "40952190842",
  },
  {
    doc: "06125410617",
  },
  {
    doc: "05770983906",
  },
  {
    doc: "11651070822",
  },
  {
    doc: "72723653587",
  },
  {
    doc: "27037288837",
  },
  {
    doc: "28854563889",
  },
  {
    doc: "30697485897",
  },
  {
    doc: "26048889801",
  },
  {
    doc: "23083266898",
  },
  {
    doc: "01534627790",
  },
  {
    doc: "16422525818",
  },
  {
    doc: "39864291823",
  },
  {
    doc: "29948141881",
  },
  {
    doc: "06457234445",
  },
  {
    doc: "04413028589",
  },
  {
    doc: "41288139829",
  },
  {
    doc: "11672223857",
  },
  {
    doc: "07423863688",
  },
  {
    doc: "29756034874",
  },
  {
    doc: "35265991816",
  },
  {
    doc: "02635731179",
  },
  {
    doc: "31787971813",
  },
  {
    doc: "07257202982",
  },
  {
    doc: "30911988807",
  },
  {
    doc: "21849488886",
  },
  {
    doc: "07335510929",
  },
  {
    doc: "12620355702",
  },
  {
    doc: "01918675511",
  },
  {
    doc: "35748935821",
  },
  {
    doc: "02286264554",
  },
  {
    doc: "32216870870",
  },
  {
    doc: "61942510268",
  },
  {
    doc: "05077681910",
  },
  {
    doc: "41790405904",
  },
  {
    doc: "47398340818",
  },
  {
    doc: "10525514830",
  },
  {
    doc: "09601089985",
  },
  {
    doc: "70854908455",
  },
  {
    doc: "12148101609",
  },
  {
    doc: "45137695807",
  },
  {
    doc: "37017156889",
  },
  {
    doc: "11920449752",
  },
  {
    doc: "12680384660",
  },
  {
    doc: "35713638847",
  },
  {
    doc: "93174136768",
  },
  {
    doc: "46206422828",
  },
  {
    doc: "47195960889",
  },
  {
    doc: "49635636814",
  },
  {
    doc: "45547295833",
  },
  {
    doc: "03494059055",
  },
  {
    doc: "50161056857",
  },
  {
    doc: "17291128706",
  },
  {
    doc: "17493751765",
  },
  {
    doc: "92147992453",
  },
  {
    doc: "01617136026",
  },
  {
    doc: "04707004245",
  },
  {
    doc: "09964417942",
  },
  {
    doc: "36177971806",
  },
  {
    doc: "04538968552",
  },
  {
    doc: "13032405645",
  },
  {
    doc: "51216514810",
  },
  {
    doc: "45004456844",
  },
  {
    doc: "05062189969",
  },
  {
    doc: "33389943897",
  },
  {
    doc: "41808615859",
  },
  {
    doc: "07383144554",
  },
  {
    doc: "37872883848",
  },
  {
    doc: "48112599807",
  },
  {
    doc: "38941628806",
  },
  {
    doc: "42496273819",
  },
  {
    doc: "06463755125",
  },
  {
    doc: "08925328909",
  },
  {
    doc: "35879449890",
  },
  {
    doc: "06501653576",
  },
  {
    doc: "45874583840",
  },
  {
    doc: "47052071819",
  },
  {
    doc: "73007757134",
  },
  {
    doc: "47368933896",
  },
  {
    doc: "15049105757",
  },
  {
    doc: "11871078911",
  },
  {
    doc: "45564573862",
  },
  {
    doc: "17358276736",
  },
  {
    doc: "85856541049",
  },
  {
    doc: "70924907401",
  },
  {
    doc: "17922847726",
  },
  {
    doc: "10673269957",
  },
  {
    doc: "44198073856",
  },
  {
    doc: "35941662874",
  },
  {
    doc: "38833083829",
  },
  {
    doc: "07206409709",
  },
  {
    doc: "61758756306",
  },
  {
    doc: "35952291856",
  },
  {
    doc: "41413184855",
  },
  {
    doc: "08309956410",
  },
  {
    doc: "09087325975",
  },
  {
    doc: "42999982860",
  },
  {
    doc: "00249583283",
  },
  {
    doc: "30827638809",
  },
  {
    doc: "09144311702",
  },
  {
    doc: "53754379968",
  },
  {
    doc: "40263216810",
  },
  {
    doc: "49839113836",
  },
  {
    doc: "08678052775",
  },
  {
    doc: "13049418680",
  },
  {
    doc: "10181602482",
  },
  {
    doc: "32115998863",
  },
  {
    doc: "43978389843",
  },
  {
    doc: "14277903703",
  },
  {
    doc: "38025203867",
  },
  {
    doc: "13557046770",
  },
  {
    doc: "13051082643",
  },
  {
    doc: "12868830676",
  },
  {
    doc: "40385852800",
  },
  {
    doc: "06264908479",
  },
  {
    doc: "29204600802",
  },
  {
    doc: "91561469572",
  },
  {
    doc: "39292287850",
  },
  {
    doc: "75093928100",
  },
  {
    doc: "41781469806",
  },
  {
    doc: "03309784380",
  },
  {
    doc: "09877105756",
  },
  {
    doc: "05676912502",
  },
  {
    doc: "14003800737",
  },
  {
    doc: "60264010329",
  },
  {
    doc: "42699867861",
  },
  {
    doc: "03741048089",
  },
  {
    doc: "62718657391",
  },
  {
    doc: "40882957813",
  },
  {
    doc: "11807073629",
  },
  {
    doc: "12894306750",
  },
  {
    doc: "10529507447",
  },
  {
    doc: "22581773839",
  },
  {
    doc: "92166768334",
  },
  {
    doc: "37054286839",
  },
  {
    doc: "11799662608",
  },
  {
    doc: "47667861811",
  },
  {
    doc: "50067902880",
  },
  {
    doc: "11711517720",
  },
  {
    doc: "14425134702",
  },
  {
    doc: "07308635309",
  },
  {
    doc: "12152176685",
  },
  {
    doc: "05272829018",
  },
  {
    doc: "10266856489",
  },
  {
    doc: "31845124871",
  },
  {
    doc: "14681658448",
  },
  {
    doc: "46331738800",
  },
  {
    doc: "11797161407",
  },
  {
    doc: "40682291803",
  },
  {
    doc: "46249799826",
  },
  {
    doc: "38664713863",
  },
  {
    doc: "36775501811",
  },
  {
    doc: "42495831800",
  },
  {
    doc: "36134806897",
  },
  {
    doc: "05504381142",
  },
  {
    doc: "60477036317",
  },
  {
    doc: "11674613717",
  },
  {
    doc: "21742997805",
  },
  {
    doc: "11315944952",
  },
  {
    doc: "04687441343",
  },
  {
    doc: "22980929824",
  },
  {
    doc: "07808768731",
  },
  {
    doc: "10696397706",
  },
  {
    doc: "38772613882",
  },
  {
    doc: "43381942867",
  },
  {
    doc: "84680989068",
  },
  {
    doc: "37704365800",
  },
  {
    doc: "36575052886",
  },
  {
    doc: "84581123020",
  },
  {
    doc: "44885213800",
  },
  {
    doc: "01531217699",
  },
  {
    doc: "36452715895",
  },
  {
    doc: "06701238971",
  },
  {
    doc: "06291557126",
  },
  {
    doc: "00180423339",
  },
  {
    doc: "44971696857",
  },
  {
    doc: "08439120737",
  },
  {
    doc: "00850537754",
  },
  {
    doc: "44163750851",
  },
  {
    doc: "33803107865",
  },
  {
    doc: "05794364386",
  },
  {
    doc: "30258973846",
  },
  {
    doc: "36877777824",
  },
  {
    doc: "61281667331",
  },
  {
    doc: "47336780881",
  },
  {
    doc: "44473920895",
  },
  {
    doc: "09630685809",
  },
  {
    doc: "02983929922",
  },
  {
    doc: "18557891830",
  },
  {
    doc: "10382334965",
  },
];

export function checkCPFDECORA20(cpf) {
  let filter = listCPF.filter((cpfInList) => {
    return cpfInList.doc === cpf;
  });

  return filter.length;
}
