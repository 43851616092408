import React, { useEffect, useContext, useState } from "react";

import { Container, Row, Card, CardBody } from "shards-react";
import { getQueryStringValue, isAuth } from "../actions/auth";

import { countProjectByUser } from "../actions/project";

import { ToastContainer } from "react-toastify";
import RegisterForm from "../components/Register/Form.tsx";
import LoginForm from "../components/Login/Form.tsx";
import { Step, StepLabel, Stepper } from "@material-ui/core";
import { SystemContext } from "../components/SystemConfig/SystemContext";
import { BUCKET_URL } from "../config";
import Register from "./Register";
import { getAllUrlParams } from "../utils/util";

function Login() {
  const page = getQueryStringValue("page");
  const { isDefault, getCompanyLabel, company } = useContext(SystemContext);
  const LOGO_LINK = isDefault
    ? "https://s3.amazonaws.com/ladecora.platform/images/logo-la-decora.png"
    : company.logo;

  const [showRegisteComponent, setShowRegisteComponent] = useState(false);

  const steps = [
    {
      completed: true,
      disabled: false,
      text: "Escolha do ambiente",
    },
    {
      completed: false,
      disabled: false,
      text: "Acesso",
    },
    {
      completed: false,
      disabled: false,
      text: "Finalizar pedido",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    if (shouldAutoRedirect()) {
      redirectPreservingParams();
    }
  }, []);

  function shouldAutoRedirect() {
    return getAllUrlParams().page && isAuth();
  }

  function redirectPreservingParams() {
    const params = getAllUrlParams();
    countProjectByUser().then((data) => {
      if (params === "" && data > 0) {
        window.open(`/environments-list`, "_self");
      } else {
        const page = params.page || "environments";
        delete params.page;
        const urlParams = Object.keys(params)
          .filter((key) => !!key)
          .reduce(
            (acc, current, idx) =>
              `${acc}${idx === 0 ? "?" : "&"}${current}=${params[current]}`,
            ""
          );
        if (page) {
          window.open(`/${page}${urlParams}`, "_self");
        }
      }
    });
  }

  return (
    <Container fluid className="main-content-container h-100 px-4 login">
      <ToastContainer />
      <img
        style={{
          padding: "30px",
          paddingBottom: "20px",
          maxWidth: "250px",
        }}
        className="auth-form__logo d-table mx-auto mb-3"
        alt="La Decora"
        src={LOGO_LINK}
      />

      <Row noGutters className="h-100">
        <div
          className="  mt-3 mb-3 w-100 auth-form ml-auto mr-auto"
          style={{ maxWidth: "700px" }}
        >
          <div className="mb-4">
            {page === "checkout" ? (
              <Stepper
                alternativeLabel
                activeStep={1}
                style={{ padding: "0px" }}
              >
                {steps.map((step, index) => (
                  <Step
                    className="Step"
                    key={index}
                    completed={step.completed}
                    disabled={step.disabled}
                  >
                    <StepLabel>
                      <span
                        style={
                          step.completed
                            ? {
                                color: "green",
                                fontSize: "14px",
                                fontFamily: "margem",
                              }
                            : {
                                color: "black",
                                fontSize: "14px",
                                fontFamily: "margem",
                              }
                        }
                      >
                        {step.text}
                      </span>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            ) : null}
          </div>
          {showRegisteComponent ? (
            <div>
              <Register setShowRegisteComponent={setShowRegisteComponent} />
            </div>
          ) : (
            <div className="d-flex align-center justify-content-center ">
              <div style={{ maxWidth: "300px" }} className="mr-5 first-access">
                <Card>
                  <CardBody>
                    <div
                      className="text-center mb-5"
                      style={{
                        fontSize: "30px",
                        color: "var(--newbackground-color)",
                      }}
                    >
                      Primeiro acesso
                    </div>
                    <RegisterForm isLoading={false} />
                  </CardBody>
                </Card>
              </div>
              <div className="d-flex align-items-center justify-content-center middle-split">
                <div
                  className="mr-1 ml-1"
                  style={{
                    borderLeft: "0.1px lightgrey solid",
                    height: "100%",
                  }}
                />
              </div>

              <div className="ml-5 already-a-user" style={{ width: "300px" }}>
                <Card>
                  <CardBody>
                    <div
                      className="text-center mb-5"
                      style={{
                        fontSize: "30px",
                        color: "var(--newbackground-color)",
                      }}
                    >
                      <div className="mobile">Login</div>
                      <div className="desktop">Já sou cliente</div>
                    </div>
                    <LoginForm
                      setShowRegisteComponent={setShowRegisteComponent}
                    />
                  </CardBody>
                </Card>
              </div>
            </div>
          )}
        </div>
      </Row>
    </Container>
  );
}

export default Login;
