export function clearPhone(
  value: string,
  maxLength: number,
  cut = false
): string {
  const v = value.replace(/[^0-9]/g, "");
  if (cut && v.length > maxLength) {
    return v.substring(v.length - maxLength);
  }
  return v;
}
