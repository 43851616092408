import React from "react";

import colors from "../../utils/colors";

function NotificationTitle({ children, theme }) {
  return (
    <div
      className="d-flex flex-row  align-items-center"
      style={{
        color: "white",
        margin: "0px",
        padding: "8px",
        backgroundColor: colors[theme || "primary"].value,
        fontSize: "14px",
        borderTopRightRadius: "5px",
        borderTopLeftRadius: "5px",
      }}
    >
      {children}
    </div>
  );
}

NotificationTitle.propTypes = {};

export default NotificationTitle;
