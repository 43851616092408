import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import Input from "../common/Input";

import { useForm } from "react-hook-form";
import { InferProps } from "prop-types";
import { LoginPropTypes, LoginValuesI } from "./types";
import { Button, FormGroup } from "shards-react";

import { SystemContext } from "../SystemConfig/SystemContext";

import {
  signin,
  isAuth,
  authenticate,
  signinGoogle,
  getQueryStringValue,
  getCookie,
  authWithToken,
  signinFacebook,
} from "../../actions/auth";
import { countProjectByUser } from "../../actions/project";
import { Link } from "react-router-dom";
import { getAllUrlParams } from "../../utils/util";
import cookie from "js-cookie";
import CONFIG from "../../config";

type LoginTypes = InferProps<typeof LoginPropTypes>;

const LoginForm: React.FC<LoginTypes> = ({ setShowRegisteComponent }) => {
  const { company } = useContext(SystemContext) as { company: any };

  const IFRAME_HOST = useMemo(() => {
    if (!company?.host) return undefined;
    if (CONFIG.AUTH_PATH) return CONFIG.AUTH_PATH;
    let temp = company.host.match(/https:\/\/(.*).com.br/);
    if (temp?.length) {
      temp = temp[1].split(".");
      return `https://auth.${temp[temp.length - 1]}.com.br`;
    }
  }, [company]);

  const {
    register,
    watch,
    formState: { errors },
  } = useForm<LoginValuesI>({
    reValidateMode: "onChange",
    mode: "onChange",
  });
  const [formFeedback, setFormFeedback] = useState({
    isLoading: false,
    error: "",
  });
  const ref = useRef(null);

  useEffect(() => {
    const listener = () => {
      const token = getCookie("oauth2_token");
      authWithToken(token).then((res) => {
        authenticate(res, () => {
          if (isAuth()) {
            if (validateUrl()) {
              window.open(`/checkout${window.location.search}`, "_self");
            } else {
              redirectPreservingParams();
            }
          }
        });
      });
    };
    if (ref.current) {
      const c = ref.current as any;

      c.addEventListener("ld-login", listener);
    }
    return () => {
      if (ref.current) {
        const c = ref.current as any;
        c.removeEventListener("ld-login", listener);
      }
    };
  }, [ref?.current]);

  useEffect(() => {
    const messageEventListener = (e) => {
      console.log(e);
      if (e.data.message === "ld-login-google") {
        const v = cookie.get("oauth2_token", {
          domain: "app.ladecora.com.gr",
        });
        signinGoogle(v, company.label).then((r) => {
          authenticate(r, () => {
            if (isAuth()) {
              if (validateUrl()) {
                window.open(`/checkout${window.location.search}`, "_self");
              } else {
                redirectPreservingParams();
              }
            }
          });
        });
      }
      if (e.data.message === "ld-login-facebook") {
        const v = cookie.get("oauth2_token", {
          domain: "app.ladecora.com.gr",
        });
        signinFacebook(
          {
            accessToken: v,
            // id: data.id,
            // email: data.email,
            // name: data.name,
          },
          company.label
        ).then((res) => {
          if (v) {
            authenticate(res, () => {
              if (isAuth()) {
                if (validateUrl()) {
                  window.open(`/checkout${window.location.search}`, "_self");
                } else {
                  redirectPreservingParams();
                }
              }
            });
          }
        });
      }
    };

    window.addEventListener("message", messageEventListener);
    return () => {
      window.removeEventListener("message", messageEventListener);
    };
  });

  function validateUrl() {
    const projectTemplatesQtd = getQueryStringValue("qty");
    let projectTemplatesHashes = getQueryStringValue("environment");

    if (typeof projectTemplatesHashes == "string") {
      projectTemplatesHashes = [projectTemplatesHashes];
    }

    return (
      projectTemplatesHashes &&
      projectTemplatesHashes.length > 0 &&
      projectTemplatesQtd &&
      projectTemplatesHashes.length === projectTemplatesQtd.length
    );
  }

  function redirectPreservingParams() {
    const params = getAllUrlParams() as { page: string } | string;
    countProjectByUser().then((data) => {
      if (params === "" && data > 0) {
        window.open(`/environments-list`, "_self");
      } else {
        const p: any = params;
        const page = p.page || "environments";
        delete p.page;
        const urlParams = Object.keys(p).reduce(
          (acc, current, idx) =>
            `${acc}${idx === 0 ? "?" : "&"}${current}=${p[current]}`,
          ""
        );
        if (page) {
          window.open(`/${page}${urlParams}`, "_self");
        }
      }
    });
  }

  const onLogin = (evt) => {
    evt.preventDefault();
    setFormFeedback({ ...formFeedback, isLoading: true });
    const user = { email: watch("email"), password: watch("password") };

    signin(user, company.label)
      .then((data) => {
        if (data.error) {
          setFormFeedback({
            ...formFeedback,
            error: data.error,
            isLoading: false,
          });
        } else {
          authenticate(data, () => {
            if (isAuth()) {
              if (validateUrl()) {
                window.open(`/checkout${window.location.search}`, "_self");
              } else {
                redirectPreservingParams();
              }
            }
          });
        }
      })
      .catch((err) => {
        setFormFeedback({
          ...formFeedback,
          error: err.message,
          isLoading: false,
        });
      });
  };

  return (
    <form onSubmit={onLogin} className="ld-login-form" ref={ref}>
      <FormGroup>
        <Input
          type="email"
          placeholder="Email"
          autoComplete="email"
          name="email"
          options={{
            form: { required: "Digite seu email" },
            register,
            error: errors.email,
          }}
        />
      </FormGroup>
      <FormGroup>
        <Input
          type="password"
          placeholder="Senha"
          autoComplete="password"
          name="password"
          options={{
            form: { required: "Digite sua senha" },
            register,
            error: errors.email,
          }}
        />
        {/* eslint-disable-next-line no-unused-vars */}
        <Link to="/forgot-password">Esqueceu sua senha?</Link>
      </FormGroup>
      <div>
        <Button
          type="submit"
          theme="accent"
          className="d-table mx-auto mt-4"
          size="lg"
          disabled={formFeedback.isLoading}
          style={{ width: "200px" }}
          onClick={onLogin}
        >
          Entrar
        </Button>
      </div>
      <div className="strike">
        <span>Acesse com</span>
      </div>

      {IFRAME_HOST ? (
        <iframe
          style={{ width: "100%" }}
          title="a"
          src={IFRAME_HOST}
          frameBorder="0"
        />
      ) : null}

      <div className="register-reference d-none">
        <div className="strike">
          <span>Novo na {company.name}?</span>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center mt-3">
          <Button
            theme="accent"
            className="d-table mx-auto"
            type="button"
            onClick={() =>
              // window.open(`/register${window.location.search}`, "_self")
              setShowRegisteComponent(true)
            }
          >
            Criar sua conta na {company.name}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default LoginForm;
