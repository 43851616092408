import React from "react";
import { PropTypes } from "prop-types";
import Slider from "react-slick";
import RenderLabels from "./RenderLabels";
import { showImageCaption } from "./RenderLabels";
import { CustomImageToSlide } from "../../common/CustomImageToSlide";
import { Button } from "shards-react";

const ImagesDownloadProjectV2 = ({
  title,
  data,
  settings,
  settingsForOne,
  sendLightbox,
  flipImage = false,
  labels = [],
  isDetailing,
}) => {
  const CONFIG = {
    settings: data.length <= 1 || isDetailing ? settingsForOne : settings,
    custom: data.length <= 1 || isDetailing ? false : true,
  };

  return (
    <div style={{ marginTop: "50px" }}>
      <h4 className="title-mobile-IDP">{title}</h4>
      <div
        style={{ marginTop: "12px" }}
        className={`${flipImage && "flipImage"}` + " manDiv-mobile"}
      >
        <Slider {...CONFIG.settings}>
          {data.map((file, idx) => {
            return (
              <div
                key={idx}
                className={`d-flex ${
                  CONFIG.custom && "flex-column"
                } justify-content-around align-items-center`}
              >
                <div className="d-flex flex-column justify-content-center align-items-center">
                  {file.subtitle ? (
                    <span className="text-center subtitle-slider">
                      {file.subtitle}
                    </span>
                  ) : null}
                  {CONFIG.custom ? (
                    <CustomImageToSlide
                      file={file.thumbnail || file.source}
                      onclick={() =>
                        sendLightbox(
                          data.map((i) => i.source),
                          idx,
                          flipImage,
                          showImageCaption(labels)
                        )
                      }
                    />
                  ) : (
                    <>
                      {/* eslint-disable-next-line*/}
                      <img
                        alt=""
                        style={{ cursor: "pointer" }}
                        className="rounded img-fluid carousel-image"
                        src={file.thumbnail || file.source}
                        onClick={() =>
                          sendLightbox(
                            data.map((i) => i.source),
                            idx,
                            flipImage,
                            showImageCaption(labels)
                          )
                        }
                      />
                    </>
                  )}

                  <div className=" mt-2 btnLegend">
                    {labels.length > 0 && labels[idx].length > 0 && (
                      <Button
                        onClick={(e) => {
                          e?.preventDefault();
                          e?.stopPropagation();
                          sendLightbox(
                            data.map((i) => i.source),
                            idx,
                            flipImage,
                            showImageCaption(labels)
                          );
                        }}
                      >
                        Ver Legenda
                      </Button>
                    )}
                  </div>
                </div>
                <RenderLabels idx={idx} labels={labels} />
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

ImagesDownloadProjectV2.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string.isRequired,
      thumbnail: PropTypes.string,
      subtitle: PropTypes.string,
    })
  ),
};

export default ImagesDownloadProjectV2;
