import { API } from "../config";
import { requestWrapper } from "../utils/api";
import { getCookie } from "./auth";

export const getProjectByShareId = (shareId: string): Promise<any> => {
  return requestWrapper(
    fetch(`${API}/shared_project/${shareId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
  );
};

export const getProjectShareId = (projectId: string): Promise<any> => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/project_share_id/${projectId}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const getExampleProject = (label: string): Promise<any> => {
  return requestWrapper(
    fetch(`${API}/project_as_example/${label}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
  );
};
