export const LAYOUT_TYPES = {
  DEFAULT: "DEFAULT",
  HEADER_NAVIGATION: "HEADER_NAVIGATION",
  ICON_SIDEBAR: "ICON_SIDEBAR",
};

const getProgressStatus = () => {
  const underscoredKeys = {
    preview: "preview",
    first_review: "firstReview",
    second_review: "secondReview",
    project: "Lista final",
  };
  const keys = {
    preview: "Proposta Inicial",
    first_review: "1º Revisão",
    second_review: "2º Revisão",
    project: "Lista final",
    firstReview: "1º Revisão",
    secondReview: "2º Revisão",
  };

  return {
    asTitle: () => {
      return keys;
    },
    asStep: () => {
      return underscoredKeys;
    },
  };
};

export const PROJECT_PROGRESS_STATUS = getProgressStatus();
