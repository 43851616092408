import React, { ChangeEvent, useMemo } from "react";
import { Control, Controller } from "react-hook-form";

import { Row, Col } from "shards-react";
import { InputTypes } from "../../utils/constants/inputDefaultPropTypes";
import { CreditCardI } from "../Checkout/types";
import FieldError from "./FieldError";

export const CreditCardExpiry: React.FC<
  InputTypes & { control: Control<CreditCardI, any> }
> = ({ disabled, control }) => {
  const [availableMonths, availableYears] = useMemo<
    [string[], string[]]
  >(() => {
    const availableMonths: string[] = [];
    const availableYears: string[] = [];
    for (let i = 1; i <= 12; i++) {
      availableMonths.push(formatSelectLabel(i));
    }
    const currentYearLast2Digits = getCurrentYearLast2Digits();
    for (let i = 0; i <= 15; i++) {
      availableYears.push(formatSelectLabel(i + currentYearLast2Digits));
    }
    return [availableMonths, availableYears];
  }, []);

  function getCurrentYearLast2Digits() {
    return parseInt(new Date().getFullYear().toString().substring(2, 4));
  }

  function formatSelectLabel(value: number): string {
    return value < 10 ? `0${value}` : value + "";
  }

  return (
    <Row form>
      <Col xs="6" className="form-group">
        <Controller
          control={control}
          name="expiryMonth"
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            function onLocalChange(ev: ChangeEvent<HTMLSelectElement>) {
              onChange(ev.target.value);
            }

            return (
              <>
                <select
                  className="form-control"
                  value={value}
                  name="month"
                  onChange={onLocalChange}
                  required
                  disabled={disabled}
                  id="checkout-card-expiry-month"
                >
                  <option disabled value="">
                    Mês
                  </option>
                  {availableMonths.map((month, idx) => (
                    <option key={idx} value={month}>
                      {month}
                    </option>
                  ))}
                </select>

                <FieldError isValid={!!error} message={error?.message} />
              </>
            );
          }}
        />
      </Col>
      <Col xs="6" className="form-group">
        <Controller
          control={control}
          name="expiryYear"
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            function onLocalChange(ev: ChangeEvent<HTMLSelectElement>) {
              onChange(ev.target.value);
            }

            return (
              <>
                <select
                  className="form-control"
                  id="checkout-card-expiry-year"
                  value={value}
                  name="year"
                  onChange={onLocalChange}
                  required
                  disabled={disabled}
                >
                  <option disabled value="">
                    Ano
                  </option>
                  {availableYears.map((year, idx) => (
                    <option key={idx} value={year}>
                      {year}
                    </option>
                  ))}
                </select>

                <FieldError isValid={!!error} message={error?.message} />
              </>
            );
          }}
        />
      </Col>
    </Row>
  );
};

export default CreditCardExpiry;
