import { useState, useEffect } from "react";

function useResize({ filter }) {
  const [winWidth, setWinWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", detectSize);

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [winWidth]);

  const detectSize = () => {
    const shouldChange = !filter || filter(window.innerWidth);

    if (shouldChange) {
      setWinWidth(window.innerWidth);
    }
  };

  return winWidth;
}

export default useResize;
