import React from "react";

import { ModalBody, ModalHeader, Modal } from "shards-react";
import NotificationItem from "./NotificationItem";
import { getFormattedDate } from "../../utils/date";

function ImportantNotificationBanner({ notifications, onBannerClose }) {
  return (
    <Modal open={true} size="md" toggle={onBannerClose}>
      <ModalHeader toggle={onBannerClose} style={{ justifyContent: "center" }}>
        Avisos importantes
      </ModalHeader>
      <ModalBody
        className="p-0"
        style={{ overflow: "auto", maxHeight: "400px" }}
      >
        {notifications
          .sort((a, b) => {
            if (a.time < b.time) {
              return 1;
            }
            if (a.time > b.time) {
              return -1;
            }
            return 0;
          })
          .map((notification, index) => (
            <NotificationItem
              title={notification.title}
              dismissable={false}
              className="mb-3"
              description={notification.description}
              key={`${index}-${notification._id}`}
              createdAt={getFormattedDate(notification.time)}
              id={notification._id}
              theme={notification.theme}
              projectId={notification.project}
              onBannerClose={onBannerClose}
            />
          ))}
      </ModalBody>
    </Modal>
  );
}

ImportantNotificationBanner.propTypes = {};

export default ImportantNotificationBanner;
