import React, { useEffect, useContext, useState } from "react";
import { Container, Row, Col, Card, CardFooter } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { SystemContext } from "../components/SystemConfig/SystemContext";
import { getEnterprises } from "../actions/enterprise";
import { useHistory } from "react-router-dom";
import AspectRatioPicture from "../components/common/AspectRatioPicture";
import Loader from "react-loader-spinner";

const Enterprises = () => {
  const history = useHistory();
  const { company } = useContext(SystemContext);
  const [enterprises, setEnterprises] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getEnterprises(company.label)
      .then((data) => {
        setIsLoading(false);
        setEnterprises(data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  const noEnterprise = () => {
    return (
      <div className="error">
        <div className="error__content">
          <h2>&#8251;</h2>
          <h3>Você não possui nenhum empreendimento cadastrado</h3>
        </div>
      </div>
    );
  };

  return (
    <Container
      fluid
      className={`main-content-container px-4 ${
        isLoading ? "align-items-center d-flex justify-content-center" : ""
      }`}
    >
      {isLoading ? (
        <Loader width="100px" height="100px" type="Rings" color="#212529" />
      ) : null}
      {enterprises.length === 0 && !isLoading ? noEnterprise() : null}
      {enterprises.length ? (
        <>
          <Row className="page-header py-4">
            <Col>
              <PageTitle
                title="Selecione um empreendimento"
                className="text-sm-left mb-3"
              />
            </Col>{" "}
          </Row>
          <Row className="text-sm-left mb-3">
            <Col>
              <h5 className="page-title" style={{ paddingLeft: "inherit" }}>
                Conte-nos mais sobre você e receba seu projeto gratuito na hora!
              </h5>
            </Col>
          </Row>

          <div
            className="d-flex align-items-center justify-content-left"
            style={{ flexWrap: "wrap" }}
          >
            {enterprises.map((enterprise, idx) => {
              return (
                <div key={idx} className="ml-2 mr-2">
                  <Card
                    small
                    className="mb-3 clickable hover-highlight"
                    onClick={() =>
                      history.push(`/enterprise/${enterprise._id}`)
                    }
                    style={{
                      borderRadius: "0.625rem",
                      width: "250px",
                    }}
                  >
                    <AspectRatioPicture
                      height="250px"
                      width="250px"
                      src={enterprise.logo}
                      thumbnail={enterprise.logo}
                      title={enterprise.name}
                      name={enterprise.logo}
                      isLoading={false}
                      isUploading={false}
                      isDeletable={false}
                      fullScreenView={false}
                    />
                    <CardFooter
                      className="border-top d-flex align-items-center justify-content-center flex-column"
                      style={{ padding: ".4rem 1rem" }}
                    >
                      <span className="file-manager__item-size">Endereço</span>

                      <span
                        className="file-manager__item-size text-center"
                        style={{ fontFamily: "sans-serif" }}
                      >
                        {enterprise.address
                          ? enterprise.address
                          : "Não informado"}
                      </span>
                    </CardFooter>
                  </Card>
                </div>
              );
            })}
          </div>
        </>
      ) : null}
    </Container>
  );
};

export default Enterprises;
