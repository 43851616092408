import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from "react";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Col,
  Row,
  FormSelect,
} from "shards-react";
import { getQueryStringValue } from "../../../actions/auth";
import { updateProjectLikedMobi } from "../../../actions/project";
import useVouchersModal from "../../../services/useVouchersModal";
import { debounce } from "../../../utils/throttle";

import { SystemContext } from "../../SystemConfig/SystemContext";

const ProductList = ({ products, projectId, hideIcon }) => {
  const { company } = useContext(SystemContext);
  const { toggleVouchersModal, Component } = useVouchersModal();
  const [productList, setProductList] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [filterValue, setFilterValue] = useState("todos");
  const [allProducts, setAllProducts] = useState([]);

  const shareId = getQueryStringValue("shareId");

  useEffect(() => {
    setProductList(checkIdproperty(products));
    setAllProducts(checkIdproperty(products));
  }, [projectId, products]);

  useEffect(() => {
    let tempfilterList = ["todos", "outros"];
    allProducts?.map((elem) => {
      let toPush =
        elem?.typology?.name &&
        checkBeforeAdd(elem.typology.name, tempfilterList);
      toPush && tempfilterList.push(toPush);
    });
    tempfilterList = tempfilterList.sort((a, b) => {
      return a.trim().localeCompare(b.trim());
    });
    setFilterList(tempfilterList);
  }, [allProducts]);

  const [furnituresTotalValue, furnituresCount, metragemCount] = useMemo(() => {
    if (allProducts?.length) {
      return allProducts.reduce(
        (acc, cur, i) => {
          if (cur.value) {
            acc[0] += cur.value * cur.count;
          }
          if (cur.selected) {
            acc[2] += 1;
          }
          acc[1] += cur.count;

          return acc;
        },
        [0, 0, 0]
      );
    }
    return [null, null];
  }, [allProducts]);

  const checkIdproperty = (data) => {
    let tempList = [];
    data.map((elem) => {
      if (elem.id !== null && typeof elem.id === "object") {
        tempList.push({
          count: elem.count,
          selected: elem.selected,
          ...elem.id,
        });
      } else if (elem._id && elem.id !== null) {
        tempList.push({ ...elem });
      }
    });
    return tempList;
  };

  const checkBeforeAdd = (param, list) => {
    if (!list.includes(param)) {
      return param;
    }
  };

  const gotToProductLink = (link) => {
    window.open(link, "_blank");
  };

  const returnLink = (mobi) => {
    if (mobi.affiliateLink && company.label !== "tok_stok") {
      return mobi.affiliateLink;
    }
    if (mobi.link) {
      return mobi.link;
    }
    return false;
  };

  const handlechange = (e) => {
    let tempProductsList = [];
    let currentFilter = e.target.value;
    setFilterValue(currentFilter);

    if (currentFilter === "todos") {
      tempProductsList = allProducts;
    } else if (currentFilter === "outros") {
      allProducts?.map((elem) => {
        (elem.typology === null || elem.typology === undefined) &&
          tempProductsList.push(elem);
      });
    } else {
      allProducts?.map((elem) => {
        elem?.typology?.name === currentFilter && tempProductsList.push(elem);
      });
    }

    setProductList(tempProductsList);
  };

  const handleLikedMobi = useCallback(
    debounce((idMobi, liked) => {
      if (projectId) {
        if (!shareId) {
          updateProjectLikedMobi(projectId, idMobi, liked).then(() => {
            setProductListValue(idMobi, liked);
          });
        } else {
          setProductListValue(idMobi, liked);
        }
      } else {
        setProductListValue(idMobi, liked);
      }
    }, 300),
    []
  );

  const setProductListValue = (idMobi, liked) =>
    setProductList((prevStatus) => {
      return prevStatus.map((elem) => {
        if (elem._id === idMobi) {
          elem.liked = !liked;
        }
        return elem;
      });
    });
  function sendToCart() {
    let urlCart = `https://www.tokstok.com.br/checkout/cart/add?utm_campaign=meuambiente&`;
    let sellerRegion = "1";
    productList.forEach((product) => {
      if (
        product.supplier === "Tok&Stok" &&
        product.sku &&
        product.count !== 0
      ) {
        urlCart += `sku=${product.sku.trim()}&`;
        urlCart += `qty=${product.count}&`;
        urlCart += `seller=${sellerRegion}&`;
      }
    });
    window.open(urlCart, "_blank").focus();
  }

  return (
    <>
      {Component}
      <hr className="dashed" />

      <div>
        <CardHeader className="p-0 mt-2 text-center">
          <h4 className=" m-0 mt-2">Lista de produtos</h4>
          {company.label === "ladecora" ? (
            <Button
              className="mt-4 mb-5 voucher-button"
              outline
              theme="success"
              size="lg"
              onClick={toggleVouchersModal}
            >
              <i style={{ fontSize: "2rem" }} className="material-icons">
                &#xf05b;
              </i>
              <div>Veja cupons exclusivos</div>
            </Button>
          ) : null}
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              {company.label === "tok_stok" ? (
                <div
                  style={{ textAlign: "center" }}
                  className="mt-5 col-12 pb-4"
                >
                  <Button
                    theme="accent"
                    size="lg"
                    className="mt-1 mr-1"
                    onClick={sendToCart}
                    style={{ float: "center" }}
                  >
                    Adicione os produtos no seu carrinho
                  </Button>
                </div>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col sx={6} className="text-right">
              <FormSelect
                value={filterValue}
                style={{ maxWidth: "160px" }}
                onChange={handlechange}
              >
                <option value="" disabled>
                  Selecione
                </option>
                {filterList.map((elem, idx) => (
                  <option value={elem} key={idx}>
                    {elem.toUpperCase()}
                  </option>
                ))}
              </FormSelect>
              <i
                style={{ fontSize: "20px" }}
                className="fas fa-info-circle ml-3"
                title="Filtragem por tipologia"
              />
            </Col>
          </Row>

          {productList?.length > 0 ? (
            <Row className="row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-4 row-cols-xl-4 d-flex align-items-stretch">
              {productList?.map((item, key) => (
                <Col key={item._id} className="mt-4 d-flex align-items-stretch">
                  <Card
                    className="w-100 shadow-lg"
                    style={{ borderRadius: "24px", position: "relative" }}
                  >
                    {!hideIcon && (
                      <div
                        style={{
                          position: "absolute",
                          top: "1px",
                          right: "4px",
                          backgroundColor: "white",
                          textAlign: "center",
                          borderRadius: "60%",
                          fontSize: "15px",
                          padding: "6px 6px 6px 7px",
                          boxShadow: "0px 0px 8px black",
                        }}
                        className="d-flex justify-content-end align-itens-end"
                      >
                        {/* eslint-disable-next-line */}
                        <i
                          className="material-icons"
                          style={{
                            color: "red",
                            cursor: "pointer",
                            top: "1px",
                          }}
                          onClick={() => handleLikedMobi(item._id, item.liked)}
                        >
                          {item.liked ? "favorite" : "favorite_border"}
                        </i>
                      </div>
                    )}
                    <img
                      className="m-3 shadow-sm img-fluid"
                      src={`${item.refimage}`}
                      alt=""
                      style={{
                        borderRadius: "12px",
                        aspectRatio: "1/1",

                        objectFit: "cover",
                      }}
                    />
                    <CardBody className="pb-2 pt-0 pl-3 pr-3">
                      <CardSubtitle className="d-flex justify-content-between align-items-center mt-3">
                        {item.supplier && <>{item.supplier}</>}
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: !item.value && "12px",
                            color: getComputedStyle(
                              document.documentElement
                            ).getPropertyValue("--newbackground-color"),
                          }}
                        >
                          {item.value
                            ? item.value.toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                              })
                            : "Preço não cadastrado"}
                        </span>
                      </CardSubtitle>
                      <CardTitle className="wrap-text-ellipsis pt-2">
                        {item.name}
                      </CardTitle>
                    </CardBody>
                    <CardBody className="pl-3">
                      <CardSubtitle className=" pt-0 pb-2">
                        {item.selected ? (
                          <>Conforme metragem</>
                        ) : (
                          <>
                            {item.count}{" "}
                            {item.unitOfMeasurement
                              ? item.unitOfMeasurement
                              : "und."}
                            {item.value && (
                              <>
                                {":"}{" "}
                                <span>
                                  {(item.value * item.count).toLocaleString(
                                    "pt-br",
                                    {
                                      style: "currency",
                                      currency: "BRL",
                                    }
                                  )}
                                </span>
                              </>
                            )}
                          </>
                        )}
                        {(item.refx || item.refy || item.refz) && (
                          <div className="mb-2">
                            <> Dimensões: </>
                            <div
                              className="d-flex justify-content-center"
                              style={{ fontSize: "10pt" }}
                            >
                              {item.refx && (
                                <div className="d-flex flex-column text-break">
                                  <span>{`${item.refx} cm`}</span>
                                  <span style={{ lineHeight: "0px" }}>(L)</span>
                                </div>
                              )}
                              {item.refy && (
                                <div className="d-flex flex-column text-break ml-1">
                                  <span>{`x ${item.refy} cm`} </span>
                                  <span style={{ lineHeight: "0px" }}>(P)</span>
                                </div>
                              )}
                              {item.refz && (
                                <div className="d-flex flex-column text-break ml-1">
                                  <span>{`x ${item.refz} cm`}</span>
                                  <span style={{ lineHeight: "0px" }}>(A)</span>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </CardSubtitle>
                    </CardBody>
                    <CardFooter className="text-center p-0 pb-3">
                      <Button
                        theme="accent"
                        style={{ fontSize: "1rem" }}
                        disabled={!returnLink(item)}
                        onClick={() => gotToProductLink(returnLink(item))}
                      >
                        Comprar na loja
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
              ))}
            </Row>
          ) : (
            <Row>
              <Col className="col-12">
                <h5 className="text-center">Nenhum produto</h5>
              </Col>
            </Row>
          )}
        </CardBody>
        <CardFooter>
          <div style={{ fontSize: "1.2rem" }} className="mb-2">
            {furnituresTotalValue ? (
              <>
                Recomendamos{" "}
                {`${furnituresCount} produto${
                  furnituresCount > 1 ? "s" : ""
                } com o valor total aproximado de `}
                <span style={{ color: "green" }}>
                  {furnituresTotalValue.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </span>
              </>
            ) : null}
            <br />
            {metragemCount
              ? `${metragemCount} produto${
                  metragemCount > 1 ? "s" : ""
                } conforme metragem.`
              : null}
          </div>
          <span style={{ fontSize: "12px", textAlign: "left" }}>
            * É importante ressaltar que recomendamos que você verifique os
            preços antes de efetuar a compra, pois eles podem variar.
          </span>
          <br />
          <span style={{ fontSize: "12px", textAlign: "left" }}>
            * Os itens marcados como &ldquo;Conforme metragem&ldquo; são
            desconsiderados do valor total.
          </span>
          <br />
          <span style={{ fontSize: "12px", textAlign: "left" }}>
            * Os itens sugeridos foram verificados em estoque antes do início da
            assessoria, porém entre o prazo de execução e entrega pode ser que
            algum produto acabe. Sugerimos que no momento da compra seja
            solicitada a ajuda de um vendedor, que estará apto a indicar uma
            opção similar para substituição que melhor componha a decoração.
          </span>
          <br />
          {company.label === "leroymerlinprojeta" ? (
            <span style={{ fontSize: "12px", textAlign: "left" }}>
              * Valores base São Paulo, consulte seu estado.
            </span>
          ) : null}
        </CardFooter>
      </div>
    </>
  );
};

export default ProductList;
