import React, { useState, useEffect, useContext, useMemo } from "react";
import { SystemContext } from "../components/SystemConfig/SystemContext";

import { Container } from "shards-react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-credit-cards/es/styles-compiled.css";
import ReactPixel, { AdvancedMatching } from "react-facebook-pixel";
import "../styles/cards.css";
import { AuthContext } from "../components/common/Authorization/AuthContext";
import { Card, Step, StepLabel, Stepper } from "@material-ui/core";
import { clearPhone } from "../utils/form_utils";
import { phoneValidation, valida_cpf_cnpj } from "../utils/form_validation.js";
import {
  CompanyI,
  EnvironmentI,
  getTotal,
  ProjectTemplateI,
  VoucherI,
} from "@ladecora/ladecora-utils";
import styles from "../components/Checkout/Checkout.module.scss";

import { getQueryStringValue } from "../actions/auth";
import { listProjectTemplatesFromCompany } from "../actions/company";
import { calculateInterest } from "../utils/payment";
import HomeOfficeImage from "../images/environments//home_office.jpg";
import { BUCKET_URL } from "../config";
import PaymentSummary from "../components/Checkout/PaymentSummary";
import ShortForm from "../components/Register/ShortForm";
import PaymentInfo from "../components/Checkout/PaymentInfo";
import Panel from "../components/Checkout/Panel";
import { WhatsButton } from "../components/common/WhatsButton";

const Checkout = () => {
  const MAX_INSTALLMENTS = 10;

  const { company } = useContext(SystemContext) as any;
  const { user } = useContext(AuthContext) as { user: any };
  const [_stepIndex, setStepIndex] = useState(1);
  const [projectTemplates, setProjectTemplates] = useState<ProjectTemplateI[]>(
    []
  );
  const [voucher, setVoucher] = useState<VoucherI>();

  const [stepIndex, steps] = useMemo(() => {
    if (user?._id && user.profile) {
      const phone = user.profile.phone
        ? clearPhone(user.profile.phone, 11, true)
        : "";
      if (
        user.profile.firstName &&
        user.profile.lastName &&
        user.email &&
        user.profile.document &&
        phone &&
        phoneValidation(phone) &&
        valida_cpf_cnpj(user.profile.document)
      )
        return [
          _stepIndex + 1,
          [
            {
              completed: true,
              disabled: false,
              text: "Escolha do ambiente",
            },
            {
              completed: false,
              disabled: false,
              text: "Pagamento",
            },
            {
              completed: false,
              disabled: false,
              text: "Pedido Finalizado",
            },
          ],
        ];
    }

    return [
      _stepIndex,
      [
        {
          completed: true,
          disabled: false,
          text: "Escolha do ambiente",
        },
        {
          completed: false,
          disabled: false,
          text: "Informações Pessoais",
        },
        {
          completed: false,
          disabled: false,
          text: "Pedido Finalizado",
        },
      ],
    ];
  }, [user, _stepIndex]);

  const [projectTemplatesToBuy, fullTotal, total, installmentsPrices] = useMemo<
    [EnvironmentI[], number, number, number[]]
  >(() => {
    if (projectTemplates?.length && company?._id) {
      const pttb = handleProjectTemplatesRequest(projectTemplates, company);
      const [fullTotal, total] = getTotal(pttb, voucher, company.label);
      const installmentsPrices: number[] = [];

      for (let i = 1; i <= MAX_INSTALLMENTS; i++) {
        const priceWithInterest = calculateInterest(total);
        const installmentWithInterest = priceWithInterest / i;
        installmentsPrices.push(i === 1 ? total : installmentWithInterest);
      }
      return [pttb, fullTotal, total, installmentsPrices];
    }
    return [[], 0, 0, []];
  }, [projectTemplates, company, voucher]);

  useEffect(() => {
    const isValid = validateUrl();
    if (!isValid) {
      window.open(`/environments`, "_self");
      return;
    }
    if (company?.tracking && company?.tracking.facebook) {
      const options = {
        autoConfig: true,
        debug: true,
      };
      ReactPixel.init(
        `${company.tracking.facebook}`,
        {} as AdvancedMatching,
        options
      );
      ReactPixel.pageView();
      ReactPixel.track("InitiateCheckout");
    }
    listProjectTemplatesFromCompany(company?.label).then(
      (projectTemplates: ProjectTemplateI[]) => {
        setProjectTemplates(projectTemplates);
      }
    );
  }, []);

  function handleProjectTemplatesRequest(
    data: ProjectTemplateI[],
    company: CompanyI,
    setPriceIncrease = true
  ): EnvironmentI[] {
    let priceIncrease = 0;
    if (setPriceIncrease) {
      priceIncrease = company.priceIncrease ? company.priceIncrease : 0;
    }
    const projectTemplatesQtd = getQueryStringValue("qty");
    const envsUrlArr: EnvironmentI[] = [];
    let projectTemplatesHashes = getQueryStringValue("environment");
    const environments: EnvironmentI[] = [];

    data.forEach((projectTemplate: ProjectTemplateI) => {
      if (projectTemplate.status === "enabled") {
        const numberOfEnvironments = projectTemplate.numberOfEnvironments
          ? projectTemplate.numberOfEnvironments
          : 1;
        environments.push({
          _id: projectTemplate._id,
          title: projectTemplate.name,
          hash: projectTemplate.hash,
          image: projectTemplate.thumbnail
            ? BUCKET_URL + "/" + projectTemplate.thumbnail
            : HomeOfficeImage,

          price: projectTemplate.price + priceIncrease * numberOfEnvironments,
          minPrice: projectTemplate.price,
          quantity: 0,
        });
      }
    });

    if (typeof projectTemplatesHashes == "string") {
      projectTemplatesHashes = [projectTemplatesHashes];
    }

    if (
      projectTemplatesHashes &&
      projectTemplatesQtd &&
      projectTemplatesHashes.length > 0 &&
      projectTemplatesHashes.length === projectTemplatesQtd.length
    ) {
      projectTemplatesHashes.forEach(
        (projectTemplateHash: string, idx: number) => {
          const environment = environments.find(
            (environment: EnvironmentI) =>
              environment.hash === projectTemplateHash
          );
          if (environment) {
            environment.quantity = parseInt(projectTemplatesQtd[idx]);
            envsUrlArr.push({
              price: environment.price,
              image: environment.image,
              hash: environment.hash,
              title: environment.title,
              quantity: environment.quantity,
              minPrice: environment.minPrice,
              _id: environment._id,
            });
          }
        }
      );
      envsUrlArr.forEach((env) => {
        const provQuestion = environments.find(
          (project) => project.hash === env.hash
        );
        if (provQuestion) provQuestion.quantity += env.quantity;
      });
      return envsUrlArr;
    } else {
      return [];
    }
  }

  function validateUrl(): boolean {
    const projectTemplatesQtd = getQueryStringValue("qty");
    let projectTemplatesHashes = getQueryStringValue("environment");

    if (typeof projectTemplatesHashes == "string") {
      projectTemplatesHashes = [projectTemplatesHashes];
    }

    if (
      !(
        projectTemplatesHashes &&
        projectTemplatesHashes.length > 0 &&
        projectTemplatesQtd &&
        projectTemplatesHashes.length === projectTemplatesQtd.length
      )
    ) {
      return false;
    }
    return true;
  }

  function onNext(index?: number): void {
    setStepIndex(index || stepIndex + 1);
  }

  return (
    <>
      <Container fluid className="main-content-container checkout">
        <Stepper alternativeLabel activeStep={_stepIndex}>
          {steps.map((step, index) => (
            <Step
              className="Step"
              key={index}
              completed={index < _stepIndex && step.text !== "Pagamento"}
              disabled={step.disabled}
            >
              <StepLabel>
                <span
                  style={
                    step.completed
                      ? {
                          color: "green",
                          fontSize: "14px",
                          fontFamily: "margem",
                        }
                      : {
                          color: "black",
                          fontSize: "14px",
                          fontFamily: "margem",
                        }
                  }
                >
                  {step.text}
                </span>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        {company ? (
          <div className={styles.step2}>
            {stepIndex === 1 ? (
              <Panel title="Identificação" className={styles.card}>
                <ShortForm onNext={onNext} userId={user._id} />
              </Panel>
            ) : null}
            {stepIndex === 2 ? (
              <>
                <Panel title="Pagamento" className={styles.card}>
                  <PaymentInfo
                    total={total}
                    company={company}
                    installmentsPrices={installmentsPrices}
                    projectTemplatesToBuy={projectTemplatesToBuy}
                    onVoucherChange={setVoucher}
                    voucher={voucher}
                    calculateVoucherNoComission={() => {
                      const envs = handleProjectTemplatesRequest(
                        projectTemplates,
                        company,
                        false
                      );
                      return envs.reduce((acc, c) => {
                        return acc + c.quantity * company.priceIncrease;
                      }, 0);
                    }}
                  />
                </Panel>
              </>
            ) : null}

            <Panel title="Resumo do pedido" className={styles.card}>
              <PaymentSummary
                company={company}
                fullTotal={fullTotal}
                total={total}
                projectTemplatesToBuy={projectTemplatesToBuy}
              />
              {company.label === "leroymerlinprojeta" ? (
                <img
                  src={
                    "https://s3.amazonaws.com/ladecora.platform/images/banner-leroy.png"
                  }
                  alt="checkout"
                  style={{
                    width: "100%",
                    height: "auto",
                    marginBottom: "20px",
                    borderRadius: "0.625rem",
                  }}
                />
              ) : null}
            </Panel>
          </div>
        ) : null}

        <ToastContainer />
      </Container>
      {stepIndex === 2 && company.label === "ladecora" ? <WhatsButton /> : null}
    </>
  );
};

export default Checkout;
