import React, { useEffect, useState } from "react";
import { Row, Card, CardHeader, CardBody, Col, Button } from "shards-react";
import PageTitle from "../../common/PageTitle";
import StepsForm from "./StepsForm";

const ConfirmInformation = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {}, []);

  const goBackFunction = () => {
    props.goBack();
  };

  const sendData = () => {
    props.sendProject();
    setIsLoading(true);
  };

  return (
    <React.Fragment>
      <Row>
        <Col>
          <h2 style={{ textAlign: "center" }}>
            <span role="img" aria-label="">
              👋
            </span>{" "}
            Atenção
          </h2>
          <h4 style={{ textAlign: "center" }}>
            Caso queira incluir ou revisar alguma informação este é o momento! A
            partir da sua confirmação vamos analisar as suas informações e a
            partir dai, enviaremos o ambiente de decoração em 5 dias úteis!
          </h4>
          <div
            style={{ textAlign: "center" }}
            className="d-grid gap-2 col-10 mx-auto"
          >
            <Button
              size="lg"
              theme="accent"
              className="btn-outline-accent mr-2 mt-2"
              onClick={props.showSummary}
            >
              Revisar informações
            </Button>
            <Button
              size="lg"
              theme="accent"
              className="mr-2 mt-2"
              onClick={sendData}
              disabled={isLoading}
            >
              {isLoading ? "Enviando..." : "Enviar informações"}
            </Button>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default ConfirmInformation;
