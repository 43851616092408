import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getQueryStringValue } from "../actions/auth";
import { getProjectByShareId } from "../actions/projectV2";
import ShareReview from "../components/environments/steps/ShareReview";

interface ProjectI {
  _id: string;
  name: string;
  finalProject: any;
  status: string;
}

const SharedProject = () => {
  const [project, setProject] = useState<ProjectI>();
  const history = useHistory();
  useEffect(() => {
    const shareId = getQueryStringValue("shareId") as string;

    if (shareId) {
      getProjectByShareId(shareId).then((project: ProjectI) => {
        if (project) {
          setProject(project);
        } else {
          history.push("/login");
        }
      });
    }
  }, []);

  return project ? (
    <ShareReview
      project={project}
      goBack={() => {}}
      callbackReturnView={() => {}}
      updateStepCompleted={() => {}}
    />
  ) : null;
};

export default SharedProject;
