import React, { useEffect, useState, useContext } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Button } from "shards-react";

import StepWizard from "react-step-wizard";

import { getProjectsByEnterpriseFilters } from "../../actions/project";
import {
  updateUserAnswers,
  getUserEnterprise,
  reactivateProjectGroup,
} from "../../actions/userEnterprise";

import LeftSidebar from "./LeftSidebar";
import StepChanger from "../common/StepChanger";

import { walkAtTree, getFlatData } from "./questionary-service";

import Blueprints from "./Blueprints";
import UserTypes from "./UserTypes";
import Container from "./Container";
import SidebarItem from "./SidebarItem";
import CollapsibleSidebarItem from "./CollapsibleSidebarItem";
import ProjectGroup from "./ProjectGroup";
import FinalStep from "./FinalStep";
import { AuthContext } from "../common/Authorization/AuthContext";
import ConfirmationButton from "../common/ConfirmationButton";

function Enterprise() {
  const history = useHistory();
  const { id } = useParams();
  const { user } = useContext(AuthContext);

  const [state, setState] = useState({
    room: null,
    bluePrint: null,
    userType: "",
    scores: {},
    step: 0,
    questionaryDone: false,
    isRoomsDone: false,
    isFinished: false,
    acceptedRooms: null,
    lastRoom: null,
    showFinalProject: false,
    answers: [],
    treeData: [],
    flatData: [],
    projects: [],
  });

  const [userEnterprise, setUserEnterprise] = useState();
  const [enterprise, setEnterprise] = useState({});
  const [allForm, setAllForm] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [answer, setAnswer] = useState();
  const { hash } = useLocation();
  const [isProjectGroupLoading, setIsProjectGroupLoading] = useState(false);
  const [isLeftSiderbarOpened, setIsLeftSiderbarOpened] = useState(false);
  const STEPS = {
    BLUE_PRINT: (
      <Container>
        <Blueprints
          isLoading={isLoading}
          enterprise={enterprise}
          onBlueprintClick={onBlueprintClick}
          selectedItem={state.bluePrint}
        />
      </Container>
    ),
    USER_TYPE: (
      <Container>
        <UserTypes
          enterprise={enterprise}
          onUserTypeClick={onUserTypeClick}
          userType={state.userType}
        />
      </Container>
    ),
    QUESTIONARY: !!allForm.length && (
      <Container>
        <div className="small-container">
          <div className="mx-3 questionary">
            <StepWizard isHashEnabled transitions>
              {allForm}
            </StepWizard>
          </div>
        </div>
      </Container>
    ),
    PROJECT_GROUP: state.room && state.projects && (
      <Container scroll={true}>
        <ProjectGroup
          room={state.room}
          projects={state.projects}
          bluePrint={state.bluePrint}
          onProjectAcceptClick={onProjectAcceptClick}
          acceptedRooms={state.acceptedRooms}
          isLoading={isProjectGroupLoading}
          enterprise={enterprise}
        />
      </Container>
    ),
    FINAL_STEP: (
      <Container>
        <FinalStep
          userEnterprise={userEnterprise}
          acceptedRooms={state.acceptedRooms}
          bluePrint={state.bluePrint}
          enterprise={enterprise}
        />
      </Container>
    ),
  };
  const STEP_KEYS = Object.keys(STEPS);
  //On First render
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    let body = {
      user: user._id,
      enterprise: id,
    };
    if (queryParams.has("idAnswers")) {
      body["_id"] = queryParams.get("idAnswers");
      queryParams.delete("idAnswers");
      history.replace({
        search: queryParams.toString(),
      });
    }

    getUserEnterprise(body).then((data) => {
      if (!data._id) {
        reactivateProjectGroup(user._id, id).then((res) => {
          setUserEnterprise(res);
        });
      } else {
        setUserEnterprise(data);
      }
    });
  }, []);

  //on userEnterprise is loaded
  useEffect(() => {
    if (userEnterprise) {
      let convertedData = userEnterprise.enterprise.rooms.map((room) => {
        if (room.mirrored) {
          let projectToCopy = userEnterprise.enterprise.rooms.filter(
            (r) => r._id === room.mirrored
          );
          projectToCopy = { ...projectToCopy[0] };
          projectToCopy.name = room.name;
          projectToCopy.mirrored = room.mirrored;
          delete projectToCopy._id;
          return projectToCopy;
        }
        return room;
      });
      const newState = { ...state };
      userEnterprise.enterprise.rooms = convertedData;
      setEnterprise(userEnterprise.enterprise);
      if (userEnterprise.questionaryAnswers) {
        newState.answers = userEnterprise.questionaryAnswers;
      }
      if (userEnterprise.bluePrint) {
        const bluePrint = userEnterprise.enterprise.rooms.find(
          (r) => r.name === userEnterprise.bluePrint
        );
        const roomsToBeAccepted = {};
        newState.lastRoom =
          bluePrint.environments[bluePrint.environments.length - 1].name;

        bluePrint.environments.forEach((e) => {
          roomsToBeAccepted[e.name] = null;
        });
        newState.acceptedRooms = roomsToBeAccepted;
        newState.bluePrint = bluePrint;
        const [treeData, flatData] = refreshQuestionary(
          bluePrint,
          userEnterprise.questionaryAnswers
        );
        newState.treeData = treeData;
        newState.flatData = flatData;
      }

      if (userEnterprise.userType) {
        newState.userType = userEnterprise.userType;
      }

      if (userEnterprise.questionaryDone) {
        newState.questionaryDone = true;
        newState.showFinalProject = true;
      }

      if (userEnterprise.acceptedRooms) {
        newState.acceptedRooms = userEnterprise.acceptedRooms;
      }

      if (userEnterprise.isDone) {
        newState.isFinished = true;
        newState.step = 4;
      }

      newState.scores = calculateScore(
        userEnterprise.questionaryAnswers,
        userEnterprise.enterprise
      );

      setState(newState);
      setIsLoading(false);
    }
  }, [userEnterprise]);

  //On Questionary answer
  useEffect(() => {
    let newState = { ...state };
    if (answer) {
      const answers = JSON.parse(JSON.stringify(state.answers));
      let form = answers.find((element) =>
        element.question === answer.question ? true : false
      );
      if (!form) {
        answers.push(JSON.parse(JSON.stringify(answer)));
      } else {
        answers.forEach((element) => {
          if (element.question === answer.question) {
            element.answer = answer.answer;
            element.goToNextStep = answer.goToNextStep;
            element.input = answer.input;
            element.score = answer.score;
            element.isMultiselect = answer.isMultiselect;
          }
        });
      }
      newState.answers = answers;

      setAnswer(null);

      const stepN = parseInt(hash.replace("#step", "") - 1);
      const isLastQuestion = state.flatData.length - 1 === stepN;
      const userAnswers = {
        user: user._id,
        enterprise: id,
        questionaryAnswers: answers,
      };
      if (isLastQuestion) {
        newState = {
          ...newState,
          ...onQuestionaryFinished(answers),
        };

        setIsLoading(true);
        updateUserAnswers(userEnterprise._id, {
          ...userAnswers,
          questionaryDone: true,
        }).then(() => {
          setIsLoading(false);
          setState(newState);
          history.replace(`/enterprise/${id}`);
        });
      } else if (answer.goToNextStep) {
        setState(newState);
        nextStep();
        updateUserAnswers(userEnterprise._id, userAnswers).catch((err) => {
          console.log(err);
        });
      }
    }
  }, [answer]);

  //On room changed.
  useEffect(() => {
    if (
      state.questionaryDone &&
      state.userType &&
      state.bluePrint &&
      state.step === 3
    ) {
      let nameBluePrint = state.bluePrint.name;
      if (state.bluePrint.mirrored) {
        let projectToCopy = enterprise.rooms.filter(
          (r) => r._id === state.bluePrint.mirrored
        );
        nameBluePrint = projectToCopy[0].name;
      }
      const filter = {
        category: null,
        style: null,
        bluePrint: nameBluePrint,
        environments: state.scores.environment,
        room: state.room.name,
      };
      Object.keys(state.scores.category).forEach((key) => {
        if (!filter.category) {
          filter.category = {
            name: key,
            value: state.scores.category[key],
          };
        } else if (state.scores.category[key] > filter.category.value) {
          filter.category = {
            name: key,
            value: state.scores.category[key],
          };
        }
      });
      Object.keys(state.scores.style).forEach((key) => {
        if (!state.scores.style[key]) return;
        if (!filter.style) {
          filter.style = {
            name: key,
            value: state.scores.style[key],
          };
        } else if (state.scores.style[key] > filter.style.value) {
          filter.style = {
            name: key,
            value: state.scores.style[key],
          };
        }
      });
      isProjectGroupLoading && isProjectGroupLoading.cancel();
      const promise = getProjectsByEnterpriseFilters(id, filter);
      setIsProjectGroupLoading(promise);
      promise
        .then((data) => {
          setIsProjectGroupLoading(false);
          setState({ ...state, showFinalProject: true, projects: data });
        })
        .catch(() => {});
    }
  }, [state.room]);

  //On room select.
  useEffect(() => {
    if (state.acceptedRooms) {
      const keys = Object.keys(state.acceptedRooms);
      const value = keys.reduce((acc, cur) => {
        return acc && state.acceptedRooms[cur];
      }, true);
      if (value !== state.isRoomsDone)
        setState({ ...state, isRoomsDone: value });
      else if (
        state.room &&
        state.lastRoom !== state.room.name &&
        state.acceptedRooms[state.room.name]
      ) {
        nextStep();
      }
    }
  }, [state.acceptedRooms]);

  function onQuestionaryFinished(answers) {
    const newState = {};
    newState.room = state.bluePrint.environments[0];
    newState.step = 3;
    newState.questionaryDone = true;
    newState.scores = calculateScore(answers);
    return newState;
  }

  function reactivate() {
    reactivateProjectGroup(user._id, id).then(() => {
      window.location.reload();
    });
  }

  const gerarScore = (param) => {
    let scoreGerado = {
      category: {},
      style: {},
      environment: {},
    };

    param?.styles?.forEach((elem) => {
      scoreGerado.style[elem?.name] = 0;
    });

    param?.categories?.forEach((elem) => {
      scoreGerado.category[elem?.name] = 0;
    });

    return scoreGerado;
  };

  function calculateScore(_answers, enterpriseParam = enterprise) {
    const score = gerarScore(enterpriseParam);
    _answers.forEach((a) => {
      if (a.score) {
        if (score[a.score.kinda][a.score.name])
          score[a.score.kinda][a.score.name] += a.score.value;
        else score[a.score.kinda][a.score.name] = a.score.value;
      }
    });

    return score;
  }

  const updateForm = (data) => {
    setAnswer(data);
  };

  const noEnterprise = () => {
    return (
      <div className="error">
        <div className="error__content">
          <h2>&#8251;</h2>
          <h3>Carregando...</h3>
        </div>
      </div>
    );
  };

  function goToStep(i) {
    window.location.hash = `step${i}`;
  }

  function nextStep() {
    if (state.step === 0) {
      setState({ ...state, step: state.step + 1, room: null });
    } else if (state.step === 1) {
      history.replace(`/enterprise/${enterprise._id}#step1`);
      const [treeData, flatData] = refreshQuestionary(
        state.bluePrint,
        state.answers
      );
      setState({
        ...state,
        treeData,
        flatData,
        step: 2,
        room: null,
      });
    } else if (state.step === 2) {
      const stepN = parseInt(hash.replace("#step", "") - 1);
      if (state.flatData.length - 1 === stepN) {
        setIsLoading(true);
        const newState = {
          ...state,
          ...onQuestionaryFinished(state.answers),
          step: 3,
        };
        updateUserAnswers(userEnterprise._id, {
          user: user._id,
          enterprise: id,
          questionaryDone: true,
        }).then(() => {
          setState(newState);
          setIsLoading(false);
          history.replace(`/enterprise/${enterprise._id}`);
        });

        return;
      }

      window.scrollTo(0, 0);
      const node = state.flatData[stepN];
      if (node.node.input) {
        if (node.node.input.action) {
          goToStep(parseInt(node.node.input.action) + 1);
        } else {
          let parentNode = state.flatData.find(
            (element) => element.treeIndex === node.path[0]
          );
          let nextStep = !parentNode.childCount
            ? node.path[0] + 2
            : parentNode.childCount + node.path[0] + 2;
          goToStep(nextStep);
        }
      } else {
        let parentNode = state.flatData.find(
          (element) => element.treeIndex === node.path[0]
        );
        //MUDADO APENAS O 2 para 1
        // let nextStep = !parentNode.childCount
        //   ? node.path[0] + 2
        //   : parentNode.childCount + node.path[0] + 2;
        let nextStep = !parentNode.childCount
          ? node.path[0] + 2
          : parentNode.childCount + node.path[0] + 2;
        goToStep(nextStep);
      }
    } else if (state.step === 3) {
      if (state.room.name !== state.lastRoom) {
        const roomNames = Object.keys(state.acceptedRooms);
        const idx = roomNames.findIndex((r) => r === state.room.name);
        setState({ ...state, room: state.bluePrint.environments[idx + 1] });
      } else {
        updateUserAnswers(userEnterprise._id, {
          user: user._id,
          enterprise: id,
          isDone: true,
        });
        setState({ ...state, isFinished: true, step: 4 });
      }
    } else {
      setState({ ...state, step: state.step + 1 });
    }
  }

  function previousStep() {
    if (state.step === 2 && hash !== "#step1") {
      window.scrollTo(0, 0);
      history.goBack();
    } else if (state.step === 3) {
      const roomNames = Object.keys(state.acceptedRooms);
      const idx = roomNames.findIndex((r) => r === state.room.name);
      if (idx === 0) {
        isProjectGroupLoading && isProjectGroupLoading.cancel();
        const [treeData, flatData] = refreshQuestionary(
          state.bluePrint,
          state.answers
        );

        setState({
          ...state,
          room: null,
          step: 2,
          treeData,
          flatData,
        });
        history.replace(`/enterprise/${enterprise._id}#step1`);
      } else {
        setState({ ...state, room: state.bluePrint.environments[idx - 1] });
      }
    } else {
      state.step > 0 && setState({ ...state, step: state.step - 1 });
    }
  }

  const getQuestions = (additionalQuestions) => {
    let tempQuestions = [];

    enterprise?.profiles?.map((elem) => {
      if (elem.kinda === state.userType) {
        tempQuestions = elem.questions;
      }
    });

    return JSON.parse(JSON.stringify(tempQuestions)).concat(
      additionalQuestions
    );
  };

  function refreshQuestionary(bluePrint, answers) {
    answers = JSON.parse(JSON.stringify(answers));
    const additionalQuestions = [];
    bluePrint.environments.forEach((e) => {
      if (e.name === "Dormitório 2") {
        const question = {
          title: `Seu segundo quarto, o que será?`,
          kinda: "multiple",
          input_list: [],
        };
        e.variations.forEach((variation) => {
          question.input_list.push({
            label: variation.name,
            score: {
              kinda: "environment",
              name: e.name,
              value: variation.hash,
            },
            image_path: "",
          });
        });

        additionalQuestions.push(question);
      }
    });

    const questions = getQuestions(additionalQuestions);
    const flatData = getFlatData(questions);
    const newState = { ...state, flatData: flatData, treeData: questions };
    walkAtTree(
      newState.treeData,
      flatData,
      {
        stats: StepChanger,
        flatData,
        update: updateForm,
        answers: answers,
        answeredQuestions: answers,
      },
      setAllForm
    );

    if (answers && answers.length) {
      flatData.forEach((d) => {
        const questionResponse = answers.find(
          (a) => a.question === d.node.title
        );
        if (!questionResponse) return;
        if (d.node.isMultiselect) {
          d.node.input_list.map((input) => {
            input["selected"] = false;

            questionResponse.answer.forEach((a) => {
              if (a.answer === input.image_path || a.answer === input.label) {
                input["selected"] = true;
              }
            });
            return input;
          });

          d.node.input = d.node.input_list;
        } else {
          d.node.input_list.map((input) => {
            input["selected"] = false;
            if (
              questionResponse.answer === input.image_path ||
              questionResponse.answer === input.label
            ) {
              d.node.input = input;
              input["selected"] = true;
            }
            return input;
          });
        }
      });
      newState.answers = answers;
    }
    return [questions, flatData];
  }

  function onBlueprintClick(bluePrint) {
    const newState = { ...state };

    const roomsToBeAccepted = {};
    newState.lastRoom =
      bluePrint.environments[bluePrint.environments.length - 1].name;
    bluePrint.environments.forEach((e) => {
      roomsToBeAccepted[e.name] = null;
    });
    newState.acceptedRooms = roomsToBeAccepted;
    newState.questionaryDone = false;
    newState.bluePrint = bluePrint;
    newState.showFinalProject = false;
    newState.answers = [];
    newState.scores = gerarScore(enterprise);
    const [treeData, flatData] = refreshQuestionary(bluePrint, []);
    newState.treeData = treeData;
    newState.flatData = flatData;
    setState(newState);

    updateUserAnswers(userEnterprise._id, {
      bluePrint: bluePrint.name,
      user: user._id,
      enterprise: id,
      questionaryDone: false,
      questionaryAnswers: [],
      acceptedRooms: roomsToBeAccepted,
    });
  }

  function onUserTypeClick(userType) {
    updateUserAnswers(userEnterprise._id, {
      userType,
      user: user._id,
      enterprise: id,
    });
    setState({ ...state, userType });
  }

  function onProjectAcceptClick(project, roomName) {
    const acceptedRooms = JSON.parse(JSON.stringify(state.acceptedRooms));
    acceptedRooms[roomName] = project;
    setState({ ...state, acceptedRooms: acceptedRooms });

    updateUserAnswers(userEnterprise._id, {
      user: user._id,
      enterprise: id,
      acceptedRooms,
    });
  }

  return (
    <div
      className={`enterprise ${
        isLeftSiderbarOpened ? "left-sidebar-opened" : ""
      }`}
    >
      <LeftSidebar show={true}>
        <div className="sidebar-title" />
        <div className="left-sidebar-items pt-3 pr-2">
          <SidebarItem
            onClick={() => setState({ ...state, step: 0 })}
            isSelected={state.step === 0}
            isDone={!!state.bluePrint}
            text="Planta baixa"
            isLocked={isLoading || state.isFinished}
          />
          <SidebarItem
            onClick={() => setState({ ...state, step: 1 })}
            isSelected={state.step === 1}
            isDone={!!state.userType}
            text="Perfil"
            isLocked={isLoading || state.isFinished}
          />
          <SidebarItem
            onClick={() => {
              history.replace(`/enterprise/${enterprise._id}#step1`);
              const [treeData, flatData] = refreshQuestionary(
                state.bluePrint,
                state.answers
              );
              setState({
                ...state,
                treeData,
                flatData,
                step: 2,
                room: null,
              });
            }}
            isLocked={!state.userType || !state.bluePrint || state.isFinished}
            isSelected={state.step === 2}
            isDone={state.questionaryDone}
            text="Questionário"
          />
          <CollapsibleSidebarItem
            isCollapsed={state.showFinalProject}
            isLocked={
              !state.bluePrint ||
              !state.userType ||
              !state.questionaryDone ||
              state.isFinished
            }
            onClick={() =>
              setState({ ...state, showFinalProject: !state.showFinalProject })
            }
            text="Projeto final"
            items={
              state.bluePrint
                ? state.bluePrint.environments.map((r) => {
                    return {
                      text: r.name,
                      isDone: !!state.acceptedRooms[r.name],
                      isLocked: state.isFinished,
                      isSelected:
                        state.step === 3 && r.name === state.room.name,
                      onClick: () => {
                        setState({ ...state, step: 3, room: r });
                      },
                    };
                  })
                : []
            }
          />
          <SidebarItem
            onClick={() => {
              setState({ ...state, step: 4 });
            }}
            isLocked={
              !state.isFinished ||
              !state.bluePrint ||
              !state.bluePrint.environments.reduce((acc, e) => {
                return acc && !!state.acceptedRooms[e.name];
              }, true)
            }
            isSelected={state.step === 4}
            isDone={state.isFinished}
            text="Conclusão"
          />
          <hr />
          <div
            onKeyDown={() => {
              //TODO
            }}
            role="button"
            tabIndex="0"
            className={`left-sidebar-item d-flex align-items-center justify-content-center mb-2 p-2`}
            onClick={() => {
              history.replace("/enterprises");
            }}
          >
            Página inicial
            <i
              className="material-icons ml-auto"
              style={{
                fontSize: "1.3rem",
                color: "black",
                top: 0,
              }}
            >
              arrow_back
            </i>
          </div>
        </div>
      </LeftSidebar>
      <div className="enterprise-content">
        {Object.keys(enterprise).length === 0 ? (
          noEnterprise()
        ) : (
          <>
            <div className="enterprise-header px-2 d-flex align-items-center">
              <Button
                theme="white"
                className="mr-3 left-sidebar-toggle"
                style={{ padding: ".4rem" }}
                onClick={() => setIsLeftSiderbarOpened(!isLeftSiderbarOpened)}
              >
                <i
                  className="material-icons"
                  style={{
                    fontSize: "1.3rem",
                    color: "black",
                  }}
                >
                  menu
                </i>
              </Button>
              <div className="building-name d-inline-block ml-4">
                {`${enterprise.name}`}{" "}
                {state.bluePrint && ` - ${state.bluePrint.name}`}
              </div>
            </div>
            <div className="main-panel">
              <div
                style={{ width: "100%", height: "100%", position: "absolute" }}
              >
                {STEPS[STEP_KEYS[state.step]]}
                {state.isFinished ? (
                  <div className="action-footer d-flex align-items-center">
                    <ConfirmationButton
                      theme="accent"
                      outline
                      className="mr-auto ml-4"
                      onClick={reactivate}
                    >
                      <i className="material-icons ml-auto">arrow_back</i>
                      Responder novamente
                    </ConfirmationButton>
                    <Button
                      theme="accent"
                      className="ml-auto mr-4"
                      onClick={() => {
                        history.replace("/environments");
                      }}
                    >
                      + Projeto personalizado
                    </Button>
                  </div>
                ) : null}
                {!state.isFinished ? (
                  <div className="action-footer d-flex align-items-center">
                    <Button
                      theme="accent"
                      outline
                      onClick={previousStep}
                      disabled={isLoading || state.step === 0}
                      className="mr-auto ml-4"
                    >
                      Anterior
                    </Button>
                    <Button
                      theme="accent"
                      className="ml-auto mr-4"
                      disabled={
                        isLoading ||
                        (state.step === 0 && !state.bluePrint) ||
                        (state.step === 1 &&
                          (!state.bluePrint || !state.userType)) ||
                        (state.step === 2 &&
                          state.flatData.length &&
                          (((!state.bluePrint || !state.userType) &&
                            hash === `#step${state.flatData.length}`) ||
                            !state.flatData[
                              parseInt(hash.replace("#step", "") - 1)
                            ].node.input)) ||
                        (state.step === 3 &&
                          !state.isRoomsDone &&
                          state.room.name === state.lastRoom)
                      }
                      onClick={nextStep}
                    >
                      {state.isRoomsDone &&
                      state.step === 3 &&
                      state.lastRoom === state.room.name
                        ? "Finalizar"
                        : "Próximo"}
                    </Button>
                  </div>
                ) : null}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

Enterprise.propTypes = {};

export default Enterprise;
