import React from "react";
import { Nav } from "shards-react";
import { NotificationProvider } from "../../../Notification/NotificationContext";

import NotificationCenter from "../../../Notification/NotificationCenterV2";

const NavbarNav = () => (
  <Nav navbar className="notification-icon flex-row">
    <NotificationProvider>
      <NotificationCenter />
    </NotificationProvider>
  </Nav>
);
export default NavbarNav;
