import React from "react";
import { Controller } from "react-hook-form";
import { phoneValidation } from "../../utils/form_validation";
import FieldError from "../common/FieldError";
import PhoneBR from "../common/PhoneBRV2";

function FormPhoneBR({ control }) {
  return (
    <Controller
      control={control}
      name="phone"
      defaultValue=""
      rules={{
        required: "Digite seu telefone",
        validate: {
          isValid: (value: string) => {
            return phoneValidation(value) ? true : "Telefone inválido";
          },
        },
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <>
            <PhoneBR
              value={value}
              onChange={(phone) => {
                onChange(phone);
              }}
              placeholder="Telefone"
              autoComplete="phone"
              name="phone"
              className="form-control"
            />
            <FieldError isValid={!!error} message={error?.message} />
          </>
        );
      }}
    />
  );
}

export default FormPhoneBR;
