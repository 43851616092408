import React from "react";

import { Button } from "shards-react";
const InternalText = (props) => {
  props.node.node.input = props.node.node.input_list[0];

  return (
    <div className="px-3 mt-2  ql-editor" style={{ fontSize: "16px" }}>
      <h4 className="mb-3">{props.node.node.title}</h4>

      <div
        dangerouslySetInnerHTML={{
          __html: props.node.node.internalText,
        }}
      />
      <div className="w-100 text-center mt-5">
        <Button size="lg" theme="accent" onClick={props.ns}>
          Continuar
        </Button>
      </div>
      {/* <props.stats step={props.index} {...props} /> */}
    </div>
  );
};
export default InternalText;
