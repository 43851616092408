import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo,
} from "react";
import {
  Row,
  Card,
  CardBody,
  CardHeader,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
} from "shards-react";

import PageTitle from "../../common/PageTitle";
import Slider from "react-slick";
import { BUCKET_URL } from "../../../config";

import { ToastContainer } from "react-toastify";
import PropTypes from "prop-types";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import "@ladecora/react-image-lightbox/style.css";

import { updateCommentInProjectReview } from "../../../actions/project";

import ShareReviewImage from "./ReviewImage";
import { debounce } from "../../../utils/throttle";
import { download, showReviewProjects } from "../../../utils/util";

import "./review.scss";
import { ShowLightbox } from "../../common/ShowLightbox";
import Moment from "react-moment";
import { limitDateFunction } from "../../../services/project";
import ProductList from "../sub-component/ProductList";
import ImagesDownloadProject from "../sub-component/ImagesDownloadProject";
import { getPdfDefaultItems, getTextItems } from "../../../actions/pdfTextItem";
import { ContractorSettings, WoodWorker } from "../../common/considerations";
import DecorationGuide from "../../common/DecorationGuide";
import { SystemContext } from "../../SystemConfig/SystemContext";
import { useHistory } from "react-router-dom";

const Review = (props) => {
  const settingsForOne = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const tabs = { 0: "preview", 1: "firstReview", 2: "secondReview" };
  const updateCommentsInReview = useCallback(
    debounce((data) => {
      const { projectId, review, fileId, comment } = data;
      updateCommentInProjectReview(projectId, review, fileId, comment).then(
        () => {}
      );
    }, 800),
    []
  );
  const [files, setFiles] = useState([]);
  const [filesFirstReview, setFilesFirstReview] = useState([]);
  const [filesSecondReview, setFilesSecondReview] = useState([]);
  const [projectId, setProjectId] = useState("");
  const [project, setProject] = useState();
  const [lightbox, setLightbox] = useState({
    open: false,
    index: 0,
    images: [],
  });
  const [valueTab, setValueTab] = useState(0);
  const [, setReadOnly] = useState(false);

  const [suggestionsEnabled, setSuggestionsEnabled] = useState(false);

  const [tableData, setTableData] = useState({
    table: {
      pageSizeOptions: [5, 10, 15, 20, 25, 30],
      pageSize: 5,
      data: [],
    },
  });
  const [customImg, setCustomImg] = useState(true);

  const [modalComment, setModalComment] = useState({
    show: false,
    likeComments: "",
    dislikeComments: "",
  });

  const { company } = useContext(SystemContext);

  const priceToShow = useMemo(() => {
    if (company.label == "tok_stok") {
      return 249;
    }
    return 299 + company.priceIncrease;
  }, [company]);

  const history = useHistory();

  const [furnitures, setFurnitures] = useState([]);
  const [allPdfDefaultItems, setAllPdfDefaultItems] = useState([]);
  const [allTextItems, setAllTextItems] = useState([]);

  let finalProjectImages = [];

  if (typeof project?.render?.decorationPanel !== "undefined") {
    project.render.decorationPanel.map((elem) => {
      finalProjectImages.push(elem.source);
    });
  }
  if (typeof project?.render?.workPlanLayout !== "undefined") {
    project.render.workPlanLayout.map((elem) => {
      finalProjectImages.push(elem.source);
    });
  }

  useEffect(() => {
    let furnitures = [];
    if (props.project && props.project.finalProject) {
      if (
        valueTab === 0 &&
        props.project.finalProject.preview &&
        props.project.finalProject.preview.furnitures
      ) {
        furnitures = props.project.finalProject.preview.furnitures;
      } else if (
        valueTab === 1 &&
        props.project.finalProject.firstReview &&
        props.project.finalProject.firstReview.furnitures
      ) {
        furnitures = props.project.finalProject.firstReview.furnitures;
      } else if (
        valueTab === 2 &&
        props.project.finalProject.secondReview &&
        props.project.finalProject.secondReview.furnitures
      ) {
        furnitures = props.project.finalProject.secondReview.furnitures;
      }
    }
    setFurnitures(furnitures);
  }, [valueTab]);

  useEffect(() => {
    if (
      props.project.status === "waiting_quality" ||
      props.project.status === "reviewing_quality" ||
      props.project.status === "customer-reproved" ||
      props.project.status === "waiting_render_approved" ||
      props.project.status === "waiting_render_reproved"
    ) {
      setReadOnly(true);
    }
    let stepsProjectToShow = showReviewProjects(props.project);

    setFiles(props.project.finalProject.preview.files);
    /*  eslint-disable */
    if (
      props.project.finalProject.firstReview.files != "" &&
      stepsProjectToShow.firstReview
    ) {
      setFilesFirstReview(props.project.finalProject.firstReview.files);
    }
    if (
      props.project.finalProject.secondReview.files != "" &&
      stepsProjectToShow.secondReview
    ) {
      setFilesSecondReview(props.project.finalProject.secondReview.files);
    }

    if (
      isReviewApproved("2º Revisão") ||
      isReviewApproved("secondReview") ||
      (!isReadOnly() && props.project.finalProject.secondReview.lastUpdate)
    ) {
      setValueTab(2);
      setTableData({
        table: {
          ...tableData.table,
          data: props.project.finalProject.secondReview.furnitures,
        },
      });
      props.project.finalProject.secondReview.furnitures;
    } else if (
      isReviewApproved("1º Revisão") ||
      isReviewApproved("firstReview") ||
      (!isReadOnly() && props.project.finalProject.firstReview.lastUpdate)
    ) {
      setValueTab(1);
      setTableData({
        table: {
          ...tableData.table,
          data: props.project.finalProject.firstReview.furnitures,
        },
      });
    } else if (
      isReviewApproved("Proposta Inicial") ||
      isReviewApproved("preview") ||
      (!isReadOnly() && props.project.finalProject.preview.lastUpdate)
    ) {
      setValueTab(0);
      setTableData({
        table: {
          ...tableData.table,
          data: props.project.finalProject.preview.furnitures,
        },
      });
    } else {
      setTableData({
        table: {
          ...tableData.table,
          data: props.project.finalProject.preview.furnitures,
        },
      });
    }

    /*  eslint-disable */
    setProjectId(props.project._id);
    setProject(props.project);

    getPdfDefaultItems().then((res) => {
      setAllPdfDefaultItems(res);
    });
    getTextItems().then((res) => {
      setAllTextItems(res);
    });
  }, [props.project]);

  const settings = {
    className: "center slider variable-width",
    centerMode: true,
    infinite: true,
    centerPadding: "8px",
    slidesToShow: 1,
    speed: 500,
    dots: true,
    variableWidth: true,
  };

  const customSettings = {
    className: "center slider variable-width",
    centerMode: true,
    infinite: true,
    centerPadding: "8px",
    slidesToShow: 1,
    speed: 500,
    dots: true,
    variableWidth: true,
  };

  const handleChange = (img, comment) => {
    const review = tabs[valueTab];
    setFiles(
      files.map((file) => {
        return file._id === img._id
          ? {
              ...file,
              likeComments: comment.likeComments,
              dislikeComments: comment.dislikeComments,
            }
          : file;
      })
    );
    updateCommentsInReview({
      projectId: props.project._id,
      review,
      fileId: img._id,
      comment,
    });
  };

  const handleChangeFirstR = (img, comment) => {
    const review = tabs[valueTab];
    setFilesFirstReview(
      filesFirstReview.map((file) => {
        return file._id === img._id
          ? {
              ...file,
              likeComments: comment.likeComments,
              dislikeComments: comment.dislikeComments,
            }
          : file;
      })
    );
    updateCommentsInReview({
      projectId: props.project._id,
      review,
      fileId: img._id,
      comment,
    });
  };

  const handleChangeSecondR = (img, comment) => {
    const review = tabs[valueTab];
    setFilesSecondReview(
      filesSecondReview.map((file) => {
        return file._id === img._id
          ? {
              ...file,
              likeComments: comment.likeComments,
              dislikeComments: comment.dislikeComments,
            }
          : file;
      })
    );
    updateCommentsInReview({
      projectId: props.project._id,
      review,
      fileId: img._id,
      comment,
    });
  };

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
    setTableData({
      table: {
        ...tableData.table,
        data: props.project.finalProject[tabs[newValue]].furnitures,
      },
    });
  };

  const sendLightbox = (data, idx) => {
    if (Array.isArray(data)) {
      setLightbox({
        ...lightbox,
        images: data,
        open: true,
        index: idx,
      });
    } else {
      setLightbox({
        ...lightbox,
        images: [data],
        open: true,
        index: 0,
      });
    }
  };

  const displayReviewMsg = () => {
    return (
      project &&
      (project.status === "customer-reproved" ||
        project.status === "waiting_confirm_review" ||
        project.status === "waiting_quality" ||
        project.status === "reviewing_quality")
    );
  };

  function isReviewApproved(name) {
    return (
      props.project &&
      props.project.finalProject.approvedProject === name &&
      ![
        "preview",
        "first_review",
        "second_review",
        "waiting_confirm_review",
        "waiting_user_feedback",
        "customer-reproved",
      ].includes(props.project.status)
    );
  }

  function isReadOnly() {
    return (
      props.project &&
      (props.project.status === "waiting_render_approved" ||
        props.project.status === "waiting_render_reproved" ||
        props.project.status === "waiting_confirm_review" ||
        props.project.status === "customer-reproved" ||
        props.project.status === "waiting_quality" ||
        props.project.status === "reviewing_quality")
    );
  }

  return (
    <div className="mr-4 ml-4">
      <ToastContainer autoClose={false} />
      {lightbox.open && (
        <ShowLightbox lightbox={lightbox} setLightbox={setLightbox} />
      )}
      <Row noGutters className="page-header py-4">
        <PageTitle
          title={`Ambiente:  ${props.project.name}`}
          subtitle="Visão Geral"
          md="12"
          className="ml-sm-auto mr-sm-auto"
        />
      </Row>
      <Row className="mobile-review">
        <Col>
          <Card>
            <CardHeader>
              <i
                className="fa fa-times"
                onClick={() => {
                  props.goBack(true);
                }}
                style={{ fontSize: "22px", cursor: "pointer", float: "right" }}
                aria-hidden="true"
              ></i>
            </CardHeader>
            <CardBody className="pt-0">
              {displayReviewMsg() && (
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <div className="d-flex flex-column justify-content-center align-items-center text-center mt-3">
                    <h3>
                      {project?.status === "waiting_confirm_review"
                        ? "Informações da revisão em análise"
                        : "Seu ambiente está em revisão"}
                    </h3>
                    {limitDateFunction(
                      props.project.logDates,
                      props.project.status,
                      props.project.forcedStep
                    ) !== "" && (
                      <>
                        <h5>Data de entrega:</h5>
                        <Moment format="DD/MM/YYYY">
                          {limitDateFunction(
                            props.project.logDates,
                            props.project.status,
                            props.project.forcedStep
                          ).toString()}
                        </Moment>
                      </>
                    )}
                  </div>

                  <div className="mt-5 pb-2 text-center">
                    <h4 style={{ color: "var(--newbackground-color)" }}>
                      Versão anterior
                    </h4>
                  </div>
                </div>
              )}

              <div className="mt-0">
                <div md="12" style={{ textAlign: "center" }}>
                  {project?.status === "final_project" ? (
                    <Slider
                      {...(finalProjectImages.length > 1
                        ? settings
                        : settingsForOne)}
                    >
                      {finalProjectImages.map((file, idx) => {
                        return (
                          <div
                            className="d-flex justify-content-center align-items-center carousel-container"
                            key={idx}
                          >
                            {finalProjectImages.length > 1 ? (
                              <div
                                role="button"
                                tabIndex="0"
                                alt=""
                                onKeyDown={() => {}}
                                className="custom-slider ml-2 shadow-lg"
                                onClick={() => {
                                  sendLightbox(finalProjectImages, idx);
                                }}
                                style={{
                                  backgroundImage: `url("${file}")`,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "cover",
                                  backgroundPosition: "center center",
                                }}
                              />
                            ) : (
                              <>
                                {/* eslint-disable-next-line */}
                                <img
                                  alt=""
                                  style={{
                                    cursor: "pointer",
                                    maxWidth: "100%",
                                  }}
                                  className="rounded img-fluid carousel-image"
                                  src={file}
                                  onClick={() =>
                                    sendLightbox(finalProjectImages, idx)
                                  }
                                />
                              </>
                            )}
                          </div>
                        );
                      })}
                    </Slider>
                  ) : (
                    <div>
                      <ThemeProvider theme={theme}>
                        <AppBar position="static" color="default">
                          <Tabs
                            value={valueTab}
                            onChange={handleChangeTab}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                            centered
                          >
                            <Tab
                              label="Proposta Inicial"
                              data-tab-detail="preview"
                              {...a11yProps(0)}
                            />
                            {/*  eslint-disable */}
                            {filesFirstReview != "" ? (
                              <Tab
                                label="1º Revisão"
                                data-tab-detail="first"
                                {...a11yProps(1)}
                              />
                            ) : (
                              ""
                            )}
                            {filesSecondReview != "" ? (
                              <Tab
                                label="2º Revisão"
                                data-tab-detail="second"
                                {...a11yProps(2)}
                              />
                            ) : (
                              ""
                            )}
                            {/* eslint-disable */}
                          </Tabs>
                        </AppBar>
                        <TabPanel
                          value={valueTab}
                          index={0}
                          dir={theme.direction}
                        >
                          <Slider
                            {...(customImg && files.length > 1
                              ? customSettings
                              : settings)}
                          >
                            {files.map((file, idx) => {
                              const imgLink =
                                BUCKET_URL +
                                "/projects/" +
                                projectId +
                                "/preview/" +
                                file.file;
                              return (
                                <ShareReviewImage
                                  key={idx}
                                  file={file}
                                  disabled={!suggestionsEnabled}
                                  projectId={projectId}
                                  onChange={handleChange}
                                  link={imgLink}
                                  onImageClick={() =>
                                    sendLightbox(
                                      files.map(
                                        (elem) =>
                                          BUCKET_URL +
                                          "/projects/" +
                                          projectId +
                                          "/preview/" +
                                          elem.file
                                      ),
                                      idx
                                    )
                                  }
                                  customImg={customImg && files.length > 1}
                                  setModalComment={setModalComment}
                                />
                              );
                            })}
                          </Slider>
                        </TabPanel>
                        <TabPanel
                          value={valueTab}
                          index={1}
                          dir={theme.direction}
                        >
                          <Slider
                            {...(customImg && filesFirstReview.length > 1
                              ? customSettings
                              : settings)}
                          >
                            {filesFirstReview.map((file, idx) => {
                              const imgLink =
                                BUCKET_URL +
                                "/projects/" +
                                projectId +
                                "/firstReview/" +
                                file.file;
                              return (
                                <ShareReviewImage
                                  key={idx}
                                  file={file}
                                  disabled={!suggestionsEnabled}
                                  projectId={projectId}
                                  onChange={handleChangeFirstR}
                                  link={imgLink}
                                  onImageClick={() =>
                                    sendLightbox(
                                      filesFirstReview.map(
                                        (elem) =>
                                          BUCKET_URL +
                                          "/projects/" +
                                          projectId +
                                          "/firstReview/" +
                                          elem.file
                                      ),
                                      idx
                                    )
                                  }
                                  customImg={
                                    customImg && filesFirstReview.length > 1
                                  }
                                  setModalComment={setModalComment}
                                />
                              );
                            })}
                          </Slider>
                        </TabPanel>
                        <TabPanel
                          value={valueTab}
                          index={2}
                          dir={theme.direction}
                        >
                          <Slider
                            {...(customImg && filesSecondReview.length > 1
                              ? customSettings
                              : settings)}
                          >
                            {filesSecondReview.map((file, idx) => {
                              const imgLink =
                                BUCKET_URL +
                                "/projects/" +
                                projectId +
                                "/secondReview/" +
                                file.file;
                              return (
                                <ShareReviewImage
                                  key={idx}
                                  file={file}
                                  disabled={!suggestionsEnabled}
                                  projectId={projectId}
                                  onChange={handleChangeSecondR}
                                  link={imgLink}
                                  onImageClick={() =>
                                    sendLightbox(
                                      filesSecondReview.map(
                                        (elem) =>
                                          BUCKET_URL +
                                          "/projects/" +
                                          projectId +
                                          "/secondReview/" +
                                          elem.file
                                      ),
                                      idx
                                    )
                                  }
                                  customImg={
                                    customImg && filesSecondReview.length > 1
                                  }
                                  setModalComment={setModalComment}
                                />
                              );
                            })}
                          </Slider>
                        </TabPanel>
                      </ThemeProvider>
                    </div>
                  )}

                  <br />
                  <br />
                </div>
                <div className="text-center">
                  {project?.status === "final_project" ? (
                    <>
                      {project.render.workPlanLayout.length > 0 && (
                        <ImagesDownloadProject
                          title="Planta de layout"
                          data={project.render.workPlanLayout.map(
                            (e) => e.source
                          )}
                          labels={project.render.workPlanLayout.map((e) =>
                            typeof e.labels === "undefined" ? [] : e.labels
                          )}
                          settings={settings}
                          settingsForOne={settingsForOne}
                          sendLightbox={sendLightbox}
                        />
                      )}
                      {project.render.finishingLayout.length > 0 && (
                        <ImagesDownloadProject
                          title="Planta de acabamentos"
                          data={project.render.finishingLayout.map(
                            (e) => e.source
                          )}
                          labels={project.render.finishingLayout.map((e) =>
                            typeof e.labels === "undefined" ? [] : e.labels
                          )}
                          settings={settings}
                          settingsForOne={settingsForOne}
                          sendLightbox={sendLightbox}
                        />
                      )}
                      {project.render.lights.length > 0 && (
                        <ImagesDownloadProject
                          title="Distribuição de luminárias"
                          data={project.render.lights.map((e) => e.source)}
                          labels={project.render.lights.map((e) =>
                            typeof e.labels === "undefined" ? [] : e.labels
                          )}
                          settings={settings}
                          settingsForOne={settingsForOne}
                          sendLightbox={sendLightbox}
                        />
                      )}
                      {project.render.details.length > 0 && (
                        <ImagesDownloadProject
                          title="Detalhamentos"
                          data={project.render.details.map((e) => e.source)}
                          labels={project.render.details.map((e) =>
                            typeof e.labels === "undefined" ? [] : e.labels
                          )}
                          settings={settings}
                          settingsForOne={settingsForOne}
                          isDetailing={true}
                          sendLightbox={sendLightbox}
                        />
                      )}

                      {allPdfDefaultItems.length > 0 && (
                        <WoodWorker
                          project={project}
                          allPdfDefaultItems={allPdfDefaultItems}
                        />
                      )}

                      {allTextItems.length > 0 && (
                        <ContractorSettings
                          project={project}
                          allTextItems={allTextItems}
                        />
                      )}
                      <div className="mt-5 mb-5">
                        <DecorationGuide
                          action={() => {
                            download(
                              BUCKET_URL +
                                "/projects/" +
                                project._id +
                                "/lastVersion/" +
                                project.finalProject.lastVersion.pdf,
                              project.finalProject.lastVersion.pdf
                            );
                          }}
                        />
                      </div>
                    </>
                  ) : null}
                </div>
                <div className="productlist-mobile">
                  {furnitures?.length > 0 && (
                    <ProductList
                      products={furnitures}
                      projectId={props.project._id}
                    />
                  )}
                </div>
                <div className="mt-5 mb-5">
                  <Card style={{ backgroundColor: "#f7f7f7" }}>
                    <CardBody>
                      <div className="banner-guide">
                        <div style={{ maxWidth: "350px" }}>
                          <h3>Inicie seu projeto</h3>
                          <p>
                            {`Gostou do que viu? Faça seu projeto de decoração com a gente por apenas R$ ${priceToShow} por ambiente`}
                          </p>
                        </div>
                        <div className="d-flex flex-column align-items-center">
                          <div
                            style={{
                              top: 0,
                              backgroundImage: `url("${require("../../../images/compras-online.png")}")`,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                              backgroundPosition: "center center",
                              height: "84px",
                              width: "84px",
                            }}
                          />
                          <div>
                            <Button
                              title={"Comprar projeto"}
                              theme="accent"
                              size="md"
                              className="mt-3"
                              onClick={() => {
                                history.push("/environments");
                              }}
                              style={{ width: "150px" }}
                            >
                              Comprar projeto
                            </Button>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal
        size="lg"
        open={modalComment.show}
        toggle={() => setModalComment({ ...modalComment, show: false })}
      >
        <ModalHeader
          className="text-right"
          toggle={() => setModalComment({ ...modalComment, show: false })}
        />
        <ModalBody>
          <Row className="review-image-comments">
            <Col md="6" xs="12" className="like-comment">
              {modalComment.likeComments ? (
                <>
                  <h6>O que gostou.</h6>
                  {modalComment.likeComments}
                </>
              ) : (
                ""
              )}
            </Col>
            <Col md="6" xs="12" className="dislike-comment">
              {modalComment.dislikeComments ? (
                <>
                  <h6>O que não gostou.</h6>
                  {modalComment.dislikeComments}
                </>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  );
};
export default Review;

//TODO Create component out of this
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const theme = createTheme({
  overrides: {
    // Style sheet name
    MuiTab: {
      root: {
        "&$selected": {
          textColorPrimary: {
            color: `${getComputedStyle(
              document.documentElement
            ).getPropertyValue("--newbackground-color")} !important`,
          },
        },
      },
      wrapper: {
        selected: {
          color: getComputedStyle(document.documentElement).getPropertyValue(
            "--newbackground-color"
          ),
        },
        color: getComputedStyle(document.documentElement).getPropertyValue(
          "--newbackground-color"
        ),
      },
      textColorPrimary: {
        selected: {
          color: getComputedStyle(document.documentElement).getPropertyValue(
            "--newbackground-color"
          ),
        },
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: getComputedStyle(
          document.documentElement
        ).getPropertyValue("--newbackground-color"),
      },
    },
  },
});
