export default function () {
  return [
    {
      title: "Ambientes",
      role: 1,
      items: [
        {
          title: "Novo ambiente",
          to: "/environments",
          htmlBefore: '<i class="material-icons">&#xe03b;</i>',
          htmlAfter: "",
        },
      ],
    },
    {
      title: "Dúvidas",
      role: 1,
      items: [
        {
          title: "FAQ",
          to: "/faq",
          htmlBefore: '<i class="material-icons">&#xe8af;</i>',
          htmlAfter: "",
        },
      ],
    },
    {
      title: "Conheça",
      role: 1,
      items: [
        {
          title: "Modelo de entrega",
          to: "/exemple",
          htmlBefore: '<i class="material-icons">&#xe66b;</i>',
          htmlAfter: "",
        },
      ],
    },
    {
      role: 1,
      title: "Login",
      items: [
        {
          to: "/login",
          htmlBefore:
            '<a class="text-success"><i class="material-icons text-success">&#xE879;</i>Entrar</a>',
          htmlAfter: "",
        },
      ],
    },
  ];
}
