import React, { useCallback, useContext, useEffect, useState } from "react";
import { InferProps } from "prop-types";
import { getLocalStorage } from "../../actions/auth";
import {
  dismissNotificationBatch,
  readBannerNotifications,
} from "../../actions/notification";
import { SocketIoContext } from "../shared/SocketIoContext";
import NotificationContainer from "./NotificationContainer";
import NotificationList from "./NotificationList";
import NoNotifications from "./NoNotifications";
import NotificationBanner from "./NotificationBanner";
import ImportantNotificationBanner from "./ImportantNotificationsBanner";
import { Badge, Button, NavItem } from "shards-react";
import { AuthContext } from "../common/Authorization/AuthContext";
import { UserNotificationI } from "../Checkout/types";
import { Store } from "../../flux";
import { debounce } from "../../utils/throttle";

const NotificationCenterPropTypes = {};

type NotificationCenterTypes = InferProps<typeof NotificationCenterPropTypes>;

const NotificationCenter: React.FC<NotificationCenterTypes> = () => {
  const {
    notifications: userNotifications,
    updateUserNotifications,
    user,
  } = useContext(AuthContext) as any;

  const [notifications, setNotifications] = useState(
    userNotifications.length ? userNotifications : []
  );
  const { registerEventHandler, removeEventHandler } = useContext(
    SocketIoContext
  ) as any;

  const handleNotification = useCallback((res) => {
    setNotifications((notifications) => {
      return [...notifications, res.notification];
    });
  }, []);
  const onDismissNotificationDebounce = useCallback(
    debounce((callback = () => {}) => {
      const notifications = Store.getNotificationsToBeDismissed();
      Store.setNotificationsToBeDismissed();

      dismissNotificationBatch(notifications)
        .then(callback)
        .catch((err) => {
          console.log(err);
        });
    }, 2000),
    []
  );
  const [showNotificationPanel, setShowNotificationPanel] =
    useState<boolean>(false);
  const [openedNotification, setOpenedNotification] = useState();

  useEffect(() => {
    if (userNotifications) {
      setNotifications(userNotifications);
    }
  }, [userNotifications]);

  const importantNotifications = notifications.filter(
    getInitialBannerNotifications
  );

  const isAdminAccess = getLocalStorage("isAdminAccess");
  const notificationsCount = notifications.filter(
    (notification) => !notification.isRead
  ).length;

  useEffect(() => {
    registerEventHandler("notification", handleNotification);
    return () => {
      removeEventHandler("notification", handleNotification);
    };
  }, []);

  function onNotificationBadgeClick() {
    setShowNotificationPanel(!showNotificationPanel);
  }

  function onDismissNotification(notificationId) {
    setNotifications(
      notifications.filter(
        (notification) => notification._id !== notificationId
      )
    );
    if (!isAdminAccess) {
      Store.setNotificationsToBeDismissed(notificationId);
      onDismissNotificationDebounce();
    }
  }

  function onOpenBanner(notification) {
    setShowNotificationPanel(false);
    setOpenedNotification(notification);
  }

  function onBannerClose() {
    setOpenedNotification(undefined);
  }

  function onImportantBannerClose() {
    if (!isAdminAccess) {
      const bannerNotifications = notifications
        .filter((notification) => notification.isImportant)
        .map((notification) => notification._id);
      readBannerNotifications(bannerNotifications)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    const clearedImportantNotifications = notifications.map((notification) => {
      notification.isImportant = false;
      return notification;
    });

    setNotifications(clearedImportantNotifications);
  }

  function getInitialBannerNotifications(notification) {
    return notification.isImportant && !notification.isRead;
  }

  function onDismissAll(): void {
    Store.setNotificationsToBeDismissed();
    notifications.forEach((n: UserNotificationI) =>
      Store.setNotificationsToBeDismissed(n._id)
    );
    setNotifications([]);
    onDismissNotificationDebounce(() => {
      updateUserNotifications();
    });
  }

  return user?._id ? (
    <NavItem className=" dropdown notifications">
      <Button
        theme="light"
        className="nav-link-icon text-center p-0"
        onClick={onNotificationBadgeClick}
        style={{
          backgroundColor: "transparent",
          border: "0px",
          boxShadow: "none",
        }}
      >
        <div
          className="nav-link-icon__wrapper clickable px-2"
          style={{ lineHeight: "0px" }}
        >
          {!!notificationsCount && (
            <Badge theme="danger" pill>
              {notificationsCount}
            </Badge>
          )}
          <span className="material-icons">notifications</span>
        </div>
      </Button>

      <NotificationContainer
        show={!!showNotificationPanel}
        onDismiss={onNotificationBadgeClick}
        onDismissAll={onDismissAll}
      >
        {notifications.length ? (
          <>
            <NotificationList
              dismissable={false}
              setShowNotificationPanel={setShowNotificationPanel}
              onDismissNotification={onDismissNotification}
              onOpenBanner={onOpenBanner}
              notifications={notifications.filter(
                (notification) => !notification.isDismissable
              )}
            />
            <NotificationList
              dismissable={true}
              setShowNotificationPanel={setShowNotificationPanel}
              onDismissNotification={onDismissNotification}
              onOpenBanner={onOpenBanner}
              notifications={notifications.filter(
                (notification) => notification.isDismissable
              )}
            />
          </>
        ) : (
          <NoNotifications />
        )}
      </NotificationContainer>

      {openedNotification && (
        <NotificationBanner
          onBannerClose={onBannerClose}
          notification={openedNotification}
        />
      )}
      {!!importantNotifications.length && (
        <ImportantNotificationBanner
          onBannerClose={onImportantBannerClose}
          notifications={importantNotifications}
        />
      )}
    </NavItem>
  ) : null;
};

NotificationCenter.propTypes = NotificationCenterPropTypes;

export default NotificationCenter;
