// PROD
module.exports = {
  APP_ID: "ladecora",
  DEFAULT_COMPANY: "ladecora",
  API: "https://api-prod.ladecora.com.br",
  BUCKET_URL: "https://s3.amazonaws.com/ladecora.platform",
  PRODUCTION: true,
  PAGARME_TOKEN: "ek_test_H3QV3IyqF1Lr8IYJ5LQA2uY5AX3psK",
  GOOGLE_APP_ID:
    "558784496561-4pcdg7j04la3a0ut3ave9ami6ldn261f.apps.googleusercontent.com",
  FACEBOOK_APP_ID: "149831527245732",
  TAG_MANAGER: "GTM-NK7RDN4",
  GA4: "331367635",
};
