import React from "react";
import MultipleImage from "../environments/types/MultipleImageV2";
import MultipleText from "../environments/types/MultipleTextV2";
import Text from "../environments/types/Text";
import Upload from "../environments/types/Upload";
import InternalText from "../environments/types/InternalText";
import { walk, getFlatDataFromTree } from "react-sortable-tree";

export function getFlatData(treeData) {
  return getFlatDataFromTree({
    treeData,
    getNodeKey,
    ignoreCollapsed: true,
  });
}

export function getNodeKey({ treeIndex }) {
  return treeIndex;
}

export function walkAtTree(treeData, fd, opt, setAllForm) {
  const form = [];
  walk({
    treeData,
    getNodeKey,
    callback: (row) => {
      if (row.node.children && row.node.children.length) {
        let rootNode = fd.find((element) => element.treeIndex === row.path[0]);
        if (!rootNode.childCount) {
          rootNode.childCount = row.node.children.length;
        } else {
          rootNode.childCount += row.node.children.length;
        }
      }

      if (row.node.type === "text") {
        form.push(
          <Text
            key={row.treeIndex + 1}
            index={row.treeIndex + 1}
            node={row}
            flatData={fd}
            {...opt}
          />
        );
      } else if ((row.node.type || row.node.kinda) === "multiple") {
        if (!row.node.input_list[0].image_path) {
          form.push(
            <MultipleText
              node={row}
              key={row.treeIndex + 1}
              index={row.treeIndex + 1}
              {...opt}
            />
          );
        } else {
          form.push(
            <MultipleImage
              node={row}
              key={row.treeIndex + 1}
              index={row.treeIndex + 1}
              {...opt}
            />
          );
        }
      } else if (row.node.type === "upload") {
        form.push(
          <Upload
            key={row.treeIndex + 1}
            index={row.treeIndex + 1}
            node={row}
            {...opt}
          />
        );
      } else if (row.node.type === "internalText") {
        form.push(
          <InternalText
            key={row.treeIndex + 1}
            index={row.treeIndex + 1}
            node={row}
            {...opt}
          />
        );
      }

      if (row.treeIndex === fd[fd.length - 1].treeIndex) {
        setAllForm(form);
      }
    },
  });
}
