import React, { useEffect, useState } from "react";
import { CardBody } from "shards-react";

export const WoodWorker = ({ project, allPdfDefaultItems }) => {
  const [woodWorker, setWoodWorker] = useState([]);

  useEffect(() => {
    setWoodWorker(getWoodWorker(project, allPdfDefaultItems));
  }, []);

  const getWoodWorker = (project, allPdfDefaultItems) => {
    let woodWorkerToPush = [];
    if (project) {
      allPdfDefaultItems.map((elemRes) => {
        project.render.woodWorker.defaults?.map((elem) => {
          if (elemRes._id === elem) {
            woodWorkerToPush.push(elemRes);
          }
        });
      });

      woodWorkerToPush =
        project.render.woodWorker?.items?.length > 0
          ? woodWorkerToPush.concat(project.render.woodWorker.items)
          : woodWorkerToPush;
    } else {
      woodWorkerToPush = [
        {
          text: "O Marceneiro deverá conferir todas as medidas no local antes da execução dos projetos de marcenaria.",
          items: [],
        },
        {
          text: "Seguir instruções para produção da marcenaria, seguindo lâminas indicadas e especificações.",
          items: [],
        },
        {
          text: "Todas portas e gavetas da marcenaria deverão conter amortecimento.",
          items: [],
        },
      ];
    }

    return woodWorkerToPush;
  };

  return woodWorker.length > 0 ? (
    <div style={{ marginTop: "50px", wordBreak: "break-word" }}>
      <hr className="dashed" />
      <CardBody>
        <h3>Considerações para marceneiro</h3>
        {woodWorker?.map((elem, idElem) => (
          <div key={idElem}>
            <ul>
              <li className="text-left">{elem.text}</li>
              {elem?.items?.length > 0 &&
                elem.items.map((item, idItem) => (
                  <ul key={idItem}>
                    <li className="text-left">{item.text}</li>
                  </ul>
                ))}
            </ul>
          </div>
        ))}
      </CardBody>
    </div>
  ) : null;
};

export const ContractorSettings = ({ project, allTextItems }) => {
  const [contractorSettings, setContractorSettings] = useState([]);

  useEffect(() => {
    setContractorSettings(getContractorSettings(project, allTextItems));
  }, []);

  const getContractorSettings = (project, allTextItems) => {
    let contractorSettingsToPush = [];

    if (project) {
      allTextItems.map((elemRes) => {
        let tempItems = [];
        elemRes?.items?.map((item) => {
          project.render?.contractorSettings?.map((elem) => {
            if (item._id === elem) {
              tempItems.push(item);
            }
          });
        });

        if (tempItems.length > 0) {
          contractorSettingsToPush.push({
            name: elemRes.name,
            items: tempItems,
          });
        }
      });

      const tempCustomSettings = [];
      project.render?.customContractorSettings?.length > 0 &&
        project.render.customContractorSettings.map((elem) => {
          if (Object.prototype.hasOwnProperty.call(elem, "name")) {
            contractorSettingsToPush.push(elem);
          } else {
            tempCustomSettings.push(elem);
          }

          contractorSettingsToPush.map((elemSub, idx) => {
            elem?.category?.toLowerCase() === elemSub?.name?.toLowerCase() &&
              contractorSettingsToPush[idx].items.push({
                ...elem,
              });
          });
        });

      const customSettings = tempCustomSettings.reduce((acc, settings) => {
        let item = acc.find((elem) => elem.name === settings.category);
        if (!item) {
          acc.push({
            name: settings.category,
            items: [settings],
            project,
          });
        } else {
          item.items.push(settings);
        }
        return acc;
      }, []);

      customSettings.forEach((elem) => {
        let item = contractorSettingsToPush.find(
          (subItem) => subItem.name === elem.name
        );

        if (!item) {
          contractorSettingsToPush.push(elem);
        }
      });
    } else {
      contractorSettingsToPush = [
        {
          name: "PISO",
          items: [
            {
              text: "Prever nivelamento do piso existente para instalação de piso vinílico.",
            },
            {
              text: "Manter piso existente",
            },
          ],
        },
        {
          name: "PAREDES",
          items: [
            {
              text: "Recomendamos que você confira o acabamento de materiais pessoalmente para verificar se os tons são complementares e de seu agrado.",
            },
            {
              text: "Instalar quadros conforme layout. A altura do meio do quadro deve ser a altura do seu olhar, normalmente consideramos 150cm no eixo.",
            },
          ],
        },
      ];
    }

    return contractorSettingsToPush;
  };

  return contractorSettings.length > 0 ? (
    <div style={{ marginTop: "50px", wordBreak: "break-word" }}>
      <hr className="dashed" />
      <CardBody>
        <h3>Considerações</h3>
        {contractorSettings?.map((elem, idElem) => (
          <div key={idElem}>
            <h5 className="text-left">{elem.name}</h5>
            {elem?.items?.length > 0 &&
              elem.items.map((item, idItem) => (
                <ul key={idItem}>
                  <li className="text-left">{item.text}</li>
                </ul>
              ))}
          </div>
        ))}
      </CardBody>
    </div>
  ) : null;
};
