import { HTTPBodyPaymentPixI } from "@ladecora/ladecora-utils";
import { API } from "../config";
import { requestWrapper } from "../utils/api";
import { getCookie } from "./auth";

interface isPaymentPaidResI {
  isPaid: boolean;
}

export const pix = (data: HTTPBodyPaymentPixI) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/transaction/pix`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(data),
    })
  );
};

export const isPaymentPaid = (
  paymentId: string
): Promise<isPaymentPaidResI> => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/payment/${paymentId}/isPaid`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};
