import React from "react";
import PropTypes from "prop-types";

import NotificationItem from "./NotificationItem";
import { getFormattedDate } from "../../utils/date";

function NotificationList({
  notifications,
  setShowNotificationPanel,
  dismissable,
  onDismissNotification,
  onOpenBanner,
}) {
  return (
    <>
      {notifications
        .sort((a, b) => {
          if (new Date(a.time) < new Date(b.time)) {
            return 1;
          }
          if (new Date(a.time) > new Date(b.time)) {
            return -1;
          }
          return 0;
        })
        .map((dismissableNotification, index) => (
          <NotificationItem
            onDismissNotification={onDismissNotification}
            onOpenBanner={() => {
              onOpenBanner(dismissableNotification);
            }}
            bannerContent={dismissableNotification.bannerContent}
            key={`${index}-${dismissableNotification._id}`}
            createdAt={getFormattedDate(dismissableNotification.time)}
            id={dismissableNotification._id}
            title={dismissableNotification.title}
            theme={dismissableNotification.theme}
            dismissable={dismissable}
            description={dismissableNotification.description}
            projectId={dismissableNotification.project}
            setShowNotificationPanel={setShowNotificationPanel}
          />
        ))}
    </>
  );
}

NotificationList.propTypes = {
  notifications: PropTypes.array.isRequired,
};

export default NotificationList;
