import React, { useContext } from "react";
import { SystemContext } from "../SystemConfig/SystemContext";
import { BUCKET_URL } from "../../config";
export const Logo = ({ props, defaultProps }) => {
  const { getCompanyLabel, isDefault } = useContext(SystemContext);

  const LOGO_LINK = isDefault
    ? "https://s3.amazonaws.com/ladecora.platform/images/logo-la-decora.png"
    : `${BUCKET_URL}/whitelabel/${getCompanyLabel()}/logo?${new Date().getTime()}`;
  /* eslint-disable  */
  return <img {...(isDefault ? defaultProps : props)} src={LOGO_LINK} />;
  /* eslint-disable  */
};

export default Logo;
