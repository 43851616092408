import { API } from "../config";
import { getCookie } from "../actions/auth";
import { requestWrapper } from "../utils/api";

export const getCompany = (key) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/company/${key}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const getTheme = (origin) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/theme`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify({
        origin,
      }),
    })
  );
};

export const listProjectTemplatesFromCompany = (company) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/company/${company}/project_templates`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};
