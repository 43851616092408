import { getTotal } from "@ladecora/ladecora-utils";
import React from "react";
import { Row, Col, Card, CardFooter, CardBody } from "shards-react";

const EnvironmentCard = (props) => {
  const {
    questions,
    setQuestions,
    total,
    setTotal,
    hasVoucher,
    setHasVoucher,
    setVoucherText,
    voucher,
    company,
    setTotalWtDiscount,
  } = props;

  function getFullTotal(newQuestion) {
    return newQuestion.reduce(
      (acc, current) => current.price * current.quantity + acc,
      0
    );
  }
  const getPriceWithDiscount = (newQuestion, newTotal) => {
    let total = 0;
    let projectTemplatesCount = newQuestion.reduce(
      (acc, current) => current.quantity + acc,
      0
    );
    if (company.label === "ladecora") {
      if (projectTemplatesCount === 1) {
        total = newTotal;
      } else if (projectTemplatesCount === 2) {
        total = newTotal * 0.9;
      } else {
        total = newTotal * 0.85;
      }
    } else if (company.label === "tok_stok") {
      if (projectTemplatesCount === 1) {
        total = newTotal;
      } else if (projectTemplatesCount === 2) {
        total = Math.floor(newTotal * 0.92);
      } else {
        total = Math.floor(newTotal * 0.88);
      }
    } else if (company.label === "leroymerlinprojeta") {
      if (projectTemplatesCount === 1) {
        total = newTotal;
      } else if (projectTemplatesCount === 2) {
        total = Math.floor(newTotal * 0.92);
      } else {
        total = Math.floor(newTotal * 0.88);
      }
    } else {
      total = newTotal;
    }
    return total;
  };

  const handleEnvironmentClickPlus = (environment) => {
    let newQuestion = questions.map((item) =>
      item.hash === environment.hash
        ? { ...item, quantity: item.quantity + 1 }
        : item
    );

    let sumPrices = getFullTotal(newQuestion);
    setTotalWtDiscount(sumPrices);
    if (hasVoucher && voucher.typeValue === "percent") {
      let voucherValue = voucher.amount;
      let discount = 0;
      let envDiscountPrice = 0;
      discount = environment.price * (voucherValue / 100);
      envDiscountPrice = environment.price - discount;
      setTotal(total + envDiscountPrice);
    } else {
      let totalWithDiscount = getTotal(newQuestion, voucher, company.label)[1];
      setTotal(totalWithDiscount);
    }
    setQuestions(newQuestion);
  };

  const handleEnvironmentClickLess = (environment) => {
    if (environment.quantity > 0) {
      let newQuestion = questions.map((item) =>
        item.hash === environment.hash
          ? { ...item, quantity: item.quantity - 1 }
          : item
      );

      let discount = 0;
      let envDiscountPrice = 0;
      let sumPrices = getFullTotal(newQuestion);
      setTotalWtDiscount(sumPrices);
      if (hasVoucher && voucher.typeValue === "percent") {
        let voucherValue = voucher.amount;
        discount = environment.price * (voucherValue / 100);
        envDiscountPrice = environment.price - discount;
        setTotal(total - envDiscountPrice);
      } else if (hasVoucher && voucher.typeValue === "fixed") {
        setHasVoucher(false);
        setVoucherText("");
        envDiscountPrice = environment.price - voucher.amount;
        setTotal(total - envDiscountPrice);
      } else {
        let totalWithDiscount = getPriceWithDiscount(newQuestion, sumPrices);
        setTotal(totalWithDiscount);
      }
      setQuestions(newQuestion);
    }
  };

  return (
    <Row id="environments-project-template-list">
      {questions.map((env, idx) => {
        return (
          <Col lg="3" sm="6" key={idx}>
            <Card
              small
              className="mb-3 environment-card-project-template"
              style={{ borderRadius: "0.625rem" }}
              id={`environment-card-project-template-${env.hash}`}
            >
              <div
                style={{
                  paddingTop: "100%",
                  width: "100%",
                  padding: "100% 0 0 0",
                  overflow: "hidden",
                  display: "block",
                  position: "relative",

                  height: "auto",
                }}
              >
                <img
                  style={{
                    display: "block",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    borderRadius: "0.625rem 0.625rem 0 0",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    objectFit: "cover",
                  }}
                  src={env.image}
                  alt={env.title}
                  width="360"
                  height="360"
                />
              </div>

              <CardBody
                style={{
                  justifyContent: "center",
                  padding: "0.1%",
                  minHeight: "57px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div>
                  <div className="ml-2 mr-2 text-center h5">{env.title}</div>
                </div>
                <div>
                  <span
                    className="file-manager__item-size ml-auto"
                    style={{
                      fontSize: "1.3rem!important",
                    }}
                  >
                    {env.price.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </span>
                </div>
              </CardBody>
              <CardFooter
                className="border-top"
                style={{ padding: ".4rem 1rem" }}
              >
                <div
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {/* eslint-disable-next-line */}
                  <i
                    className="fas fa-minus-circle fa-lg"
                    onClick={() => handleEnvironmentClickLess(env)}
                    style={{
                      color: getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--newbackground-color"),
                      cursor: "pointer",
                    }}
                  ></i>
                  <input
                    value={questions[idx].quantity}
                    style={{
                      borderRadius: "5px",
                      textAlign: "center",
                      fontSize: "16px",
                      border: "1px solid #acacac",
                      maxWidth: "48px",
                      maxHeight: "33px",
                      margin: "0 10px",
                    }}
                    disabled
                  />

                  {/* eslint-disable-next-line */}
                  <i
                    className="fas fa-plus-circle fa-lg"
                    onClick={() => handleEnvironmentClickPlus(env)}
                    style={{
                      color: getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--newbackground-color"),
                      cursor: "pointer",
                    }}
                  />
                </div>
              </CardFooter>
            </Card>
          </Col>
        );
      })}
    </Row>
  );
};
export default EnvironmentCard;
