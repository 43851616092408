import React from "react";
import Slider from "react-slick";
import RenderLabels from "./RenderLabels";
import { showImageCaption } from "./RenderLabels";
import { CustomImageToSlide } from "../../common/CustomImageToSlide";
import { Button } from "shards-react";

const ImagesDownloadProject = ({
  title,
  data,
  labels = [],
  settings,
  settingsForOne,
  sendLightbox,
  flipImage = false,
  isDetailing,
}) => {
  const CONFIG = {
    settings: data.length <= 1 || isDetailing ? settingsForOne : settings,
    custom: data.length <= 1 || isDetailing ? false : true,
  };

  return (
    <div style={{ marginTop: "50px" }}>
      <hr className="dashed" />
      <div>
        <h4 className="title-mobile-IDP">{title}</h4>
        <div className={`${flipImage && "flipImage"}` + " manDiv-mobile"}>
          <Slider {...CONFIG.settings}>
            {data.map((file, idx) => {
              return (
                <div
                  key={idx}
                  className={`d-flex ${
                    CONFIG.custom && "flex-column"
                  } justify-content-around align-items-center`}
                >
                  <div className="d-flex flex-column justify-content-around align-items-center ">
                    {file.subtitle ? (
                      <span className="pt-3 text-center subtitle-slider">
                        {file.subtitle}
                      </span>
                    ) : null}
                    {CONFIG.custom ? (
                      <CustomImageToSlide
                        file={file}
                        onclick={(e) => {
                          e?.preventDefault();
                          e?.stopPropagation();
                          sendLightbox(
                            data,
                            idx,
                            flipImage,
                            showImageCaption(labels)
                          );
                        }}
                      />
                    ) : (
                      <>
                        {/* eslint-disable-next-line */}
                        <img
                          alt=""
                          style={{ cursor: "pointer" }}
                          className="rounded img-fluid carousel-image"
                          src={file}
                          onClick={() =>
                            sendLightbox(
                              data,
                              idx,
                              flipImage,
                              showImageCaption(labels)
                            )
                          }
                        />
                      </>
                    )}
                    <div className=" mt-2 btnLegend">
                      {labels.length > 0 && labels[idx].length > 0 && (
                        <Button
                          theme="accent"
                          onClick={(e) => {
                            e?.preventDefault();
                            e?.stopPropagation();
                            sendLightbox(
                              data,
                              idx,
                              flipImage,
                              showImageCaption(labels)
                            );
                          }}
                        >
                          Ver Legenda
                        </Button>
                      )}
                    </div>
                  </div>
                  <RenderLabels idx={idx} labels={labels} />
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default ImagesDownloadProject;
