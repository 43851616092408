import { API } from "../config";
import { getCookie } from "./auth";
import { requestWrapper } from "../utils/api";

export const dismissNotification = (notificationId, isByLinkId) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(
      `${API}/notification/${notificationId}/dismiss${
        isByLinkId ? "?linkId=true" : ""
      }`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "x-access-token": token,
        },
      }
    )
  );
};

export const readNotifications = (notificationIds) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/notification/read`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(notificationIds),
    })
  );
};

export const readBannerNotifications = (notificationIds) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/notification/read_banner`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(notificationIds),
    })
  );
};

export const dismissNotificationBatch = (notificationIds) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/notification/dismiss_batch`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(notificationIds),
    }),
    undefined,
    true
  );
};
