import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Fade } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import EnvironmentCard from "../components/file-manager-cards/DocumentsList";
import { calculateInterest } from "../utils/payment";

import { SystemContext } from "../components/SystemConfig/SystemContext";

import { listProjectTemplatesFromCompany } from "../actions/company";

import { BUCKET_URL } from "../config";

import { ToastContainer } from "react-toastify";
import { isAuth, getQueryStringValue } from "../actions/auth";
import { useHistory } from "react-router-dom";

import ReactPixel from "react-facebook-pixel";
import { fireEvent, sanitizeString } from "../utils/tag-manager";
import Banner from "../components/Benner/Banner";
import { getAllUrlParams } from "../utils/util";

const Environments = () => {
  const history = useHistory();
  const MAX_INSTALLMENTS = 3;
  let envsUrl = [];
  const envsUrlArr = [];
  const qtyUrl = getQueryStringValue("qty");
  const { company } = useContext(SystemContext);

  if (company.tracking && company.tracking.facebook) {
    const options = {
      autoConfig: true,
      debug: false,
    };
    ReactPixel.init(`${company.tracking.facebook}`, {}, options);
  }

  const priceIncrease = company.priceIncrease ? company.priceIncrease : 0;

  useEffect(() => {
    if (
      !isAuth() &&
      (company.label === "eztec" || company.label === "tok_stok")
    ) {
      history.push("/login");
    }

    if (company.tracking && company.tracking.facebook) {
      ReactPixel.pageView();
    }
    listProjectTemplatesFromCompany(company.label).then((data) => {
      let newQuestions = [];
      data.forEach((question) => {
        if (question.status === "enabled") {
          let numberOfEnvironments = question.numberOfEnvironments
            ? question.numberOfEnvironments
            : 1;
          newQuestions.push({
            id: question._id,
            title: question.name,
            hash: question.hash,
            image: question.thumbnail
              ? BUCKET_URL + "/" + question.thumbnail
              : require("../images/environments/home_office.jpg"),
            price: question.price + priceIncrease * numberOfEnvironments,
            quantity: 0,
          });
        }
      });
      checkPreSelectedCart(data, newQuestions);

      setQuestions(newQuestions);
    });
    const script = document.createElement("script");
    script.src = "https://assets.pagar.me/checkout/1.1.0/checkout.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const checkPreSelectedCart = (data, newQuestions) => {
    let urlParams = getQueryStringValue("environment");
    if (typeof urlParams == "string") {
      envsUrl.push(urlParams);
    } else {
      envsUrl = urlParams;
    }
    if (
      envsUrl !== undefined &&
      envsUrl.length > 0 &&
      qtyUrl !== undefined &&
      envsUrl.length === qtyUrl.length
    ) {
      let hasEnv = false;
      envsUrl.forEach((env, idx) => {
        let envReturned = data.find((prod) => prod.hash === env);
        if (envReturned) {
          envReturned.quantity = parseInt(qtyUrl[idx]);
          envsUrlArr.push({
            price: envReturned.price,
            hash: envReturned.hash,
            title: envReturned.name,
            quantity: envReturned.quantity,
          });
          hasEnv = true;
        }
      });
      let valueTotal = total;
      envsUrlArr.forEach((env) => {
        let provQuestion = newQuestions.find(
          (project) => project.hash === env.hash
        );
        provQuestion.quantity += env.quantity;
        valueTotal += env.price * env.quantity;
        handleEnvironmentClickPlus(provQuestion);
      });
      setTotal(valueTotal);
      if (hasEnv) {
        toggleTermsOfServiceModal();
      }
    }
  };

  const [openModalTermsUse, setOpenModalTermsUse] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [total, setTotal] = useState(0);
  const [totalWtDiscount, setTotalWtDiscount] = useState(0);
  const installmentsPrice = [];

  for (let i = 1; i <= MAX_INSTALLMENTS; i++) {
    let priceWithInterest = calculateInterest(total, i);
    let installmentWithInterest = priceWithInterest / i;
    installmentsPrice.push(i === 1 ? total : installmentWithInterest);
  }

  const handleEnvironmentClickPlus = (environment) => {
    setQuestions(
      questions.map((item) =>
        item.hash === environment.hash
          ? { ...item, quantity: item.quantity + environment.quantity }
          : item
      )
    );
  };

  const toggleTermsOfServiceModal = () => {
    setOpenModalTermsUse(!openModalTermsUse);
  };

  const checkCheckout = () => {
    const selectedProjectTemplates = questions.filter(
      (projectTemplate) => projectTemplate.quantity > 0
    );

    fireEvent(
      "begin_checkout",
      "ladecoradecora:enhanced-ecommerce",
      "begin_checkout",
      {
        ecommerce: {
          items: selectedProjectTemplates.map((projectTemplate) => {
            return {
              item_name: sanitizeString(projectTemplate.title),
              item_id: projectTemplate.hash,
              currency: "BRL",
              price: parseFloat(projectTemplate.price.toFixed(2)),
              quantity: parseInt(projectTemplate.quantity),
              variant: projectTemplate.quantity,
            };
          }),
        },
      }
    );

    let params = selectedProjectTemplates.reduce(
      (acc, current) =>
        `${acc}&environment=${current.hash}&qty=${current.quantity}`,
      ""
    );

    if (!params) {
      alert(
        "Selecione no mínimo um serviço para continuar realizando a compra!"
      );
    } else {
      const p = getAllUrlParams();
      if (p.voucher) {
        params = `${params}&voucher=${p.voucher}`;
      }
      if (isAuth()) {
        history.push(`/checkout?${params}`);
      } else {
        history.push(`/login?${params}&page=checkout`);
      }
    }
  };

  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    let el = document.getElementById("buy-button-area");
    if (el != null) {
      if (document.documentElement.scrollTop > 90) {
        el.classList.add("fixed-right-botton", "col-md-12");
        el.classList.remove("col-md-6");

        let scrollPosition =
          document.documentElement.scrollHeight -
          document.documentElement.scrollTop;
        if (document.documentElement.clientHeight === scrollPosition) {
          el.style.bottom = el.clientHeight + "px";
        } else {
          el.style.bottom = "0px";
        }
      } else {
        el.classList.add("col-md-6");
        el.classList.remove("fixed-right-botton", "col-md-12");
      }
    }
  }

  const [hasVoucher, setHasVoucher] = useState(false);
  const voucher = {
    amount: 0,
    typeValue: "",
    code: "",
  };

  return (
    <Container fluid className="main-content-container px-4 mb-5">
      <ToastContainer />

      <Row className="page-header py-4">
        <Col md="6">
          <PageTitle
            title="Escolha seu ambiente para começar o projeto dos seus sonhos"
            className="text-sm-left mb-3 col-sm-8"
          />
        </Col>
        <Col
          id="buy-button-area"
          md="6"
          className="d-flex flex-row-reverse align-items-center"
        >
          <button
            onClick={checkCheckout}
            data-gtm-event-category="ladecora:home"
            data-gtm-event-action="clique:botao:comprar"
            data-gtm-event-label="comprar-environment"
            className="btn btn-custom-primary btn-accent mt-3"
          >
            <i className="material-icons">&#xe8cc;</i> Comprar
          </button>
          <Fade in={!!total} theme="accent" className="mr-2">
            Total:{" "}
            {totalWtDiscount !== total && (
              <>
                <s id="PaymentSummaryTotal">
                  De:{" "}
                  {totalWtDiscount.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                  <br />
                </s>
                Por:{" "}
                {total.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
              </>
            )}
            {totalWtDiscount === total &&
              total.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}
          </Fade>
        </Col>
      </Row>
      <div className="file-manager file-manager-cards">
        <EnvironmentCard
          questions={questions}
          setQuestions={setQuestions}
          setTotal={setTotal}
          total={total}
          hasVoucher={hasVoucher}
          setHasVoucher={setHasVoucher}
          setVoucherText={() => {}}
          voucher={voucher}
          company={company}
          setTotalWtDiscount={setTotalWtDiscount}
        />
        {company.label !== "tok_stok" && <Banner company={company} />}
      </div>
    </Container>
  );
};

export default Environments;
