import React from "react";

export const CustomImageToSlide = ({ file, onclick }) => {
  return (
    <div
      onKeyDown={() => {}}
      role="button"
      tabIndex="0"
      alt=""
      className="custom-slider ml-2 shadow-lg"
      onClick={onclick}
      style={{
        backgroundImage: `url("${file}")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center center",
      }}
    />
  );
};
