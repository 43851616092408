import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Card, Button, CardBody } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import ImagesDownloadProject from "../components/environments/sub-component/ImagesDownloadProject";
import ProductList from "../components/environments/sub-component/ProductList";

import { SystemContext } from "../components/SystemConfig/SystemContext";
import { ShowLightbox } from "../components/common/ShowLightbox";
import DecorationGuide from "../components/common/DecorationGuide";
import {
  ContractorSettings,
  WoodWorker,
} from "../components/common/considerations";
import { CustomImageToSlide } from "../components/common/CustomImageToSlide";
import { getExampleProject } from "../actions/projectV2";
import { getDefaultValues } from "../data/ProjectExample";
import { BUCKET_URL } from "../config";
import { getPdfDefaultItems, getTextItems } from "../actions/pdfTextItem";

const Exemple = () => {
  const { company } = useContext(SystemContext);
  const [lightbox, setLightbox] = useState({
    open: false,
    index: 0,
    images: [],
  });
  const [isEnterprise, setIsEnterprise] = useState(true);
  const [values, setValues] = useState();

  useEffect(() => {
    if (window.location.pathname.includes("enterprise")) {
      setIsEnterprise(true);
    } else {
      setIsEnterprise(false);
    }
  }, []);

  useEffect(() => {
    if (company?.exampleProjectId) {
      Promise.all([
        getExampleProject(company?.label),
        getTextItems(),
        getPdfDefaultItems(),
      ]).then((res) => {
        const [project, textItems, pdfDefaultItems] = res;

        if (project) {
          let furnitures =
            project.finalProject[project.finalProject.approvedProject]
              .furnitures;
          if (furnitures?.length) {
            furnitures = furnitures.map((f) => {
              return {
                ...f,
                affiliateLink: "",
                link: "",
                id: { ...f.id, link: "", affiliateLink: "" },
              };
            });
          }

          const files = project.finalProject[
            project.finalProject.approvedProject
          ].files
            .filter((file) => file.tag == "decorationPanel")
            .map((f) => {
              return (
                BUCKET_URL +
                "/projects/" +
                project._id +
                `/${project.finalProject.approvedProject}/` +
                `${f.thumbnail ? "thumbnails/" : ""}${f.file}`
              );
            });
          const details = project.render.details.reduce(
            (acc, f) => {
              acc.links.push(f.source);
              acc.labels.push(typeof f.labels === "undefined" ? [] : f.labels);
              return acc;
            },
            { links: [], labels: [] }
          );

          const workPlanLayout = project.render.workPlanLayout.reduce(
            (acc, f) => {
              acc.links.push(f.source);
              acc.labels.push(typeof f.labels === "undefined" ? [] : f.labels);
              return acc;
            },
            { links: [], labels: [] }
          );

          const finishingLayout = project.render.finishingLayout.reduce(
            (acc, f) => {
              acc.links.push(f.source);
              acc.labels.push(typeof f.labels === "undefined" ? [] : f.labels);
              return acc;
            },
            { links: [], labels: [] }
          );

          const lights = project.render.lights.reduce(
            (acc, f) => {
              acc.links.push(f.source);
              acc.labels.push(typeof f.labels === "undefined" ? [] : f.labels);
              return acc;
            },
            { links: [], labels: [] }
          );

          const downloadLink =
            BUCKET_URL +
            "/projects/" +
            project._id +
            "/lastVersion/" +
            project.finalProject.lastVersion.pdf;

          setValues({
            lights,
            finishingLayout,
            workPlanLayout,
            details,
            files,
            furnitures,
            downloadLink,
            textItems,
            pdfDefaultItems,
            project,
          });
        } else {
          setValues(getDefaultValues(company.label));
        }
      });
    } else {
      setValues(getDefaultValues(company.label));
    }
  }, [company]);

  const history = useHistory();
  const settings = {
    className: "center slider variable-width",
    centerMode: true,
    infinite: true,
    centerPadding: "8px",
    slidesToShow: 1,
    speed: 500,
    dots: true,
    variableWidth: true,
  };
  const settingsForOne = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const {
    lights = null,
    finishingLayout = null,
    workPlanLayout = null,
    details = null,
    files = null,
    furnitures = null,
    downloadLink = null,
    project = null,
    textItems = null,
    pdfDefaultItems = null,
  } = values || {};

  const sendLightbox = (data, idx) => {
    if (Array.isArray(data)) {
      setLightbox({ ...lightbox, images: data, open: true, index: idx });
    } else {
      setLightbox({ ...lightbox, images: [data], open: true, index: 0 });
    }
  };

  function download(fileURL, fileName) {
    // for non-IE
    if (!window.ActiveXObject) {
      var save = document.createElement("a");
      save.href = fileURL;
      save.target = "_blank";
      var filename = fileURL.substring(fileURL.lastIndexOf("/") + 1);
      save.download = fileName || filename;
      if (
        navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
        navigator.userAgent.search("Chrome") < 0
      ) {
        document.location = save.href;
        // window event not working here
      } else {
        var evt = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: false,
        });
        save.dispatchEvent(evt);
        (window.URL || window.webkitURL).revokeObjectURL(save.href);
      }
    }

    // for IE < 11
    else if (!!window.ActiveXObject && document.execCommand) {
      var _window = window.open(fileURL, "_blank");
      _window.document.close();
      _window.document.execCommand("SaveAs", true, fileName || fileURL);
      _window.close();
    }
  }

  return (
    <Container fluid className="main-content-container px-4">
      {lightbox.open && (
        <ShowLightbox lightbox={lightbox} setLightbox={setLightbox} />
      )}
      <Row className="page-header py-4">
        <Col>
          <PageTitle
            title="Conheça nosso modelo de entrega!"
            subtitle="Visão Geral"
            className="text-sm-left mb-3"
            md="12"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card small className="mb-4">
            <CardBody className="exemple-dash">
              {/* eslint-disable-next-line */}
              <label
                className="mb-1"
                style={{ border: "none", cursor: "pointer" }}
                onClick={() => {
                  history.push("/environments");
                }}
              >
                &#8592; VOLTAR
              </label>
              <Row style={{ justifyContent: "center" }} className="mb-4">
                <Col ld="8" md="10" sm="8">
                  <h4 style={{ textAlign: "center" }}>Modelo de entrega</h4>
                  {isEnterprise && (
                    <p className="p-3 text-left">
                      Como funciona Através de uma jornada interativa e
                      automatizada, receba a assessoria de decoração que mais
                      combina com você e com o seu imóvel! <br /> Desenvolvido
                      por profissionais com mais de 15 anos de experiência em
                      decoração. <br />
                      Descubra sua nova forma de decorar ambientes, feito por
                      especialistas e de forma totalmente online.
                    </p>
                  )}
                </Col>
              </Row>
              <Row
                className="exemple-steps"
                style={{ justifyContent: "center", textAlign: "center" }}
              >
                {isEnterprise ? (
                  <>
                    <Col lg="3" md="6" sm="8">
                      <Card className="h-100">
                        <div style={{ fontSize: "45px" }}>
                          <i className="material-icons">&#xe873;</i>
                        </div>
                        <h4>1. Questionário</h4>
                        <p className="mt-20 p-3">
                          Acesse a nossa plataforma e escolha seu ambiente,
                          inicie respondendo nosso questionário de estilo com
                          imagens e perguntas, onde identificamos suas
                          preferências de decoração!
                        </p>
                      </Card>
                    </Col>{" "}
                    <Col lg="3" md="6" sm="8">
                      <Card className="h-100">
                        <div style={{ fontSize: "45px" }}>
                          <i className="material-icons">&#xe439;</i>
                        </div>
                        <h4>2. Projeto 3D</h4>
                        <p className="mt-20 p-3">
                          Através das suas respostas, vamos indicar a melhor
                          opção para o seu ambiente de acordo com o seu estilo!
                        </p>
                      </Card>
                    </Col>{" "}
                    <Col lg="3" md="6" sm="8">
                      <Card className="h-100">
                        <div style={{ fontSize: "45px" }}>
                          <i className="material-icons">&#xe9b4;</i>
                        </div>
                        <h4>3. Pronto</h4>
                        <p className="mt-20 p-3">
                          De forma automática, você terá seu ambiente decorado
                          em mãos. Com todas as informações para que o seu
                          ambiente vire realidade!
                        </p>
                      </Card>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col lg="3" md="6" sm="8">
                      <Card className="h-100">
                        <div style={{ fontSize: "45px" }}>
                          <i className="material-icons">&#xe873;</i>
                        </div>
                        <h4>1. Questionário</h4>
                        <p className="mt-20 p-3">
                          Acesse a nossa plataforma e escolha seu ambiente,
                          inicie respondendo nosso questionário de estilo com
                          imagens e perguntas, onde identificamos suas
                          preferências de decoração!
                        </p>
                      </Card>
                    </Col>
                    <Col lg="3" md="6" sm="8">
                      <Card className="h-100">
                        <div style={{ fontSize: "45px" }}>
                          <i className="material-icons">&#xe439;</i>
                        </div>
                        <h4>2. Fotos do local</h4>
                        <p className="mt-20 p-3">
                          Você nos envia fotos do local que deseja decorar,
                          planta baixa ou medidas básicas para ficarmos por
                          dentro do que já existe e para montarmos seu ambiente
                          personalizado!
                        </p>
                      </Card>
                    </Col>
                    <Col lg="3" md="6" sm="8">
                      <Card className="h-100">
                        <div style={{ fontSize: "45px" }}>
                          <i className="material-icons">&#xea44;</i>
                        </div>
                        <h4>3. Projeto 3D</h4>
                        <p className="mt-20 p-3">
                          Agora é com a gente! Uma decoradora vai transformar
                          seus desejos em um ambiente lindo e que combine com
                          seu estilo! Você receberá sua assessoria em até 5 dias
                          úteis direto na plataforma!
                        </p>
                      </Card>
                    </Col>
                    <Col lg="3" md="6" sm="8">
                      <Card className="h-100">
                        <div style={{ fontSize: "45px" }}>
                          <i className="material-icons">&#xe9b4;</i>
                        </div>
                        <h4>4. Pronto</h4>
                        <p className="mt-20 p-3">
                          Caso precise de alguma alteração, poderá solicitar até
                          2 revisões e de forma muito prática obter seu ambiente
                          completo, com todas as informações importantes para
                          transformar seu ambiente.
                        </p>
                      </Card>
                    </Col>
                  </>
                )}
              </Row>
              <Row className="mt-5" style={{ justifyContent: "center" }}>
                <Col ld="8" md="10" sm="8">
                  <h4 style={{ textAlign: "center" }}>
                    Nosso modelo de entrega
                  </h4>
                </Col>
              </Row>
              {values ? (
                <>
                  <div className="mt-3 d-flex flex-comlun justify-content-center align-items-center">
                    <Col
                      md="12"
                      className="mb-5"
                      style={{ textAlign: "center" }}
                    >
                      <div>
                        <Slider
                          {...(files.length > 1 ? settings : settingsForOne)}
                        >
                          {files.map((file, idx) => {
                            return (
                              <div
                                key={idx}
                                className="d-flex justify-content-center align-items-center"
                              >
                                {files.length > 1 ? (
                                  <CustomImageToSlide
                                    file={file}
                                    onclick={() =>
                                      sendLightbox(
                                        files.map((f) => {
                                          return f.replace("/thumbnails", "");
                                        }),
                                        idx
                                      )
                                    }
                                  />
                                ) : (
                                  <div className="d-flex justify-content-center align-items-center carousel-container">
                                    {/* eslint-disable-next-line */}
                                    <img
                                      alt=""
                                      style={{ cursor: "pointer" }}
                                      className="rounded img-fluid carousel-image"
                                      src={file}
                                      onClick={() =>
                                        sendLightbox(
                                          files.map((f) => {
                                            return f.replace("/thumbnails", "");
                                          }),
                                          idx
                                        )
                                      }
                                    />
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </Slider>
                      </div>
                      <ImagesDownloadProject
                        title="Planta de layout"
                        data={workPlanLayout.links}
                        labels={workPlanLayout.labels}
                        settings={settings}
                        settingsForOne={settingsForOne}
                        sendLightbox={sendLightbox}
                      />

                      <ImagesDownloadProject
                        title="Planta de acabamento"
                        data={finishingLayout.links}
                        labels={finishingLayout.labels}
                        settings={settings}
                        settingsForOne={settingsForOne}
                        sendLightbox={sendLightbox}
                      />
                      <ImagesDownloadProject
                        title="Distribuição de luminárias"
                        data={lights.links}
                        labels={lights.labels}
                        settings={settings}
                        settingsForOne={settingsForOne}
                        sendLightbox={sendLightbox}
                      />
                      <ImagesDownloadProject
                        title="Detalhamentos"
                        data={details.links}
                        labels={details.labels}
                        settingsForOne={settingsForOne}
                        settings={settings}
                        sendLightbox={sendLightbox}
                        isDetailing={true}
                      />
                      {project ? (
                        <WoodWorker
                          project={project}
                          allPdfDefaultItems={pdfDefaultItems}
                        />
                      ) : (
                        <WoodWorker />
                      )}
                      {project ? (
                        <ContractorSettings
                          project={project}
                          allTextItems={textItems}
                        />
                      ) : (
                        <ContractorSettings />
                      )}
                    </Col>
                  </div>
                  <ProductList products={furnitures} />
                  <DecorationGuide
                    action={() => {
                      download(downloadLink, "Exemplo.pdf");
                    }}
                  />
                </>
              ) : null}

              <div style={{ textAlign: "center" }} className="mt-3 mb-3 col-12">
                <hr className="dashed" />

                <Button
                  theme="accent"
                  size="lg"
                  className="mt-1 mr-1"
                  onClick={() => {
                    window.open(
                      `${isEnterprise ? "/enterprises" : "/environments"}`,
                      "_self"
                    );
                  }}
                  style={{ cssFloat: "center" }}
                >
                  {company.label === "tok_stok"
                    ? "Inicie sua assessoria"
                    : "Inicie seu projeto"}
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Exemple;
