import { API } from "../config";
import { getCookie } from "../actions/auth";

import { requestWrapper } from "../utils/api";

export const getUser = () => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/user`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const updateUser = (userId, user) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/user/${userId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(user),
    })
  );
};
