import React, { useState, useEffect } from "react";

import ImagesDownloadProject from "../environments/sub-component/ImagesDownloadProject";
import ImagesDownloadProjectV2 from "../environments/sub-component/ImagesDownloadProjectV2";
import { BUCKET_URL } from "../../config";
import Slider from "react-slick";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  FormTextarea,
  FormRadio,
} from "shards-react";
import { toast, ToastContainer } from "react-toastify";
import { ShowLightbox } from "../common/ShowLightbox";
import { updateUserRating } from "../../actions/userEnterprise";
import DecorationGuide from "../common/DecorationGuide";
import ProductList from "../environments/sub-component/ProductList";
import { ContractorSettings, WoodWorker } from "../common/considerations";
import { getPdfDefaultItems, getTextItems } from "../../actions/pdfTextItem";
import { CustomImageToSlide } from "../common/CustomImageToSlide";
function FinalStep({ acceptedRooms, bluePrint, userEnterprise, enterprise }) {
  const [invertImage, setInvertImage] = useState(false);
  const [projectModalId, setProjectModalId] = useState("");
  const [pdfLinkModal, setPdfLinkModal] = useState("");
  const [invertimagelightbox, setInvertImageLightBox] = useState(false);
  const roomNames = Object.keys(acceptedRooms);
  const [lightbox, setLightbox] = useState({
    open: false,
    index: 0,
    images: [],
    caption: [],
  });
  const [rating, setRating] = useState(
    userEnterprise.rating ? userEnterprise.rating : 0
  );
  const [otherQuestions, setOtherQuestions] = useState({
    indicate: 0,
    contact: undefined,
    comment: "",
  });
  const [showModalRating, setShowModalRating] = useState(false);
  const [showModalEztec, setShowModalEztec] = useState(false);
  const settings = {
    className: "center slider variable-width",
    centerMode: true,
    infinite: true,
    centerPadding: "8px",
    slidesToShow: 1,
    speed: 500,
    dots: true,
    variableWidth: true,
  };

  const settingsForOne = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [allPdfDefaultItems, setAllPdfDefaultItems] = useState([]);
  const [allTextItems, setAllTextItems] = useState([]);

  useEffect(() => {
    if (bluePrint.mirrored) {
      setInvertImage(true);
    }
    if (userEnterprise.enterprise.company === "eztec") {
      userEnterprise.rating
        ? setShowModalEztec(false)
        : setShowModalEztec(true);
    }
    getTextItems().then((res) => {
      setAllTextItems(res);
    });

    getPdfDefaultItems().then((res) => {
      setAllPdfDefaultItems(res);
    });
  }, []);

  const sendLightbox = (data, idx, flipImage = false, caption = []) => {
    setInvertImageLightBox(flipImage);
    if (Array.isArray(data)) {
      setLightbox({
        ...lightbox,
        images: data,
        open: true,
        index: idx,
        caption,
      });
    } else {
      setLightbox({
        ...lightbox,
        images: [data],
        open: true,
        index: 0,
        caption,
      });
    }
  };

  function download(fileURL, fileName) {
    // for non-IE
    if (!window.ActiveXObject) {
      var save = document.createElement("a");
      save.href = fileURL;
      save.target = "_self";
      var filename = fileURL.substring(fileURL.lastIndexOf("/") + 1);
      save.download = fileName || filename;
      if (
        navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
        navigator.userAgent.search("Chrome") < 0
      ) {
        document.location = save.href;
        // window event not working here
      } else {
        var evt = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: false,
        });
        save.dispatchEvent(evt);
        (window.URL || window.webkitURL).revokeObjectURL(save.href);
      }
    }

    // for IE < 11
    else if (!!window.ActiveXObject && document.execCommand) {
      var _window = window.open(fileURL, "_blank");
      _window.document.close();
      _window.document.execCommand("SaveAs", true, fileName || fileURL);
      _window.close();
    }
  }

  const updateRating = () => {
    let body = {
      rating: rating,
      otherQuestions: otherQuestions,
    };
    updateUserRating({
      userEnterpriseId: userEnterprise._id,
      body,
    }).then((data) => {
      toast.success(data.sms, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setRating(rating);
    });
  };

  const checkRating = (projectId, finalProjectPdf) => {
    if (userEnterprise.enterprise.company === "eztec") {
      if (rating > 0) {
        download(
          BUCKET_URL +
            "/projects/" +
            projectId +
            "/lastVersion/" +
            finalProjectPdf,
          finalProjectPdf
        );
        setShowModalRating(false);
      } else {
        setProjectModalId(projectId);
        setPdfLinkModal(finalProjectPdf);
        checkRatingOnModal();
      }
    } else {
      download(
        BUCKET_URL +
          "/projects/" +
          projectId +
          "/lastVersion/" +
          finalProjectPdf,
        finalProjectPdf
      );
    }
  };

  const checkRatingOnModal = () => {
    rating === 0
      ? setShowModalRating(!showModalRating)
      : setShowModalRating(false);
  };

  return (
    <div>
      <ToastContainer />
      {roomNames.map((rn, idx) => {
        const project = acceptedRooms[rn];

        const approvedReview = project.finalProject.approvedProject;
        const furnitures = project.furnitures.length
          ? project.furnitures
          : approvedReview &&
            project.finalProject[approvedReview] &&
            project.finalProject[approvedReview].furnitures &&
            project.finalProject[approvedReview].furnitures.length
          ? project.finalProject[approvedReview].furnitures
          : [];

        const {
          workPlanLayout,
          finishingLayout,
          lights,
          coverageFloorPlans,
          details,
        } = project.render;

        let dataWorkPlanLayout = [];
        let workPlanLayoutLabels = [];
        let dataFinishingLayout = [];
        let finishingLabels = [];
        let dataLights = [];
        let lightLabels = [];
        let dataCoverageFloorPlans = [];
        let coverageFloorPlansLabels = [];
        let dataDetails = [];
        let detailsLabels = [];

        workPlanLayout.map((elem) => {
          dataWorkPlanLayout.push(elem.source);
          workPlanLayoutLabels.push(
            typeof elem.labels === "undefined" ? [] : elem.labels
          );
        });

        finishingLayout.map((elem) => {
          dataFinishingLayout.push(elem.source);
          finishingLabels.push(
            typeof elem.labels === "undefined" ? [] : elem.labels
          );
        });

        lights.map((elem) => {
          dataLights.push(elem.source);
          lightLabels.push(
            typeof elem.labels === "undefined" ? [] : elem.labels
          );
        });

        coverageFloorPlans.map((elem) => {
          dataCoverageFloorPlans.push(elem.source);
          coverageFloorPlansLabels.push(
            typeof elem.labels === "undefined" ? [] : elem.labels
          );
        });

        details.map((elem) => {
          dataDetails.push(elem.source);
          detailsLabels.push(
            typeof elem.labels === "undefined" ? [] : elem.labels
          );
        });

        const {
          showConsiderations,
          showDetails,
          showFinishingLayout,
          showGuide,
          showLights,
          showProductList,
          showCoverageFloorPlans,
          showWorkPlanLayout,
        } = enterprise;
        return (
          <div className="mt-3" key={idx}>
            {lightbox.open && (
              <ShowLightbox
                lightbox={lightbox}
                setLightbox={setLightbox}
                flipImage={invertimagelightbox}
              />
            )}
            <Modal
              className="centered-modal"
              centered={true}
              open={showModalEztec}
              toggle={() => {
                setShowModalEztec(false);
              }}
              size="lg"
            >
              <ModalHeader
                toggle={() => {
                  setShowModalEztec(false);
                }}
              />
              <ModalBody>
                Tudo certo, agora é só baixar os seus projetos clicando em
                <span style={{ color: "red" }}> “VER TUDO!”</span> no guia de
                decoração!
              </ModalBody>
            </Modal>
            <Modal
              className="centered-modal"
              centered={true}
              open={showModalRating}
              toggle={checkRatingOnModal}
              size="lg"
            >
              <ModalHeader toggle={checkRatingOnModal}>
                Avaliação de experiência
              </ModalHeader>
              <ModalBody>
                <div className=" d-flex flex-column justify-content-center align-items-center text-center">
                  <p className="m-0 ">
                    <u>
                      Avalie a sua experiência no EZ DECORA e baixe os seus
                      books gratuitamente...
                    </u>{" "}
                    <br /> <br />
                    Você acredita que esse serviço de decoração agregou valor na
                    sua experiência de compra com a EZTEC? (1: Não agregou
                    valor; 10: Agregou muito valor)
                    <br />
                    {[...Array(10).keys()].map((number, key) => {
                      let realNumber = number + 1;
                      return (
                        <FormRadio
                          inline
                          name="nps"
                          key={key}
                          checked={rating === realNumber}
                          onChange={() => {
                            setRating(realNumber);
                          }}
                        >
                          {realNumber}
                        </FormRadio>
                      );
                    })}
                  </p>
                  <p className="mt-3">
                    Qual a probabilidade de você indicar o EZ DECORA para outras
                    pessoas?
                    <br />
                    (1: Nem um pouco provável; 10: Extremamente provável) <br />
                    {[...Array(10).keys()].map((number, key) => {
                      let realNumber = number + 1;
                      return (
                        <FormRadio
                          inline
                          name="indicate"
                          key={key}
                          checked={otherQuestions.indicate === realNumber}
                          onChange={() => {
                            setOtherQuestions({
                              ...otherQuestions,
                              indicate: realNumber,
                            });
                          }}
                        >
                          {realNumber}
                        </FormRadio>
                      );
                    })}
                  </p>
                  <span>Comente sobre a sua experiência</span>
                  <FormTextarea
                    onChange={(value) => {
                      setOtherQuestions({
                        ...otherQuestions,
                        comment: value.target.value,
                      });
                    }}
                  />
                  <span className="mt-3">
                    Autoriza a EZTEC entrar em contato para obter
                    esclarecimentos adicionais sobre a sua experiência?{" "}
                  </span>
                  <div>
                    <FormRadio
                      inline
                      name="contact"
                      checked={otherQuestions.contact === "Sim"}
                      onChange={() => {
                        setOtherQuestions({
                          ...otherQuestions,
                          contact: "Sim",
                        });
                      }}
                    >
                      Sim
                    </FormRadio>
                    <FormRadio
                      inline
                      name="contact"
                      checked={otherQuestions.contact === "Não"}
                      onChange={() => {
                        setOtherQuestions({
                          ...otherQuestions,
                          contact: "Não",
                        });
                      }}
                    >
                      Não
                    </FormRadio>
                  </div>
                  <Button
                    disabled={
                      rating > 0 &&
                      otherQuestions.indicate > 0 &&
                      typeof otherQuestions.contact !== "undefined"
                        ? false
                        : true
                    }
                    theme="accent"
                    size="lg"
                    className="mt-1 "
                    onClick={() => {
                      updateRating();
                      checkRating(projectModalId, pdfLinkModal);
                    }}
                    style={{ cssFloat: "center" }}
                  >
                    {rating > 0 &&
                    otherQuestions.indicate > 0 &&
                    typeof otherQuestions.contact !== "undefined"
                      ? "Continuar o Download"
                      : "Você precisa avaliar primeiro"}
                  </Button>
                </div>
              </ModalBody>
            </Modal>
            {idx !== 0 && <hr />}
            <div className="question-title">
              <u>{rn}</u>
            </div>
            <div className="Final-project-card-mobile">
              <div className="mb-2" style={{ textAlign: "center" }}>
                <div
                  style={{ marginTop: "50px" }}
                  className={`${invertImage && "flipImage"}`}
                >
                  <Slider
                    {...(project.render.decorationPanel.length > 1
                      ? settings
                      : settingsForOne)}
                  >
                    {project.render.decorationPanel.map((file, idx) => {
                      return (
                        <div
                          key={idx}
                          className="d-flex justify-content-center align-items-center"
                        >
                          {project.render.decorationPanel.length > 1 ? (
                            <CustomImageToSlide
                              file={`${file.thumbnail || file.source}`}
                              onclick={() => {
                                sendLightbox(
                                  project.render.decorationPanel.map(
                                    (dp) => dp.source
                                  ),
                                  idx,
                                  invertImage
                                );
                              }}
                            />
                          ) : (
                            <div className="d-flex justify-content-center align-items-center carousel-container">
                              {/* eslint-disable-next-line */}
                              <img
                                alt=""
                                style={{ cursor: "pointer" }}
                                className="rounded img-fluid carousel-image"
                                src={file.thumbnail || file.source}
                                onClick={() =>
                                  sendLightbox(
                                    project.render.decorationPanel.map(
                                      (dp) => dp.source
                                    ),
                                    idx,
                                    invertImage
                                  )
                                }
                              />
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </Slider>
                </div>
                {showWorkPlanLayout && workPlanLayout.length > 0 && (
                  <ImagesDownloadProject
                    title="Planta de layout"
                    data={dataWorkPlanLayout}
                    labels={workPlanLayoutLabels}
                    settings={settings}
                    settingsForOne={settingsForOne}
                    sendLightbox={sendLightbox}
                  />
                )}
                {showFinishingLayout && finishingLayout.length > 0 && (
                  <ImagesDownloadProject
                    title="Planta de acabamentos"
                    data={dataFinishingLayout}
                    labels={finishingLabels}
                    settings={settings}
                    settingsForOne={settingsForOne}
                    sendLightbox={sendLightbox}
                  />
                )}
                {showLights && lights.length > 0 && (
                  <ImagesDownloadProject
                    title="Distribuição de luminárias"
                    data={dataLights}
                    labels={lightLabels}
                    settings={settings}
                    settingsForOne={settingsForOne}
                    sendLightbox={sendLightbox}
                  />
                )}
                {showCoverageFloorPlans && coverageFloorPlans.length > 0 && (
                  <ImagesDownloadProject
                    title="Planta baixa de cobertura"
                    data={dataCoverageFloorPlans}
                    labels={coverageFloorPlansLabels}
                    settings={settings}
                    settingsForOne={settingsForOne}
                    sendLightbox={sendLightbox}
                  />
                )}
                {showDetails && details.length > 0 && (
                  <ImagesDownloadProjectV2
                    title="Detalhamentos"
                    data={details}
                    labels={detailsLabels}
                    settings={settings}
                    settingsForOne={settingsForOne}
                    isDetailing={true}
                    sendLightbox={sendLightbox}
                  />
                )}
                {showConsiderations &&
                  allPdfDefaultItems.length > 0 &&
                  allTextItems.length > 0 && (
                    <>
                      <WoodWorker
                        project={project}
                        allPdfDefaultItems={allPdfDefaultItems}
                      />
                      <ContractorSettings
                        project={project}
                        allTextItems={allTextItems}
                      />
                    </>
                  )}
              </div>
              <br />
              <br />
              {showProductList && (
                <ProductList products={furnitures} hideIcon={true} />
              )}
              <br />
              <br />
              {showGuide && (
                <DecorationGuide
                  action={() => {
                    checkRating(
                      project._id,
                      project.finalProject.lastVersion.pdf
                    );
                  }}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}

FinalStep.propTypes = {};

export default FinalStep;
