import React, { useState, useEffect, useMemo } from "react";
import StepWizard from "react-step-wizard";
import { Row, Card, CardBody, Col } from "shards-react";
import PageTitle from "../../common/PageTitle";
import { getDefaultQuestion } from "../../../actions/question";

import { walkAtTree, getFlatData } from "../../Enterprise/questionary-service";
import { useHistory, useLocation } from "react-router-dom";
import StepChanger from "../../common/StepChanger";

const DefaultQuestions = (props) => {
  const history = useHistory();
  const [allForm, setAllForm] = useState([]);
  const [flatData, setFlatData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [answers, setAnswers] = useState([]);
  const [answer, setAnswer] = useState();
  const { hash } = useLocation();
  const [sync, setSync] = useState();

  useEffect(() => {
    if (sync) {
      nextStep();
    }
  }, [sync]);

  useEffect(() => {
    history.push("/environments-list#step1");
    getDefaultQuestion().then((question) => {
      let treeData = question.template;
      const fd = getFlatData(treeData);
      setFlatData(fd);
      walkAtTree(
        treeData,
        fd,
        {
          stats: StepChanger,
          flatData: fd,
          update: updateForm,
          answers: answers,
          answeredQuestions: [],
          ns: () => setSync({}),
        },
        setAllForm
      );
    });
  }, []);

  useEffect(() => {
    if (!answer) return;
    let form = answers.find((element) =>
      element.question === answer.question ? true : false
    );
    if (!form) {
      answers.push(answer);
    } else {
      answers.forEach((element) => {
        if (element.question === answer.question) {
          element.answer = answer.answer;
        }
      });
    }
    setAnswers([...answers]);
    setAnswer(null);
    if (answer.goToNextStep) nextStep();
  }, [answer]);

  const onSave = () => {
    setIsLoading(true);
    window.scrollTo(0, 0);
    props.updateDefaultQuestionsCompleted(answers);
  };

  function goToStep(i) {
    window.location.hash = `step${i}`;
  }

  const updateForm = (data) => {
    setAnswer(data);
  };

  function nextStep() {
    const stepN = parseInt(hash.replace("#step", "") - 1);
    if (flatData.length - 1 === stepN) {
      return onSave();
    }
    window.scrollTo(0, 0);
    const node = flatData[stepN];
    if (node.node.input) {
      if (node.node.input.action) {
        goToStep(parseInt(node.node.input.action) + 1);
      } else {
        let parentNode = flatData.find(
          (element) => element.treeIndex === node.path[0]
        );
        let nextStep = !parentNode.childCount
          ? node.path[0] + 2
          : parentNode.childCount + node.path[0] + 2;
        goToStep(nextStep);
      }
    } else {
      let parentNode = flatData.find(
        (element) => element.treeIndex === node.path[0]
      );
      //MUDADO APENAS O 2 para 1
      // let nextStep = !parentNode.childCount
      //   ? node.path[0] + 2
      //   : parentNode.childCount + node.path[0] + 2;
      let nextStep = !parentNode.childCount
        ? node.path[0] + 2
        : parentNode.childCount + node.path[0] + 2;
      goToStep(nextStep);
    }
  }

  function previousStep() {
    window.scrollTo(0, 0);
    answers.pop();
    history.goBack();
  }

  return (
    <React.Fragment>
      <Row noGutters className="page-header py-4">
        <PageTitle
          title={`Ambiente: Perguntas do perfil `}
          subtitle="Visão Geral"
          md="12"
          className="ml-sm-auto mr-sm-auto"
        />
        {/* <StepsForm activeStep={0} project={props.project} /> */}
      </Row>
      <Row>
        <Col>
          <Card className="mb-4">
            <CardBody className="wizard">
              {!!allForm.length && (
                <div className="mx-3 questionary">
                  <StepWizard isHashEnabled transitions>
                    {allForm}
                  </StepWizard>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <div className="d-flex flex-row align-items-center fixed-right-botton wizard-footer">
        <button
          type="button"
          className=" ml-4 btn btn-accent"
          onClick={previousStep}
          disabled={flatData && hash === `#step1`}
        >
          Voltar
        </button>
        <button
          type="button"
          className="ml-auto mr-4 btn btn-accent"
          onClick={nextStep}
          disabled={flatData && isLoading}
        >
          {flatData && (hash === `#step${flatData.length}` || isLoading)
            ? "Finalizar"
            : "Próximo"}
        </button>
      </div>
    </React.Fragment>
  );
};

export default DefaultQuestions;
