import React from "react";
import PropTypes from "prop-types";

import NotificationTitle from "./NotificationTitle";
import NotificationDescription from "./NotificationDescription";
import { Button } from "shards-react";

function NotificationItem({
  title,
  description,
  theme,
  dismissable,
  id,
  createdAt,
  bannerContent,
  onOpenBanner,
  onDismissNotification,
  style,
  className,
  projectId,
  onBannerClose,
  setShowNotificationPanel,
}) {
  function onDismissClick() {
    onDismissNotification(id);
  }

  function onOpenBannerClick() {
    onOpenBanner();
  }

  const clickOnSeeProject = () => {
    onBannerClose && onBannerClose();
    setShowNotificationPanel && setShowNotificationPanel(false);
    window.open(`/environments-list?show=${projectId}`, "_self");
    window.scrollTo(0, 0);
  };

  return (
    <div
      className={`mb-2 mt-2${className ? " " + className : ""}`}
      style={{
        boxShadow:
          "0 2px 0 rgba(90, 97, 105, 0.11), 0 4px 8px rgba(90, 97, 105, 0.12), 0 10px 10px rgba(90, 97, 105, 0.06), 0 7px 70px rgba(90, 97, 105, 0.1)",
        margin: "5px",
        borderBottomRightRadius: "5px",
        borderBottomLeftRadius: "5px",
        ...style,
      }}
    >
      <NotificationTitle theme={theme}>
        {title}
        {dismissable && (
          <span
            onKeyDown={() => {
              //TODO
            }}
            role="button"
            tabIndex="0"
            onClick={onDismissClick}
            className="material-icons ml-auto clickable"
          >
            close
          </span>
        )}
      </NotificationTitle>
      <p style={{ paddingLeft: "8px", paddingTop: "4px", margin: "0px" }}>
        {createdAt}
      </p>
      <NotificationDescription html={description} />
      {projectId && (
        <Button
          className="m-2"
          outline
          theme="accent"
          size="sm"
          onClick={clickOnSeeProject}
        >
          Verificar ambiente
        </Button>
      )}
      {bannerContent ? (
        /* eslint-disable-next-line */
        <p
          onClick={onOpenBannerClick}
          className="clickable"
          style={{
            paddingLeft: "8px",
            paddingRight: "8px",
            paddingBottom: "5px",
            margin: "0px",
            textAlign: "right",
          }}
        >
          Clique para ver mais
        </p>
      ) : null}
    </div>
  );
}

NotificationItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

NotificationItem.defaultProps = {
  style: {},
};

export default NotificationItem;
