export default function () {
  return [
    {
      title: "Banco de projetos",
      role: 1,
      items: [
        {
          title: "Empreendimentos",
          to: "/enterprises",
          htmlBefore: '<i class="material-icons">apartment</i>',
          htmlAfter: "",
        },
        {
          title: "Meus Ambientes",
          to: "/enterprises-answer",
          htmlBefore: '<i class="material-icons">&#xf88c</i>',
          htmlAfter: "",
        },
        {
          title: "FAQ",
          to: "/entreprises-faq",
          htmlBefore: '<i class="material-icons">&#xe8af;</i>',
          htmlAfter: "",
        },
        {
          title: "Modelo de entrega",
          to: "/exemple-enterprise",
          htmlBefore: '<i class="material-icons">&#xe66b;</i>',
          htmlAfter: "",
        },
      ],
    },
    {
      title: "Ambientes",
      role: 1,
      items: [
        {
          title: "Novo ambiente",
          to: "/environments",
          htmlBefore: '<i class="material-icons">&#xe03b;</i>',
          htmlAfter: "",
        },
        {
          title: "Meus ambientes",
          to: "/environments-list",
          htmlBefore: '<i class="material-icons">&#xf88c;</i>',
          htmlAfter: "",
        },
        // {
        //   title: "Meus ambientes 2",
        //   to: "/projects",
        //   htmlBefore: '<i class="material-icons">&#xf88c;</i>',
        //   htmlAfter: "",
        // },
        {
          title: "Histórico de pagamento",
          to: "/transaction-history",
          htmlBefore: '<i class="material-icons">&#xE889;</i>',
          htmlAfter: "",
        },
        {
          title: "FAQ",
          to: "/faq",
          htmlBefore: '<i class="material-icons">&#xe8af;</i>',
          htmlAfter: "",
        },
        {
          title: "Modelo de entrega",
          to: "/exemple",
          htmlBefore: '<i class="material-icons">&#xe66b;</i>',
          htmlAfter: "",
        },
      ],
    },
    {
      title: "Dúvidas",
      role: 1,
      items: [
        {
          title: "Central de Ajuda",
          to: "/support",
          htmlBefore: '<i class="material-icons">&#xf0e2;</i>',
          htmlAfter: "",
        },
      ],
    },
    {
      title: "Configurações",
      role: 1,
      items: [
        {
          title: "Perfil",
          to: "/profile",
          htmlBefore: '<i class="material-icons">manage_accounts</i>',
          htmlAfter: "",
        },
      ],
    },
    {
      role: 1,
      items: [
        {
          to: "",
          htmlBefore:
            '<a class="text-danger"><i class="material-icons text-danger">&#xE879;</i>Sair</a>',
          htmlAfter: "",
        },
      ],
    },
  ];
}
