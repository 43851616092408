import React, { useContext, useMemo } from "react";
import { Nav } from "shards-react";

import SidebarNavItem from "./SidebarNavItem";
import { Store } from "../../../flux";
import { signout } from "../../../actions/auth";

import { SystemContext } from "../../SystemConfig/SystemContext";
import { AuthContext } from "../../common/Authorization/AuthContext";

const SidebarNavItems = () => {
  const showEnterprises = ["hathy", "eztec", "comercial", "yesempreendimentos"];

  const { user } = useContext(AuthContext);
  const { company } = useContext(SystemContext);
  const [userName, items] = useMemo(() => {
    let navItems = user?._id
      ? Store.getSidebarItems()
      : Store.getSidebarOffItems();

    if (!showEnterprises.includes(company.label)) {
      navItems = navItems.filter((item) => {
        return item.title !== "Banco de projetos";
      });
    }

    navItems.forEach((item) => {
      if (company.label === "eztec") {
        if (item.title === "Banco de projetos") {
          item.title = "Decora meu ape";
          item.items.map((sub_menu) => {
            if (sub_menu.title === "Empreendimentos") {
              sub_menu.title = "Projeto gratuito";
            }
          });
        }
        if (item.title === "Projetos") {
          item.title = "Decora do meu jeito";
          item.items.map((sub_menu) => {
            if (sub_menu.title === "Novo projeto") {
              sub_menu.title = "Projeto personalizado";
            }
          });
        }
      }
    });

    const userName =
      user?._id && user?.profile?.firstName
        ? `${user.profile.firstName} ${user.profile.lastName || ""}`
        : "visitante";

    return [userName, navItems];
  }, [user]);

  return (
    <div className="nav-wrapper">
      <h6
        className="ml-4 mt-4"
        style={{
          color: getComputedStyle(document.documentElement).getPropertyValue(
            "--newbackground-color"
          ),
        }}
      >
        Olá, {userName}{" "}
      </h6>
      {items.map((nav, idx) =>
        nav.title ? (
          <div
            key={idx}
            style={{
              marginBottom:
                userName !== "visitante" && items.length - 2 === idx
                  ? "150px"
                  : "",
            }}
          >
            <h6 className="main-sidebar__nav-title">{nav.title}</h6>

            {typeof nav.items !== "undefined" && nav.items.length && (
              <Nav className="nav--no-borders flex-column">
                {nav.items.map((item, idx) => (
                  <SidebarNavItem key={idx} item={item} />
                ))}
              </Nav>
            )}
          </div>
        ) : (
          <div
            onKeyDown={() => {
              //TODO
            }}
            role="button"
            tabIndex="0"
            onClick={() => {
              signout().then(() => window.open(`/login`, "_self"));
            }}
            style={{ bottom: "0", position: "absolute", width: "100%" }}
            key={idx}
          >
            {typeof nav.items !== "undefined" && nav.items.length && (
              <Nav className="nav--no-borders flex-column">
                {nav.items.map((item, idx) => (
                  <SidebarNavItem key={idx} item={item} />
                ))}
              </Nav>
            )}
          </div>
        )
      )}
    </div>
  );
};

export default SidebarNavItems;
