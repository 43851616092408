import { API } from "../config";
import { requestWrapper } from "../utils/api";
import { getCookie } from "./auth";

export const updateUserAnswers = (userEnterpriseId, data) => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/user_enterprise/${userEnterpriseId}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(data),
    })
  );
};

export const reactivateProjectGroup = (userId, enterpriseId) => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(
      `${API}/user_enterprise/user/${userId}/enterprise/${enterpriseId}/reactivate`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "x-access-token": token,
        },
      }
    )
  );
};

export const getUserEnterprise = (body) => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/user_enterprise/user/enterprise`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(body),
    })
  );
};
export const updateUserRating = (body) => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/user_enterprise/user/rating`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(body),
    })
  );
};

export const getUserEnterpriseAnswer = (userId) => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/user_enterprise_answers/${userId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};
