import React, { useMemo } from "react";
import PropTypes, { InferProps } from "prop-types";
import Cards from "react-credit-cards";

const AnimatedCreditCardPropTypes = {
  watch: PropTypes.func.isRequired,
};

type AnimatedCreditCardTypes = InferProps<typeof AnimatedCreditCardPropTypes>;

const AnimatedCreditCard: React.FC<AnimatedCreditCardTypes> = ({ watch }) => {
  const cvc = watch("cvc") || "";
  const expiryMonth = watch("expiryMonth") || "";
  const expiryYear = watch("expiryYear") || "";
  const name = watch("name") || "";
  const number = watch("number") || "";
  const focus = watch("focus") || "";

  const expiry = useMemo<string>(() => {
    return `${expiryMonth}/${expiryYear}`;
  }, [expiryMonth, expiryYear]);

  return (
    <Cards
      cvc={cvc}
      expiry={expiry}
      focused={focus}
      name={name}
      number={number}
      locale={{ valid: "Validade" }}
      placeholders={{
        name: "Seu nome",
        expiry: "Validade",
      }}
    />
  );
};

AnimatedCreditCard.propTypes = AnimatedCreditCardPropTypes;

export default AnimatedCreditCard;
