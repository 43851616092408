import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Row, Col, Card, CardBody } from "shards-react";
import styles from "./Price.module.scss";

const Price = ({ company, priceIncrease }) => {
  console.log(company);
  let discount2 = 0.9;
  let discount3 = 0.85;
  if (company?.label === "leroymerlinprojeta") {
    discount2 = 0.92;
    discount3 = 0.88;
  }

  const history = useHistory();
  const getPrice = (qtd) => {
    let base = 299;
    let price = base + priceIncrease;
    let result;
    if (qtd === 1) {
      result = price;
    } else if (qtd === 2) {
      result = qtd * price * discount2;
    } else {
      result = price * qtd * discount3;
    }
    return result;
  };

  return (
    <>
      <h4>Escolha quantos ambientes vamos decorar</h4>
      <Row className={styles.isColLg}>
        <Col xs={12} sm={5} md={4} lg={3}>
          <div className={styles.cardWrapper}>
            <Card>
              <CardBody>
                <div
                  className="mb-5"
                  style={{
                    fontSize: "2rem",
                    color: "var(--newbackground-color)",
                  }}
                >
                  1 projeto
                </div>
                <ul>
                  <li style={{ color: "black", fontSize: "1.3rem" }}>
                    {(getPrice(1) * 1).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                    <br />
                    ou:{" "}
                  </li>
                  <li>
                    <strong>
                      10x{" "}
                      {(getPrice(1) / 10).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </strong>
                  </li>
                  <li className="mt-5" style={{ fontSize: "1.1rem" }}>
                    ✓ 100% online
                  </li>
                  <li style={{ fontSize: "1.1rem" }}>✓ Rápido e acessível</li>
                  {company?.lp?.pricing &&
                  company.lp.pricing["1"]?.extraItems?.length
                    ? company.lp.pricing["1"].extraItems.map((ei, i) => (
                        <li key={i} style={{ fontSize: "1.1rem" }}>
                          ✓ {ei}
                        </li>
                      ))
                    : null}
                </ul>
                <div className="mt-4">
                  <Button
                    theme="accent"
                    onClick={() => {
                      history.push(`/environments${history.location.search}`);
                    }}
                  >
                    Comprar
                  </Button>
                </div>
              </CardBody>
            </Card>
          </div>
        </Col>
        <Col xs={12} sm={5} md={4} lg={3}>
          <div className={styles.cardWrapper}>
            <Card>
              <CardBody>
                <div
                  className="mb-4"
                  style={{
                    fontSize: "2rem",
                    color: "var(--newbackground-color)",
                  }}
                >
                  2 projetos
                </div>
                <div
                  className="mb-4"
                  style={{ fontSize: "1.2rem", color: "black" }}
                >
                  *O mais escolhido
                </div>
                <ul>
                  <li style={{ color: "black", fontSize: "1.3rem" }}>
                    De{" "}
                    <s>
                      {(getPrice(1) * 2).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </s>{" "}
                    <br />
                    por:{" "}
                  </li>
                  <li>
                    <strong>
                      10x{" "}
                      {(getPrice(2) / 10).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </strong>
                  </li>

                  <li className="mt-5" style={{ fontSize: "1.1rem" }}>
                    ✓ 100% online
                  </li>
                  <li style={{ fontSize: "1.1rem" }}>✓ Rápido e acessível</li>
                  {company?.lp?.pricing &&
                  company.lp.pricing["2"]?.extraItems?.length
                    ? company.lp.pricing["2"].extraItems.map((ei, i) => (
                        <li key={i} style={{ fontSize: "1.1rem" }}>
                          ✓ {ei}
                        </li>
                      ))
                    : null}
                </ul>
                <div className="mt-4">
                  <Button
                    theme="accent"
                    onClick={() => {
                      history.push(`/environments${history.location.search}`);
                    }}
                  >
                    Comprar
                  </Button>
                </div>
              </CardBody>
            </Card>
          </div>
        </Col>
        <Col xs={12} sm={5} md={4} lg={3}>
          <div className={styles.cardWrapper}>
            <Card>
              <CardBody>
                <div
                  className="mb-5"
                  style={{
                    fontSize: "2rem",
                    color: "var(--newbackground-color)",
                  }}
                >
                  3 projetos
                </div>
                <ul>
                  <li style={{ color: "black", fontSize: "1.3rem" }}>
                    De{" "}
                    <s>
                      {(getPrice(1) * 3).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </s>{" "}
                    <br />
                    por:{" "}
                  </li>
                  <li>
                    <strong>
                      10x{" "}
                      {(getPrice(3) / 10).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </strong>
                  </li>
                  <li className="mt-5" style={{ fontSize: "1.1rem" }}>
                    ✓ 100% online
                  </li>
                  <li style={{ fontSize: "1.1rem" }}>✓ Rápido e acessível</li>
                  {company?.lp?.pricing &&
                  company.lp.pricing["3"]?.extraItems?.length
                    ? company.lp.pricing["3"].extraItems.map((ei, i) => (
                        <li key={i} style={{ fontSize: "1.1rem" }}>
                          ✓ {ei}
                        </li>
                      ))
                    : null}
                </ul>
                <div className="mt-4">
                  <Button
                    theme="accent"
                    onClick={() => {
                      history.push(`/environments${history.location.search}`);
                    }}
                  >
                    Comprar
                  </Button>
                </div>
              </CardBody>
            </Card>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Price;
