import React from "react";
import PropTypes from "prop-types";
import AspectRatioPicture from "../common/AspectRatioPicture";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function PictureItem({
  onClick,
  src,
  thumbnail,
  text,
  idx,
  isSelected,
  height,
  width,
  isLoading,
}) {
  return (
    <div className="ml-2 mr-2">
      <div
        onKeyDown={() => {
          //TODO
        }}
        role="button"
        tabIndex="0"
        className={`mb-3 clickable hover-highlight picture-item ${
          isSelected ? "picture-item--selected" : ""
        }`}
        onClick={() => onClick(idx)}
      >
        <div className="p-2">
          {isLoading ? (
            <Skeleton style={{ width: "150px", height: "150px" }} />
          ) : (
            <AspectRatioPicture
              height={height ? height : "150px"}
              width={width ? width : "150px"}
              src={src}
              thumbnail={thumbnail || src}
              name={src}
              title={""}
              isLoading={false}
              isUploading={false}
              isDeletable={false}
              fullScreenView={false}
            />
          )}
        </div>
        <hr className="m-0" />
        <div
          className="text-center py-2 picture-item-bottom-text"
          style={{ width }}
        >
          {text}
        </div>
      </div>
    </div>
  );
}

PictureItem.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  src: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  thumbnail: PropTypes.string,
  idx: PropTypes.number,
  onClick: PropTypes.func,
  text: PropTypes.string,
  isLoading: PropTypes.bool,
};

PictureItem.defaultProps = {
  isLoading: false,
  isSelected: false,
  text: "",
  idx: 0,
};

export default PictureItem;
